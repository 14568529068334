import { Message } from '../interfaces';
import createActionCreator from '../../../../../Helpers/createActionCreator';

export interface IFetchNotificationsPayload {
	fetched: number;
}

export interface IFetchNotificationsSuccessPayload {
	notifications: Message[];
}
export interface IReceivedNewNotification {
	notification: Message;
}
export interface IMarkReadPayload {
	notificationId: string;
}
export interface IUnreadPayload {
	notificationId: string;
}

export const fetchNotifications = createActionCreator(
	'NOTIFICATION/FETCH',
	{} as IFetchNotificationsPayload
);

export const fetchNotificationsSuccess = createActionCreator(
	'NOTIFICATION/FETCH_SUCCESS',
	{
		notifications: [],
	} as IFetchNotificationsSuccessPayload
);

export const receivedNotification = createActionCreator(
	'NOTIFICATION/RECEIVED_NEW',
	{} as IReceivedNewNotification
);

export const markRead = createActionCreator(
	'NOTIFICATION/READ',
	{} as IMarkReadPayload
);

export const unread = createActionCreator(
	'NOTIFICATION/READ',
	{} as IUnreadPayload
);

export const markAllRead = createActionCreator('NOTIFICATION/READ_ALL');

const { NODE_ENV = 'development', REACT_APP_ENV = 'development' } = process.env;

const wsURL =
	REACT_APP_ENV === 'production' || NODE_ENV === 'production'
		? 'wss://tms-dashboard.hkjctree.com'
		: 'ws://localhost:4000';

const uploadURI =
	REACT_APP_ENV === 'production' || NODE_ENV === 'production'
		? 'https://tms-dashboard.hkjctree.com/upload'
		: 'http://localhost:4000/upload';

const portal_url =
	REACT_APP_ENV === 'production' || NODE_ENV === 'production'
		? 'https://tms-auth.hkjctree.com'
		: 'http://localhost:3001';

const {
	REACT_APP_AUTH_PORTAL = `${portal_url}/authorize`,
	REACT_APP_AUTH_PORTA_LOGOUT = `${portal_url}/sign_out`,
	REACT_APP_UPLOAD_RECORDS_URI = uploadURI,
	REACT_APP_DASHBOARD_WS_URL = wsURL,
	SERVER_SIDE,
	REACT_APP_APP_ID = '5f1e81d644991d6245c2aa21',
} = process.env;

export default {
	AUTH_PORTAL: REACT_APP_AUTH_PORTAL,
	AUTH_PORTA_LOGOUT: REACT_APP_AUTH_PORTA_LOGOUT,
	DASHBOARD_WS_URL: REACT_APP_DASHBOARD_WS_URL,
	UPLOAD_RECORDS_URI: REACT_APP_UPLOAD_RECORDS_URI,
	APP_ID: REACT_APP_APP_ID,
	SERVER_SIDE,
	NODE_ENV,
};

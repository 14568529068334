import styled from 'styled-components';
import { View } from '../../View';

export const PopoverWrapper = styled(View)`
	position: absolute;
	transition: opacity 150ms, transform 150ms;
	z-index: 1000;
	&:focus {
		outline: none;
	}
`;

export const PopoverBackdrop = styled(View)`
	position: absolute;
	background: transparent;
	width: 100%;
	height: 100%;
	z-index: 1000;
`;

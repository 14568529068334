import OlSelect, { Options, SelectEvent } from 'ol/interaction/Select';
import { useMemo, useContext, useEffect, MutableRefObject } from 'react';
import context from '../context';
import { Feature } from 'ol';

interface Props {
	selectRef?: MutableRefObject<OlSelect | null>;
	selectOptions?: Options;
	onSelect: (feature: Feature[]) => any;
}

export default function Select(props: Props) {
	const { selectOptions, onSelect, selectRef } = props;

	const { map } = useContext(context);

	const select = useMemo(() => new OlSelect(selectOptions), [selectOptions]);

	useEffect(() => {
		if (selectRef) selectRef.current = select;

		map.addInteraction(select);
		return () => {
			map.removeInteraction(select);
		};
	}, [select, map, selectRef]);

	useEffect(() => {
		function handleFeatureSelect(e: SelectEvent) {
			const { selected } = e;
			onSelect(selected);

			return true;
		}

		function handleBlur() {
			const selected = select.getFeatures();
			if (selected.getLength() === 0) onSelect([]);
		}

		map.on('click', handleBlur);
		select.on('select', handleFeatureSelect);
		return () => {
			map.un('click', handleBlur);
			select.un('select', handleFeatureSelect);
		};
	});

	return null;
}

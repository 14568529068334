import React from 'react';
import styled from 'styled-components';
import { View } from './View';

const StyledImage = styled.img`
	display: flex;
`;

const Placeholder = styled(View)`
	background-color: ${({ theme: { background } }) => background.grey};
`;

interface Props {
	src: string | null;
}

export function Image(props: Props) {
	const { src, ...rest } = props;

	return src ? (
		<StyledImage src={src} {...rest} />
	) : (
		<Placeholder {...rest} />
	);
}

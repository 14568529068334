import React, {
	useContext,
	useMemo,
	useCallback,
	useRef,
	RefObject,
} from 'react';
import { createPortal } from 'react-dom';
import {
	IndexCellResizeHandle as SIndexCellResizeHandle,
	IndexCellResizeHandleShadow as SIndexCellResizeHandleShadow,
} from './IndexCellResizeHandle.sc';
import useRowResize from '../Hooks/useRowResize';
import context from '../../../context';

interface IndexCellResizeHandleShadowProps {
	tableRef: RefObject<HTMLDivElement>;
	resizing: boolean;
	top: number;
}
function IndexCellResizeHandleShadow(props: IndexCellResizeHandleShadowProps) {
	const { tableRef, resizing, top } = props;
	return useMemo(() => {
		const table = tableRef.current;
		if (!resizing || !table) return null;
		return createPortal(
			<SIndexCellResizeHandleShadow style={{ top }} />,
			table
		);
	}, [tableRef, resizing, top]);
}

interface IndexCellResizeHandleProps {
	indexCellRef: RefObject<HTMLDivElement>;
	resizing: boolean;
	recordId: string;
}
function IndexCellResizeHandle(props: IndexCellResizeHandleProps) {
	const { indexCellRef, resizing, recordId } = props;
	const contextObj = useContext(context);
	const { tableRef } = contextObj;
	const { height, startRowResize } = useRowResize(contextObj, recordId);
	const indexCellTopRef = useRef(0);

	const handleMouseDown = useCallback(() => {
		const table = tableRef.current;
		const indexCell = indexCellRef.current;
		if (table && indexCell)
			indexCellTopRef.current =
				indexCell.getBoundingClientRect().top -
				table.getBoundingClientRect().top;
		startRowResize();
	}, [tableRef, indexCellRef, startRowResize]);

	return useMemo(() => {
		const top = indexCellTopRef.current + height;
		return (
			<>
				<SIndexCellResizeHandle onMouseDown={handleMouseDown} />
				<IndexCellResizeHandleShadow
					tableRef={tableRef}
					resizing={resizing}
					top={top}
				/>
			</>
		);
	}, [tableRef, resizing, height, handleMouseDown]);
}

export default IndexCellResizeHandle;

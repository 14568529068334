import { useContext, useCallback } from 'react';
import windowContext from '../context';
import { StandardResizeHandleProps } from '../../../interfaces';

export default function useStandardResizeHandle(
	props: StandardResizeHandleProps
) {
	const {
		onResizeInit,
		onResizeStart,
		onResize,
		onResizeEnd,
		onResizeCancel,
	} = useContext(windowContext);
	const { direction, modifyKey = null, threshold = 0, timeout = 0 } = props;
	return useCallback(
		(e: React.MouseEvent) => {
			let accumX = 0,
				accumY = 0;
			let timer: number;
			let started = false;

			function setupMoveResponder() {
				timer = setTimeout(() => {
					initMove();
				}, timeout);
				window.addEventListener('mouseup', cancelMove);
				window.addEventListener('keyup', cancelMoveByKey);
			}

			function initMove() {
				onResizeInit({
					direction,
					movementX: 0,
					movementY: 0,
					accumX,
					accumY,
				});
				window.addEventListener('mousemove', prepareMove);
			}

			function prepareMove(e: MouseEvent) {
				const { movementX, movementY } = e;
				accumX += movementX;
				accumY += movementY;

				if (modifyKey && !e[modifyKey]) {
					cancelMove();
				}

				const distance = (accumX ** 2 + accumY ** 2) ** 0.5;
				if (distance > threshold) {
					started = true;
					onResizeStart({
						direction,
						movementX,
						movementY,
						accumX,
						accumY,
						e,
					});
					setupMove();
				}
			}

			function setupMove() {
				window.addEventListener('mousemove', move);
				window.removeEventListener('mousemove', prepareMove);
			}

			function move(e: MouseEvent) {
				const { movementX, movementY } = e;
				onResize({
					direction,
					movementX,
					movementY,
					accumX,
					accumY,
					e,
				});
			}

			function cancelMove() {
				if (started) {
					onResizeEnd({
						direction,
						movementX: 0,
						movementY: 0,
						accumX,
						accumY,
					});
				} else {
					onResizeCancel({
						direction,
						movementX: 0,
						movementY: 0,
						accumX,
						accumY,
					});
				}

				clearTimeout(timer);
				window.removeEventListener('mousemove', prepareMove);
				window.removeEventListener('mousemove', move);
				window.removeEventListener('mouseup', cancelMove);
				window.removeEventListener('keyup', cancelMoveByKey);
			}

			function cancelMoveByKey(e: KeyboardEvent) {
				if (started) {
					onResizeEnd({
						direction,
						movementX: 0,
						movementY: 0,
						accumX,
						accumY,
					});
				} else {
					onResizeCancel({
						direction,
						movementX: 0,
						movementY: 0,
						accumX,
						accumY,
					});
				}

				clearTimeout(timer);
				window.removeEventListener('mousemove', prepareMove);
				window.removeEventListener('mousemove', move);
				window.removeEventListener('mouseup', cancelMove);
				window.removeEventListener('keyup', cancelMoveByKey);
			}

			if (modifyKey === null || e[modifyKey]) {
				setupMoveResponder();
			}
		},
		[
			direction,
			onResizeInit,
			onResizeStart,
			onResize,
			onResizeEnd,
			onResizeCancel,
			threshold,
			timeout,
			modifyKey,
		]
	);
}

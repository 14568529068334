import React, {
	useCallback,
	useMemo,
	ChangeEvent as RChangeEvent,
	KeyboardEvent as RKeyboardEvent,
} from 'react';
import { ExportRecordModalNumberInput } from './ExportRecordModalRangeInput.sc';
import { ModalText } from './ExportRecordModal.sc';

interface ExportRecordModalRangeInputProps {
	disabled: boolean;
	from: number;
	to: number;
	setFrom(from: number): void;
	setTo(to: number): void;
}

function ExportRecordModalRangeInput(props: ExportRecordModalRangeInputProps) {
	const { from, to, setFrom, setTo, disabled = false } = props;

	const handleKeyDown = useCallback((e: RKeyboardEvent<HTMLInputElement>) => {
		if (!e.ctrlKey && /^[^0-9]$/i.test(e.key)) e.preventDefault();
	}, []);

	const handleFromInputChange = useCallback(
		(e: RChangeEvent<HTMLInputElement>) => {
			setFrom(parseInt(e.target.value) || 0);
		},
		[setFrom]
	);

	const handleFromInputBlur = useCallback(() => {
		setFrom(Math.max(from, 1));
	}, [from, setFrom]);

	const handleToInputChange = useCallback(
		(e: RChangeEvent<HTMLInputElement>) => {
			setTo(parseInt(e.target.value) || 0);
		},
		[setTo]
	);

	const handleToInputBlur = useCallback(() => {
		setTo(Math.max(to, 1));
	}, [to, setTo]);

	return useMemo(() => {
		return (
			<>
				<ModalText>From</ModalText>
				<ExportRecordModalNumberInput
					tabIndex={0}
					value={from}
					disabled={disabled}
					onKeyDown={handleKeyDown}
					onChange={handleFromInputChange}
					onBlur={handleFromInputBlur}
				/>
				<ModalText>To</ModalText>
				<ExportRecordModalNumberInput
					tabIndex={0}
					value={to}
					disabled={disabled}
					onKeyDown={handleKeyDown}
					onChange={handleToInputChange}
					onBlur={handleToInputBlur}
				/>
			</>
		);
	}, [
		from,
		to,
		disabled,
		handleKeyDown,
		handleFromInputChange,
		handleFromInputBlur,
		handleToInputChange,
		handleToInputBlur,
	]);
}

export default ExportRecordModalRangeInput;

import { makeStyles } from '@material-ui/core/styles';

export const useToolbarStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.secondary.dark,
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		flex: '0 0 auto',
	},
	activateButton: ({ record }: any) => ({
		color: record.active
			? theme.palette.primary.main
			: theme.palette.error.main,
	}),
	iconButton: {
		color: theme.palette.getContrastText(theme.palette.secondary.dark),
	},
}));

export const useItemStyles = makeStyles((theme) => ({
	root: {
		overflow: 'hidden',
		display: 'flex',
		alignItems: 'stretch',
		flexDirection: 'column',
		height: 480 - theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	},
	title: {
		flexGrow: 1,
		color: theme.palette.getContrastText(theme.palette.secondary.dark),
	},
	field: {
		color: theme.palette.primary.main,
	},
	value: {
		color: theme.palette.getContrastText(theme.palette.secondary.dark),
	},
	tableContainer: {
		flex: 1,
	},
	table: {},
	tableBody: {},
	tableRow: {
		maxHeight: theme.spacing(3),
	},
	valueCell: {
		minWidth: 300,
		// backgroundColor: ({ editing }: any) =>
		// 	editing ? 'transparent' : theme.palette.background.paper,
	},
	textField: {
		flexGrow: 1,
		// marginRight: theme.spacing(0.5),
	},
	input: {
		color: theme.palette.getContrastText(theme.palette.secondary.dark),
		fontSize: 12,
	},
	inputLabel: {
		color: theme.palette.getContrastText(theme.palette.secondary.dark),
	},
	inputUnderline: {
		color: theme.palette.getContrastText(theme.palette.secondary.dark),
	},
	chipList: {
		display: 'flex',
		flexWrap: 'wrap',
		listStyle: 'none',
		padding: theme.spacing(0.5),
		margin: 0,
	},
	chip: {
		margin: theme.spacing(0.5),
	},
	select: {
		color: theme.palette.getContrastText(theme.palette.secondary.dark),
	},
	button: {
		height: 56,
	},
}));

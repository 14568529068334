import { useContext, useCallback } from 'react';
import notificationContext from '../context';

export default function useNotificationStore() {
	const { loading, notificationIds, onMarkAllNotificationRead } = useContext(
		notificationContext
	);

	const getItemKey = useCallback(
		(index: number) => notificationIds.toList().get(index, ''),
		[notificationIds]
	);

	const handleMarkAllRead = useCallback(() => {
		onMarkAllNotificationRead && onMarkAllNotificationRead();
	}, [onMarkAllNotificationRead]);

	return {
		loading,
		count: notificationIds.size,
		notificationIds,
		getItemKey,
		handleMarkAllRead,
	};
}

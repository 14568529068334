import { createContext } from 'react';
import { OrderedSet, Map } from 'immutable';
import { IRecordsFeedGridContext } from './interfaces';
import { noop } from '@babel/types';

export const context = createContext<IRecordsFeedGridContext>({
	width: 0,
	height: 0,
	columnCount: 0,
	addButtonText: 'Add',
	containerRef: { current: null },
	search: '',
	itemHeight: 0,
	searchPlaceholder: '',
	records: Map(),
	recordIds: OrderedSet(),
	exhausted: false,
	loading: false,
	updating: Map(),
	// onRequestDispatch: noop,
	onSearchTextChange: noop,
	onEndReach: noop,
	onAdd: noop,
	children: () => null,
});

import createReducer from '../../../../../Helpers/createReducer';
import { Record, List, Map, OrderedSet } from 'immutable';
import {
	fetchFeatures,
	updateFeatureCount,
	importFeatures,
	updateZoom,
	updateRotation,
	updateCenter,
	updateSearchText,
	updateSearchResults,
	focusSearchResult,
	selectFeature,
	addFilter,
	removeFilter,
	importFilterFields,
	FeatureWithId,
	importTreeDetails,
} from './actions';
import { Dict } from '../../../../../../types/Common';
import { FilterField, TreeMapStore } from '../interfaces';

export const FilterFieldRecord = Record<FilterField>({
	name: '',
	value: {
		type: 'number',
		fieldname: '',
	},
});

export const FeatureRecord = Record<GeoJSON.Feature>({
	type: 'Feature',
	geometry: {
		type: 'Point',
		coordinates: [0, 0],
	},
	properties: {},
});

export const TreeMapStoreRecord = Record<TreeMapStore>({
	search: '',
	searchResults: List(),
	focusedResult: null,
	filters: Map(),
	filterNames: Map(),
	filterFields: List(),
	center: [12707975.92093638, 2545840.017349782],
	zoom: 12,
	rotation: 0,
	featureIds: OrderedSet(),
	features: Map(),
	count: 0,
	selected: null,
	treeDetails: null,
});

const initialState = TreeMapStoreRecord();
export const mapReducer = createReducer(
	initialState,
	{
		fetchFeatures,
		updateFeatureCount,
		importFeatures,
		updateCenter,
		updateZoom,
		updateRotation,
		updateSearchText,
		updateSearchResults,
		focusSearchResult,
		selectFeature,
		addFilter,
		removeFilter,
		importFilterFields,
		importTreeDetails,
	},
	{
		fetchFeatures: (state) => state,
		updateFeatureCount: (state, { payload: { count } }) =>
			state.set('count', count),
		importFeatures: (state, { payload: { features } }) =>
			state.withMutations((s) => {
				s.update('featureIds', (featureIds) =>
					featureIds.concat(
						features.map((feature) => feature.properties._id)
					)
				);
				s.update('features', (featureDict) =>
					featureDict.merge(
						features.reduce(
							(r: Dict<FeatureWithId>, feature) => ({
								...r,
								[feature.properties._id]: feature,
							}),
							{}
						)
					)
				);
			}),
		updateCenter: (state, { payload: { center } }) =>
			state.set('center', center),
		updateZoom: (state, { payload: { zoom } }) => state.set('zoom', zoom),
		updateRotation: (state, { payload: { rotation } }) =>
			state.set('rotation', rotation),
		updateSearchText: (state, { payload: { searchText } }) =>
			state.set('search', searchText),
		updateSearchResults: (state, { payload: { searchResults } }) =>
			state
				.set('searchResults', List(searchResults))
				.set('focusedResult', 0),
		focusSearchResult: (state, { payload: { index } }) =>
			state.withMutations((s) => {
				s.set('focusedResult', index);
				// const feature = s.searchResults.get(index);
				// if (feature) {
				// 	s.set('center', feature.geometry.coordinates);
				// 	s.set('zoom', 18);
				// }
			}),
		selectFeature: (state, { payload: { feature } }) =>
			state.withMutations((s) => {
				s.set('selected', feature);
				if (!feature) s.set('treeDetails', null);
			}),
		addFilter: (state, { payload: { filterFormula, filterName, key } }) =>
			state.withMutations((s) => {
				s.setIn(['filters', key], filterFormula);
				s.setIn(['filterNames', key], filterName);
				s.set('selected', null);
				s.set('featureIds', OrderedSet());
				s.set('features', Map());
			}),
		removeFilter: (state, { payload: { key } }) =>
			state.withMutations((s) => {
				s.deleteIn(['filters', key]);
				s.deleteIn(['filterNames', key]);
				s.set('selected', null);
				s.set('featureIds', OrderedSet());
				s.set('features', Map());
			}),
		importFilterFields: (state, { payload: { fields } }) =>
			state.set(
				'filterFields',
				List(fields.map((field) => FilterFieldRecord(field)))
			),
		importTreeDetails: (
			state,
			{ payload: { tree, sensors, tiltingAngleData } }
		) =>
			state.set('treeDetails', {
				tree,
				sensors,
				tiltingAngleData,
			}),
	}
);

export default mapReducer;

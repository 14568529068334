import styled from 'styled-components';
import { View } from '../../../../Common/View';

export interface HeaderCellWrapperProps {
	rearranging: boolean;
}

export const HeaderCellWrapper = styled(View)<HeaderCellWrapperProps>`
	position: absolute;
	overflow: visible;
	flex-direction: column;
	align-items: stretch;
	user-select: none;
	box-sizing: border-box;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	border-left: 1px solid rgba(0, 0, 0, 0.1);

	color: ${({
		theme: {
			font: {
				color: { lightBgSecondary },
			},
		},
	}) => lightBgSecondary};
	${({ rearranging, theme: { shadow } }) => (rearranging ? shadow[4] : '')}

	z-index: ${({ rearranging }) => (rearranging ? 1000 : 'auto')};
`;

import styled from 'styled-components';
import { View } from '../../../../Common/View';
import { BodyText2 } from '../../../../Common/Text';
import WrappedInput from '../../../../Common/WrappedInput';
import IconTextButton from '../../../../Common/IconTextButton';
import Radiobox from '../../../../Common/Radiobox';

export const ExportRecordModalContentWrapper = styled(View)`
	width: 300px;
	border-radius: 4px;
	overflow: hidden;
	padding: 16px;
	background: ${({
		theme: {
			background: { light },
		},
	}) => light};
`;

export const ModalRowWrapper = styled(View)`
	flex-direction: row;
`;

export const ModalFirstRowWrapper = styled(ModalRowWrapper)`
	height: 32px;
`;

export const ModalSecondRowWrapper = styled(ModalRowWrapper)`
	margin-top: 8px;
	height: 64px;
`;

export const ModalThirdRowWrapper = styled(ModalRowWrapper)`
	height: 32px;
	margin-top: 16px;
	justify-content: right;
`;

export const ModalColumnWrapper = styled(View)``;

export const ExportRecordModalInput = styled(WrappedInput)`
	justify-content: center;
	& > [x-component='input'] {
		box-sizing: border-box;
		border-bottom: 2px solid
			${({
				theme: {
					background: { darker },
				},
			}) => darker};
		padding: 2px;
	}
`;

export const ModalRadiobox = styled(Radiobox).attrs({
	iconProps: { size: 0.7 },
})`
	height: 32px;
	width: 32px;
`;

export const ModalText = styled(BodyText2)`
	height: 32px;
	line-height: 32px;
	margin-left: 6px;
	margin-right: 6px;
	white-space: nowrap;
	color: ${({
		theme: {
			font: {
				color: { lightBgSecondary },
			},
		},
	}) => lightBgSecondary};
`;

export const ModalExportButton = styled(IconTextButton).attrs(
	({
		theme: {
			font: {
				color: { darkBgPrimary, lightBgSecondary },
			},
		},
		disabled,
	}) => ({
		iconProps: {
			size: 0.7,
			color: disabled ? lightBgSecondary : darkBgPrimary,
		},
	})
)`
	height: 32px;
	margin: 4px;
	background-color: ${({
		theme: {
			background: { accent },
		},
	}) => accent};
	color: ${({
		theme: {
			font: {
				color: { darkBgSecondary },
			},
		},
	}) => darkBgSecondary};
	border-radius: 4px;
	& > [x-component='overlay'] {
		border-radius: 4px;
	}
	&[x-mode~='disabled'] {
		background-color: ${({
			theme: {
				background: { darker },
			},
		}) => darker};
		color: ${({
			theme: {
				font: {
					color: { lightBgSecondary },
				},
			},
		}) => lightBgSecondary};
	}
`;

export const ModalResetButton = styled(IconTextButton).attrs({
	iconProps: { size: 0.7 },
})`
	height: 32px;
	margin: 4px;
	border-radius: 4px;
	& > [x-component='overlay'] {
		border-radius: 4px;
	}
`;

import React, {
	useCallback,
	useMemo,
	forwardRef,
	Ref,
	MouseEvent as RMouseEvent,
	FocusEvent as RFocusEvent,
	KeyboardEvent as RKeyboardEvent,
} from 'react';
import { ButtonOverlay, ButtonWrapper } from './Button.sc';
import useForwardedRef from '../../../../Hooks/useForwardedRef';
import useObject from '../../../../Hooks/useObject';
import useHover from '../../../../Hooks/useHover';
import useFocus from '../../../../Hooks/useFocus';
import usePress from '../../../../Hooks/usePress';
import { ButtonOverlayShape, ButtonProps } from '../interfaces';

function getXMode(
	hovered: boolean,
	focused: boolean,
	pressed: boolean,
	activated: boolean,
	disabled: boolean
) {
	let xMode = '';
	if (hovered) xMode += ' hovered';
	if (focused) xMode += ' focused';
	if (pressed) xMode += ' pressed';
	if (activated) xMode += ' activated';
	if (disabled) xMode += ' disabled';
	return xMode;
}

const Button = forwardRef(function(
	props: ButtonProps,
	ref: Ref<HTMLDivElement>
) {
	const {
		tabIndex = -1,
		lightBg = true,
		hovered,
		focused,
		pressed,
		activated = false,
		disabled = false,
		focusOnMouseDown = false,
		overlayShape = ButtonOverlayShape.rectangle,
		onMouseEnter,
		onMouseLeave,
		onMouseDown,
		onClick,
		onKeyDown,
		onFocus,
		onBlur,
		onPress,
		render: ButtonContent,
		children,
		...restProps
	} = props;

	const _ref = useForwardedRef(ref);
	const _restProps = useObject(restProps);
	const {
		hovered: _hovered,
		handleMouseEnter,
		handleMouseLeave,
	} = useHover();
	const _handleMouseEnter = useCallback(
		(e: RMouseEvent<HTMLDivElement>) => {
			onMouseEnter && onMouseEnter(e);
			handleMouseEnter();
		},
		[onMouseEnter, handleMouseEnter]
	);
	const _handleMouseLeave = useCallback(
		(e: RMouseEvent<HTMLDivElement>) => {
			onMouseLeave && onMouseLeave(e);
			handleMouseLeave();
		},
		[onMouseLeave, handleMouseLeave]
	);
	const __hovered = hovered !== undefined ? hovered : _hovered;

	const { focused: _focused, handleFocus, handleBlur } = useFocus();
	const _handleFocus = useCallback(
		(e: RFocusEvent<HTMLDivElement>) => {
			onFocus && onFocus(e);
			handleFocus();
		},
		[onFocus, handleFocus]
	);
	const _handleBlur = useCallback(
		(e: RFocusEvent<HTMLDivElement>) => {
			onBlur && onBlur(e);
			handleBlur();
		},
		[onBlur, handleBlur]
	);

	const __focused = focused !== undefined ? focused : _focused;

	const { pressed: _pressed, handleMouseDown } = usePress();
	const _handleMouseDown = useCallback(
		(e: RMouseEvent<HTMLDivElement>) => {
			onMouseDown && onMouseDown(e);
			if (!focusOnMouseDown) e.preventDefault();
			handleMouseDown();
		},
		[onMouseDown, focusOnMouseDown, handleMouseDown]
	);
	const __pressed = pressed !== undefined ? pressed : _pressed;

	const handleClick = useCallback(
		(e: RMouseEvent<HTMLDivElement>) => {
			onClick && onClick(e);
			onPress && onPress(e);
		},
		[onClick, onPress]
	);

	const handleKeyDown = useCallback(
		(e: RKeyboardEvent<HTMLDivElement>) => {
			onKeyDown && onKeyDown(e);
			if (e.key === 'Enter') {
				onPress && onPress(e);
			}
		},
		[onKeyDown, onPress]
	);

	const buttonOverlay = useMemo(() => {
		if (overlayShape === ButtonOverlayShape.none) return null;
		return (
			<ButtonOverlay
				lightBg={lightBg}
				hovered={__hovered}
				focused={__focused}
				pressed={__pressed}
				activated={activated}
				disabled={disabled}
				overlayShape={overlayShape}
				x-component="overlay"
			/>
		);
	}, [
		lightBg,
		__hovered,
		__focused,
		__pressed,
		activated,
		disabled,
		overlayShape,
	]);

	const buttonContent = ButtonContent ? (
		<ButtonContent
			{...props}
			hovered={__hovered}
			focused={__focused}
			pressed={__pressed}
			activated={activated}
			disabled={disabled}
		/>
	) : null;

	return useMemo(() => {
		const xMode = getXMode(
			__hovered,
			__focused,
			__pressed,
			activated,
			disabled
		);
		const _tabIndex = disabled ? -1 : tabIndex;
		return (
			<ButtonWrapper
				ref={_ref}
				tabIndex={_tabIndex}
				disabled={disabled}
				onMouseEnter={_handleMouseEnter}
				onMouseLeave={_handleMouseLeave}
				onMouseDown={_handleMouseDown}
				onClick={handleClick}
				onKeyDown={handleKeyDown}
				onFocus={_handleFocus}
				onBlur={_handleBlur}
				x-mode={xMode}
				{..._restProps}
			>
				{buttonOverlay}
				{buttonContent}
				{children}
			</ButtonWrapper>
		);
	}, [
		_ref,
		tabIndex,
		__hovered,
		__focused,
		__pressed,
		activated,
		disabled,
		_handleMouseEnter,
		_handleMouseLeave,
		_handleMouseDown,
		handleClick,
		handleKeyDown,
		_handleFocus,
		_handleBlur,
		_restProps,
		buttonOverlay,
		buttonContent,
		children,
	]);
});

export default Button;

import createReducer from '../../Helpers/createReducer';
import { Record } from 'immutable';
import { IWeatherPanelStore } from './interfaces';
import { fetchWeatherInfo, importWeatherInfo } from './actions';

export const WeatherPanelStore = Record<IWeatherPanelStore>({
	loading: false,
	warnings: {
		WFIREY: false,
		WFIRER: false,
		WRAINA: false,
		WRAINR: false,
		WRAINB: false,
		TC1: false,
		TC3: false,
		WTCPRE8: false,
		TC8NE: false,
		TC8SE: false,
		TC8SW: false,
		TC8NW: false,
		TC9: false,
		TC10: false,
		WFROST: false,
		WHOT: false,
		WCOLD: false,
		WMSGNL: false,
		WFNTSA: false,
		WL: false,
		WTMW: false,
		WTS: false,
	},
	weather: {
		humidity: { data: [], recordTime: new Date() },
		lightning: { data: [], endTime: new Date(), startTime: new Date() },
		rainfall: { data: [], endTime: new Date(), startTime: new Date() },
		temperature: { data: [], recordTime: new Date() },
		updateTime: new Date(),
	},
});

const initialState = WeatherPanelStore();

export const weatherReducer = createReducer(
	initialState,
	{
		fetchWeatherInfo,
		importWeatherInfo,
	},
	{
		fetchWeatherInfo: (state) => state.set('loading', true),
		importWeatherInfo: (state, { payload: { weather, warnings } }) =>
			state.merge({
				loading: false,
				...(weather ? { weather } : {}),
				warnings,
			}),
	}
);

import styled from 'styled-components';
import WrappedIcon from '../../WrappedIcon';

export interface IconTextButtonIconProps {
	lightBg: boolean;
	disabled: boolean;
	activated: boolean;
}

export const IconTextButtonIcon = styled(WrappedIcon).attrs<
	IconTextButtonIconProps
>(
	({
		theme: {
			font: {
				color: {
					primary,
					darkBgPrimary,
					lightBgSecondary,
					darkBgSecondary,
					lightBgDisabled,
					darkBgDisabled,
				},
			},
		},
		lightBg,
		disabled,
		activated,
		iconProps,
	}) => ({
		iconProps: {
			color: lightBg
				? disabled
					? lightBgDisabled
					: activated
					? primary
					: lightBgSecondary
				: disabled
				? darkBgDisabled
				: activated
				? darkBgPrimary
				: darkBgSecondary,
			...iconProps,
		},
	})
)<IconTextButtonIconProps>``;

import { Ref, useRef, useEffect } from 'react';

export default function useForwardedRef<T>(forwardedRef?: Ref<T>) {
	const selfRef = useRef<T>(null);
	useEffect(() => {
		if (typeof forwardedRef === 'function') {
			const elm = selfRef.current;
			forwardedRef(elm);
			return () => {
				forwardedRef(null);
			};
		}
	}, [forwardedRef, selfRef]);
	if (typeof forwardedRef === 'function') return selfRef;
	return forwardedRef || selfRef;
}

import {
	useCallback,
	useRef,
	RefObject,
	MutableRefObject,
	MouseEvent as RMouseEvent,
} from 'react';
import { VariableSizeGrid } from 'react-window';
import useForwardedRef from '../../../../../Hooks/useForwardedRef';
import useScrollOnMouseAtEdge from '../../../../../Hooks/useScrollOnMouseNearEdge';
import isBetween from '../../../../../Helpers/isBetween';
import { ITableContext } from '../../../context';

function useBodyViewRef(bodyGridRef: RefObject<VariableSizeGrid>) {
	const bodyViewRef = useRef<HTMLDivElement>();
	const bodyGrid = bodyGridRef.current;
	const outerRef = useForwardedRef(bodyGrid ? bodyGrid.props.outerRef : null);
	const outer = outerRef.current;
	bodyViewRef.current = outer ? outer.view : undefined;
	return bodyViewRef as RefObject<HTMLDivElement>;
}

export default function useCellSelectByMouse(
	contextObj: ITableContext,
	contextObjRef: MutableRefObject<ITableContext>,
	rIndex: number,
	cIndex: number
) {
	const {
		bodyGridRef,
		selectionBoxRef,
		setSelected,
		setSelecting,
	} = contextObj;
	const containerRef = useBodyViewRef(bodyGridRef);
	const { handleMouseMove, stopScroll } = useScrollOnMouseAtEdge({
		containerRef,
	});

	const handleMouseDown = useCallback(
		(e: RMouseEvent) => {
			const { selected } = contextObjRef.current;
			const button = e.button;
			if (button === 2) {
				if (selected) {
					const { start, end } = selected;
					if (
						isBetween(rIndex, start[0], end[0]) &&
						isBetween(cIndex, start[1], end[1])
					)
						return;
				}
			}
			if (button === 0 || button === 2) {
				const indices: [number, number] = [rIndex, cIndex];
				setSelected({ start: indices, end: indices });
			}
			if (button === 0) {
				setSelecting(true);
				const handleMouseUp = () => {
					const selectionBox = selectionBoxRef.current;
					if (selectionBox)
						selectionBox.focus({ preventScroll: true });
					window.removeEventListener('mouseup', handleMouseUp);
					window.removeEventListener('mousemove', handleMouseMove);
					stopScroll();
					setSelecting(false);
				};
				window.addEventListener('mouseup', handleMouseUp);
				window.addEventListener('mousemove', handleMouseMove);
			}
		},
		[
			contextObjRef,
			selectionBoxRef,
			cIndex,
			rIndex,
			handleMouseMove,
			stopScroll,
			setSelecting,
			setSelected,
		]
	);

	const handleMouseEnter = useCallback(() => {
		const { selecting, selected } = contextObjRef.current;
		if (!selecting || !selected) return;
		setSelected({
			start: selected.start,
			end: [rIndex, cIndex],
		});
	}, [contextObjRef, rIndex, cIndex, setSelected]);

	return {
		handleMouseDown,
		handleMouseEnter,
	};
}

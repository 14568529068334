import React, { useMemo, forwardRef, Ref } from 'react';
import styled from 'styled-components';
import {
	mdiCheckboxBlank,
	mdiCheckboxMarked,
	mdiCheckboxBlankOutline,
} from '@mdi/js';
import useObject from '../../Hooks/useObject';
import IconTextButton from './IconTextButton';
import { ButtonOverlayShape } from './Button/interfaces';
import { IconTextButtonProps } from './IconTextButton/interfaces';

export const CheckboxButton = styled(IconTextButton).attrs<IconTextButtonProps>(
	{
		overlayShape: ButtonOverlayShape.circle,
	}
)`
	justify-content: center;
	& > [x-component='text'] {
		text-transform: unset;
	}
`;

export interface CheckboxProps extends Omit<IconTextButtonProps, 'icon'> {
	checked?: boolean | null;
}

const Checkbox = forwardRef(function(
	props: CheckboxProps,
	ref: Ref<HTMLDivElement>
) {
	const { checked, ...restProps } = props;
	const _restProps = useObject(restProps);
	return useMemo(() => {
		const icon =
			typeof checked !== 'boolean'
				? mdiCheckboxBlankOutline
				: checked
				? mdiCheckboxMarked
				: mdiCheckboxBlank;
		return <CheckboxButton ref={ref} icon={icon} {..._restProps} />;
	}, [ref, checked, _restProps]);
});

export default Checkbox;

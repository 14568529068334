import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import logger from './logger';
import rootReducer from './reducers/root';
import rootSaga from './sagas/rootSaga';
import configs from '../configs';

// const _window = window as any;

const { NODE_ENV, SERVER_SIDE } = configs;

export default function configureStore() {
	const sagaMiddleware = createSagaMiddleware();
	let middlewares;

	if (SERVER_SIDE !== '1') {
		middlewares =
			NODE_ENV === 'production'
				? [sagaMiddleware]
				: [sagaMiddleware, logger];
	} else {
		middlewares =
			NODE_ENV === 'production'
				? [sagaMiddleware]
				: [sagaMiddleware, logger];
	}

	const store = createStore(
		rootReducer,
		compose(
			applyMiddleware(...middlewares)
			// NODE_ENV === 'development' &&
			// _window.__REDUX_DEVTOOLS_EXTENSION__ &&
			// 	_window.__REDUX_DEVTOOLS_EXTENSION__()
		)
	);

	sagaMiddleware.run(rootSaga);

	return store;
}

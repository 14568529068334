import createReducer from '../../../../../Helpers/createReducer';
import { Record, List, Map } from 'immutable';
import { WorkspaceStore, SerializedWorkspaceStore } from '../interfaces';
import {
	startApplication,
	closeActivity,
	focusActivity,
	changeWindowState,
	importOverview,
} from './actions';
import { MaximizeType } from '../../../../Layouts/interfaces';

export const WorkspaceStoreRecord = Record<WorkspaceStore>({
	activities: List(),
	windows: Map(),
	windowIndice: List(),
	overview: {
		activeSensorData: [],
		sensorTypeData: [],
		tiltingAngleData: [],
		signalIntervalData: [],
		batteryLevelData: [],
		inactiveSensorData: [],
		scenarioChangeRecords: [],
	},
});

const initialState = WorkspaceStoreRecord();

export default createReducer(
	initialState,
	{
		startApplication,
		closeActivity,
		focusActivity,
		changeWindowState,
		importOverview,
	},
	{
		startApplication: (
			state,
			{
				payload: {
					application: { key, name },
					id,
					parameters,
					windowProps,
				},
			}
		) =>
			state.withMutations((s) => {
				s.update('activities', (activities) =>
					activities.push({ key, name, title: name, id, parameters })
				);
				s.update('windows', (windows) =>
					windows.set(id, {
						left: 0.3,
						top: 0.2,
						width: 0.4,
						height: 0.6,
						maximized: MaximizeType.REGULAR,
						minimized: false,
						originalHeight: null,
						originalLeft: null,
						originalTop: null,
						originalWidth: null,
						...windowProps,
					})
				);
				s.update('windowIndice', (windowIndice) =>
					windowIndice.push(id)
				);
			}),
		closeActivity: (state, { payload: { activityId } }) =>
			state.withMutations((s) => {
				s.update('windows', (windows) =>
					windows.filter((window, id) => id !== activityId)
				);
				s.update('activities', (activities) =>
					activities.filter(({ id }) => id !== activityId)
				);
				s.update('windowIndice', (windowIndice) =>
					windowIndice.filter((id) => id !== activityId)
				);
			}),
		focusActivity: (state, { payload: { activityId } }) =>
			state.withMutations((s) => {
				s.update('windowIndice', (windowIndice) =>
					windowIndice
						.filter((windowId) => windowId !== activityId)
						.push(activityId)
				);
			}),
		changeWindowState: (state, { payload: { activityId, windowState } }) =>
			state.withMutations((s) => {
				s.update('windows', (windows) =>
					windows.mergeIn([activityId], windowState)
				);
			}),
		importOverview: (
			state,
			{
				payload: {
					activeSensorData,
					batteryLevelData,
					inactiveSensorData,
					scenarioChangeRecords,
					sensorTypeData,
					signalIntervalData,
					tiltingAngleData,
				},
			}
		) =>
			state.set('overview', {
				activeSensorData: activeSensorData.map(({ count, x }) => ({
					count: typeof count === 'number' ? count : 0,
					x: new Date(x),
				})),
				batteryLevelData: batteryLevelData,
				inactiveSensorData,
				scenarioChangeRecords: scenarioChangeRecords.map(
					({ count, x, from, to }) => ({
						count,
						x: new Date(x),
						from,
						to,
					})
				),
				sensorTypeData,
				signalIntervalData,
				tiltingAngleData,
			}),
	}
);

export const workspaceParser = (
	state: SerializedWorkspaceStore = {
		activities: [],
		windowIndice: [],
		windows: {},
	}
) => {
	const { activities, windowIndice, windows } = state;
	return WorkspaceStoreRecord({
		activities: List(activities),
		windowIndice: List(windowIndice),
		windows: Map(windows),
	});
};

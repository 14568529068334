import { WindowEdgeProps } from '../interfaces';
import React, { useMemo } from 'react';
import { WindowEdgeWrapper } from './WindowEdge.sc';
import { CSSProperties } from 'styled-components';
import { EDGE_SIZE } from '../../Window/constant';
import { ResizeDirection } from '../../../interfaces';
import useStandardResizeHandle from '../../Window/Hooks/useStandardResizeHandle';

function getEdgeStyle(direction: ResizeDirection): CSSProperties {
	switch (direction) {
		case ResizeDirection.TOP:
			return {
				cursor: 'n-resize',
				height: EDGE_SIZE,
				top: 0,
				left: EDGE_SIZE,
				right: EDGE_SIZE,
			};
		case ResizeDirection.BOTTOM:
			return {
				cursor: 's-resize',
				height: EDGE_SIZE,
				bottom: 0,
				left: EDGE_SIZE,
				right: EDGE_SIZE,
			};
		case ResizeDirection.LEFT:
			return {
				cursor: 'w-resize',
				width: EDGE_SIZE,
				left: 0,
				top: EDGE_SIZE,
				bottom: EDGE_SIZE,
			};
		case ResizeDirection.RIGHT:
			return {
				cursor: 'e-resize',
				width: EDGE_SIZE,
				right: 0,
				top: EDGE_SIZE,
				bottom: EDGE_SIZE,
			};

		case ResizeDirection.TOP_LEFT:
			return {
				cursor: 'nw-resize',
				height: EDGE_SIZE * 1.5,
				width: EDGE_SIZE * 1.5,
				top: 0,
				left: 0,
			};

		case ResizeDirection.TOP_RIGHT:
			return {
				cursor: 'ne-resize',
				height: EDGE_SIZE * 1.5,
				width: EDGE_SIZE * 1.5,
				top: 0,
				right: 0,
			};

		case ResizeDirection.BOTTOM_LEFT:
			return {
				cursor: 'sw-resize',
				height: EDGE_SIZE * 1.5,
				width: EDGE_SIZE * 1.5,
				bottom: 0,
				left: 0,
			};

		case ResizeDirection.BOTTOM_RIGHT:
			return {
				cursor: 'se-resize',
				height: EDGE_SIZE * 1.5,
				width: EDGE_SIZE * 1.5,
				bottom: 0,
				right: 0,
			};
		default:
			return {};
	}
}

export default function WindowEdge(props: WindowEdgeProps) {
	const { direction } = props;
	const handleMouseDown = useStandardResizeHandle(props);

	return useMemo(
		() => (
			<WindowEdgeWrapper
				style={getEdgeStyle(direction)}
				onMouseDown={handleMouseDown}
			/>
		),
		[direction, handleMouseDown]
	);
}

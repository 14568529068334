import React, { useMemo, useRef, useContext } from 'react';
import {
	mdiFilterOutline,
	mdiEqual,
	mdiLessThan,
	mdiLessThanOrEqual,
	mdiGreaterThan,
	mdiGreaterThanOrEqual,
	mdiNotEqualVariant,
	mdiCodeBrackets,
	mdiApproximatelyEqual,
	mdiSetLeftRight,
} from '@mdi/js';
import {
	HeaderCellFilterWrapper,
	HeaderCellFilterButton,
} from './HeaderCellFilter.sc';
import HeaderFilterMenu from './HeaderCellFilterMenu';
import HeaderFilterInput from './HeaderCellFilterInput';
import { usePopoverOnElement } from '../../../../Common/Popover';
import { useColumn } from '../../../Hooks/Column';
import useFocus from '../../../../../Hooks/useFocus';
import { FilterType } from '../../../interfaces';
import context from '../../../context';

interface HeaderFilterProps {
	columnKey: string;
}

export const filterIconDict = {
	[FilterType.none]: mdiFilterOutline,
	[FilterType.eq]: mdiEqual,
	[FilterType.lt]: mdiLessThan,
	[FilterType.lte]: mdiLessThanOrEqual,
	[FilterType.gt]: mdiGreaterThan,
	[FilterType.gte]: mdiGreaterThanOrEqual,
	[FilterType.ne]: mdiNotEqualVariant,
	[FilterType.in]: mdiCodeBrackets,
	[FilterType.nin]: mdiSetLeftRight,
	[FilterType.between]: mdiCodeBrackets,
	[FilterType.contain]: mdiApproximatelyEqual,
};

function HeaderCellFilter(props: HeaderFilterProps) {
	const { columnKey } = props;
	const contextObj = useContext(context);
	const { dataType, filter } = useColumn(contextObj, columnKey);
	const dataTypeStr = dataType.type;
	const filterType = filter.type;
	const ref = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const { open: openMenu, getPopoverProps } = usePopoverOnElement(ref);
	const { focused, handleFocus, handleBlur } = useFocus();
	return useMemo(
		() => (
			<HeaderCellFilterWrapper
				ref={ref}
				onFocus={handleFocus}
				onBlur={handleBlur}
			>
				{dataTypeStr !== 'boolean' ? (
					<HeaderCellFilterButton
						tabIndex={0}
						icon={filterIconDict[filterType]}
						onPress={openMenu}
					/>
				) : null}
				<HeaderFilterInput
					ref={inputRef}
					columnKey={columnKey}
					focused={focused}
				/>
				<HeaderFilterMenu
					inputRef={inputRef}
					columnKey={columnKey}
					{...getPopoverProps()}
				/>
			</HeaderCellFilterWrapper>
		),
		[
			dataTypeStr,
			filterType,
			columnKey,
			focused,
			getPopoverProps,
			handleFocus,
			handleBlur,
			openMenu,
		]
	);
}

export default HeaderCellFilter;

import Embedded from '../../Embedded';
import Workspace, { updateTitle } from './Workspace';
import React, { useCallback, useMemo, useContext } from 'react';
import { DesktopWindowState } from '../../Layouts/Components/Desktop/interfaces';
import {
	changeWindowState,
	focusActivity,
	startApplication,
	closeActivity,
} from './Workspace';
import { LinkedWorkspaceProps, EmbeddedWorkspaceProps } from '../interfaces';
import context from '../context';
// import { boardcastAction } from '../../../Store/actions/remoteStore';
import { AnyAction } from 'redux';
import makeId from '../../../Helpers/makeId';
import { fetchOverview, FetchOverviewPayload } from './Workspace/Store/actions';
import { MaximizeType } from '../../Layouts/interfaces';

function EmbeddedWorkspace(props: EmbeddedWorkspaceProps) {
	const {
		state: { activities, windowIndice, windows, overview },
		dispatch: uplinkDispatch,
		forwardAction,
	} = props;

	const { applications } = useContext(context);

	const dispatch = useCallback(
		(action: AnyAction) => {
			uplinkDispatch(forwardAction(action));
		},
		[uplinkDispatch, forwardAction]
	);

	const handleWindowChange = useCallback(
		(activityId: string, windowState: Partial<DesktopWindowState>) => {
			dispatch(changeWindowState({ activityId, windowState }));
		},
		[dispatch]
	);

	const handleWindowFocus = useCallback(
		(activityId: string) => {
			dispatch(focusActivity({ activityId }));
		},
		[dispatch]
	);

	const handleStartApplication = useCallback(
		(application: { key: string; name: string }, parameters?: any) => {
			dispatch(
				startApplication({
					application,
					id: makeId(16),
					parameters,
					windowProps: {
						left: 0,
						top: 0,
						width: 1,
						height: 1,
						maximized: MaximizeType.FULL,
						minimized: false,
						originalHeight: 0.6,
						originalLeft: 0.3,
						originalTop: 0.2,
						originalWidth: 0.4,
					},
				})
			);
		},
		[dispatch]
	);

	const handleCloseActivity = useCallback(
		(activityId: string) => {
			dispatch(closeActivity({ activityId }));
		},
		[dispatch]
	);

	const handleUpdateTitle = useCallback(
		(activityId: string, title: string) => {
			dispatch(updateTitle({ activityId, title }));
		},
		[dispatch]
	);

	const handleFetchOverview = useCallback(
		(payload: FetchOverviewPayload) => {
			uplinkDispatch(forwardAction(fetchOverview(payload)));
		},
		[uplinkDispatch, forwardAction]
	);

	return useMemo(() => {
		return (
			<Workspace
				overview={overview}
				windows={windows}
				windowIndice={windowIndice}
				activities={activities}
				applications={applications}
				closeActivity={handleCloseActivity}
				onWindowChange={handleWindowChange}
				onWindowFocus={handleWindowFocus}
				startApplication={handleStartApplication}
				updateTitle={handleUpdateTitle}
				onRequestFetchOverview={handleFetchOverview}
			/>
		);
	}, [
		overview,
		windows,
		windowIndice,
		activities,
		applications,
		handleCloseActivity,
		handleWindowChange,
		handleWindowFocus,
		handleStartApplication,
		handleUpdateTitle,
		handleFetchOverview,
	]);
}

export default function LinkedWorkspace(props: LinkedWorkspaceProps) {
	const { workspaceId } = props;
	return useMemo(
		() => (
			<Embedded
				storeId={workspaceId}
				type="workspace"
				component={EmbeddedWorkspace}
			/>
		),
		[workspaceId]
	);
}

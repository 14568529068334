import { take, actionChannel, call, put, race } from 'redux-saga/effects';
import send from '../effects/sendMessage';
import WebSocketClient from '../services/WebSocketClient';
import {
	uplinkAction,
	webSocketReady,
	closeWebsocket,
} from '../../actions/remoteStore';
import { buffers } from 'redux-saga';

export function* handleUplinkAction(
	client: WebSocketClient,
	uplink: ReturnType<typeof uplinkAction>
) {
	const {
		payload: { action },
	} = uplink;
	yield send(client, JSON.stringify(action));
	yield put(action);
}

export default function* watchUplinkAction() {
	while (true) {
		const channel = yield actionChannel(
			uplinkAction.type,
			buffers.expanding()
		);
		const {
			payload: { client },
		} = yield take(webSocketReady.type);

		let closed = false;

		while (!closed) {
			const { action, close } = yield race({
				action: take(channel),
				close: take(closeWebsocket.type),
			});
			if (close) {
				closed = true;
			} else {
				yield call(handleUplinkAction, client, action);
			}
		}
	}
}

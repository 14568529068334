import React, { useRef, useEffect, useMemo } from 'react';
import { VariableSizeGrid } from 'react-window';

import {
	TableWrapper,
	TableHeaderWrapper,
	TableIndexBodyWrapper,
} from './Table.sc';
import TableProvider, { TableProviderProps } from './TableProvider';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import TableIndex from './TableIndex';
import TablePanel from './TablePanel';
import TablePagination from './TablePagination';
import LoadingModal from './LoadingModal';
import {
	useGetColumnWidth,
	useGetRowHeight,
	useCellWidthRefresh,
	useCellHeightRefresh,
	useSyncScroll,
	useColumnWidthSum,
} from '../Hooks/Table';
import useContextWithRef from '../../../Hooks/useContextWithRef';
import context from '../context';

function TableCore() {
	const tableRef = useRef<HTMLDivElement>(null);
	const headerGridRef = useRef<VariableSizeGrid>(null);
	const indexGridRef = useRef<VariableSizeGrid>(null);
	const bodyGridRef = useRef<VariableSizeGrid>(null);
	const { contextObj, contextObjRef } = useContextWithRef(context);
	const {
		setTableRef,
		setHeaderGridRef,
		setIndexGridRef,
		setBodyGridRef,
	} = contextObj;

	useEffect(() => {
		setTableRef(tableRef);
		setHeaderGridRef(headerGridRef);
		setIndexGridRef(indexGridRef);
		setBodyGridRef(bodyGridRef);
	}, [setTableRef, setHeaderGridRef, setIndexGridRef, setBodyGridRef]);

	// Cell Width/Height Control
	const getColumnWidth = useGetColumnWidth(contextObjRef);
	const getRowHeight = useGetRowHeight(contextObjRef);
	useCellWidthRefresh(contextObj);
	useCellHeightRefresh(contextObj);

	// Scroll
	const syncScroll = useSyncScroll(contextObj);

	// Column Width Sum
	const columnWidthSum = useColumnWidthSum(contextObj);

	return useMemo(() => {
		return (
			<TableWrapper ref={tableRef}>
				<TableHeaderWrapper>
					<TableHeader columnWidth={getColumnWidth} />
				</TableHeaderWrapper>
				<TablePanel columnWidthSum={columnWidthSum} />
				<TableIndexBodyWrapper>
					<TableIndex rowHeight={getRowHeight} />
					<TableBody
						columnWidth={getColumnWidth}
						rowHeight={getRowHeight}
						onScroll={syncScroll}
					/>
				</TableIndexBodyWrapper>
				<TablePagination columnWidthSum={columnWidthSum} />
				<LoadingModal />
			</TableWrapper>
		);
	}, [columnWidthSum, getColumnWidth, getRowHeight, syncScroll]);
}

export type TableProps = Omit<TableProviderProps, 'children'>;
function Table(props: TableProps) {
	return (
		<TableProvider {...props}>
			<TableCore />
		</TableProvider>
	);
}

export default Table;

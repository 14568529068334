import { is, Collection, RecordOf } from 'immutable';
import { useRef } from 'react';

export function useImmutable<
	C extends Collection<any, any> | RecordOf<any> | undefined | null
>(collection: C): C {
	const collectionRef = useRef(collection);
	const prevCollection = collectionRef.current;
	if (!is(prevCollection, collection)) {
		collectionRef.current = collection;
	}
	return collectionRef.current;
}

function useCustomEqualMemo<P>(
	factory: () => P,
	deps: readonly any[] | undefined,
	isEqual: (a: any, b: any) => boolean
): P {
	const valueRef = useRef<P>();
	const depsRef = useRef<readonly any[] | undefined>();
	const prevDeps = depsRef.current;
	if (
		!valueRef.current ||
		!prevDeps ||
		!deps ||
		prevDeps.length !== deps.length ||
		prevDeps.some((prevDep, i) => !isEqual(prevDep, deps[i]))
	) {
		const value = factory();
		valueRef.current = value;
	}
	depsRef.current = deps;
	return valueRef.current;
}

function useCustomEqualEffect(
	effect: React.EffectCallback,
	deps: readonly any[] | undefined,
	isEqual: (a: any, b: any) => boolean
): void {
	const depsRef = useRef<readonly any[] | undefined>();
	const prevDeps = depsRef.current;
	const unsubscribeRef = useRef<ReturnType<React.EffectCallback>>(() => {});
	if (
		!prevDeps ||
		!deps ||
		prevDeps.length !== deps.length ||
		prevDeps.some((prevDep, i) => !isEqual(prevDep, deps[i]))
	) {
		unsubscribeRef.current = effect();
	}
	depsRef.current = deps;
}

export function useImmutableMemo<P>(
	factory: () => P,
	deps?: readonly any[] | undefined
) {
	return useCustomEqualMemo(factory, deps, is);
}

export function useImmutableEffect(
	effect: React.EffectCallback,
	deps?: readonly any[] | undefined
) {
	return useCustomEqualEffect(effect, deps, is);
}

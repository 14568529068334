import React, { useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import theme from '../theme';
import Home from '../App/Components/Dashboard/Components/Home';
import noop from '../App/Helpers/noop';

const demoActiveSensorData = [
	{
		x: new Date('2019-1-1'),
		count: 100,
	},
	{
		x: new Date('2019-1-2'),
		count: 101,
	},
	{
		x: new Date('2019-1-3'),
		count: 105,
	},
	{
		x: new Date('2019-1-4'),
		count: 103,
	},
];
const demoSensorTypeData = [
	{
		x: 'LoRa',
		count: 100,
	},
	{
		x: 'NB',
		count: 151,
	},
];

function HomeDemo() {
	return useMemo(
		() => (
			<ThemeProvider theme={theme}>
				<Home
					activeSensorData={demoActiveSensorData}
					sensorTypeData={demoSensorTypeData}
					tiltingAngleData={[]}
					signalIntervalData={[]}
					batteryLevelData={[]}
					inactiveSensorData={[]}
					scenarioChangeRecords={[]}
					onRequestFetchOverview={noop}
				/>
			</ThemeProvider>
		),
		[]
	);
}

export default HomeDemo;

import React, { useState, useCallback } from 'react';
import MapView, { DragRotate } from '../App/Components/MapView';

import { useImmutableMemo } from '../App/Hooks/useImmutable';
import { ThemeProvider } from 'styled-components';
import theme from '../theme';
import TileLayer from '../App/Components/MapView/Components/TileLayer';
import * as proj from 'ol/proj';
import VectorLayer from '../App/Components/MapView/Components/VectorLayer';
import Select from '../App/Components/MapView/Components/Select';
import { Feature as OlFeature } from 'ol';
import Feature from '../App/Components/MapView/Components/Feature';

import districtGeoJSON from '../App/Assets/Hong_Kong_18_Districts.json';
import { altKeyOnly } from 'ol/events/condition';

const mapOptions = {};
const viewOptions = {
	projection: proj.get('EPSG:3857'),
};

const sourceOptions = {
	crossOrigin: 'anonymous',
	url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
};

const json = districtGeoJSON as GeoJSON.FeatureCollection;
const dragRotateOptions = {
	condition: altKeyOnly,
};

const tileLayerProps = { zIndex: 1 };
const vectorLayerOptions = { zIndex: 2 };

export default function MapViewDemo() {
	const [{ center, rotation, zoom }, setState] = useState({
		center: [12707975.92093638, 2545840.017349782],
		zoom: 12,
		rotation: 0,
	});

	const [selected, setSelected] = useState<OlFeature[]>([]);

	const handleCenterChange = useCallback(
		(center: number[]) => {
			setState((s) => ({ ...s, center }));
		},
		[setState]
	);

	const handleRotate = useCallback(
		(rotate: number) => {
			setState((s) => ({ ...s, rotate }));
		},
		[setState]
	);

	const handleZoom = useCallback(
		(zoom: number) => {
			setState((s) => ({ ...s, zoom }));
		},
		[setState]
	);

	const handleSelect = useCallback((selected: OlFeature[]) => {
		setSelected(selected);
	}, []);

	return useImmutableMemo(() => {
		return (
			<ThemeProvider theme={theme}>
				<MapView
					center={center}
					rotation={rotation}
					zoom={zoom}
					mapOptions={mapOptions}
					viewOptions={viewOptions}
					onCenterChange={handleCenterChange}
					onZoom={handleZoom}
					onRotate={handleRotate}
				>
					<TileLayer
						sourceType="xyz"
						sourceOptions={sourceOptions}
						layerOptions={tileLayerProps}
					/>
					<VectorLayer layerOptions={vectorLayerOptions}>
						{json.features.map((feature) => {
							const id = feature.properties
								? feature.properties.ENAME
								: '';
							return <Feature key={id} feature={feature} />;
						})}
					</VectorLayer>
					<DragRotate dragRotateOptions={dragRotateOptions} />
					<Select onSelect={handleSelect} />
				</MapView>
			</ThemeProvider>
		);
	}, [center, rotation, zoom, selected]);
}

import { takeEvery, call, put, take } from 'redux-saga/effects';
import { authenticate } from '../../actions/auth';
import WebSocketClient from '../services/WebSocketClient';
import configs from '../../../configs';
import { webSocketReady, closeWebsocket } from '../../actions/remoteStore';

const { DASHBOARD_WS_URL } = configs;

export function* handleInitializeConnection(
	action: ReturnType<typeof authenticate>
) {
	const {
		payload: { token },
	} = action;
	const client = new WebSocketClient(`${DASHBOARD_WS_URL}?token=${token}`);

	yield call([client, client.open]);
	yield put(webSocketReady({ client }));

	yield take(closeWebsocket.type);

	yield call([client, client.close]);
}

export function watchAuthenticate() {
	return takeEvery(authenticate.type, handleInitializeConnection);
}

export default function* remoteStoreSaga() {
	yield watchAuthenticate();
}

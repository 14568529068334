import styled from 'styled-components';
import { View } from '../../View';

export const TextAreaWrapper = styled(View)`
	&:focus {
		outline: none;
	}
`;

export interface TextAreaProps {
	disabled: boolean;
	lightBg: boolean;
}

export const TextArea = styled.textarea<TextAreaProps>`
	position: relative;
	width: 100%;
	outline: none;
	border: none;
	background-color: transparent;
	color: ${({
		theme: {
			font: {
				color: {
					lightBgPrimary,
					lightBgDisabled,
					darkBgPrimary,
					darkBgDisabled,
				},
			},
		},
		disabled,
		lightBg,
	}) =>
		lightBg
			? disabled
				? lightBgDisabled
				: lightBgPrimary
			: disabled
			? darkBgDisabled
			: darkBgPrimary};
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'unset')};
	::placeholder {
		color: ${({
			theme: {
				font: {
					color: { lightBgWatermark, darkBgWatermark },
				},
			},
			lightBg,
		}) => (lightBg ? lightBgWatermark : darkBgWatermark)};
		opacity: 1;
	}
`;

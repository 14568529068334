import { createContext } from 'react';
import { WindowContext } from './interfaces';
import noop from '../../../../Helpers/noop';

export default createContext<WindowContext>({
	left: 0,
	top: 0,
	width: 0,
	height: 0,
	minWidth: 0,
	minHeight: 0,
	parentWidth: 0,
	parentHeight: 0,
	onMoveInit: noop,
	onMoveStart: noop,
	onMove: noop,
	onMoveEnd: noop,
	onMoveCancel: noop,
	onResizeInit: noop,
	onResizeStart: noop,
	onResize: noop,
	onResizeEnd: noop,
	onResizeCancel: noop,
});

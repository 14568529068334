import React, { useMemo } from 'react';
import {
	NotificationListWrapper,
	NotificationLoadingSpinner,
	NoMessageWrapper,
	NoMessageText,
	NotificaitonButtonWrapper,
	ReadAllText,
	WarningWrapper,
	WarningIcon,
	WarningText,
} from './NotificationList.sc';
import { FixedSizeList } from 'react-window';
import useNotificationStore from '../Hooks/useNotificationStore';
import Scrollbars from '../../../../Common/Scrollbars';
import {
	NOTIFICATION_PANEL_WIDTH,
	NOTIFICATION_ITEM_HEIGHT,
} from '../constants';
import useInfiniteScroll from '../Hooks/useInfiniteScroll';
import NotificationItem from './NotificationItem';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../Store/reducers/root';

const warningIndex = {
	WFIREY: {
		icon: 'https://www.hko.gov.hk/en/wxinfo/dailywx/images/firey.gif',
		title: 'Fire Danger Warning (Yellow Fire)',
	},
	WFIRER: {
		icon: 'https://www.hko.gov.hk/en/wxinfo/dailywx/images/firer.gif',
		title: 'Fire Danger Warning (Red Fire)',
	},
	WRAINA: {
		icon: 'https://pda.weather.gov.hk/img/warn/raina.gif',
		title: 'Rainstorm Warning Signal (Amber)',
	},
	WRAINR: {
		icon: 'https://pda.weather.gov.hk/img/warn/rainr.gif',
		title: 'Rainstorm Warning Signal (Red)',
	},
	WRAINB: {
		icon: 'https://pda.weather.gov.hk/img/warn/rainb.gif',
		title: 'Rainstorm Warning Signal (Black)',
	},
	TC1: {
		icon: 'https://pda.weather.gov.hk/img/warn/tc1.gif',
		title: 'Tropical Cyclone Warning Signal (No. 1)',
	},
	TC3: {
		icon: 'https://pda.weather.gov.hk/img/warn/tc3.gif',
		title: 'Tropical Cyclone Warning Signal (No. 3)',
	},
	TC8NE: {
		icon: 'https://www.hko.gov.hk/en/wxinfo/dailywx/images/tc8ne.gif',
		title: 'Tropical Cyclone Warning Signal (No. 8 North East)',
	},
	TC8SE: {
		icon: 'https://www.hko.gov.hk/en/wxinfo/dailywx/images/tc8b.gif',
		title: 'Tropical Cyclone Warning Signal (No. 8 South East)',
	},
	TC8SW: {
		icon: 'https://www.hko.gov.hk/en/wxinfo/dailywx/images/tc8c.gif',
		title: 'Tropical Cyclone Warning Signal (No. 8 South West)',
	},
	TC8NW: {
		icon: 'https://www.hko.gov.hk/en/wxinfo/dailywx/images/tc8d.gif',
		title: 'Tropical Cyclone Warning Signal (No. 8 North West)',
	},
	TC9: {
		icon: 'https://www.hko.gov.hk/en/wxinfo/dailywx/images/tc9.gif',
		title: 'Tropical Cyclone Warning Signal (No. 9)',
	},
	TC10: {
		icon: 'https://www.hko.gov.hk/en/wxinfo/dailywx/images/tc10.gif',
		title: 'Tropical Cyclone Warning Signal (No. 10)',
	},
	WFROST: {
		icon: 'https://www.hko.gov.hk/en/wxinfo/dailywx/images/frost.gif',
		title: 'Frost Warning',
	},
	WHOT: {
		icon: 'https://pda.weather.gov.hk/img/warn/vhot.gif',
		title: 'Hot Weather Warning',
	},
	WCOLD: {
		icon: 'https://www.hko.gov.hk/en/wxinfo/dailywx/images/cold.gif',
		title: 'Cold Weather Warning',
	},
	WMSGNL: {
		icon: 'https://www.hko.gov.hk/en/wxinfo/dailywx/images/sms.gif',
		title: 'Strong Monsoon Signal',
	},
	WFNTSA: {
		icon: 'https://www.hko.gov.hk/en/wxinfo/dailywx/images/ntfl.gif',
		title:
			'Special Announcement on Flooding in the northern New Territories',
	},
	WL: {
		icon: 'https://www.hko.gov.hk/en/wxinfo/dailywx/images/landslip.gif',
		title: 'Landslip Warning',
	},
	WTMW: {
		icon:
			'https://www.hko.gov.hk/en/wxinfo/dailywx/images/tsunami-warn.gif',
		title: 'Tsunami Warning',
	},
	WTS: {
		icon: 'https://www.hko.gov.hk/en/wxinfo/dailywx/images/ts.gif',
		title: 'Thunderstorm Warning',
	},
};

export default function NotificationList() {
	const {
		loading,
		count,
		getItemKey,
		handleMarkAllRead,
		notificationIds,
	} = useNotificationStore();
	const { detectEndReached } = useInfiniteScroll();

	const warnings = useSelector(
		(state: IRootState) => state.weatherPanel.warnings
	);

	return useMemo(() => {
		return (
			<NotificationListWrapper>
				{Object.keys(warningIndex)
					.filter(
						(warning) => warnings[warning as keyof typeof warnings]
					)
					.map((warning) => {
						const { icon, title } = warningIndex[
							warning as keyof typeof warningIndex
						];
						return (
							<WarningWrapper>
								<WarningIcon src={icon} />
								<WarningText>{title}</WarningText>
							</WarningWrapper>
						);
					})}
				<FixedSizeList
					outerElementType={Scrollbars}
					itemCount={count}
					itemSize={NOTIFICATION_ITEM_HEIGHT}
					height={
						Math.max(Math.min(count, 5), 2) *
						NOTIFICATION_ITEM_HEIGHT
					}
					width={NOTIFICATION_PANEL_WIDTH}
					itemKey={getItemKey}
					onItemsRendered={detectEndReached}
				>
					{NotificationItem}
				</FixedSizeList>
				{loading ? <NotificationLoadingSpinner /> : null}
				{notificationIds.size === 0 ? (
					<NoMessageWrapper>
						<NoMessageText>NO NOTIFICATIONS</NoMessageText>
					</NoMessageWrapper>
				) : null}
				<NotificaitonButtonWrapper onMouseDown={handleMarkAllRead}>
					<ReadAllText>ACKNOWLEDGE ALL</ReadAllText>
				</NotificaitonButtonWrapper>
			</NotificationListWrapper>
		);
	}, [
		count,
		warnings,
		detectEndReached,
		getItemKey,
		handleMarkAllRead,
		loading,
		notificationIds.size,
	]);
}

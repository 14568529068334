import React, { useMemo } from 'react';

import { WindowProps } from './interfaces';
import useStandardMoveHandle from './Hooks/useStandardMoveHandle';
import useStandardResizeHandle from './Hooks/useStandardResizeHandle';
import windowContext from './context';
import noop from '../../../../Helpers/noop';
import moveWindow from './moveWindow';
import resizeWindow from './resizeWindow';

const { Provider } = windowContext;

export default function Window(props: WindowProps) {
	const {
		children,
		left,
		top,
		width,
		height,
		minWidth = 50,
		minHeight = 50,
		parentWidth,
		parentHeight,
		onMoveInit = noop,
		onMoveStart = noop,
		onMove = noop,
		onMoveEnd = noop,
		onResizeInit = noop,
		onResizeStart = noop,
		onResize = noop,
		onResizeEnd = noop,
		onMoveCancel = noop,
		onResizeCancel = noop,
	} = props;

	return useMemo(() => {
		return (
			<Provider
				value={{
					parentWidth,
					parentHeight,
					minWidth,
					minHeight,
					left,
					top,
					width,
					height,
					onMoveInit,
					onMoveStart,
					onMove,
					onMoveEnd,
					onMoveCancel,
					onResizeInit,
					onResizeStart,
					onResize,
					onResizeEnd,
					onResizeCancel,
				}}
			>
				{children}
			</Provider>
		);
	}, [
		parentWidth,
		parentHeight,
		children,
		minWidth,
		minHeight,
		left,
		top,
		width,
		height,
		onMoveInit,
		onMoveStart,
		onMove,
		onMoveEnd,
		onResizeInit,
		onResizeStart,
		onResize,
		onResizeEnd,
		onMoveCancel,
		onResizeCancel,
	]);
}

export {
	moveWindow,
	resizeWindow,
	useStandardMoveHandle,
	useStandardResizeHandle,
};

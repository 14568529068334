import styled from 'styled-components';
import { View } from '../Common/View';
import Icon from '@mdi/react';
import { CaptionText } from '../Common/Text';
import { makeStyles } from '@material-ui/core/styles';

export const RecordsFeedGridWrapper = styled(View)`
	flex: 1;
	align-self: stretch;
	align-items: stretch;
	/* border-radius: 8px; */
	overflow: hidden;
	background-color: ${({
		theme: {
			background: { light },
		},
	}) => light};

	${({ theme: { shadow } }) => shadow[2]};
`;

export const SearchBarWrapper = styled(View)`
	position: absolute;
	padding: 12px;
	width: 100%;
	box-sizing: border-box;
`;

export const LoadingIndicatorWrapper = styled(View)`
	position: absolute;
	bottom: 24px;
	height: 24px;
	width: 100%;
`;

export const LoadingIndicatorIcon = styled(Icon).attrs(
	({
		theme: {
			font: {
				color: { primary },
			},
		},
	}) => ({
		size: 0.8,
		color: primary,
	})
)`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
`;
export const LoadingIndicatorLowerIcon = styled(Icon).attrs(
	({
		theme: {
			font: {
				color: { lightBgDisabled },
			},
		},
	}) => ({
		size: 0.8,
		color: lightBgDisabled,
	})
)`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
`;

export const NoRecordsWrapper = styled(View)`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 24px;
	align-items: center;
	justify-content: center;
`;
export const NoRecordsText = styled(CaptionText)`
	color: ${({
		theme: {
			font: {
				color: { lightBgDisabled },
			},
		},
	}) => lightBgDisabled};
`;

export const useSearchBarStyles = makeStyles((theme) => ({
	root: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		width: 400,
		marginRight: theme.spacing(2),
	},
	addButton: {
		color: theme.palette.common.white,
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	iconButton: {
		padding: 10,
	},
	divider: {
		height: 28,
		margin: 4,
	},
}));

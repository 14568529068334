import React, { useMemo, ReactElement, ComponentType } from 'react';
import { IconTextButtonIcon as SIconTextButtonIcon } from './IconTextButtonIcon.sc';
import useObject from '../../../../Hooks/useObject';
import { ButtonChildrenProps } from '../../Button/interfaces';
import { IconProps } from '../../WrappedIcon';

export interface IconTextButtonIconProps extends ButtonChildrenProps {
	icon?: string | ReactElement;
	iconProps?: Partial<IconProps>;
	CustomIcon?: ComponentType<ButtonChildrenProps>;
	lightBg: boolean;
}

function IconTextButtonIcon(props: IconTextButtonIconProps) {
	const { icon, iconProps, CustomIcon, lightBg, ...restProps } = props;
	const _restProps = useObject(restProps);
	return useMemo(() => {
		if (CustomIcon) return <CustomIcon {..._restProps} />;
		if (icon === undefined) return null;
		if (typeof icon === 'string') {
			const { disabled, activated } = _restProps;
			return (
				<SIconTextButtonIcon
					icon={icon}
					lightBg={lightBg}
					disabled={disabled}
					activated={activated}
					iconProps={iconProps}
					x-component="icon"
				/>
			);
		}
		return icon;
	}, [icon, iconProps, lightBg, _restProps, CustomIcon]);
}

export default IconTextButtonIcon;

import React, { useEffect, useCallback, useMemo, useRef } from 'react';
import MapView, {
	Select,
	DragRotate,
	TileLayer,
	VectorLayer,
	Feature,
} from '../../../../../Components/MapView';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../Store/reducers/root';
import { RecordOf } from 'immutable';
import { AnyAction } from 'redux';
import { boardcastAction } from '../../../../../Store/actions/remoteStore';

import { TreeMapStore } from '../interfaces';
import { Activity } from '../../../../../Components/Dashboard/interfaces';
import { ActivityWindow } from '../../../../../Components/Dashboard';
import Embedded from '../../../../../Components/Embedded';
import { EmbeddedComponentProps } from '../../../../../Components/Embedded/interfaces';
import {
	updateSearchText,
	updateZoom,
	updateCenter,
	updateRotation,
	selectFeature,
	fetchFeatures,
	focusSearchResult,
	fetchTreeDetails,
} from '../Store/actions';

import * as proj from 'ol/proj';
import GeoJSON from 'ol/format/GeoJSON';
import { Feature as OlFeature, Map } from 'ol';
import * as condition from 'ol/events/condition';
import { Dict } from '../../../../../../types/Common';
import {
	selectedStyle,
	normalStyle,
} from '../../../../../Components/MapView/featureStyle';

import * as loadingstrategy from 'ol/loadingstrategy';
import * as tilegrid from 'ol/tilegrid';
import {
	TreeMapWrapper,
	TreeMapOverlayWrapper,
	TreeMapControlWrapper,
	TreeMapSearchBarWrapper,
	TreeMapFiltersWrapper,
	TreeMapCountText,
	TreeMapCountNumber,
} from './TreeMap.sc';
import WeatherPanel from '../../../../../Components/WeatherPanel/WeatherPanel';
import { WeatherPanelStore } from '../../../../../Components/WeatherPanel/reducer';
import SearchBar from '../../../../../Components/SearchBar/SearchBar';
import { mdiMagnify, mdiTree } from '@mdi/js';
import TreeFilters from './TreeFilter/TreeFilters';
import TreeDetails from './TreeDetails';
import { useStartApplication } from '../../../../../Components/Dashboard';
import { FilterType } from '../../../../../Components/Table/interfaces';
import View, { ViewOptions } from 'ol/View';
import { Options } from 'ol/layer/Vector';
import { MAP_PANEL_WIDTH } from '../constants';
import { Select as OlSelect } from 'ol/interaction';

const viewOptions: ViewOptions = {
	projection: proj.get('EPSG:3857'),
	maxZoom: 22,
	minZoom: 10,
};

const selectOptions = {
	condition: condition.click,
};

const dragRotateOptions = {
	condition: condition.altKeyOnly,
};

const tileLayerOptions = {
	zIndex: 1,
};

const sourceOptions = {
	crossOrigin: 'anonymous',
	url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
};

const vectorLayerOptions: Options = {
	zIndex: 2,
	// maxResolution: 12,
	minResolution: 0,
	maxResolution: 4,
};

const geojson = new GeoJSON();

function EmbeddedTreeMap(
	props: EmbeddedComponentProps<RecordOf<TreeMapStore>>
) {
	const {
		dispatch,
		forwardAction,
		state: {
			center,
			zoom,
			rotation,
			featureIds,
			features,
			filterFields,
			treeDetails,
			filters,
			filterNames,
			count,
			search,
			searchResults,
			focusedResult,
			selected,
		},
	} = props;

	const initialSearchRef = useRef(search);

	const mapRef = useRef<Map | null>(null);
	const viewRef = useRef<View | null>(null);
	const selectRef = useRef<OlSelect | null>(null);

	const boardcastDispatch = useCallback(
		(action: AnyAction) => {
			dispatch(boardcastAction({ action: forwardAction(action) }));
		},
		[dispatch, forwardAction]
	);

	const uplinkDispatch = useCallback(
		(action: AnyAction) => {
			dispatch(forwardAction(action));
		},
		[dispatch, forwardAction]
	);

	useEffect(() => {
		boardcastDispatch(
			updateSearchText({
				searchText: initialSearchRef.current,
				collection: 'trees',
				key: 'treeId',
				select: { treeId: 1 },
			})
		);
	}, [initialSearchRef, boardcastDispatch]);

	const handleUpdateSearchText = useCallback(
		(searchText: string) => {
			boardcastDispatch(
				updateSearchText({
					searchText,
					collection: 'trees',
					key: 'treeId',
					select: { treeId: 1 },
				})
			);
		},
		[boardcastDispatch]
	);

	const handleUpdateZoom = useCallback(
		(zoom: number) => {
			boardcastDispatch(updateZoom({ zoom }));
		},
		[boardcastDispatch]
	);

	const handleUpdateCenter = useCallback(
		(center: number[]) => {
			boardcastDispatch(updateCenter({ center }));
		},
		[boardcastDispatch]
	);

	const handleUpdateRotation = useCallback(
		(rotation: number) => {
			boardcastDispatch(updateRotation({ rotation }));
		},
		[boardcastDispatch]
	);

	const handleSelect = useCallback(
		(features: OlFeature[]) => {
			boardcastDispatch(
				selectFeature({
					feature: features.length
						? geojson.writeFeatureObject(features[0])
						: null,
				})
			);
		},
		[boardcastDispatch]
	);

	const startApplication = useStartApplication();

	const handleOpenTreeTable = useCallback(
		(treeId: string) => {
			startApplication &&
				startApplication(
					{
						key: 'treesTable',
						name: `Tree Details - ${treeId}`,
					},
					{
						columns: {
							treeId: {
								filter: {
									values: { eq: treeId },
									type: FilterType.eq,
								},
							},
						},
					}
				);
		},
		[startApplication]
	);

	const handleOpenSensorTable = useCallback(
		(sensorId: string) => {
			startApplication &&
				startApplication(
					{
						key: 'sensorsTable',
						name: `Sensor Details - ${sensorId}`,
					},
					{
						columns: {
							sensorId: {
								filter: {
									values: { eq: sensorId },
									type: FilterType.eq,
								},
							},
						},
					}
				);
		},
		[startApplication]
	);

	const handleOpenReadingTable = useCallback(
		(treeId: string) => {
			startApplication &&
				startApplication(
					{
						key: 'readingsTable',
						name: `Tree readings - ${treeId}`,
					},
					{
						columns: {
							treeId: {
								filter: {
									values: { eq: treeId },
									type: FilterType.eq,
								},
							},
						},
						sorts: [{ recordedAt: -1 }],
					}
				);
		},
		[startApplication]
	);

	useEffect(() => {
		if (selected && selected.properties && selected.properties.treeId)
			uplinkDispatch(
				fetchTreeDetails({ treeId: selected.properties.treeId })
			);
	}, [selected, uplinkDispatch]);

	const vectorSourceOptions = useMemo(
		() => ({
			zIndex: 2,
			loader: (extent: number[]) => {
				const bbox = extent as GeoJSON.BBox;
				uplinkDispatch(
					fetchFeatures({
						bbox,
						collection: 'trees',
						filters: filters.toJS() as Dict<string>,
						locationField: 'location',
						select: { treeId: 1 },
					})
				);
			},
			strategy: loadingstrategy.tile(
				tilegrid.createXYZ({
					tileSize: 1024,
				})
			),
		}),
		[uplinkDispatch, filters]
	);

	const { loading, weather } = useSelector((state: RecordOf<IRootState>) =>
		state.get('weatherPanel', WeatherPanelStore())
	);

	useEffect(() => {
		if (typeof focusedResult === 'number') {
			const focusedFeature = searchResults.get(focusedResult);
			if (!focusedFeature) return;
			const map = mapRef.current;
			const view = viewRef.current;
			if (!map || !view) return;

			const mapSize = map.getSize();

			if (!mapSize) return;

			const [width = 0, height = 0] = mapSize;
			view.setZoom(18);
			view.centerOn(focusedFeature.geometry.coordinates, mapSize, [
				MAP_PANEL_WIDTH + (width - MAP_PANEL_WIDTH) / 2,
				height / 2,
			]);
		}
	}, [boardcastDispatch, focusedResult, searchResults]);

	const handleFocusSearchResult = useCallback(
		(index: number | null) => {
			boardcastDispatch(focusSearchResult({ index }));
		},
		[boardcastDispatch]
	);

	const handleSubmit = useCallback(() => {
		const confirmIndex =
			typeof focusedResult === 'number' ? focusedResult : 0;
		const confirmFeature = searchResults.get(confirmIndex);
		if (confirmFeature)
			boardcastDispatch(selectFeature({ feature: confirmFeature }));
	}, [boardcastDispatch, searchResults, focusedResult]);

	const handleBlur = useCallback(() => {
		const selector = selectRef.current;
		if (!selector) return;
		selector.getFeatures().clear();
	}, []);

	return useMemo(() => {
		return (
			<TreeMapWrapper>
				<MapView
					mapRef={mapRef}
					viewRef={viewRef}
					center={center}
					zoom={zoom}
					rotation={rotation}
					onZoom={handleUpdateZoom}
					onRotate={handleUpdateRotation}
					onCenterChange={handleUpdateCenter}
					viewOptions={viewOptions}
				>
					<Select
						selectRef={selectRef}
						onSelect={handleSelect}
						selectOptions={selectOptions}
					/>
					<DragRotate dragRotateOptions={dragRotateOptions} />
					<VectorLayer
						sourceOptions={vectorSourceOptions}
						layerOptions={vectorLayerOptions}
					>
						{featureIds
							.map((featureId) => {
								let feature = features.get(featureId);
								const properties = selected
									? selected.properties
									: null;
								const _id = properties ? properties._id : null;
								return feature ? (
									<Feature
										key={featureId}
										style={
											_id === featureId
												? selectedStyle
												: normalStyle
										}
										feature={feature}
									/>
								) : null;
							})
							.toArray()}
					</VectorLayer>
					<TileLayer
						sourceOptions={sourceOptions}
						sourceType="xyz"
						layerOptions={tileLayerOptions}
					/>
				</MapView>
				<TreeMapOverlayWrapper>
					<TreeMapControlWrapper>
						<WeatherPanel loading={loading} weather={weather} />
						<TreeMapSearchBarWrapper>
							<SearchBar
								searchText={search}
								leftIcon={mdiMagnify}
								onChange={handleUpdateSearchText}
								onFocusSuggestion={handleFocusSearchResult}
								onSubmit={handleSubmit}
								suggestions={searchResults
									.map((feature) => {
										const { properties } = feature;
										const treeId = properties
											? properties.treeId
											: null;
										return {
											name: treeId,
											value: treeId,
											icon: mdiTree,
										};
									})
									.toArray()}
							/>
						</TreeMapSearchBarWrapper>
						<TreeMapFiltersWrapper>
							<TreeFilters
								filters={filters}
								filterNames={filterNames}
								filterFields={filterFields}
								onRequestBlur={handleBlur}
								onBoardcast={boardcastDispatch}
								onUplink={uplinkDispatch}
							/>
						</TreeMapFiltersWrapper>
						<TreeMapCountText>
							<TreeMapCountNumber>
								{count} Trees
							</TreeMapCountNumber>
							have been found on map
						</TreeMapCountText>
					</TreeMapControlWrapper>
					{selected && treeDetails ? (
						<TreeMapControlWrapper style={{ flex: 1 }}>
							<TreeDetails
								{...treeDetails}
								onRequestOpenTreesTable={handleOpenTreeTable}
								onRequestOpenReadingsTable={
									handleOpenReadingTable
								}
								onRequestOpenSensorsTable={
									handleOpenSensorTable
								}
							/>
						</TreeMapControlWrapper>
					) : null}
				</TreeMapOverlayWrapper>
			</TreeMapWrapper>
		);
	}, [
		filters,
		filterNames,
		filterFields,
		handleUpdateSearchText,
		loading,
		search,
		searchResults,
		weather,
		center,
		rotation,
		zoom,
		featureIds,
		features,
		count,
		uplinkDispatch,
		handleSelect,
		handleUpdateCenter,
		handleUpdateRotation,
		handleUpdateZoom,
		selected,
		vectorSourceOptions,
		boardcastDispatch,
		treeDetails,
		handleOpenTreeTable,
		handleOpenSensorTable,
		handleOpenReadingTable,
		handleFocusSearchResult,
		handleSubmit,
		handleBlur,
	]);
}

interface TreeMapProps {
	activity: Activity;
}

export default function TreeMap(props: TreeMapProps) {
	const {
		activity: { id },
	} = props;
	return useMemo(() => {
		return (
			<ActivityWindow activityId={id}>
				<Embedded
					storeId={id}
					type="treeMap"
					component={EmbeddedTreeMap}
				/>
			</ActivityWindow>
		);
	}, [id]);
}

import styled from 'styled-components';
// import { View } from '../../../../Common/View';

const IndexInnerWrapper = styled.div`
	box-sizing: border-box;
	border-left: 1px solid
		${({
			theme: {
				background: { grey },
			},
		}) => grey};
	border-right: 1px solid
		${({
			theme: {
				background: { grey },
			},
		}) => grey};
	background-color: ${({
		theme: {
			background: { grey },
		},
	}) => grey};
`;

export default IndexInnerWrapper;

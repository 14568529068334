import React, { useMemo } from 'react';
import useNotificationItem from '../Hooks/useNotificationItem';
import {
	NotificationItemWrapper,
	ReadButton,
	ReadButtonIcon,
	NotificaitonContentWrapper,
	NotificationTitle,
	NotificationText,
	AttachmentButton,
	AttachmentIcon,
	DisabledAttachmentIcon,
	InfoWrapper,
	ReadIndictorIcon,
} from './NotificationItem.sc';
import { ListChildComponentProps } from 'react-window';
import {
	mdiCheck,
	mdiCircleOutline,
	mdiFile,
	mdiLoading,
	mdiCheckboxBlankCircle,
	mdiCheckboxBlankCircleOutline,
} from '@mdi/js';
import mime from 'mime';
import NotificationTimestamp from './NotificationTimestamp';

export default function NotificationItem(props: ListChildComponentProps) {
	const { notification, handleMarkRead } = useNotificationItem(props);
	const { style } = props;

	return useMemo(() => {
		if (!notification) return null;
		const {
			delivered,
			title,
			content,
			attachments,
			createdAt,
		} = notification;
		const attachment =
			attachments && attachments.size > 0
				? attachments.get(0, null)
				: null;
		return (
			<NotificationItemWrapper delivered={delivered} style={style}>
				<ReadButton
					onMouseDown={delivered ? undefined : handleMarkRead}
				>
					{delivered ? (
						<ReadButtonIcon path={mdiCheck} />
					) : (
						<ReadButtonIcon path={mdiCircleOutline} />
					)}
				</ReadButton>
				<NotificaitonContentWrapper>
					<NotificationTitle>{title}</NotificationTitle>
					<NotificationText>{content}</NotificationText>
				</NotificaitonContentWrapper>
				{attachment ? (
					<AttachmentButton
						style={{
							pointerEvents: attachment.ready ? 'auto' : 'none',
						}}
						href={attachment.url}
						download={`${attachment.filename}.${mime.getExtension(
							attachment.contentType
						)}`}
					>
						{attachment.ready ? (
							<AttachmentIcon path={mdiFile} />
						) : (
							<DisabledAttachmentIcon path={mdiLoading} spin />
						)}
					</AttachmentButton>
				) : null}
				<InfoWrapper>
					<NotificationTimestamp timestamp={createdAt} />
					<ReadIndictorIcon
						path={
							delivered
								? mdiCheckboxBlankCircle
								: mdiCheckboxBlankCircleOutline
						}
					/>
				</InfoWrapper>
			</NotificationItemWrapper>
		);
	}, [handleMarkRead, notification, style]);
}

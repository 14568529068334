import React, { useMemo, useCallback, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ObjectId from 'bson-objectid';

import RecordsFeedGrid from '../../../../../Components/RecordsFeedGrid/RecordsFeedGrid';
import { RecordOf } from 'immutable';
import { IRootState } from '../../../../../Store/reducers/root';
import { SensorControlWrapper } from './SensorControl.sc';
import {
	fetchRecords,
	updateSearchText,
	updateRecord,
} from '../../../../../Components/RecordsFeed/actions';
import { ActivityWindow } from '../../../../../Components/Dashboard';
import { Activity } from '../../../../../Components/Dashboard/interfaces';
import { useUplinkDispatch } from '../../../../../Hooks/useUplinkDispatch';
import ModeSwitchRecord from './ModeSwitchRecord';

type Props = {
	activity: Activity;
};

function useSensorControlStore() {
	return useSelector((state: RecordOf<IRootState>) => state.sensorControl);
}

function getDefaultModeSwitch() {
	return {
		_id: new ObjectId().toHexString(),
		name: 'New Scenario',
		schedule: '0 */10 * * * *',
		active: false,
		scope: 'true',
		criteria: 'true',
		priority: 0,
		settings: {
			samplingInterval: 3,
			sendInterval: 120,
			failureRetry: 3,
		},
	};
}

const feedId = 'sensorControl';
const collection = 'modeSwitches';
export default function WindowedSensorControl(props: Props) {
	const {
		activity: { id },
	} = props;

	return (
		<ActivityWindow activityId={id}>
			<SensorControl />
		</ActivityWindow>
	);
}

export function SensorControl() {
	const sensorControlStore = useSensorControlStore();
	const {
		exhausted,
		loading,
		recordIds,
		records,
		search,
		updating,
	} = sensorControlStore;

	const containerRef = useRef<HTMLDivElement>(null);

	const dispatch = useUplinkDispatch();

	const handleAdd = useCallback(() => {
		const newRule = getDefaultModeSwitch();
		dispatch(
			updateRecord({
				collection,
				feedId,
				record: newRule,
				recordId: newRule._id,
			})
		);
	}, [dispatch]);

	const fetchData = useCallback(() => {
		if (!loading && !exhausted)
			dispatch(
				fetchRecords({
					fetched: recordIds.size,
					collection,
					search,
					searchKey: 'name',
					feedId,
				})
			);
	}, [loading, exhausted, recordIds.size, search, dispatch]);

	useEffect(() => {
		fetchData();
	}, [search, fetchData]);

	const handleSearchTextChange = useCallback(
		(search: string) => {
			dispatch(
				updateSearchText({
					feedId,
					collection,
					searchKey: 'name',
					searchText: search,
				})
			);
		},
		[dispatch]
	);

	return useMemo(
		() => (
			<SensorControlWrapper ref={containerRef}>
				<RecordsFeedGrid
					updating={updating}
					search={search}
					loading={loading}
					exhausted={exhausted}
					addButtonText="Add Scenario"
					searchPlaceholder="Search with scenario"
					itemHeight={480}
					xsCount={1}
					smCount={1}
					mdCount={2}
					lgCount={3}
					xlCount={3}
					recordIds={recordIds}
					records={records}
					onAdd={handleAdd}
					onEndReach={fetchData}
					onSearchTextChange={handleSearchTextChange}
				>
					{ModeSwitchRecord}
				</RecordsFeedGrid>
			</SensorControlWrapper>
		),
		[
			exhausted,
			loading,
			recordIds,
			records,
			search,
			updating,
			fetchData,
			handleAdd,
			handleSearchTextChange,
		]
	);
}

import React, { useMemo, useReducer } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { View } from '../App/Components/Common/View';
import theme from '../theme';

import {
	ActivityWindow,
	WorkspaceStoreRecord,
	workspaceReducer,
	dashboardReducer,
	workspaceParser,
	dashboardParser,
	DashboardStoreRecord,
} from '../App/Components/Dashboard';
import { Activity } from '../App/Components/Dashboard/interfaces';

import Embedded, { EmbeddedProvider } from '../App/Components/Embedded';
import { Map, List } from 'immutable';
import { mdiTestTube } from '@mdi/js';
import createEmbeddedReducer from '../App/Components/Embedded/Store/createEmbeddedReducer';
import LinkedDashboard from '../App/Components/Dashboard/Components/LinkedDashboard';
import noop from '../App/Helpers/noop';

const WorkspaceDemoWrapper = styled(View)`
	flex: 1;
	overflow: hidden;
`;

function TestAppBody() {
	return <div>test</div>;
}

function TestApp(props: { activity: Activity }) {
	const {
		activity: { id },
	} = props;
	return (
		<ActivityWindow activityId={id}>
			<Embedded storeId={id} type="test" component={TestAppBody} />
		</ActivityWindow>
	);
}

const reducer = createEmbeddedReducer({
	dashboard: {
		reducer: dashboardReducer,
		initialState: DashboardStoreRecord(),
		parser: dashboardParser,
	},
	workspace: {
		reducer: workspaceReducer,
		initialState: WorkspaceStoreRecord(),
		parser: workspaceParser,
	},
});

const applications = Map({
	test: {
		key: 'test',
		icon: mdiTestTube,
		name: 'Test App',
		component: TestApp,
	},
});

export default function DashboardDemo() {
	const [stores, dispatch] = useReducer(
		reducer,
		Map({
			testDashboard: {
				initialized: true,
				loading: false,
				message: '',
				state: DashboardStoreRecord({
					workspaces: List([
						{ id: 'test', name: 'Test' },
						{ id: 'test2', name: 'Test 2' },
					]),
					currentWorkspace: 0,
				}),
			},
			test: {
				initialized: true,
				loading: false,
				message: '',
				state: WorkspaceStoreRecord(),
			},
		})
	);

	return useMemo(() => {
		return (
			<ThemeProvider theme={theme}>
				<EmbeddedProvider value={{ stores, dispatch }}>
					<WorkspaceDemoWrapper>
						<LinkedDashboard
							username="test"
							groups={[]}
							userId="test"
							dashboardId="testDashboard"
							applications={applications}
							onRequestLogout={noop}
						/>
					</WorkspaceDemoWrapper>
				</EmbeddedProvider>
			</ThemeProvider>
		);
	}, [stores, dispatch]);
}

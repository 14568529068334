import React from 'react';
import { SvgIcon, SvgIconProps, Tooltip } from '@material-ui/core';

export function MouseOverIcon(props: SvgIconProps) {
	return (
		<Tooltip title="Mouse over chart to see details">
			<SvgIcon
				{...props}
				style={{
					color: 'rgba(255,255,255, 0.7)',
					alignSelf: 'center',
					marginLeft: '4px',
				}}
			>
				<path
					fill="currentColor"
					d="M10.76,8.69A0.76,0.76 0 0,0 10,9.45V20.9C10,21.32 10.34,21.66 10.76,21.66C10.95,21.66 11.11,21.6 11.24,21.5L13.15,19.95L14.81,23.57C14.94,23.84 15.21,24 15.5,24C15.61,24 15.72,24 15.83,23.92L18.59,22.64C18.97,22.46 19.15,22 18.95,21.63L17.28,18L19.69,17.55C19.85,17.5 20,17.43 20.12,17.29C20.39,16.97 20.35,16.5 20,16.21L11.26,8.86L11.25,8.87C11.12,8.76 10.95,8.69 10.76,8.69M15,10V8H20V10H15M13.83,4.76L16.66,1.93L18.07,3.34L15.24,6.17L13.83,4.76M10,0H12V5H10V0M3.93,14.66L6.76,11.83L8.17,13.24L5.34,16.07L3.93,14.66M3.93,3.34L5.34,1.93L8.17,4.76L6.76,6.17L3.93,3.34M7,10H2V8H7V10"
				/>
			</SvgIcon>
		</Tooltip>
	);
}

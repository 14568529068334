import React, {
	useMemo,
	forwardRef,
	Ref,
	ReactNode,
	CSSProperties,
} from 'react';
import { BodyInnerWrapper as SBodyInnerWrapper } from './BodyInnerWrapper.sc';
import BodySelectionBox from './BodySelectionBox';
import BodyContextMenu from './BodyContextMenu';
import { usePopoverAtCursor } from '../../../../Common/Popover';
import useObject from '../../../../../Hooks/useObject';

interface BodyInnerWrapperProps {
	style: CSSProperties;
	children?: ReactNode;
}

const BodyInnerWrapper = forwardRef(function(
	props: BodyInnerWrapperProps,
	ref: Ref<HTMLDivElement>
) {
	const { style, children } = props;
	const _style = useObject(style);
	const { open: openMenu, getPopoverProps } = usePopoverAtCursor();
	return useMemo(() => {
		// console.log('render BodyInnerWrapper');
		return (
			<>
				<SBodyInnerWrapper
					ref={ref}
					style={_style}
					onContextMenu={openMenu}
				>
					{children}
					<BodySelectionBox />
				</SBodyInnerWrapper>
				<BodyContextMenu {...getPopoverProps()} />
			</>
		);
	}, [ref, _style, children, getPopoverProps, openMenu]);
});

export default BodyInnerWrapper;

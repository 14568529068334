import OlStyle from 'ol/style/Style';
import OlIcon from 'ol/style/Icon';
import OlText from 'ol/style/Text';
import OlFill from 'ol/style/Fill';
import OlStroke from 'ol/style/Stroke';

import treeIcon from '../../Assets/images/tree_map_icon.png';

export const normalStyle = (feature: any) =>
	new OlStyle({
		zIndex: 50,
		image: new OlIcon({
			src: treeIcon,
			anchor: [0.5, 1],
			size: [120, 120],
			scale: 0.3,
		}),
		text: new OlText({
			overflow: true,
			offsetY: -40,
			scale: 1.1,
			fill: new OlFill({
				color: [0, 160, 142, 1],
			}),
			stroke: new OlStroke({
				width: 1.5,
				color: [255, 255, 255, 1],
			}),
			text: feature.get('treeId'),
		}),
	});

export const selectedStyle = (feature: any) =>
	new OlStyle({
		zIndex: 999,
		image: new OlIcon({
			src: treeIcon,
			size: [120, 120],
			anchor: [0.5, 1],
			scale: 0.5,
		}),
		text: new OlText({
			overflow: true,
			offsetY: -65,
			scale: 1.5,
			fill: new OlFill({
				color: [255, 128, 0, 1],
			}),
			stroke: new OlStroke({
				width: 2.3,
				color: [255, 255, 255, 1],
			}),
			text: feature.get('treeId'),
		}),
	});

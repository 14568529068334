import {
	CSSProperties,
	RefObject,
	ReactNode,
	MouseEvent as RMouseEvent,
} from 'react';
export enum PopoverEnterFrom {
	center = 'center',
	top = 'top',
	left = 'left',
	right = 'right',
	bottom = 'bottom',
}

export interface PopoverStyleProps {
	left: number;
	top: number;
	width?: number;
	height?: number;
}

export interface usePopoverOptions {
	containerRef?: RefObject<HTMLElement>;
	topOffset?: number;
	leftOffset?: number;
}

export interface PopoverControlProps extends PopoverStyleProps {
	opened: boolean;
	onRequestClose(): void;
}

export interface PopoverProps extends PopoverControlProps {
	style?: CSSProperties;
	className?: string;
	containerRef?: RefObject<HTMLElement>;
	focusTargetRef?: RefObject<HTMLElement>;
	disabled?: boolean;
	edgeDodging?: boolean;
	withBackdrop?: boolean;
	blurOnMouseDownOutside?: boolean;
	closeOnMouseDownOutside?: boolean;
	closeOnEsc?: boolean;
	focusOnOpen?: boolean;
	enterFrom?: PopoverEnterFrom;
	enterFromDistance?: number;
	timeout?: number;
	onMouseDownOutside?(e: RMouseEvent<HTMLDivElement>): void;
	children?: ReactNode;
}

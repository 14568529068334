import { useCallback, useContext } from 'react';
import windowContext from '../context';
import { StandardMoveHandleProps } from '../../../interfaces';

export default function useStandardMoveHandle(props: StandardMoveHandleProps) {
	const {
		onMoveInit,
		onMoveStart,
		onMove,
		onMoveEnd,
		onMoveCancel,
	} = useContext(windowContext);
	const { threshold = 0, timeout = 0, modifyKey = null } = props;

	return useCallback(
		(e: React.MouseEvent) => {
			let accumX = 0,
				accumY = 0;
			let timer: number;
			let started = false;

			function setupMoveResponder() {
				timer = setTimeout(() => {
					initMove();
				}, timeout);
				window.addEventListener('mouseup', cancelMove);
				window.addEventListener('keyup', cancelMoveByKey);
			}

			function initMove() {
				onMoveInit({ movementX: 0, movementY: 0, accumX, accumY });
				window.addEventListener('mousemove', prepareMove);
			}

			function prepareMove(e: MouseEvent) {
				const { movementX, movementY } = e;
				accumX += movementX;
				accumY += movementY;

				if (modifyKey && !e[modifyKey]) {
					cancelMove();
				}

				const distance = (accumX ** 2 + accumY ** 2) ** 0.5;
				if (distance > threshold) {
					started = true;
					onMoveStart({
						movementX,
						movementY,
						accumX,
						accumY,
						e,
					});
					setupMove();
				}
			}

			function setupMove() {
				window.addEventListener('mousemove', move);
				window.removeEventListener('mousemove', prepareMove);
			}

			function move(e: MouseEvent) {
				const { movementX, movementY } = e;
				accumX += movementX;
				accumY += movementY;
				onMove({
					movementX,
					movementY,
					accumX,
					accumY,
					e,
				});
			}

			function cancelMove() {
				if (started) {
					onMoveEnd({ movementX: 0, movementY: 0, accumX, accumY });
				} else {
					onMoveCancel({
						movementX: 0,
						movementY: 0,
						accumX,
						accumY,
					});
				}

				clearTimeout(timer);
				window.removeEventListener('mousemove', prepareMove);
				window.removeEventListener('mousemove', move);
				window.removeEventListener('mouseup', cancelMove);
				window.removeEventListener('keyup', cancelMoveByKey);
			}

			function cancelMoveByKey(e: KeyboardEvent) {
				if (started) {
					onMoveEnd({ movementX: 0, movementY: 0, accumX, accumY });
				} else {
					onMoveCancel({
						movementX: 0,
						movementY: 0,
						accumX,
						accumY,
					});
				}

				clearTimeout(timer);
				window.removeEventListener('mousemove', prepareMove);
				window.removeEventListener('mousemove', move);
				window.removeEventListener('mouseup', cancelMove);
				window.removeEventListener('keyup', cancelMoveByKey);
			}

			if (modifyKey === null || e[modifyKey]) {
				setupMoveResponder();
			}
		},
		[
			onMoveInit,
			onMoveStart,
			onMove,
			onMoveEnd,
			onMoveCancel,
			threshold,
			timeout,
			modifyKey,
		]
	);
}

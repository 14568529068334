import React, {
	useCallback,
	useMemo,
	useState,
	ChangeEvent as RChangeEvent,
} from 'react';
import { FormatModalInput as SFormatModalInput } from './FormatModalInput.sc';
import useAsState from '../../../../../Hooks/useAsState';

function defaultValidateValue() {
	return true;
}
interface FormatModalInputProps {
	autoFocus?: boolean;
	value: string;
	updateValue(value: string): void;
	validateValue?(value: string): boolean;
}
function FormatModalInput(props: FormatModalInputProps) {
	const {
		autoFocus,
		value,
		updateValue,
		validateValue = defaultValidateValue,
	} = props;
	const [_value, setValue] = useAsState(value);
	const [valid, setValid] = useState(validateValue(value));

	const handleChange = useCallback(
		(e: RChangeEvent<HTMLInputElement>) => {
			const value = e.target.value;
			setValue(value);
			setValid(validateValue(value));
		},
		[setValue, validateValue]
	);
	const handleBlur = useCallback(() => {
		if (valid && _value !== value) updateValue(_value);
	}, [valid, _value, value, updateValue]);

	return useMemo(() => {
		return (
			<SFormatModalInput
				autoFocus={autoFocus}
				tabIndex={0}
				valid={valid}
				value={_value}
				onChange={handleChange}
				onBlur={handleBlur}
			/>
		);
	}, [autoFocus, valid, _value, handleChange, handleBlur]);
}

export default FormatModalInput;

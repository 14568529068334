import createActionCreator from '../../../../../Helpers/createActionCreator';
import { Dict } from '../../../../../../types/Common';
import {
	FilterField,
	// Tree,
	Sensor,
	TiltingAngleChartData,
} from '../interfaces';

interface IFetchFeaturesPayload {
	bbox: GeoJSON.BBox;
	filters: Dict<string>;
	collection: string;
	locationField: string;
	additionalFields?: { [key: string]: string };
	select?: { [key: string]: 1 };
}

interface IUpdateSearchPayload {
	searchText: string;
	key: string;
	collection: string;
	additionalFields?: { [key: string]: string };
	select?: { [key: string]: 1 };
}

export const updateSearchText = createActionCreator(
	'MAP/UPDATE_SEARCH_TEXT',
	{} as IUpdateSearchPayload
);

export const fetchFeatures = createActionCreator(
	'MAP/FETCH_FEATURES',
	{} as IFetchFeaturesPayload
);

interface ICountFeaturePayload {
	filters: Dict<string>;
	collection: string;
	locationField: string;
	select?: { [key: string]: 1 };
}

export const countFeatures = createActionCreator(
	'MAP/COUNT_FEATURES',
	{} as ICountFeaturePayload
);

interface UpdateFeatureCountPayload {
	count: number;
}

export const updateFeatureCount = createActionCreator(
	'MAP/UPDATE_COUNT',
	{} as UpdateFeatureCountPayload
);

export interface FeatureWithId extends GeoJSON.Feature {
	properties: {
		_id: string;
	};
}

interface IImportFeaturesPayload {
	bbox: GeoJSON.BBox;
	features: FeatureWithId[];
}

export const importFeatures = createActionCreator(
	'MAP/FETCH_FEATURES_SUCCESS',
	{} as IImportFeaturesPayload
);

interface ISelectFeaturePayload {
	feature: GeoJSON.Feature | null;
}

export const selectFeature = createActionCreator(
	'MAP/SELECT_FEATURE',
	{} as ISelectFeaturePayload
);

interface IUpdateCenterPayload {
	center: number[];
}

export const updateCenter = createActionCreator(
	'MAP/UPDATE_CENTER',
	{} as IUpdateCenterPayload
);

interface IUpdateZoomPayload {
	zoom: number;
}

export const updateZoom = createActionCreator(
	'MAP/UPDATE_ZOOM',
	{} as IUpdateZoomPayload
);

interface IUpdateRotationPayload {
	rotation: number;
}

export const updateRotation = createActionCreator(
	'MAP/UPDATE_ROTATION',
	{} as IUpdateRotationPayload
);

interface IUpdateSearchResultsPayload {
	searchResults: GeoJSON.Feature<GeoJSON.Point>[];
}

export const updateSearchResults = createActionCreator(
	'MAP/UPDATE_SEARCH_RESULTS',
	{} as IUpdateSearchResultsPayload
);

interface IFocusSearchResultPayload {
	index: number | null;
}

export const focusSearchResult = createActionCreator(
	'MAP/FOCUS_SEARCH_RESULT',
	{} as IFocusSearchResultPayload
);

interface IAddFilterPayload {
	key: string;
	filterFormula: string;
	filterName: string;
}

export const addFilter = createActionCreator(
	'MAP/ADD_FILTER',
	{} as IAddFilterPayload
);

interface IRemoveFilterPayload {
	key: string;
}

export const removeFilter = createActionCreator(
	'MAP/REMOVE_FILTER',
	{} as IRemoveFilterPayload
);

export const fetchFilterFields = createActionCreator('MAP/FETCH_FILTER_FIELDS');

interface IImportFilterFieldsPayload {
	fields: FilterField[];
}

export const importFilterFields = createActionCreator(
	'MAP/IMPORT_FILTER_FIELDS',
	{} as IImportFilterFieldsPayload
);

interface FetchTreeDetailsPayload {
	treeId: string;
}

export const fetchTreeDetails = createActionCreator(
	'MAP/FETCH_TREE_DETAILS',
	{} as FetchTreeDetailsPayload
);

interface ImportTreeDetailsPayload {
	tree: any;
	sensors: Sensor[];
	tiltingAngleData: {
		sensorId: string;
		data: TiltingAngleChartData;
	}[];
}

export const importTreeDetails = createActionCreator(
	'MAP/IMPORT_TREE_DETAILS',
	{} as ImportTreeDetailsPayload
);

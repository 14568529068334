import React, { useMemo } from 'react';
import {
	NavigationWrapper,
	NavigationControlWrapper,
	NavigationLogo,
	NavigationTitleWrapper,
	NavigationTitleText,
	NavigationSubtitleText,
} from './Navigation.sc';
import { NavigationProps } from '../interfaces';

import projectLogo from '../../../../../Assets/images/projectLogo.png';
import { DASHBOARD_ZH_NAME, DASHBOARD_ENG_NAME } from '../constants';
import UserPanel from '../../UserPanel';
import NotificationPanel from '../../NotificationPanel';

export default function Navigation(props: NavigationProps) {
	return useMemo(() => {
		return (
			<NavigationWrapper>
				<NavigationLogo src={projectLogo} />
				<NavigationTitleWrapper>
					<NavigationTitleText>
						{DASHBOARD_ZH_NAME}
					</NavigationTitleText>
					<NavigationSubtitleText>
						{DASHBOARD_ENG_NAME}
					</NavigationSubtitleText>
				</NavigationTitleWrapper>
				<NavigationControlWrapper>
					<UserPanel />
					<NotificationPanel />
				</NavigationControlWrapper>
			</NavigationWrapper>
		);
	}, []);
}

import { useEffect, RefObject } from 'react';
import MouseTrap from 'mousetrap';

interface KeyBindMap {
	[keyBind: string]: (e: KeyboardEvent) => void;
}

interface UseKeyControlOptions {
	ref?: RefObject<HTMLElement>;
	eventType?: 'keydown' | 'keyup';
	preventDefault?: boolean;
	stopPropagation?: boolean;
	disabled?: boolean;
}

MouseTrap.prototype.stopCallback = () => false;

export default function useHotKeys(
	keyBindMap: KeyBindMap,
	options: UseKeyControlOptions = {}
) {
	const {
		ref,
		eventType = 'keydown',
		preventDefault = false,
		stopPropagation = false,
		disabled = false,
	} = options;
	useEffect(() => {
		if (disabled) return;
		const keybinds = Object.keys(keyBindMap);
		if (!ref) {
			const MouseTrapInstance = new MouseTrap();
			keybinds.forEach((keyBind) => {
				const handler = keyBindMap[keyBind];
				const _handler = (e: ExtendedKeyboardEvent) => {
					if (preventDefault) e.preventDefault();
					if (stopPropagation) e.stopPropagation();
					handler(e);
				};
				MouseTrapInstance.bind(keyBind, _handler, eventType);
			});
			return () => {
				keybinds.forEach((keyBind) => {
					MouseTrapInstance.unbind(keyBind, eventType);
				});
			};
		} else {
			const element = ref.current;
			if (!element) return;
			const MouseTrapInstance = new MouseTrap(element);
			keybinds.forEach((keyBind) => {
				const handler = keyBindMap[keyBind];
				const _handler = (e: ExtendedKeyboardEvent) => {
					if (preventDefault) e.preventDefault();
					if (stopPropagation) e.stopPropagation();
					handler(e);
				};
				MouseTrapInstance.bind(keyBind, _handler, eventType);
			});
			return () => {
				keybinds.forEach((keyBind) => {
					MouseTrapInstance.unbind(keyBind, eventType);
				});
			};
		}
	}, [disabled, ref, keyBindMap, preventDefault, stopPropagation, eventType]);
}

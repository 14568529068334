export enum ResizeDirection {
	TOP,
	BOTTOM,
	LEFT,
	RIGHT,
	TOP_LEFT,
	TOP_RIGHT,
	BOTTOM_LEFT,
	BOTTOM_RIGHT,
}

export interface Movement {
	movementX: number;
	movementY: number;
	accumX: number;
	accumY: number;
	e?: MouseEvent | React.MouseEvent;
}

export interface ResizeMovement extends Movement {
	direction: ResizeDirection;
}

export interface StandardMoveHandleProps {
	threshold?: number;
	timeout?: number;
	modifyKey?: 'ctrlKey' | 'altKey' | 'shiftKey' | null;
}

export interface StandardResizeHandleProps {
	direction: ResizeDirection;
	threshold?: number;
	timeout?: number;
	modifyKey?: 'ctrlKey' | 'altKey' | 'shiftKey' | null;
}

export interface StandardWindowState {
	moving: boolean;
	resizing: boolean;
	x1: number;
	x2: number;
	y1: number;
	y2: number;
	left: number;
	top: number;
	width: number;
	height: number;
}

export enum MaximizeType {
	FULL,
	LEFT,
	RIGHT,
	REGULAR,
}

export interface Bound {
	x1: number;
	x2: number;
	y1: number;
	y2: number;
}
export interface DropZone extends Bound {
	id: any;
}

export interface TileProps {
	dropZones: DropZone[];
	onEnterZone: (dropZone: DropZone, e: MouseEvent) => any;
	onLeaveZones: (e: MouseEvent) => any;
	onDrop: (dropZone: DropZone, e: MouseEvent) => any;
}

import styled from 'styled-components';
import { View } from '../../../../Common/View';

export const WindowFrameWrapper = styled(View)`
	position: absolute;
	overflow: hidden;
	background-color: ${({
		theme: {
			background: { light },
		},
	}) => light};
	box-sizing: border-box;

	${({ theme: { shadow } }) => shadow[2]}
`;

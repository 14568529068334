import React, {
	useMemo,
	useState,
	useEffect,
	useCallback,
	forwardRef,
	CSSProperties,
	HTMLAttributes,
	Ref,
	MouseEvent as RMouseEvent,
} from 'react';
import { createPortal } from 'react-dom';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import {
	PopoverWrapper as SPopoverWrapper,
	PopoverBackdrop,
} from './Popover.sc';
import useEdgeDodging from '../../../../Hooks/useEdgeDodging';
import useObject from '../../../../Hooks/useObject';
import useHotKeys from '../../../../Hooks/useHotKeys';
import useForwardedRef from '../../../../Hooks/useForwardedRef';
import stopPropagation from '../../../../Helpers/stopPropagation';
import preventDefault from '../../../../Helpers/preventDefault';
import { PopoverEnterFrom, PopoverProps } from '../interfaces';

function getInOutStyle(
	status: TransitionStatus,
	enterFrom: PopoverEnterFrom,
	enterFromDistance: number
): CSSProperties {
	if (status === 'entered') return { opacity: 1 };
	switch (enterFrom) {
		case PopoverEnterFrom.center:
			return { opacity: 0 };
		case PopoverEnterFrom.left:
			return {
				opacity: 0,
				transform: `translateX(-${enterFromDistance}px)`,
			};
		case PopoverEnterFrom.top:
			return {
				opacity: 0,
				transform: `translateY(-${enterFromDistance}px)`,
			};
		case PopoverEnterFrom.right:
			return {
				opacity: 0,
				transform: `translateX(${enterFromDistance}px)`,
			};
		case PopoverEnterFrom.bottom:
			return {
				opacity: 0,
				transform: `translateY(${enterFromDistance}px)`,
			};
		default:
			return {};
	}
}

interface Size {
	width: number;
	height: number;
}

interface PopoverWrapperProps extends HTMLAttributes<HTMLDivElement> {
	setSize(size: Size): void;
}

const PopoverWrapper = forwardRef(function(
	props: PopoverWrapperProps,
	ref: Ref<HTMLDivElement>
) {
	const { setSize, ...restProps } = props;
	const _ref = useForwardedRef(ref);

	useEffect(() => {
		const self = _ref.current;
		if (!self) return;
		const { width, height } = self.getBoundingClientRect();
		setSize({ width, height });
	}, [_ref, setSize]);

	return <SPopoverWrapper ref={_ref} {...restProps} />;
});

const Popover = forwardRef(function(
	props: PopoverProps,
	ref: Ref<HTMLDivElement>
) {
	const {
		style,
		className,
		top,
		left,
		enterFrom = PopoverEnterFrom.center,
		enterFromDistance = 100,
		edgeDodging = true,
		withBackdrop = true,
		closeOnMouseDownOutside = true,
		closeOnEsc = true,
		blurOnMouseDownOutside = true,
		focusOnOpen = false,
		timeout = 150,
		containerRef,
		focusTargetRef,
		opened,
		disabled = false,
		onRequestClose,
		onMouseDownOutside,
		children,
	} = props;
	const _ref = useForwardedRef(ref);
	const _focusTargetRef = focusTargetRef || _ref;
	const _style = useObject(style);
	const [size, setSize] = useState<Size>({ width: 0, height: 0 });
	const {
		bound: { top: dodgedTop, left: dodgedLeft },
		updateBound,
	} = useEdgeDodging(
		{
			top,
			left,
			...size,
		},
		{
			containerRef,
			disabled: disabled || !edgeDodging,
		}
	);

	useEffect(() => {
		if (!disabled && edgeDodging) {
			window.addEventListener('resize', updateBound);
			return () => {
				window.removeEventListener('resize', updateBound);
			};
		}
	}, [disabled, edgeDodging, updateBound]);

	useEffect(() => {
		if (!disabled && opened && !withBackdrop) {
			const handleMouseDownOutside = () => {
				if (blurOnMouseDownOutside) {
					const activeElement = document.activeElement;
					if (activeElement instanceof HTMLElement)
						activeElement.blur();
				}
				closeOnMouseDownOutside && onRequestClose();
			};
			window.addEventListener('mousedown', handleMouseDownOutside);
			return () => {
				window.removeEventListener('mousedown', handleMouseDownOutside);
			};
		}
	}, [
		disabled,
		opened,
		withBackdrop,
		blurOnMouseDownOutside,
		closeOnMouseDownOutside,
		onRequestClose,
	]);

	useEffect(() => {
		if (!disabled && focusOnOpen && opened) {
			const focusTarget = _focusTargetRef.current;
			if (focusTarget) focusTarget.focus({ preventScroll: true });
		}
	}, [disabled, focusOnOpen, opened, _focusTargetRef]);

	const handleMouseDownOutside = useCallback(
		(e: RMouseEvent<HTMLDivElement>) => {
			e.stopPropagation();
			onMouseDownOutside && onMouseDownOutside(e);
			if (blurOnMouseDownOutside) {
				const activeElement = document.activeElement;
				if (activeElement instanceof HTMLElement) activeElement.blur();
			}
			closeOnMouseDownOutside && onRequestClose();
		},
		[
			blurOnMouseDownOutside,
			closeOnMouseDownOutside,
			onMouseDownOutside,
			onRequestClose,
		]
	);

	const backdrop = useMemo(() => {
		if (disabled || !withBackdrop) return null;
		const container = containerRef ? containerRef.current : document.body;
		if (!container) return null;
		return createPortal(
			<PopoverBackdrop
				onMouseDown={handleMouseDownOutside}
				onContextMenu={preventDefault}
			/>,
			container
		);
	}, [disabled, withBackdrop, containerRef, handleMouseDownOutside]);

	//HotKeys
	const keyBindMap = useMemo(
		() => ({
			esc: onRequestClose,
		}),
		[onRequestClose]
	);
	useHotKeys(keyBindMap, { disabled: !closeOnEsc });

	return useMemo(() => {
		if (disabled) return null;
		const container = containerRef ? containerRef.current : document.body;
		if (!container) return null;
		return (
			<Transition
				in={opened}
				timeout={timeout}
				onEntering={updateBound}
				unmountOnExit
			>
				{(status) => {
					return createPortal(
						<>
							{backdrop}
							<PopoverWrapper
								tabIndex={-1}
								ref={_ref}
								style={{
									..._style,
									...getInOutStyle(
										status,
										enterFrom,
										enterFromDistance
									),
									top: dodgedTop,
									left: dodgedLeft,
								}}
								className={className}
								setSize={setSize}
								onMouseDown={stopPropagation}
							>
								{children}
							</PopoverWrapper>
						</>,
						container
					);
				}}
			</Transition>
		);
	}, [
		_ref,
		containerRef,
		_style,
		className,
		enterFrom,
		enterFromDistance,
		disabled,
		opened,
		timeout,
		dodgedLeft,
		dodgedTop,
		updateBound,
		backdrop,
		children,
	]);
});

export default Popover;

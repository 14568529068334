import { Dict } from '../../../../types/Common';
import {
	ITableStore,
	FilterType,
	SortOrder,
	DataType,
	ColumnJS,
} from '../interfaces';
import createActionCreator from '../../../Helpers/createActionCreator';

export type UpdateTablePayload = Partial<ITableStore>;
export const updateTable = createActionCreator(
	'TABLE/UPDATE',
	{} as UpdateTablePayload
);

export interface DropColumnsPayload {
	columnKeys: string[] | null;
}
export const dropColumns = createActionCreator(
	'TABLE/DROP_COLUMNS',
	{} as DropColumnsPayload
);
export interface InsertColumnsPayload {
	index: number | null;
	columnKeys: string[];
	columns: Dict<ColumnJS> | null;
}
export const insertColumns = createActionCreator(
	'TABLE/INSERT_COLUMNS',
	{} as InsertColumnsPayload
);

export interface RearrangeColumnsPayload {
	from: number;
	to: number;
}
export const rearrangeColumns = createActionCreator(
	'TABLE/REARRANGE_COLUMNS',
	{} as RearrangeColumnsPayload
);

export interface ResizeColumnPayload {
	columnKey: string;
	width: number;
}
export const resizeColumn = createActionCreator(
	'TABLE/RESIZE_COLUMN',
	{} as ResizeColumnPayload
);

export interface ResizeRowPayload {
	recordId: string;
	height: number;
}
export const resizeRow = createActionCreator(
	'TABLE/RESIZE_ROW',
	{} as ResizeRowPayload
);

export interface RenameColumnPayload {
	columnKey: string;
	name: string;
}
export const renameColumn = createActionCreator(
	'TABLE/RENAME_COLUMN',
	{} as RenameColumnPayload
);

export interface UpdateColumnEditablePayload {
	columnKey: string;
	editable: boolean;
}
export const updateColumnEditable = createActionCreator(
	'TABLE/UPDATE_COLUMN_EDITABLE',
	{} as UpdateColumnEditablePayload
);

export interface UpdateColumnReadonlyPayload {
	columnKey: string;
	readonly: boolean;
}
export const updateColumnReadonly = createActionCreator(
	'TABLE/UPDATE_COLUMN_READONLY',
	{} as UpdateColumnReadonlyPayload
);

export interface UpdateColumnSortablePayload {
	columnKey: string;
	sortable: boolean;
}
export const updateColumnSortable = createActionCreator(
	'TABLE/UPDATE_COLUMN_SORTABLE',
	{} as UpdateColumnSortablePayload
);

export interface UpdateColumnDataTypePayload {
	columnKey: string;
	dataType: DataType;
}
export const updateColumnDataType = createActionCreator(
	'TABLE/UPDATE_COLUMN_DATA_TYPE',
	{} as UpdateColumnDataTypePayload
);

export interface UpdateColumnBooleanFormatPayload {
	columnKey: string;
	booleanFormat: string;
}
export const updateColumnBooleanFormat = createActionCreator(
	'TABLE/UPDATE_COLUMN_BOOLEAN_FORMAT',
	{} as UpdateColumnBooleanFormatPayload
);

export interface UpdateColumnNumberFormatPayload {
	columnKey: string;
	numberFormat: string;
}
export const updateColumnNumberFormat = createActionCreator(
	'TABLE/UPDATE_COLUMN_NUMBER_FORMAT',
	{} as UpdateColumnNumberFormatPayload
);

export interface UpdateColumnDateFormatPayload {
	columnKey: string;
	dateFormat: string;
}
export const updateColumnDateFormat = createActionCreator(
	'TABLE/UPDATE_COLUMN_DATE_FORMAT',
	{} as UpdateColumnDateFormatPayload
);

export interface UpdateColumnUnitPayload {
	columnKey: string;
	unit: string;
}
export const updateColumnUnit = createActionCreator(
	'TABLE/UPDATE_COLUMN_UNIT',
	{} as UpdateColumnUnitPayload
);

export interface DropColumnFiltersPayload {
	columnKeys: string[] | null;
}
export const dropColumnFilters = createActionCreator(
	'TABLE/DROP_COLUMN_FILTERS',
	{} as DropColumnFiltersPayload
);

export interface UpdateColumnFilterTypePayload {
	columnKey: string;
	type: FilterType;
}
export const updateColumnFilterType = createActionCreator(
	'TABLE/UPDATE_COLUMN_FILTER_TYPE',
	{} as UpdateColumnFilterTypePayload
);

export interface DropColumnFilterValuePayload {
	columnKey: string;
	type: string;
}
export const dropColumnFilterValue = createActionCreator(
	'TABLE/DROP_COLUMN_FILTER_VALUE',
	{} as DropColumnFilterValuePayload
);

export interface UpdateColumnFilterValuePayload {
	columnKey: string;
	value: any;
	type: string;
}
export const updateColumnFilterValue = createActionCreator(
	'TABLE/UPDATE_COLUMN_FILTER_VALUE',
	{} as UpdateColumnFilterValuePayload
);

export interface UpdateColumnFormulaValuePayload {
	columnKey: string;
	value: string;
}
export const updateColumnFormulaValue = createActionCreator(
	'TABLE/UPDATE_COLUMN_FORMULA_VALUE',
	{} as UpdateColumnFormulaValuePayload
);

export interface DropSortsPayload {
	columnKeys: string[] | null;
}
export const dropSorts = createActionCreator(
	'TABLE/DROP_SORTS',
	{} as DropSortsPayload
);

export interface InsertSortsPayload {
	index: number | null;
	sorts: Dict<SortOrder>[];
}
export const insertSorts = createActionCreator(
	'TABLE/INSERT_SORTS',
	{} as InsertSortsPayload
);

export interface ReplaceSortsPayload {
	sorts: Dict<SortOrder>[];
}
export const replaceSorts = createActionCreator(
	'TABLE/REPLACE_SORTS',
	{} as ReplaceSortsPayload
);

export interface UpdateSortPayload {
	sort: Dict<SortOrder>;
}
export const updateSort = createActionCreator(
	'TABLE/UPDATE_SORT',
	{} as UpdateSortPayload
);

export interface UpdatePagePayload {
	page: number;
}
export const updatePage = createActionCreator(
	'TABLE/UPDATE_PAGE',
	{} as UpdatePagePayload
);

export interface UpdatePageSizePayload {
	pageSize: number;
}
export const updatePageSize = createActionCreator(
	'TABLE/UPDATE_PAGE_SIZE',
	{} as UpdatePageSizePayload
);

export const refreshRecords = createActionCreator('TALBE/REFRESH_RECORDS');

export interface ReplaceRecordsPayload {
	recordIds: string[];
	records: Dict<Dict<any>>;
	recordCount: number | null;
}
export const replaceRecords = createActionCreator(
	'TABLE/REPLACE_RECORDS',
	{} as ReplaceRecordsPayload
);

export interface ReplaceRecordPayload {
	record: Dict<any>;
	recordId: string;
}
export const replaceRecord = createActionCreator(
	'TABLE/REPLACE_RECORD',
	{} as ReplaceRecordPayload
);

export interface DropUnmergedRecordsPayload {
	keys: { recordId: string; columnKey: string }[] | null;
}
export const dropUnmergedRecords = createActionCreator(
	'TABLE/DROP_UNMERGED_RECORDS',
	{} as DropUnmergedRecordsPayload
);

export interface UpdateUnmergedRecordsPayload {
	changes: { columnKey: string; recordId: string; value: any }[];
}
export const updateUnmergedRecords = createActionCreator(
	'TABLE/UPDATE_UNMERGED_RECORDS',
	{} as UpdateUnmergedRecordsPayload
);

export interface RequestMergeRecordsPayload {
	keys: { recordId: string; columnKey: string }[] | null;
}
export const requestMergeRecords = createActionCreator(
	'TABLE/REQUEST_MERGE_RECORDS',
	{} as RequestMergeRecordsPayload
);
export interface MergeRecordsSuccessPayload {
	keys: { recordId: string; columnKey: string }[];
}
export const mergeRecordsSuccess = createActionCreator(
	'TABLE/MERGE_RECORDS_SUCCESS',
	{} as MergeRecordsSuccessPayload
);
export interface MergeRecordsFailurePayload {
	keys: { recordId: string; columnKey: string }[];
}
export const mergeRecordsFailure = createActionCreator(
	'TABLE/MERGE_RECORDS_FAILURE',
	{} as MergeRecordsFailurePayload
);

export interface DropCellsEditingByPayload {
	keys: { recordId: string; columnKey: string }[];
}
export const dropCellsEditingBy = createActionCreator(
	'TABLE/DROP_CELLS_EDITING_BY',
	{} as DropCellsEditingByPayload
);
export interface UpdateCellsEditingByPayload {
	changes: { columnKey: string; recordId: string; editingBy: any }[];
}
export const updateCellsEditingBy = createActionCreator(
	'TABLE/UPDATE_CELLS_EDITING_BY',
	{} as UpdateCellsEditingByPayload
);

export interface ExportRecordsPayload {
	from: number;
	to: number;
	all: boolean;
	filename: string;
	extension: string;
}

export const exportRecords = createActionCreator(
	'TABLE/EXPORT_RECORDS',
	{} as ExportRecordsPayload
);

export interface ImportRecordsFilePayload {
	file: File;
}

export const importRecordsFile = createActionCreator(
	'TABLE/IMPORT_RECORDS_FILE',
	{} as ImportRecordsFilePayload
);
export interface UpdateLoadingPayload {
	loading: boolean;
}

export const updateLoading = createActionCreator(
	'TABLE/UPDATE_LOADING',
	{} as UpdateLoadingPayload
);

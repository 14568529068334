import React, {
	useState,
	useCallback,
	useMemo,
	forwardRef,
	Ref,
	ComponentType,
} from 'react';
import { MenuWrapper } from './Menu.sc';
import useForwardedRef from '../../../../Hooks/useForwardedRef';
import useHotKeys from '../../../../Hooks/useHotKeys';
import useObject from '../../../../Hooks/useObject';
import { MenuProps, MenuItemProps, MenuChildrenProps } from '../interfaces';

interface MenuItemGroupProps {
	data: MenuItemProps[];
	focusedIndex: number | null;
	setFocusedIndex(key: number | null): void;
	onRequestClose(): void;
	getItemKey: (index: number) => string;
	render: ComponentType<MenuChildrenProps>;
}

function MenuItemGroup(props: MenuItemGroupProps) {
	const {
		data,
		focusedIndex,
		setFocusedIndex,
		onRequestClose,
		getItemKey,
		render: Item,
	} = props;
	return useMemo(() => {
		const itemCount = data.length;
		return (
			<>
				{Array.from({ length: itemCount }).map((_, index) => {
					return (
						<Item
							key={getItemKey(index)}
							data={data}
							index={index}
							focusedIndex={focusedIndex}
							setFocusedIndex={setFocusedIndex}
							onRequestClose={onRequestClose}
						/>
					);
				})}
			</>
		);
	}, [data, focusedIndex, setFocusedIndex, onRequestClose, getItemKey]);
}

const defaultGetItemKey = (index: number) => index.toString();

export const Menu = forwardRef(function(
	props: MenuProps,
	ref: Ref<HTMLDivElement>
) {
	const {
		focusTargetRef,
		disabled,
		opened,
		timeout = 50,
		focusOnOpen = true,
		onRequestClose,
		data,
		getItemKey = defaultGetItemKey,
		render: Item,
		...restProps
	} = props;
	const _restProps = useObject(restProps);
	const _ref = useForwardedRef(ref);
	const _focusTargetRef = focusTargetRef || _ref;
	const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

	const handleRequestClose = useCallback(() => {
		onRequestClose();
		setFocusedIndex(null);
	}, [onRequestClose]);

	// HotKeys
	const itemCount = data.length;
	const handleDown = useCallback(() => {
		setFocusedIndex(
			focusedIndex === null ? 0 : (focusedIndex + 1) % itemCount
		);
	}, [itemCount, focusedIndex, setFocusedIndex]);
	const handleUp = useCallback(() => {
		setFocusedIndex(
			focusedIndex === null
				? itemCount - 1
				: (itemCount + focusedIndex - 1) % itemCount
		);
	}, [itemCount, focusedIndex, setFocusedIndex]);
	const keybindMap = useMemo(
		() => ({
			down: handleDown,
			up: handleUp,
		}),
		[handleDown, handleUp]
	);
	useHotKeys(keybindMap, {
		ref: focusTargetRef,
		disabled: disabled || !opened,
	});

	return useMemo(() => {
		// console.log('render Menu');
		return (
			<MenuWrapper
				ref={_ref}
				focusTargetRef={_focusTargetRef}
				disabled={disabled}
				opened={opened}
				timeout={timeout}
				focusOnOpen={focusOnOpen}
				onRequestClose={handleRequestClose}
				{..._restProps}
			>
				<MenuItemGroup
					getItemKey={getItemKey}
					data={data}
					focusedIndex={focusedIndex}
					setFocusedIndex={setFocusedIndex}
					onRequestClose={handleRequestClose}
					render={Item}
				/>
			</MenuWrapper>
		);
	}, [
		_ref,
		_focusTargetRef,
		disabled,
		opened,
		timeout,
		focusOnOpen,
		data,
		focusedIndex,
		_restProps,
		handleRequestClose,
		getItemKey,
		Item,
	]);
});

export default Menu;

import styled from 'styled-components';
import { View } from '../../../../Common/View';
import {
	CaptionText,
	HeadingText3,
	HeadingText6,
	BodyText2,
} from '../../../../Common/Text';
import IconTextButton from '../../../../Common/IconTextButton';
import { ButtonOverlayShape } from '../../../../Common/Button/interfaces';

export const ChartWrapper = styled(View)`
	flex: 1;
	margin: 8px;
	border-radius: 8px;
	background-color: ${({
		theme: {
			background: { frame },
		},
	}) => frame};
	${({ theme: { shadow } }) => shadow[2]};
	overflow: hidden;
`;

export const ChartHeaderWrapper = styled(View)`
	padding: 8px 12px 0px 12px;
	flex: 0 0 0;
	align-items: center;
	flex-direction: row;
`;

export const ChartBodyWrapper = styled(View)`
	flex: 1;
	box-sizing: border-box;
	padding: 0px 8px 8px 8px;
	overflow: hidden;
`;
export const ChartTitleTextWrapper = styled(View)``;

export const ChartTitleText = styled(HeadingText6)`
	flex: 1;
	color: ${({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => darkBgPrimary};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;
export const ChartSubtitleText = styled(BodyText2)`
	flex: 1;
	color: ${({
		theme: {
			font: {
				color: { darkBgSecondary },
			},
		},
	}) => darkBgSecondary};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const ChartLatestCountTextWrapper = styled(View)`
	position: absolute;
	top: 24px;
	right: 24px;
`;

export const ChartLatestCountText = styled(HeadingText3)`
	color: ${({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => darkBgPrimary};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const ChartNoDataWrapper = styled(View)`
	align-self: stretch;
	height: 250px;
	align-items: center;
	justify-content: center;
`;

export const ChartNoDataText = styled(CaptionText)`
	color: ${({
		theme: {
			font: {
				color: { darkBgDisabled },
			},
		},
	}) => darkBgDisabled};
`;

export const ChartIcon = styled(IconTextButton).attrs({
	overlayShape: ButtonOverlayShape.none,
	lightBg: false,
	iconProps: {
		size: 1,
	},
})`
	cursor: unset;
`;

import React, { useMemo, forwardRef, Ref } from 'react';
import { TooltipWrapper, TooltipText } from './Tooltip.sc';
import { TooltipType, TooltipLocation, TooltipProps } from '../interfaces';
import useObject from '../../../../Hooks/useObject';

interface TooltipInfo {
	left: number;
	top: number;
	width?: number;
	height?: number;
}

function getTooltipInfo(
	info: TooltipInfo,
	location: TooltipLocation
): TooltipInfo {
	const { left, top, width, height } = info;
	const _width = width || 0;
	const _height = height || 0;
	switch (location) {
		case TooltipLocation.center: {
			return {
				left: left + _width / 2,
				top: top + _height / 2,
				width,
				height,
			};
		}
		case TooltipLocation.top: {
			return {
				left: left + _width / 2,
				top: top - _height / 2,
				width,
				height,
			};
		}
		case TooltipLocation.left: {
			return {
				left: left - _width / 2,
				top: top + _height / 2,
				width,
				height,
			};
		}
		case TooltipLocation.right: {
			return {
				left: left + (_width * 3) / 2,
				top: top + _height / 2,
				width,
				height,
			};
		}
		case TooltipLocation.bottom: {
			return {
				left: left + _width / 2,
				top: top + (_height * 3) / 2,
				width,
				height,
			};
		}
	}
}

const Tooltip = forwardRef(function(
	props: TooltipProps,
	ref: Ref<HTMLDivElement>
) {
	const {
		left,
		top,
		width,
		height,
		timeout = 400,
		type = TooltipType.default,
		location = TooltipLocation.center,
		children,
		...restProps
	} = props;
	const _restProps = useObject(restProps);
	const _children = useMemo(() => {
		if (
			typeof children === 'string' ||
			typeof children === 'number' ||
			typeof children === 'boolean'
		)
			return <TooltipText x-component="text">{children}</TooltipText>;
		return children;
	}, [children]);

	return useMemo(() => {
		// console.log('render Tooltip');
		const {
			left: _left,
			top: _top,
			width: _width,
			height: _height,
		} = getTooltipInfo(
			{
				left,
				top,
				width,
				height,
			},
			location
		);
		return (
			<TooltipWrapper
				ref={ref}
				timeout={timeout}
				left={_left}
				top={_top}
				width={_width}
				height={_height}
				withBackdrop={false}
				closeOnMouseDownOutside={false}
				blurOnMouseDownOutside={false}
				type={type}
				{..._restProps}
			>
				{_children}
			</TooltipWrapper>
		);
	}, [
		ref,
		timeout,
		left,
		top,
		width,
		height,
		type,
		location,
		_restProps,
		_children,
	]);
});

export default Tooltip;

import { RefObject, useCallback, useState } from 'react';
import noop from '../Helpers/noop';

interface UseEdgeDodgingOptions {
	containerRef?: RefObject<HTMLElement>;
	disabled?: boolean;
}

interface Bound {
	left: number;
	top: number;
	width: number;
	height: number;
}

export default function useEdgeDodging(
	bound: Bound,
	options: UseEdgeDodgingOptions = {}
) {
	const { containerRef, disabled = false } = options;
	const [dodgedBound, setDodgedBound] = useState<Bound>(bound);

	const updateBound = useCallback(() => {
		const container = containerRef ? containerRef.current : document.body;
		if (!container) return;
		const {
			width: cWidth,
			height: cHeight,
		} = container.getBoundingClientRect();
		const { left, top, width, height } = bound;
		setDodgedBound({
			left: Math.max(Math.min(left, cWidth - width), 0),
			top: Math.max(Math.min(top, cHeight - height), 0),
			width,
			height,
		});
	}, [containerRef, bound]);

	if (disabled) {
		return {
			bound,
			updateBound: noop,
		};
	}

	return {
		bound: dodgedBound,
		updateBound,
	};
}

import {
	useCallback,
	useEffect,
	useRef,
	MutableRefObject,
	useMemo,
} from 'react';
import { Column, Row } from '../Store/store';
import { useImmutable } from '../../../Hooks/useImmutable';
import { ITableContext } from '../context';

export function useGetColumnWidth(
	contextObjRef: MutableRefObject<ITableContext>
) {
	return useCallback(
		(index: number) => {
			const { columnKeys, columns } = contextObjRef.current;
			const columnKey = columnKeys.get(index, '');
			return columns.get(columnKey, Column()).width;
		},
		[contextObjRef]
	);
}

export function useGetRowHeight(
	contextObjRef: MutableRefObject<ITableContext>
) {
	return useCallback(
		(index: number) => {
			const { recordIds, rows } = contextObjRef.current;
			const recordId = recordIds.get(index, '');
			return rows.get(recordId, Row()).height;
		},
		[contextObjRef]
	);
}

export function useSyncScroll(contextObj: ITableContext) {
	const { headerGridRef, indexGridRef } = contextObj;
	const frameIdRef = useRef<number>();
	return useCallback(
		(params: { scrollTop: number; scrollLeft: number }) => {
			const { scrollTop, scrollLeft } = params;
			frameIdRef.current = requestAnimationFrame(() => {
				const frameId = frameIdRef.current;
				if (frameId) cancelAnimationFrame(frameId);
				const headerGrid = headerGridRef.current;
				const indexGrid = indexGridRef.current;
				if (!headerGrid || !indexGrid) return;
				headerGrid.scrollTo({ scrollTop, scrollLeft });
				indexGrid.scrollTo({ scrollTop, scrollLeft });
			});
		},
		[headerGridRef, indexGridRef]
	);
}

function useColumnWidths(contextObj: ITableContext) {
	const { columnKeys, columns } = contextObj;
	const _columnKeys = useImmutable(columnKeys);
	const _columns = useImmutable(columns);
	const columnWidths = useMemo(
		() => _columnKeys.map((key) => _columns.get(key, Column()).width),
		[_columnKeys, _columns]
	);
	return useImmutable(columnWidths);
}

export function useColumnWidthSum(contextObj: ITableContext) {
	const { columnKeys, columns } = contextObj;
	const _columnKeys = useImmutable(columnKeys);
	const _columns = useImmutable(columns);
	return useMemo(
		() =>
			_columnKeys.reduce((w, columnKey) => {
				const { width } = _columns.get(columnKey, Column());
				return w + width;
			}, 0),
		[_columnKeys, _columns]
	);
}

export function useCellWidthRefresh(contextObj: ITableContext) {
	const { headerGridRef, bodyGridRef } = contextObj;
	const columnWidths = useColumnWidths(contextObj);
	const columnWidthsRef = useRef<typeof columnWidths>();
	useEffect(() => {
		const prevColumnWidths = columnWidthsRef.current;
		let refreshIndex = 0;
		if (prevColumnWidths) {
			refreshIndex = columnWidths.findIndex(
				(w, i) => w !== prevColumnWidths.get(i)
			);
		}
		if (refreshIndex >= 0) {
			const headerGrid = headerGridRef.current;
			const bodyGrid = bodyGridRef.current;
			headerGrid && headerGrid.resetAfterColumnIndex(refreshIndex);
			bodyGrid && bodyGrid.resetAfterColumnIndex(refreshIndex);
		}

		columnWidthsRef.current = columnWidths;
	}, [bodyGridRef, headerGridRef, columnWidths]);
}

function useRowHeights(contextObj: ITableContext) {
	const { recordIds, rows } = contextObj;
	const _recordIds = useImmutable(recordIds);
	const _rows = useImmutable(rows);
	const rowHeights = useMemo(
		() => _recordIds.map((id) => _rows.get(id, Row()).height),
		[_recordIds, _rows]
	);
	return useImmutable(rowHeights);
}

export function useCellHeightRefresh(contextObj: ITableContext) {
	const { indexGridRef, bodyGridRef } = contextObj;
	const rowHeights = useRowHeights(contextObj);
	const rowHeightsRef = useRef<typeof rowHeights>();
	useEffect(() => {
		const prevRowHeights = rowHeightsRef.current;
		let refreshIndex = 0;
		if (prevRowHeights) {
			refreshIndex = rowHeights.findIndex(
				(h, i) => h !== prevRowHeights.get(i)
			);
		}
		if (refreshIndex >= 0) {
			const indexGrid = indexGridRef.current;
			const bodyGrid = bodyGridRef.current;
			indexGrid && indexGrid.resetAfterRowIndex(refreshIndex);
			bodyGrid && bodyGrid.resetAfterRowIndex(refreshIndex);
		}
		rowHeightsRef.current = rowHeights;
	}, [bodyGridRef, indexGridRef, rowHeights]);
}

import { useRef, useContext, Context } from 'react';
import useRefUpdate from './useRefUpdate';

export default function useContextWithRef<T>(context: Context<T>) {
	const contextObj = useContext(context);
	const contextObjRef = useRef(contextObj);
	useRefUpdate(contextObjRef, contextObj);
	return {
		contextObj,
		contextObjRef,
	};
}

import styled from 'styled-components';
// import { View } from '../../../../Common/View';

export const BodyInnerWrapper = styled.div`
	overflow: hidden;
	border-right: 1px solid
		${({
			theme: {
				background: { darken },
			},
		}) => darken[1]};
	border-bottom: 1px solid
		${({
			theme: {
				background: { darken },
			},
		}) => darken[1]};
	background-color: ${({
		theme: {
			background: { light },
		},
	}) => light};
	&:focus {
		outline: none;
	}
`;

import React, { useMemo, useContext, forwardRef, Ref } from 'react';
import dashboardContext from '../../../context';
import { ButtonProps } from '../../../../Common/Button/interfaces';
import useForwardedRef from '../../../../../Hooks/useForwardedRef';
import {
	UserPanelButtonWrapper,
	UserIconWrapper,
	UserIconText,
	UserNameText,
} from './UserPanelButton.sc';

export default forwardRef(function UserPanelButton(
	props: ButtonProps,
	ref: Ref<HTMLDivElement>
) {
	const { onPress } = props;
	const { username } = useContext(dashboardContext);
	const buttonRef = useForwardedRef(ref);
	return useMemo(() => {
		return (
			<UserPanelButtonWrapper ref={buttonRef} onPress={onPress}>
				<UserIconWrapper username={username}>
					<UserIconText>
						{username.slice(0, 1).toUpperCase()}
					</UserIconText>
				</UserIconWrapper>
				<UserNameText>{username}</UserNameText>
			</UserPanelButtonWrapper>
		);
	}, [onPress, username, buttonRef]);
});

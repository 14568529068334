import { TreeMapStoreRecord, FilterFieldRecord } from './reducer';
import { List, Map } from 'immutable';

export default function initMapViewStoreParser(initialState: any = {}) {
	return function(serializied: any = initialState) {
		const {
			selected = null,
			search = '',
			searchResults = [],
			center,
			filters = {},
			focusedResult = 0,
			filterFields = [],
			filterNames = {},
			rotation,
			zoom,
		} = serializied;
		return TreeMapStoreRecord({
			center,
			filters: Map(filters),
			filterNames: Map(filterNames),
			focusedResult,
			rotation,
			search,
			searchResults: List(searchResults),
			zoom,
			selected,
			filterFields: List(filterFields.map(FilterFieldRecord)),
		});
	};
}

import { select, put, fork } from 'redux-saga/effects';
import { setItem, getItem, removeItem } from '../effects/localStorage';
import {
	getSessionId,
	setSessionId,
	setSession,
	getSession,
	removeSession,
} from '../effects/sessionStorage';
import { IRootState } from '../../reducers/root';
import { RecordOf } from 'immutable';
import { initialize, authenticate, logout } from '../../actions/auth';
import watchLogout from './logout';
import { IAuth } from '../../reducers/auth';
import moment from 'moment';
import querystring from 'query-string';
import configs from '../../../configs';
import makeId from '../../../Helpers/makeId';

const { AUTH_PORTAL, APP_ID } = configs;

export const selectAuth = (state: RecordOf<IRootState>) => state.auth;

export const selectInitialized = (state: RecordOf<IRootState>) =>
	state.auth.initialized;

interface AppState {
	location: Location;
}

export function* authTask() {
	let token = null;
	let state: AppState = {
		location: window.location,
	};
	while (!token) {
		let initialized = yield select(selectInitialized);
		if (!initialized) {
			const authJSON = yield getItem('auth');
			const auth: IAuth = authJSON ? JSON.parse(authJSON) : null;
			token = auth ? auth.token : null;

			yield put(initialize());
		} else {
			const { hash } = window.location;
			if (hash) {
				const { access_token, state: sessionId } = querystring.parse(
					window.location.hash.slice(1)
				);
				const storedSessionId: string = yield getSessionId();

				if (storedSessionId === sessionId) {
					token =
						typeof access_token === 'string' ? access_token : null;

					let stateJSON = yield getSession(sessionId);
					yield removeSession(sessionId);
					state = JSON.parse(stateJSON);
				}
			} else {
				const sessionId = makeId(10);
				yield setSessionId(sessionId);
				yield setSession(sessionId, {
					location: window.location,
				});

				const portalURI = querystring.stringifyUrl(
					{
						url: AUTH_PORTAL,
						query: {
							response_type: 'token',
							redirect_uri: window.location.origin,
							client_id: APP_ID,
							provider_id: APP_ID,
							scope: 'openid',
							state: sessionId,
						},
					},
					{
						encode: true,
					}
				);
				window.location.replace(portalURI);
				return;
			}
		}
	}

	yield put(authenticate({ token, state }));

	yield fork(watchLogout);

	const auth: IAuth = yield select(selectAuth);

	const { exp } = auth;
	if (exp && moment.unix(exp).isAfter(moment.now())) {
		yield setItem('auth', auth);
	} else {
		yield removeItem('auth');
		yield put(logout());
	}
}

export default function* authSaga() {
	yield fork(authTask);
}

import { isValid, format, parse } from 'date-fns';

export default function validateDateFormat(dateFormat: string): boolean {
	const currentDate = new Date();
	try {
		return isValid(
			parse(format(currentDate, dateFormat), dateFormat, currentDate)
		);
	} catch (err) {
		return false;
	}
}

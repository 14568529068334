import { useContext, useCallback, useState } from 'react';
import workspaceContext from '../../Workspace/context';
import { LauncherProps } from '../interfaces';
import { Activity } from '../../../interfaces';

export default function useLauncher(props: LauncherProps) {
	const { application } = props;
	const { startApplication, activities, switchActivity } = useContext(
		workspaceContext
	);

	const [currentActivity, setCurrentActivity] = useState(0);

	const appActivities = activities.filter(
		(task: Activity) => task.key === application.key
	);

	const handleStartApplication = useCallback(() => {
		startApplication && startApplication(application);
	}, [application, startApplication]);

	const handleSwitchActivity = useCallback(() => {
		const nextActivity = appActivities.get(currentActivity);
		nextActivity && switchActivity(nextActivity);
		setCurrentActivity((v) => (v >= appActivities.size - 1 ? 0 : v + 1));
	}, [currentActivity, switchActivity, appActivities]);

	return {
		currentActivity,
		activities: appActivities,
		handleStartApplication,
		handleSwitchActivity,
	};
}

import styled, { css } from 'styled-components';
import { View } from '../Common/View';
import { ButtonText, CaptionText, HeadingText6 } from '../Common/Text';
import { TransitionStatus } from 'react-transition-group/Transition';
import { SnackbarSide } from './reducer';
import Icon from '@mdi/react';

interface ISnackbarStyleProps {
	status: TransitionStatus;
	side: SnackbarSide;
	maxWidth?: number;
}

const rightEnterTranslation = css`
	transform: translateX(0);
`;

const rightExitTranslation = css`
	transform: translateX(50%);
`;

const bottomEnterTranslation = css`
	transform: translateY(0);
`;

const bottomExitTranslation = css`
	transform: translateY(50%);
`;

function getTranslation(props: ISnackbarStyleProps) {
	const { status, side } = props;
	return status === 'entered'
		? side === SnackbarSide.bottom
			? bottomEnterTranslation
			: rightEnterTranslation
		: side === SnackbarSide.bottom
		? bottomExitTranslation
		: rightExitTranslation;
}

export const SnackbarWrapper = styled(View)<ISnackbarStyleProps>`
	flex-direction: row;
	align-items: center;

	user-select: none;

	width: 450px;
	max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '999')}px;
	margin: 6px;
	padding: 12px;
	background-color: ${({ theme: { background } }) => background.frameDarker};
	border-radius: 2px;

	transition: all 0.2s;

	opacity: ${({ status }) => (status === 'entered' ? 1 : 0)};
	${getTranslation}

	${({ theme: { shadow } }) => shadow[5]};
`;

export const SpinnerWrapper = styled(View)`
	width: 64px;
	height: 64px;
	align-items: center;
	justify-content: center;
	margin-right: 8px;
`;

export const LoadedIcon = styled(Icon).attrs(({ theme: { background } }) => ({
	color: background.accent,
	size: 1.2,
}))``;

export const SnackbarMessageWrapper = styled(View)`
	flex: 1;
	margin-right: 16px;
	overflow: hidden;
`;

export const SnackbarTitle = styled(HeadingText6)`
	color: ${({
		theme: {
			font: { color },
		},
	}) => color.darkBgPrimary};
	text-align: left;
	word-wrap: break-word;
`;

export const SnackbarMessage = styled(CaptionText)`
	color: ${({
		theme: {
			font: { color },
		},
	}) => color.darkBgPrimary};
	text-align: left;
	word-wrap: break-word;
`;

export const DismissButton = styled(View)`
	align-items: center;
	justify-content: center;
	padding-left: 8px;
	padding-right: 8px;
	padding-top: 12px;
	padding-bottom: 12px;

	cursor: pointer;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transition: all 0.2s;
		border-radius: 4px;
	}

	color: ${({
		theme: {
			font: { color },
		},
	}) => color.darkBgPrimary};

	&:hover {
		color: ${({
			theme: {
				font: { color },
			},
		}) => color.primary};

		&:before {
			background-color: ${({ theme: { background } }) =>
				background.lighten[2]};
		}
	}
`;

export const DismissText = styled(ButtonText)``;

import { takeEvery, put } from 'redux-saga/effects';
import { receivedNotification } from '../../../Components/NotificationList/actions';
import { sendSnackbar } from '../../../Components/Snackbar/actions';

function* handleReceivedNotification(
	action: ReturnType<typeof receivedNotification>
) {
	const {
		payload: {
			notification: { content, title, _id, attachments, delivered },
		},
	} = action;
	if (!delivered)
		yield put(
			sendSnackbar({
				autoDismiss: 5000,
				title,
				snackbarId: _id,
				message: content,
				loading:
					attachments && attachments.length
						? !attachments[0].ready
						: undefined,
			})
		);
}

export default function* watchNotification() {
	yield takeEvery(receivedNotification.type, handleReceivedNotification);
}

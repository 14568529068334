import React, { useMemo, forwardRef, Ref } from 'react';
import { ButtonProps } from '../../../../Common/Button/interfaces';
import useForwardedRef from '../../../../../Hooks/useForwardedRef';
import {
	NotificationPanelButtonWrapper,
	NotificationPanelButtonIcon,
} from './NotificationPanelButton.sc';
import { mdiBell } from '@mdi/js';

export default forwardRef(function UserPanelButton(
	props: ButtonProps,
	ref: Ref<HTMLDivElement>
) {
	const { onPress } = props;
	const buttonRef = useForwardedRef(ref);
	return useMemo(() => {
		return (
			<NotificationPanelButtonWrapper ref={buttonRef} onPress={onPress}>
				<NotificationPanelButtonIcon path={mdiBell} />
			</NotificationPanelButtonWrapper>
		);
	}, [onPress, buttonRef]);
});

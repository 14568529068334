import { useCallback, MutableRefObject } from 'react';
import { insertColumns } from '../../../Store/actions';
import orderSelected from '../Helpers/orderSelected';
import { randomColumnKey } from '../../../Helpers/Column';
import { ITableContext } from '../../../context';

export function useSelectedColumnsLeftAdd(
	contextObjRef: MutableRefObject<ITableContext>
) {
	return useCallback(() => {
		const {
			selected,
			setSelected,
			onRequestDispatch,
		} = contextObjRef.current;
		if (!selected) return;
		const [tl, br] = orderSelected(selected);
		const newColumnCount = br[1] - tl[1] + 1;
		const newColumnIndex = tl[1];
		const newColumnKeys = Array.from({ length: newColumnCount }).map(() =>
			randomColumnKey()
		);
		onRequestDispatch(
			insertColumns({
				index: newColumnIndex,
				columnKeys: newColumnKeys,
				columns: null,
			})
		);
		setSelected(null);
	}, [contextObjRef]);
}

export function useSelectedColumnsRightAdd(
	contextObjRef: MutableRefObject<ITableContext>
) {
	return useCallback(() => {
		const {
			selected,
			setSelected,
			onRequestDispatch,
		} = contextObjRef.current;
		if (!selected) return;
		const [tl, br] = orderSelected(selected);
		const newColumnCount = br[1] - tl[1] + 1;
		const newColumnIndex = br[1] + 1;
		const newColumnKeys = Array.from({ length: newColumnCount }).map(() =>
			randomColumnKey()
		);
		onRequestDispatch(
			insertColumns({
				index: newColumnIndex,
				columnKeys: newColumnKeys,
				columns: null,
			})
		);
		setSelected(null);
	}, [contextObjRef]);
}

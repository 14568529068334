import React, { useMemo, useState, useEffect } from 'react';
import { NotificationTimestampProps } from '../interfaces';
import { TimestampText } from './NotificationTimestamp.sc';
import moment from 'moment';

export default function NotificationTimestamp(
	props: NotificationTimestampProps
) {
	const { timestamp } = props;

	const [now, setNow] = useState(new Date());

	useEffect(() => {
		let intervalId = setInterval(() => {
			setNow(new Date());
		}, 5000);

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	return useMemo(() => {
		return <TimestampText>{moment(timestamp).from(now)}</TimestampText>;
	}, [timestamp, now]);
}

import React, { useMemo, forwardRef, Ref } from 'react';
import { DashboardProps } from '../interfaces';
import { DashboardWrapper } from './Dashboard.sc';

import Navigation from './Navigation';
import dashboardContext from '../context';
import noop from '../../../Helpers/noop';
import LinkedWorkspace from './LinkedWorkspace';
import useForwardedRef from '../../../Hooks/useForwardedRef';

const { Provider } = dashboardContext;

export default forwardRef(function Dashboard(
	props: DashboardProps,
	ref: Ref<HTMLDivElement>
) {
	const {
		username,
		groups,
		currentWorkspace,
		workspaces,
		applications,
		onCreateWorkspace = noop,
		onRenameWorkspace = noop,
		onSelectWorkspace = noop,
		onRemoveWorkspace = noop,
		onRequestLogout = noop,
	} = props;

	const currentWorkspaceInfo = workspaces.get(currentWorkspace);
	const currentWorkspaceId = currentWorkspaceInfo
		? currentWorkspaceInfo.id
		: null;

	const _ref = useForwardedRef(ref);

	return useMemo(() => {
		return (
			<Provider
				value={{
					username,
					groups,
					currentWorkspace,
					applications,
					workspaces,
					onCreateWorkspace,
					onRenameWorkspace,
					onSelectWorkspace,
					onRemoveWorkspace,
					onRequestLogout,
				}}
			>
				<DashboardWrapper ref={_ref}>
					<Navigation />
					{currentWorkspaceId ? (
						<LinkedWorkspace workspaceId={currentWorkspaceId} />
					) : null}
				</DashboardWrapper>
			</Provider>
		);
	}, [
		_ref,
		username,
		groups,
		currentWorkspace,
		currentWorkspaceId,
		workspaces,
		applications,
		onCreateWorkspace,
		onRenameWorkspace,
		onSelectWorkspace,
		onRemoveWorkspace,
		onRequestLogout,
	]);
});

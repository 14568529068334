import React, { useMemo, forwardRef, Ref } from 'react';
import styled from 'styled-components';
import { mdiRadioboxBlank, mdiRadioboxMarked } from '@mdi/js';
import useObject from '../../Hooks/useObject';
import IconTextButton from './IconTextButton';
import { ButtonOverlayShape } from './Button/interfaces';
import { IconTextButtonProps } from './IconTextButton/interfaces';

export const RadioboxButton = styled(IconTextButton).attrs<IconTextButtonProps>(
	{
		overlayShape: ButtonOverlayShape.circle,
	}
)`
	justify-content: center;
	& > [x-component='text'] {
		text-transform: unset;
	}
`;

export interface RadioButtonProps extends Omit<IconTextButtonProps, 'icon'> {
	checked?: boolean | null;
}

const Radiobox = forwardRef(function(
	props: RadioButtonProps,
	ref: Ref<HTMLDivElement>
) {
	const { checked = false, ...restProps } = props;
	const _restProps = useObject(restProps);
	return useMemo(() => {
		const icon = checked ? mdiRadioboxMarked : mdiRadioboxBlank;
		return <RadioboxButton ref={ref} icon={icon} {..._restProps} />;
	}, [ref, checked, _restProps]);
});

export default Radiobox;

import React, {
	forwardRef,
	Ref,
	UIEvent as RUIEvent,
	CSSProperties,
	ReactNode,
} from 'react';
import styled from 'styled-components';
import RScrollbars from 'react-custom-scrollbars';

interface ScrollThumbProps {
	style: CSSProperties;
}

interface ScrollTrackProps {
	style: CSSProperties;
}

export const ScrollThumb = styled.div<ScrollThumbProps>`
	border-radius: 3px;
	background: ${({
		theme: {
			background: { primaryAlpha },
		},
	}) => primaryAlpha[4]};
`;

export const ScrollHorizontalTrack = styled.div<ScrollTrackProps>`
	right: 2px;
	bottom: 2px;
	left: 2px;
	border-radius: 3px;
	background: ${({
		theme: {
			background: { darken },
		},
	}) => darken[1]};
`;

export const ScrollVerticalTrack = styled.div<ScrollTrackProps>`
	top: 2px;
	right: 2px;
	bottom: 2px;
	border-radius: 3px;
	background: ${({
		theme: {
			background: { darken },
		},
	}) => darken[1]};
`;

export interface ScrollbarsProps {
	style?: CSSProperties;
	className?: string;
	onScroll?(e: RUIEvent): void;
	children: ReactNode;
}

function renderScrollThumb(props: ScrollThumbProps) {
	return <ScrollThumb {...props} />;
}

function renderScrollHorizontalTrack(props: ScrollTrackProps) {
	return <ScrollHorizontalTrack {...props} />;
}

function renderScrollVerticalTrack(props: ScrollTrackProps) {
	return <ScrollVerticalTrack {...props} />;
}

function renderView(props: any) {
	return (
		<div
			{...props}
			style={{
				...props.style,
				// display: 'flex',
			}}
		/>
	);
}

const Scrollbars = forwardRef(function(
	props: ScrollbarsProps,
	ref: Ref<RScrollbars>
) {
	const { style, className, onScroll, children } = props;
	return (
		<RScrollbars
			ref={ref}
			style={{
				...style,
				overflow: 'hidden',
				// display: 'flex',
			}}
			className={className}
			autoHide
			hideTracksWhenNotNeeded
			onScroll={onScroll}
			renderView={renderView}
			renderThumbHorizontal={renderScrollThumb}
			renderThumbVertical={renderScrollThumb}
			renderTrackHorizontal={renderScrollHorizontalTrack}
			renderTrackVertical={renderScrollVerticalTrack}
		>
			{children}
		</RScrollbars>
	);
});

export default Scrollbars;

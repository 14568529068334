import React, { useMemo, useContext, useCallback, useEffect } from 'react';
import { DataTableProps, EmbeddedDataTableProps } from '../interfaces';
import Embedded from '../../../../../Components/Embedded';
import { ActivityWindow } from '../../../../../Components/Dashboard';
import Table from '../../../../../Components/Table';
import context from '../../../../../Components/Dashboard/context';
import { AnyAction } from 'redux';
import { EmbeddedComponentProps } from '../../../../../Components/Embedded/interfaces';
import { RecordOf } from 'immutable';
import { ITableStore } from '../../../../../Components/Table/interfaces';
import { refreshRecords } from '../../../../../Components/Table/Store/actions';

import mergeWith from 'lodash.mergewith';

function EmbeddedDataTable(props: EmbeddedDataTableProps) {
	const {
		state,
		dispatch,
		forwardAction,
		collection,
		keyField,
		allowEdit,
	} = props;
	const { username, groups } = useContext(context);

	const {
		cells,
		columnKeys,
		columns,
		loading,
		page,
		pageSize,
		recordCount,
		recordIds,
		records,
		rows,
		sorts,
		unmergedRecords,
	} = state;

	const boardcastDispatch = useCallback(
		(action: AnyAction) => {
			dispatch(
				forwardAction({
					...action,
					metadata: { collection, keyField },
				})
			);
		},
		[dispatch, forwardAction, collection, keyField]
	);

	useEffect(() => {
		dispatch(
			forwardAction({
				...refreshRecords(),
				metadata: { collection, keyField },
			})
		);
	}, [dispatch, collection, forwardAction, keyField]);

	const editable = allowEdit.some((group) => !!~groups.indexOf(group));

	return useMemo(() => {
		return (
			<Table
				user={username}
				editable={editable}
				cells={cells}
				columnKeys={columnKeys}
				columns={columns}
				loading={loading}
				page={page}
				pageSize={pageSize}
				recordCount={recordCount}
				recordIds={recordIds}
				records={records}
				rows={rows}
				sorts={sorts}
				unmergedRecords={unmergedRecords}
				onRequestDispatch={boardcastDispatch}
			/>
		);
	}, [
		editable,
		username,
		cells,
		columnKeys,
		columns,
		loading,
		page,
		pageSize,
		recordCount,
		recordIds,
		records,
		rows,
		sorts,
		unmergedRecords,
		boardcastDispatch,
	]);
}

const defaultAllowEdit: string[] = [];

function customizer(objValue: any, srcValue: any) {
	if (objValue instanceof Array) {
		return objValue.concat(srcValue);
	}
}

export default function DataTable(props: DataTableProps) {
	const {
		activity: { id, parameters },
		type,
		defaultState,
		collection,
		keyField,
		allowEdit = defaultAllowEdit,
	} = props;

	const initialState = useMemo(
		() => mergeWith({}, defaultState, parameters || {}, customizer),
		[defaultState, parameters]
	);

	const table = useCallback(
		(props: EmbeddedComponentProps<RecordOf<ITableStore>>) => {
			return (
				<EmbeddedDataTable
					{...props}
					allowEdit={allowEdit}
					collection={collection}
					keyField={keyField}
				/>
			);
		},
		[collection, keyField, allowEdit]
	);

	return useMemo(() => {
		return (
			<ActivityWindow activityId={id}>
				<Embedded
					initialState={initialState}
					storeId={id}
					type={type}
					component={table}
				/>
			</ActivityWindow>
		);
	}, [id, type, initialState, table]);
}

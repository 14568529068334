import React, { forwardRef, Ref, CSSProperties } from 'react';
import styled from 'styled-components';
import { View } from './View';
import { Icon } from '@mdi/react';

const IconWrapper = styled(View)`
	align-items: center;
	justify-content: center;
`;

const SIcon = styled(Icon)`
	position: relative;
`;

export interface IconProps {
	size?: number;
	color?: string;
	horizontal?: boolean;
	vertical?: boolean;
	rotate?: number;
	spin?: boolean | number;
}

export interface WrappedIconProps {
	className?: string;
	style?: CSSProperties;
	icon: string;
	iconProps?: Partial<IconProps>;
}

const defaultIconProps = { size: 0.8 };

function WrappedIcon(props: WrappedIconProps, ref: Ref<HTMLDivElement>) {
	const { className, style, icon, iconProps, ...restProps } = props;
	return (
		<IconWrapper
			ref={ref}
			style={style}
			className={className}
			{...restProps}
		>
			<SIcon
				path={icon}
				{...defaultIconProps}
				{...iconProps}
				x-component="icon"
			/>
		</IconWrapper>
	);
}

export default forwardRef(WrappedIcon);

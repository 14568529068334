import styled from 'styled-components';
import { View } from '../../Common/View';
import LoadingSpinner, {
	LoadingSpinnerProps,
} from '../../Common/LoadingSpinner';
import { CaptionText } from '../../Common/Text';

export const LoadingScreenWrapper = styled(View)`
	flex: 1 0 0;
	align-self: stretch;
	align-items: center;
	justify-content: center;
	flex-direction: row;
`;

export const EmbeddedLoadingSpinner = styled(LoadingSpinner).attrs<
	{},
	LoadingSpinnerProps
>(({ theme: { font: { color: { primary, lightBgWatermark } } } }) => ({
	upperColor: primary,
	lowerColor: lightBgWatermark,
	size: 0.8,
}))`
	width: 32px;
	height: 32px;
`;

export const EmbeddedLoadingText = styled(CaptionText)`
	color: ${({
		theme: {
			font: {
				color: { lightBgSecondary },
			},
		},
	}) => lightBgSecondary};
`;

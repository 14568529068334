import { useCallback, MutableRefObject } from 'react';
import { rearrangeColumns } from '../../../Store/actions';
import { ITableContext } from '../../../context';

export default function useColumnRearrange(
	contextObjRef: MutableRefObject<ITableContext>
) {
	const handleColumnRearrangeStart = useCallback(
		({ index }: { index: number }) => {
			const {
				columnKeys,
				setRearrangingColumnKey,
			} = contextObjRef.current;
			const columnKey = columnKeys.get(index, '');
			setRearrangingColumnKey(columnKey);
			document.body.style.cursor = 'move';
		},
		[contextObjRef]
	);
	const handleColumnRearrangeEnd = useCallback(
		({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
			const {
				onRequestDispatch,
				setRearrangingColumnKey,
				setSelected,
			} = contextObjRef.current;
			onRequestDispatch(
				rearrangeColumns({
					from: oldIndex,
					to: newIndex,
				})
			);
			setRearrangingColumnKey(null);
			setSelected(null);
			document.body.style.cursor = 'unset';
		},
		[contextObjRef]
	);

	return {
		handleColumnRearrangeStart,
		handleColumnRearrangeEnd,
	};
}

import { Record, Map, OrderedSet, RecordOf } from 'immutable';
import createReducer from '../../Helpers/createReducer';
import { sendSnackbar, dismissSnackbar } from './actions';

export enum SnackbarSide {
	right,
	bottom,
}

export interface ISnackbarRecord {
	loading?: boolean;
	autoDismiss?: number;
	title: string;
	message: string;
}

export const SnackbarRecord = Record<ISnackbarRecord>({
	title: '',
	message: '',
	loading: undefined,
	autoDismiss: undefined,
});

export interface ISnackbarGroupStore {
	snackbars: Map<string, RecordOf<ISnackbarRecord>>;
	snackbarIds: OrderedSet<string>;
}

export const SnackbarGroupStore = Record<ISnackbarGroupStore>({
	snackbars: Map(),
	snackbarIds: OrderedSet(),
});

const initialState = SnackbarGroupStore();

export const snackbarReducer = createReducer(
	initialState,
	{
		sendSnackbar,
		dismissSnackbar,
	},
	{
		dismissSnackbar: (state, { payload: { snackbarId } }) =>
			state.withMutations((s) => {
				s.update('snackbarIds', (snackbarIds) =>
					snackbarIds.delete(snackbarId)
				);
				s.update('snackbars', (snackbars) =>
					snackbars.filter((v, k) => k !== snackbarId)
				);
			}),
		sendSnackbar: (
			state,
			{ payload: { autoDismiss, loading, message, snackbarId, title } }
		) =>
			state.withMutations((s) => {
				s.update('snackbarIds', (snackbarIds) =>
					snackbarIds.add(snackbarId)
				);
				s.update('snackbars', (snackbars) =>
					snackbars.set(
						snackbarId,
						SnackbarRecord({
							title,
							autoDismiss,
							loading,
							message,
						})
					)
				);
			}),
	}
);

export default snackbarReducer;

import React, { useMemo, useCallback } from 'react';
import { mdiBattery } from '@mdi/js';
import { VictoryTooltip, VictoryChart, VictoryBar } from 'victory';

import {
	ChartWrapper,
	ChartHeaderWrapper,
	ChartBodyWrapper,
	ChartNoDataWrapper,
	ChartNoDataText,
	ChartIcon,
	ChartTitleText,
	ChartTitleTextWrapper,
} from './BatteryLevelChart.sc';
import { chartDarkTheme } from '../../../../../chartTheme';
import { useStartApplication, useToggleHomePage } from '../../Workspace';
import { FilterType } from '../../../../Table/interfaces';
import { MouseOverIcon } from './MouseOverIcon';

export interface BatteryLevelChartDatum {
	x: number;
	count: number;
}
export type BatteryLevelChartData = BatteryLevelChartDatum[];

interface BatteryLevelChartProps {
	data: BatteryLevelChartData;
}

const categories = {
	x: [
		'< 2.7V',
		'2.7V',
		'2.8V',
		'2.9V',
		'3V',
		'3.1V',
		'3.2V',
		'3.3V',
		'≥ 3.4V',
	],
};

const filters: { [key: string]: any } = {
	'< 2.7V': { values: { lt: 2.7 }, type: FilterType.lt },
	'2.7V': { values: { eq: 2.7 }, type: FilterType.eq },
	'2.8V': { values: { eq: 2.8 }, type: FilterType.eq },
	'2.9V': { values: { eq: 2.9 }, type: FilterType.eq },
	'3V': { values: { eq: 3.0 }, type: FilterType.eq },
	'3.1V': { values: { eq: 3.1 }, type: FilterType.eq },
	'3.2V': { values: { eq: 3.2 }, type: FilterType.eq },
	'3.3V': { values: { eq: 3.3 }, type: FilterType.eq },
	'≥ 3.4V': { values: { gte: 3.4 }, type: FilterType.gte },
};

function BatteryLevelChart(props: BatteryLevelChartProps) {
	const { data } = props;

	const startApplication = useStartApplication();
	const toggleHomePage = useToggleHomePage();

	const handleOpenSensorTable = useCallback(
		(...args: any) => {
			const [, props] = args;
			const { data, index } = props;
			const { x } = data[index];
			const filter = filters[x];
			filter &&
				startApplication &&
				startApplication(
					{ key: 'sensorsTable', name: `Sensor with voltage ${x}` },
					{
						columns: {
							roundedVoltage: {
								formula: {
									value: 'ROUND((voltage ?= 0) / 1000,1)',
								},
								filter,
								type: { type: 'number' },
								name: 'Battery Level (V)',
								dataType: { type: 'number' },
								editable: false,
								readonly: false,
								sortable: false,
								unit: 'V',
							},
						},
						columnKeys: ['roundedVoltage'],
					}
				);
			toggleHomePage(false);
			return [];
		},
		[startApplication, toggleHomePage]
	);
	return useMemo(() => {
		const dataCount = data.length;
		return (
			<ChartWrapper>
				<ChartHeaderWrapper>
					<ChartIcon icon={mdiBattery} />
					<ChartTitleTextWrapper>
						<ChartTitleText>SENSOR BATTERY LEVEL</ChartTitleText>
					</ChartTitleTextWrapper>
					<MouseOverIcon />
				</ChartHeaderWrapper>
				<ChartBodyWrapper>
					{dataCount > 1 ? (
						<VictoryChart
							domainPadding={{ x: [30, 30] }}
							theme={chartDarkTheme}
						>
							<VictoryBar
								style={{
									data: {
										fill: ({ datum }) =>
											datum.x === '< 2.7V'
												? '#FFC700'
												: '#38B10D',
									},
								}}
								data={data}
								x="x"
								y="count"
								labels={({ datum }) =>
									`${datum.x}, ${datum.count}`
								}
								categories={categories}
								labelComponent={
									<VictoryTooltip style={{ fontSize: 24 }} />
								}
								events={[
									{
										target: 'data',
										eventHandlers: {
											onClick: handleOpenSensorTable,
										},
									},
								]}
							/>
						</VictoryChart>
					) : (
						<ChartNoDataWrapper>
							<ChartNoDataText>NO DATA</ChartNoDataText>
						</ChartNoDataWrapper>
					)}
				</ChartBodyWrapper>
			</ChartWrapper>
		);
	}, [data, handleOpenSensorTable]);
}

export default BatteryLevelChart;

import React, { useMemo, useCallback, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { mdiUpload } from '@mdi/js';
import {
	ImportRecordModalContentWrapper,
	ModalFileDropzone,
	ModalFileDropzoneText,
	ModalFileInputWrapper,
	ModalFileInput,
	ModalImportButton,
} from './ImportRecordModal.sc';
import Modal from '../../../../Common/Modal';
import { useRecordsFileImport } from '../../../Hooks/Record';
import {
	ModalProps,
	ModalChildrenProps,
} from '../../../../Common/Modal/interfaces';
import context from '../../../context';
import useFocus from '../../../../../Hooks/useFocus';

interface ImportButtonProps {
	file?: File;
	onRequestClose(): void;
}

function ImportButton(props: ImportButtonProps) {
	const { file, onRequestClose } = props;
	const contextObj = useContext(context);
	const importRecordsFile = useRecordsFileImport(contextObj);
	const handlePress = useCallback(() => {
		if (file) {
			importRecordsFile(file);
			onRequestClose();
		}
	}, [file, importRecordsFile, onRequestClose]);
	const disabled = !file;
	return useMemo(() => {
		return (
			<ModalImportButton
				tabIndex={0}
				disabled={disabled}
				icon={mdiUpload}
				text="IMPORT"
				onPress={handlePress}
			/>
		);
	}, [disabled, handlePress]);
}

type ImportRecordModalContentProps = ModalChildrenProps;
function ImportRecordModalContent(props: ImportRecordModalContentProps) {
	const { onRequestClose } = props;
	const {
		isDragActive,
		// isDragAccept,
		// isDragReject,
		acceptedFiles,
		// rejectedFiles,
		getRootProps,
		getInputProps,
	} = useDropzone({ multiple: false });
	const { focused, handleFocus, handleBlur } = useFocus();

	let file: File | undefined = acceptedFiles[0];
	// if (!file) {
	// 	const rejectedFile: File | undefined = rejectedFiles[0];
	// 	console.log(rejectedFile);
	// 	if (
	// 		rejectedFile &&
	// 		!rejectedFile.type &&
	// 		/\.(json|csv)$/i.test(rejectedFile.name)
	// 	) {
	// 		file = rejectedFile;
	// 	}
	// }

	return useMemo(() => {
		// const dropzoneText = isDragAccept
		// 	? 'Valid File'
		// 	: isDragReject
		// 	? 'Invalid File'
		// 	: 'Click to Choose File or Drag File over Here';
		const filename = file ? file.name : '';
		const dropzoneText = 'Click to Choose File or Drag File over Here';
		return (
			<ImportRecordModalContentWrapper>
				<ModalFileDropzone
					focused={focused}
					isDragActive={isDragActive}
					// isDragAccept={isDragAccept}
					// isDragReject={isDragReject}
					{...getRootProps({
						onFocus: handleFocus,
						onBlur: handleBlur,
					})}
					tabIndex={-1}
				>
					<input
						{...getInputProps()}
						style={{
							position: 'absolute',
							width: 1,
							height: 1,
							opacity: 0,
						}}
						autoFocus
						tabIndex={0}
					/>
					<ModalFileDropzoneText>
						{dropzoneText}
					</ModalFileDropzoneText>
				</ModalFileDropzone>
				<ModalFileInputWrapper>
					<ModalFileInput
						readOnly
						tabIndex={0}
						value={filename}
						placeholder="No Chosen File"
					/>
				</ModalFileInputWrapper>
				<ImportButton file={file} onRequestClose={onRequestClose} />
			</ImportRecordModalContentWrapper>
		);
	}, [
		focused,
		isDragActive,
		// isDragAccept,
		// isDragReject,
		file,
		getRootProps,
		getInputProps,
		handleFocus,
		handleBlur,
		onRequestClose,
	]);
}

type ImportRecordModalProps = Omit<ModalProps, 'render' | 'children'>;
function ImportRecordModal(props: ImportRecordModalProps) {
	return (
		<Modal
			title="Import Record"
			render={ImportRecordModalContent}
			{...props}
		/>
	);
}

export default ImportRecordModal;

import styled from 'styled-components';
import Underline from '../../Underline';

export const TextAreaUnderline = styled(Underline)`
	background-color: ${({
		theme: {
			background: { accent },
		},
	}) => accent};
`;

import { View } from '../../../../../Components/Common/View';
import styled from 'styled-components';
import { MAP_PANEL_WIDTH } from '../constants';
import { SubtitleText2 } from '../../../../../Components/Common/Text';

export const TreeMapWrapper = styled(View)`
	flex: 1 1 0;
`;

export const TreeMapOverlayWrapper = styled(View)`
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: absolute;
	pointer-events: none;
`;

export const TreeMapControlWrapper = styled(View)`
	flex: 0 0 0;
	width: ${MAP_PANEL_WIDTH}px;
	pointer-events: auto;

	padding: 8px;
	margin: 8px;
	border-radius: 8px;
	background-color: ${({
		theme: {
			background: { frame },
		},
	}) => frame};
	${({ theme: { shadow } }) => shadow[2]};
`;

export const TreeMapSearchBarWrapper = styled(View)`
	padding: 8px;
`;

export const TreeMapFiltersWrapper = styled(View)`
	padding: 8px;
`;

export const TreeMapCountText = styled(SubtitleText2)`
	color: ${({
		theme: {
			font: { color },
		},
	}) => color.darkBgPrimary};
	padding-left: 12px;
	margin-bottom: 4px;
`;

export const TreeMapCountNumber = styled(SubtitleText2)`
	color: ${({
		theme: {
			font: { color },
		},
	}) => color.primary};

	margin-right: 4px;
`;

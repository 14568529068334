import React, { useContext, useMemo } from 'react';
import dashboardContext from '../../../context';
import {
	UserPanelMenuWrapper,
	UserPanelItemButton,
	UserPanelItemText,
	UserPanelButtonIcon,
} from './UserPanelMenu.sc';
import { mdiLogout } from '@mdi/js';

export default function UserPanelMenu() {
	const { onRequestLogout } = useContext(dashboardContext);
	return useMemo(() => {
		return (
			<UserPanelMenuWrapper>
				<UserPanelItemButton onPress={onRequestLogout}>
					<UserPanelButtonIcon path={mdiLogout} />
					<UserPanelItemText>LOGOUT</UserPanelItemText>
				</UserPanelItemButton>
			</UserPanelMenuWrapper>
		);
	}, [onRequestLogout]);
}

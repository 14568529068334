import React from 'react';
import { OrderedMap } from 'immutable';
// import { ActivityWindow } from '../../../Components/Dashboard';
import {
	mdiTree,
	mdiChip,
	mdiChartAreaspline,
	mdiWrench,
	mdiAlarmLightOutline,
	mdiWeatherPouring,
	mdiAccountBadgeAlert,
	mdiMap,
	mdiClockOutline,
	mdiAccountBoxMultiple,
} from '@mdi/js';
import DataTable from './DataTable';
import { Activity } from '../../../Components/Dashboard/interfaces';
import WindowedAlertControl from './AlertControl';
import WindowedSensorControl from './SensorControl';
import TreeMap from './TreeMap';
import {
	treesTableInitialState,
	sensorsTableInitialState,
	readingsTableInitialState,
	maintenanceInitialState,
	systemLogsInitialState,
	scenarioChangeLogsInitialState,
} from '../../../Store/reducers/tablesInitialState';
import { ActivityWindow } from '../../../Components/Dashboard';

// function WorkInProgress(props: { activity: Activity }) {
// 	const {
// 		activity: { id },
// 	} = props;
// 	return <ActivityWindow activityId={id}>Work in progress</ActivityWindow>;
// }

const tableEditingGroups = [
	'admin',
	'jwe',
	'poly',
	'tmo',
	'lcsd_editor',
	'hyd_editor',
	'archsd_editor',
	'hd_editor',
];

export const Applications = OrderedMap({
	treeMap: {
		key: 'treeMap',
		icon: mdiMap,
		name: 'Map',
		component: TreeMap,
	},
	treesTable: {
		key: 'treesTable',
		icon: mdiTree,
		name: 'Tree Inventory',
		component: ({ activity }: { activity: Activity }) => {
			return (
				<DataTable
					allowEdit={tableEditingGroups}
					activity={activity}
					type="treesTable"
					keyField="treeId"
					collection="trees"
					defaultState={treesTableInitialState}
				/>
			);
		},
	},
	sensorsTable: {
		key: 'sensorsTable',
		icon: mdiChip,
		name: 'Sensor Inventory',
		component: ({ activity }: { activity: Activity }) => {
			return (
				<DataTable
					allowEdit={tableEditingGroups}
					activity={activity}
					type="sensorsTable"
					keyField="sensorId"
					collection="sensors"
					defaultState={sensorsTableInitialState}
				/>
			);
		},
	},
	readingsTable: {
		key: 'readingsTable',
		icon: mdiChartAreaspline,
		name: 'Reading Records',
		allowAccess: ['poly', 'jwe', 'admin'],
		component: ({ activity }: { activity: Activity }) => {
			return (
				<DataTable
					allowEdit={tableEditingGroups}
					activity={activity}
					type="readingsTable"
					keyField="_id"
					collection="readings"
					defaultState={readingsTableInitialState}
				/>
			);
		},
	},
	maintenanceRecordsTable: {
		key: 'maintenanceRecordsTable',
		icon: mdiWrench,
		name: 'Maintenance Records',
		component: ({ activity }: { activity: Activity }) => {
			return (
				<DataTable
					allowEdit={tableEditingGroups}
					activity={activity}
					type="maintenanceRecordsTable"
					keyField="maintenanceId"
					collection="maintenances"
					defaultState={maintenanceInitialState}
				/>
			);
		},
	},
	alertControl: {
		key: 'alertControl',
		icon: mdiAlarmLightOutline,
		name: 'Alert Control',
		allowAccess: ['admin', 'jwe', 'poly', 'tmo'],
		component: WindowedAlertControl,
	},
	scenarioSetting: {
		key: 'scenarioSetting',
		icon: mdiWeatherPouring,
		name: 'Scenario Setting',
		allowAccess: ['admin', 'jwe', 'poly', 'tmo'],
		component: WindowedSensorControl,
	},
	scenarioChangeLogsTable: {
		key: 'scenarioChangeLogsTable',
		icon: mdiClockOutline,
		name: 'Scenario Change Log',
		allowAccess: ['admin', 'jwe', 'poly', 'tmo'],
		component: ({ activity }: { activity: Activity }) => {
			return (
				<DataTable
					allowEdit={tableEditingGroups}
					activity={activity}
					type="scenarioChangeLogsTable"
					keyField="_id"
					collection="modeChangeLogs"
					defaultState={scenarioChangeLogsInitialState}
				/>
			);
		},
	},
	systemLogsTable: {
		key: 'systemLogsTable',
		icon: mdiAccountBadgeAlert,
		name: 'System Log',
		allowAccess: ['admin', 'jwe', 'poly', 'tmo'],
		component: ({ activity }: { activity: Activity }) => {
			return (
				<DataTable
					allowEdit={tableEditingGroups}
					activity={activity}
					type="systemLogsTable"
					keyField="_id"
					collection="actionLogs"
					defaultState={systemLogsInitialState}
				/>
			);
		},
	},
	userManagement: {
		key: 'userManagement',
		icon: mdiAccountBoxMultiple,
		name: 'User Management',
		allowAccess: ['admin'],
		component: ({ activity: { id } }: any) => (
			<ActivityWindow activityId={id}>
				<iframe
					style={{ width: '100%', height: '100%' }}
					src="https://tms-auth.hkjctree.com/dashboard/user"
					title="User Management"
				/>
			</ActivityWindow>
		),
	},
});

import createActionCreator from '../../Helpers/createActionCreator';

interface IChangeActiveWorkspacePayload {
	name: string;
}

export const changeActiveWorkspace = createActionCreator(
	'SYSTEM_BAR/CHANGE_ACTIVE_WORKSPACE',
	{} as IChangeActiveWorkspacePayload
);

interface ICreateWorkspacePayload {
	name: string;
}

export const createWorkspace = createActionCreator(
	'SYSTEM_BAR/CREATE_WORKSPACE',
	{} as ICreateWorkspacePayload
);

interface IRemoveWorkspacePayload {
	name: string;
}

export const removeWorkspace = createActionCreator(
	'SYSTEM_BAR/REMOVE_WORKSPACE',
	{} as IRemoveWorkspacePayload
);

import styled from 'styled-components';
import { View } from '../../View';

const MenuSeparator = styled(View)`
	margin-top: 8px;
	margin-bottom: 8px;
	height: 1px;
	background-color: ${({
		theme: {
			background: { darker },
		},
	}) => darker};
	align-self: stretch;
`;

export default MenuSeparator;

import styled from 'styled-components';
import { View } from '../../../../Common/View';
import ToggleableInput from '../../../../Common/ToggleableInput';
import { DatePicker } from '../../../../Common/DatePicker';
import Checkbox from '../../../../Common/Checkbox';
import { ButtonOverlayShape } from '../../../../Common/Button/interfaces';
import constants from '../../../constants';

const { MIN_ROW_HEIGHT } = constants;

export const BodyCellInputWrapper = styled(View)`
	flex: 1 0 0;
	flex-direction: row;
	align-items: center;
`;

export const BodyCellBaseInput = styled(ToggleableInput)`
	flex: 1 0 0;
	& > [x-component='input'] {
		box-sizing: border-box;
		padding: 6px;
		color: ${({
			theme: {
				font: {
					color: { lightBgPrimary },
				},
			},
		}) => lightBgPrimary};
	}
	& > [x-component='input'][x-mode~='disabled'] {
		color: ${({
			theme: {
				font: {
					color: { lightBgSecondary },
				},
			},
		}) => lightBgSecondary};
	}
`;

export const BodyCellBooleanCheckbox = styled(Checkbox)`
	width: ${MIN_ROW_HEIGHT}px;
`;

export const BodyCellDatePicker = styled(DatePicker).attrs({
	overlayShape: ButtonOverlayShape.circle,
})`
	width: ${MIN_ROW_HEIGHT}px;
`;

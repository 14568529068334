import React, { useMemo } from 'react';

import { mdiAngleAcute } from '@mdi/js';
import {
	ChartWrapper,
	ChartHeaderWrapper,
	ChartIcon,
	ChartTitleTextWrapper,
	ChartTitleText,
	ChartBodyWrapper,
	ChartNoDataWrapper,
	ChartNoDataText,
	ChartScrollWrapper,
} from './TiltingAngleChart.sc';
import {
	VictoryChart,
	VictoryGroup,
	VictoryTooltip,
	VictoryStack,
	VictoryBar,
	VictoryAxis,
	VictoryLabel,
} from 'victory';
import { chartDarkTheme, stackColors } from '../../../../../chartTheme';
import { MouseOverIcon } from './MouseOverIcon';

export interface TiltingAngleChartDatum {
	roundedAngle: number;
	data: {
		x: string;
		y: number;
	}[];
}

export type TiltingAngleChartData = TiltingAngleChartDatum[];

interface Props {
	data: TiltingAngleChartData;
}

const tiltingLevelColor = [
	'#38B10D',
	'#FFC700',
	'#F2994A',
	'#EB5757',
	'#EB5757',
	'#BE3030',
	'#BE3030',
];

export default function TiltingAngleChart(props: Props) {
	const { data } = props;
	const dataCount = data.length;

	return useMemo(() => {
		return (
			<ChartWrapper>
				<ChartHeaderWrapper>
					<ChartIcon icon={mdiAngleAcute} />
					<ChartTitleTextWrapper>
						<ChartTitleText>TILTING ANGLE</ChartTitleText>
					</ChartTitleTextWrapper>
					<MouseOverIcon />
				</ChartHeaderWrapper>
				<ChartScrollWrapper>
					<ChartBodyWrapper>
						{dataCount > 0 ? (
							<VictoryChart
								theme={chartDarkTheme}
								domainPadding={{ x: [20, 50], y: [20, 70] }}
								height={80 * data[0].data.length + 70}
							>
								<VictoryStack
									colorScale={tiltingLevelColor}
									// categories={categories}
								>
									{data.map(
										({ data: _data, roundedAngle }, i) => {
											const nextData = data[i + 1];
											const label = nextData
												? i === data.length - 2
													? '5° ≤ x < 6°'
													: `${roundedAngle} ≤ x < ${nextData.roundedAngle}`
												: `${roundedAngle}`;
											return (
												<VictoryGroup
													horizontal
													data={_data.map(
														({ x, y }) => ({
															x: x || 'unknow',
															y,
														})
													)}
													key={i}
													x="x"
													y="y"
													style={{
														data: {
															width: 25,
															color:
																stackColors[
																	roundedAngle -
																		1
																],
														},
													}}
													labels={({ datum }) =>
														`${label}, ${datum.y}`
													}
													labelComponent={
														<VictoryTooltip
															orientation="top"
															dy={-10}
															style={{
																fontSize: 24,
															}}
														/>
													}
												>
													<VictoryBar />
												</VictoryGroup>
											);
										}
									)}
								</VictoryStack>
								<VictoryAxis dependentAxis />
								<VictoryAxis
									orientation={'right'}
									offsetX={650}
									style={{
										tickLabels: {
											fontSize: 24,
										},
									}}
									tickLabelComponent={
										<VictoryLabel dy={-35} />
									}
								/>
							</VictoryChart>
						) : (
							<ChartNoDataWrapper>
								<ChartNoDataText>NO DATA</ChartNoDataText>
							</ChartNoDataWrapper>
						)}
					</ChartBodyWrapper>
				</ChartScrollWrapper>
			</ChartWrapper>
		);
	}, [dataCount, data]);
}

import React, { useMemo, useState, Ref, forwardRef } from 'react';
import context from '../context';
import { DesktopProps, TilePreviewState, DesktopEdge } from '../interfaces';
import { DesktopWrapper, TilePreview } from './Desktop.sc';
import { Transition } from 'react-transition-group';
import useForwardedRef from '../../../../../Hooks/useForwardedRef';

const { Provider } = context;

export default forwardRef(function Desktop(
	props: DesktopProps,
	ref: Ref<HTMLDivElement>
) {
	const {
		children,
		windows,
		windowIndice,
		width,
		height,
		onWindowChange,
		onWindowFocus,
		onWindowMoveStart,
		onWindowMoveEnd,
		onWindowResizeStart,
		onWindowResizeEnd,
	} = props;

	const [tilePreview, setTilePreview] = useState<TilePreviewState>({
		edge: DesktopEdge.NULL,
		x: 0,
		y: 0,
	});

	const containerRef = useForwardedRef(ref);

	return useMemo(() => {
		const { x, y, edge } = tilePreview;

		return (
			<Provider
				value={{
					containerRef,
					windows,
					windowIndice,
					desktopWidth: width,
					desktopHeight: height,
					onWindowChange,
					onWindowFocus,
					onWindowMoveStart,
					onWindowMoveEnd,
					onWindowResizeStart,
					onWindowResizeEnd,
					setTilePreview,
				}}
			>
				<DesktopWrapper ref={containerRef}>
					{children}
					<Transition
						in={tilePreview.edge !== DesktopEdge.NULL}
						timeout={200}
						unmountOnExit
					>
						{(status) => {
							const previewBound =
								status === 'entered'
									? edge === DesktopEdge.LEFT
										? {
												width: width / 2,
												height,
												left: 0,
												top: 0,
										  }
										: edge === DesktopEdge.RIGHT
										? {
												width: width / 2,
												height,
												left: width / 2,
												top: 0,
										  }
										: {
												width,
												height,
												left: 0,
												top: 0,
										  }
									: {
											width: 0,
											height: 0,
											left: x,
											top: y,
									  };
							return (
								<TilePreview
									style={{
										...previewBound,
										zIndex: windowIndice.size,
									}}
								/>
							);
						}}
					</Transition>
				</DesktopWrapper>
			</Provider>
		);
	}, [
		containerRef,
		width,
		height,
		windows,
		windowIndice,
		children,
		tilePreview,
		onWindowChange,
		onWindowFocus,
		onWindowMoveStart,
		onWindowMoveEnd,
		onWindowResizeStart,
		onWindowResizeEnd,
	]);
});

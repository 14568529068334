import React, {
	useMemo,
	forwardRef,
	ReactNode,
	Ref,
	CSSProperties,
	HTMLAttributes,
} from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { HeaderInnerWrapper as SHeaderInnerWrapper } from './HeaderInnerWrapper.sc';
import useColumnRearrange from '../Hooks/useColumnRearrange';
import useObject from '../../../../../Hooks/useObject';
import useContextWithRef from '../../../../../Hooks/useContextWithRef';
import context from '../../../context';

interface HeaderInnerWrapperProps {
	style: CSSProperties;
	children: ReactNode;
}

interface SSHeaderInnerWrapperProps
	extends Omit<HTMLAttributes<HTMLDivElement>, 'ref'> {
	forwardedRef: Ref<HTMLDivElement>;
	onSortEnd(sortInfo: { newIndex: number; oldIndex: number }): void;
	updateBeforeSortStart(sortInfo: { index: number }): void;
}

const SSHeaderInnerWrapper = SortableContainer(
	({
		forwardedRef,
		onSortEnd,
		updateBeforeSortStart,
		...restProps
	}: SSHeaderInnerWrapperProps) => (
		<SHeaderInnerWrapper ref={forwardedRef} {...restProps} />
	)
);

const HeaderInnerWrapper = forwardRef(function(
	props: HeaderInnerWrapperProps,
	ref: Ref<HTMLDivElement>
) {
	const { style, children } = props;
	const _style = useObject(style);
	const { contextObjRef } = useContextWithRef(context);
	const {
		handleColumnRearrangeStart,
		handleColumnRearrangeEnd,
	} = useColumnRearrange(contextObjRef);

	return useMemo(() => {
		// console.log('render HeaderInnerWrapper');
		return (
			<SSHeaderInnerWrapper
				axis="x"
				lockAxis="x"
				useDragHandle
				forwardedRef={ref}
				updateBeforeSortStart={handleColumnRearrangeStart}
				onSortEnd={handleColumnRearrangeEnd}
				style={_style}
			>
				{children}
			</SSHeaderInnerWrapper>
		);
	}, [
		ref,
		_style,
		children,
		handleColumnRearrangeStart,
		handleColumnRearrangeEnd,
	]);
});

export default HeaderInnerWrapper;

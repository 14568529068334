import styled from 'styled-components';
import WrappedInput from '../../../../Common/WrappedInput';

export interface FormatModalInputProps {
	valid: boolean;
}

export const FormatModalInput = styled(WrappedInput)<FormatModalInputProps>`
	display: flex;
	flex: 1 0 0;
	margin-right: 8px;
	& > [x-component='input'] {
		box-sizing: border-box;
		border-bottom: 2px solid
			${({
				theme: {
					background: { darker },
				},
			}) => darker};
		padding: 2px;
		color: ${({
			theme: {
				font: {
					color: { lightBgSecondary },
				},
			},
		}) => lightBgSecondary};
		background-color: ${({
			theme: {
				background: { light, error },
			},
			valid,
		}) => (valid ? light : error)};
		::placeholder {
			color: ${({
				theme: {
					font: {
						color: { lightBgDisabled },
					},
				},
			}) => lightBgDisabled};
			opacity: 1;
		}
	}
`;

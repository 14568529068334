import styled, { css } from 'styled-components';

import { View } from '../Common/View';
import { SnackbarSide } from './reducer';

export interface ISnackbarGroupWrapper {
	side: SnackbarSide;
}

const bottomStyle = css`
	bottom: 0;
	left: 50%;
	transform: translate3d(-50%, 0, 0);
`;

const rightStyle = css`
	top: 50px;
	right: 0;
`;

function getSideStyle(props: { side: SnackbarSide }) {
	switch (props.side) {
		case SnackbarSide.bottom:
			return bottomStyle;
		case SnackbarSide.right:
			return rightStyle;
	}
}

export const SnackbarGroupWrapper = styled(View)<ISnackbarGroupWrapper>`
	position: absolute;
	flex-direction: column;
	z-index: 2000;

	${getSideStyle}
`;

import styled from 'styled-components';
import { View } from '../../../../Common/View';
import { NAVIGATION_HEIGHT, LOGO_SIZE } from '../constants';
import { Image } from '../../../../Common/Image';
import { HeadingText6, BodyText2 } from '../../../../Common/Text';

export const NavigationWrapper = styled(View)`
	flex: 0 0 ${NAVIGATION_HEIGHT}px;
	align-self: stretch;
	align-items: stretch;
	box-sizing: border-box;
	background-color: ${({
		theme: {
			background: { accent },
		},
	}) => accent};

	border-bottom: 2px solid
		${({
			theme: {
				background: { accentDarker },
			},
		}) => accentDarker};

	flex-direction: row;
`;

export const NavigationControlWrapper = styled(View)`
	flex: 1 0 auto;
	flex-direction: row-reverse;
`;

export const NavigationLogo = styled(Image)`
	width: ${LOGO_SIZE - 4}px;
	height: ${LOGO_SIZE - 4}px;
	margin-left: 8px;
	margin-right: 8px;
	align-self: center;
`;

export const NavigationTitleWrapper = styled(View)`
	flex: 0 1 auto;
	flex-direction: column;
	justify-content: center;
	padding-left: 8px;
	user-select: none;
	overflow: hidden;
	height: ${NAVIGATION_HEIGHT}px;
`;

export const NavigationTitleText = styled(HeadingText6)`
	color: ${({
		theme: {
			font: { color },
		},
	}) => color.darkBgPrimary};
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 100%;
`;
export const NavigationSubtitleText = styled(BodyText2)`
	color: ${({
		theme: {
			font: { color },
		},
	}) => color.darkBgPrimary};
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 100%;
`;

import { takeEvery, put } from 'redux-saga/effects';
import { downlinkAction, boardcastAction } from '../../actions/remoteStore';

export function* handleBroadcastAction(
	broadcast: ReturnType<typeof downlinkAction>
) {
	const {
		payload: { action },
	} = broadcast;
	yield put(action);
}

export default function* watchBoardcastAction() {
	yield takeEvery(boardcastAction.type, handleBroadcastAction);
}

import React, { useMemo, useRef } from 'react';
import Popover, { usePopoverOnElement } from '../../../../Common/Popover';
import NotificationPanelButton from './NotificationPanelButton';
import NotificationList from './NotificationList';
import {
	PopoverProps,
	PopoverEnterFrom,
} from '../../../../Common/Popover/interfaces';

function getPopoverStyle({ height = 0 }: PopoverProps) {
	return {
		transform: `translateY(${height - 4}px)`,
	};
}

export default function NotificationPanel() {
	const buttonRef = useRef<HTMLDivElement>(null);

	const { open: openPopover, getPopoverProps } = usePopoverOnElement(
		buttonRef
	);

	return useMemo(() => {
		const popoverProps = getPopoverProps();
		const popoverStyle = getPopoverStyle(popoverProps);
		return (
			<>
				<NotificationPanelButton
					ref={buttonRef}
					onPress={openPopover}
				/>
				<Popover
					style={popoverStyle}
					enterFrom={PopoverEnterFrom.top}
					edgeDodging
					{...popoverProps}
				>
					<NotificationList />
				</Popover>
			</>
		);
	}, [buttonRef, getPopoverProps, openPopover]);
}

import React, { useMemo, useRef } from 'react';
import {
	HeaderButtonWrapper,
	HeaderButtonIcon,
	HeaderButtonText,
} from './WindowHeaderButton.sc';
import Tooltip from '../../../../Common/Tooltip';
import { usePopoverOnElement } from '../../../../Common/Popover';
import { WindowHeaderButtonProps, IconPosition } from '../interfaces';
import { TooltipLocation } from '../../../../Common/Tooltip/interfaces';
import { ButtonOverlayShape } from '../../../../Common/Button/interfaces';

const stopPropagation = (e: React.MouseEvent) => e.stopPropagation();

export default function WindowHeaderButton(props: WindowHeaderButtonProps) {
	const {
		icon,
		iconPosition = IconPosition.LEFT,
		text,
		tooltip,
		bordered = false,
		tabIndex = 1,
		onPress,
	} = props;

	const buttonRef = useRef<HTMLDivElement>(null);

	const {
		open: openTooltip,
		close: closeTooltip,
		getPopoverProps,
	} = usePopoverOnElement(buttonRef);
	return useMemo(() => {
		return (
			<HeaderButtonWrapper
				ref={buttonRef}
				tabIndex={tabIndex}
				bordered={bordered}
				overlayShape={
					bordered
						? ButtonOverlayShape.rectangle
						: ButtonOverlayShape.circle
				}
				onMouseEnter={openTooltip}
				onMouseLeave={closeTooltip}
				onMouseDown={stopPropagation}
				onDoubleClick={stopPropagation}
				onPress={onPress}
			>
				{iconPosition === IconPosition.LEFT && icon ? (
					<HeaderButtonIcon path={icon} />
				) : null}
				{text ? <HeaderButtonText>{text}</HeaderButtonText> : null}
				{iconPosition === IconPosition.RIGHT && icon ? (
					<HeaderButtonIcon path={icon} />
				) : null}
				{tooltip ? (
					<Tooltip
						location={TooltipLocation.bottom}
						{...getPopoverProps()}
					>
						{tooltip}
					</Tooltip>
				) : null}
			</HeaderButtonWrapper>
		);
	}, [
		text,
		icon,
		iconPosition,
		bordered,
		tabIndex,
		tooltip,
		getPopoverProps,
		closeTooltip,
		openTooltip,
		onPress,
	]);
}

import React, {
	useCallback,
	useMemo,
	ChangeEvent as RChangeEvent,
	useContext,
} from 'react';

import { mdiFunctionVariant } from '@mdi/js';
import { useColumn, useColumnFormulaValueUpdate } from '../../../Hooks/Column';
import {
	FormulaButton,
	PanelFormulaWrapper,
	PanelFormulaTextArea as SPanelFormulaTextArea,
} from './PanelFormula.sc';
import context from '../../../context';

interface FormulaIconProps {
	disabled: boolean;
}
function FormulaIcon(props: FormulaIconProps) {
	const { disabled } = props;
	return useMemo(() => {
		return (
			<FormulaButton
				disabled={disabled}
				icon={mdiFunctionVariant}
				tooltip="Formula"
			/>
		);
	}, [disabled]);
}

interface PanelFormulaTextAreaProps {
	disabled: boolean;
	columnKey: string;
}
function PanelFormulaTextArea(props: PanelFormulaTextAreaProps) {
	const { disabled, columnKey } = props;
	const contextObj = useContext(context);
	const {
		formula: { value },
	} = useColumn(contextObj, columnKey);
	const updateColumnFormulaValue = useColumnFormulaValueUpdate(
		contextObj,
		columnKey
	);
	const handleChange = useCallback(
		(e: RChangeEvent<HTMLTextAreaElement>) => {
			const newValue = e.target.value;
			if (value !== newValue) updateColumnFormulaValue(newValue);
		},
		[value, updateColumnFormulaValue]
	);

	return useMemo(() => {
		return (
			<SPanelFormulaTextArea
				tabIndex={0}
				disabled={disabled}
				value={value}
				onChange={handleChange}
			/>
		);
	}, [disabled, value, handleChange]);
}

interface PanelFormulaProps {
	columnKey: string;
}
export default function PanelFormula(props: PanelFormulaProps) {
	const { columnKey } = props;
	return useMemo(() => {
		const disabled = !columnKey;
		return (
			<PanelFormulaWrapper>
				<FormulaIcon disabled={disabled} />
				<PanelFormulaTextArea
					disabled={disabled}
					columnKey={columnKey}
				/>
			</PanelFormulaWrapper>
		);
	}, [columnKey]);
}

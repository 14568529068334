import React, { useMemo, useCallback, useEffect, useRef } from 'react';
import {
	SearchBarIconWrapper,
	SearchBarIcon,
	SuggestonText,
	SuggestionWrapper,
} from './SearchBar.sc';
import { Suggestion } from './interfaces';

interface SuggestionProps extends Suggestion {
	index: number;
	focused: boolean;
	fallbackIcon: string;
	onSelect: (index: number) => any;
}

export default function SearchSuggestion(props: SuggestionProps) {
	const { index, icon, fallbackIcon, name, focused, onSelect } = props;

	const containerRef = useRef<HTMLDivElement>(null);
	const handleSelect = useCallback(() => {
		onSelect(index);
	}, [onSelect, index]);

	useEffect(() => {
		const container = containerRef.current;
		if (focused && container)
			container.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
			});
	}, [focused]);

	return useMemo(
		() => (
			<SuggestionWrapper
				ref={containerRef}
				focused={focused}
				key={name}
				onMouseDown={handleSelect}
			>
				<SearchBarIconWrapper>
					<SearchBarIcon path={icon || fallbackIcon} />
				</SearchBarIconWrapper>
				<SuggestonText>{name}</SuggestonText>
			</SuggestionWrapper>
		),
		[focused, name, icon, fallbackIcon, handleSelect]
	);
}

import { Record, Map, OrderedSet, fromJS, RecordOf } from 'immutable';
import createReducer from '../../Helpers/createReducer';
import {
	fetchRecords,
	importRecord,
	importRecords,
	updateSearchText,
	deleteRecordSuccess,
} from './actions';
import { IRecordsFeedStore } from './interfaces';
import { AnyAction } from 'redux';

export const RecordsFeedStore = Record<IRecordsFeedStore>({
	updating: Map(),
	search: '',
	loading: false,
	exhausted: false,
	records: Map(),
	recordIds: OrderedSet(),
});

const initialState = RecordsFeedStore();

export const recordsFeedReducer = (feedId: string) => {
	const reducer = createReducer(
		initialState,
		{
			fetchRecords,
			importRecord,
			importRecords,
			updateSearchText,
			deleteRecordSuccess,
		},
		{
			fetchRecords: (state) => state.set('loading', true),
			importRecords: (state, { payload: { records, recordIds } }) => {
				return state.withMutations((s) => {
					if (records.length === 0) {
						s.set('exhausted', true);
					} else {
						records.forEach((record, i) => {
							s.setIn(['records', recordIds[i]], fromJS(record));
						});
						s.set('recordIds', OrderedSet(recordIds));
					}
					s.set('loading', false);
				});
			},
			importRecord: (state, { payload: { record, recordId } }) =>
				state.withMutations((s) => {
					s.setIn(['records', recordId], fromJS(record));
					s.update('recordIds', (recordIds) =>
						recordIds.add(recordId).sort((a, b) => {
							const aUpdatedAt = s.records
								.get(a, Map())
								.get('updatedAt');
							const bUpdatedAt = s.records
								.get(b, Map())
								.get('updatedAt');

							return (
								new Date(bUpdatedAt).getTime() -
								new Date(aUpdatedAt).getTime()
							);
						})
					);
				}),
			updateSearchText: (state, { payload: { searchText } }) =>
				state.merge({
					search: searchText,
					recordIds: OrderedSet(),
					records: Map(),
					exhausted: false,
				}),
			deleteRecordSuccess: (state, { payload: { recordId } }) =>
				state.withMutations((s) => {
					s.update('records', (records) =>
						records.filter((record, id) => id !== recordId)
					);
					s.update('recordIds', (recordIds) =>
						recordIds.delete(recordId)
					);
				}),
		}
	);

	return (
		state: RecordOf<IRecordsFeedStore> = initialState,
		action: AnyAction
	) => {
		const { payload } = action;
		if (!payload || !payload.feedId || payload.feedId !== feedId)
			return state;

		return reducer(state, action);
	};
};

export default recordsFeedReducer;

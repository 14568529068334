import { StandardWindowProps } from '../interfaces';
import { useCallback } from 'react';
import { moveWindow } from '../../Window';
import { Movement, StandardWindowState } from '../../../interfaces';
import noop from '../../../../../Helpers/noop';
import useAsRef from '../../../../../Hooks/useAsRef';

export default function useMove(
	props: StandardWindowProps,
	setWindowState: React.Dispatch<React.SetStateAction<StandardWindowState>>
) {
	const {
		width,
		height,
		parentHeight,
		parentWidth,
		onChange = noop,
		onMoveInit,
		onMoveStart,
		onMove,
		onMoveEnd,
		onMoveCancel,
	} = props;

	const widthRef = useAsRef(width);
	const heightRef = useAsRef(height);

	const handleMoveInit = useCallback(
		(movement: Movement) => {
			onMoveInit && onMoveInit(movement);
			setWindowState((s) => {
				return {
					...s,
					moving: true,
				};
			});
		},
		[setWindowState, onMoveInit]
	);

	const handleMoveStart = useCallback(
		(movement: Movement) => {
			const { accumX, accumY } = movement;
			onMoveStart && onMoveStart(movement);
			setWindowState((s) => {
				const { left, top, x, y } = moveWindow(
					{ x: s.x1, y: s.y1 },
					{ dx: accumX, dy: accumY },
					widthRef.current,
					heightRef.current,
					parentWidth,
					parentHeight
				);
				return {
					...s,
					x1: x,
					y1: y,
					left,
					top,
				};
			});
		},
		[
			setWindowState,
			heightRef,
			widthRef,
			parentWidth,
			parentHeight,
			onMoveStart,
		]
	);

	const handleMove = useCallback(
		(movement: Movement) => {
			const { movementX, movementY } = movement;
			onMove && onMove(movement);
			setWindowState((s) => {
				const { left, top, x, y } = moveWindow(
					{ x: s.x1, y: s.y1 },
					{ dx: movementX, dy: movementY },
					widthRef.current,
					heightRef.current,
					parentWidth,
					parentHeight
				);
				return {
					...s,
					moving: true,
					x1: x,
					y1: y,
					left,
					top,
				};
			});
		},
		[setWindowState, heightRef, widthRef, parentWidth, parentHeight, onMove]
	);

	const handleMoveEnd = useCallback(
		(movement: Movement) => {
			onMoveEnd && onMoveEnd(movement);
			setWindowState((s) => {
				const newState = {
					...s,
					moving: false,
					x1: s.left,
					y1: s.top,
				};

				onChange(newState);

				return newState;
			});
		},
		[setWindowState, onChange, onMoveEnd]
	);

	const handleMoveCancel = useCallback(
		(movement: Movement) => {
			onMoveCancel && onMoveCancel(movement);
			setWindowState((s) => {
				const newState = {
					...s,
					moving: false,
				};

				onChange(newState);

				return newState;
			});
		},
		[setWindowState, onChange, onMoveCancel]
	);

	return {
		handleMoveInit,
		handleMoveStart,
		handleMove,
		handleMoveEnd,
		handleMoveCancel,
	};
}

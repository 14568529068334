import styled from 'styled-components';
import { View } from './Components/Common/View';

export const AppWrapper = styled(View)`
	flex: 1;
	align-self: stretch;
	overflow: hidden;

	background-color: ${({ theme: { background } }) => background.darker};
`;

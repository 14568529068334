import styled from 'styled-components';
import { mdiClose } from '@mdi/js';
import Popover from '../../Popover/Components/Popover';
import { View } from '../../View';
import { CaptionText } from '../../Text';
import IconTextButton from '../../IconTextButton';
import { ButtonOverlayShape } from '../../Button/interfaces';

export const ModalWrapper = styled(Popover)`
	transform: translateX(-50%) translateY(-50%);
	overflow: hidden;
	background: ${({
		theme: {
			background: { light },
		},
	}) => light};
	border-radius: 4px;
	${({ theme: { shadow } }) => shadow[2]}
`;

export const ModalTitleWrapper = styled(View)`
	width: 100%;
	height: 32px;
	user-select: none;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background: ${({
		theme: {
			background: { frame },
		},
	}) => frame};
	color: ${({
		theme: {
			font: {
				color: { darkBgSecondary },
			},
		},
	}) => darkBgSecondary};
`;

export const ModalTitleText = styled(CaptionText)``;

export const ModalCloseButton = styled(IconTextButton).attrs({
	lightBg: false,
	icon: mdiClose,
	overlayShape: ButtonOverlayShape.circle,
})`
	position: absolute;
	top: 2px;
	right: 4px;
	width: 28px;
	height: 28px;
	justify-content: center;
`;

import { useRef, useCallback } from 'react';
import { resizeRow } from '../../../Store/actions';
import { useRow } from '../../../Hooks/Row';
import useAsState from '../../../../../Hooks/useAsState';
import { ITableContext } from '../../../context';
import constants from '../../../constants';

const { MIN_ROW_HEIGHT } = constants;

export default function useRowResize(
	contextObj: ITableContext,
	recordId: string
) {
	const { onRequestDispatch, setResizingRecordId, setSelected } = contextObj;
	const { height } = useRow(contextObj, recordId);
	const [_height, setHeight] = useAsState(height);
	const heightRef = useRef(0);

	const startRowResize = useCallback(() => {
		setResizingRecordId(recordId);
		document.body.style.cursor = 'ns-resize';
		heightRef.current = _height;

		const handleMouseMove = (e: MouseEvent) => {
			const { movementY } = e;
			heightRef.current += movementY;
			setHeight(Math.max(heightRef.current, MIN_ROW_HEIGHT));
		};
		const handleMouseUp = () => {
			onRequestDispatch(
				resizeRow({
					recordId,
					height: Math.max(heightRef.current, MIN_ROW_HEIGHT),
				})
			);
			setResizingRecordId(null);
			setSelected(null);
			document.body.style.cursor = 'unset';
			window.removeEventListener('mousemove', handleMouseMove);
			window.removeEventListener('mouseup', handleMouseUp);
		};

		window.addEventListener('mousemove', handleMouseMove);
		window.addEventListener('mouseup', handleMouseUp);
	}, [
		recordId,
		_height,
		setResizingRecordId,
		setSelected,
		setHeight,
		onRequestDispatch,
	]);

	return { height: _height, startRowResize };
}

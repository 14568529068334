import { useCallback } from 'react';
import { RecordOf } from 'immutable';
import { Cell } from '../Store/store';
import { updateCellsEditingBy, dropCellsEditingBy } from '../Store/actions';
import { useImmutable } from '../../../Hooks/useImmutable';
import { ICell } from '../interfaces';
import { ITableContext } from '../context';

export function useCell(
	contextObj: ITableContext,
	recordId: string,
	columnKey: string
): RecordOf<ICell> {
	const { cells } = contextObj;
	return useImmutable(cells.getIn([recordId, columnKey], Cell()));
}

export function useCellEditingByUpdate(
	contextObj: ITableContext,
	recordId: string,
	columnKey: string
) {
	const { user, onRequestDispatch } = contextObj;
	return useCallback(() => {
		onRequestDispatch(
			updateCellsEditingBy({
				changes: [{ recordId, columnKey, editingBy: user }],
			})
		);
	}, [onRequestDispatch, recordId, columnKey, user]);
}

export function useCellEditingByDrop(
	contextObj: ITableContext,
	recordId: string,
	columnKey: string
) {
	const { onRequestDispatch } = contextObj;
	return useCallback(() => {
		onRequestDispatch(
			dropCellsEditingBy({ keys: [{ recordId, columnKey }] })
		);
	}, [onRequestDispatch, recordId, columnKey]);
}

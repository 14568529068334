import { RecordOf, Record, Map, List } from 'immutable';
import { combineReducers } from 'redux-immutable';
import {
	mapReducer,
	TreeMapStoreRecord,
} from '../../Scenes/Main/Components/TreeMap/Store/reducer';

import { IChildState } from '../../Helpers/createTreeReducer';
import auth, { IAuth, Auth } from './auth';
import notificationReducer, {
	NotificationStore,
} from '../../Components/NotificationList/reducer';
import { INotificationStore } from '../../Components/NotificationList/interfaces';
import snackbarReducer, {
	SnackbarGroupStore,
	ISnackbarGroupStore,
} from '../../Components/Snackbar/reducer';
import {
	virtualDesktopReducer,
	VirtualDesktopStore,
} from '../../Components/DesktopControl/reducer';
import { tableReducer } from '../../Components/Table/Store/reducer';
import TableStore, {
	tableStorefromJS,
} from '../../Components/Table/Store/store';
import { readingChartsReducer } from '../../Components/ReadingCharts';
import { ReadingChartsStore } from '../../Components/ReadingCharts/recordFactories';
import { IReadingChartsStore } from '../../Components/ReadingCharts/interfaces';
import initMapViewStoreParser from '../../Scenes/Main/Components/TreeMap/Store/stateParser';
import {
	treesTableInitialState,
	sensorsTableInitialState,
	readingsTableInitialState,
	maintenanceInitialState,
	systemLogsInitialState,
	scenarioChangeLogsInitialState,
} from './tablesInitialState';

import { IRecordsFeedStore } from '../../Components/RecordsFeed/interfaces';
import recordsFeedReducer, {
	RecordsFeedStore,
} from '../../Components/RecordsFeed/reducer';
import {
	weatherReducer,
	WeatherPanelStore,
} from '../../Components/WeatherPanel/reducer';
import { IWeatherPanelStore } from '../../Components/WeatherPanel/interfaces';
import createEmbeddedReducer from '../../Components/Embedded/Store/createEmbeddedReducer';
import { EmbeddedState } from '../../Components/Embedded/interfaces';
import dashboardReducer, {
	DashboardStoreRecord,
	dashboardParser,
} from '../../Components/Dashboard/Store/reducer';
import workspaceReducer, {
	workspaceParser,
	WorkspaceStoreRecord,
} from '../../Components/Dashboard/Components/Workspace/Store/reducer';
import { resetApp } from '../actions/auth';
import makeId from '../../Helpers/makeId';

export interface IRootState {
	auth: RecordOf<IAuth>;
	stores: Map<string, EmbeddedState<any>>;
	weatherPanel: RecordOf<IWeatherPanelStore>;
	alertControl: RecordOf<IRecordsFeedStore>;
	sensorControl: RecordOf<IRecordsFeedStore>;
	notification: RecordOf<INotificationStore>;
	readings: RecordOf<IReadingChartsStore>;
	snackbar: RecordOf<ISnackbarGroupStore>;
}

export const RootState = Record<IRootState>({
	auth: Auth(),
	stores: Map(),
	weatherPanel: WeatherPanelStore(),
	alertControl: RecordsFeedStore(),
	sensorControl: RecordsFeedStore(),
	notification: NotificationStore(),
	readings: ReadingChartsStore(),
	snackbar: SnackbarGroupStore(),
});

export const ChildVirtualDesktopStore = Record<
	IChildState<typeof virtualDesktopReducer>
>({
	initialized: false,
	loading: false,
	data: VirtualDesktopStore(),
});

export const ChildMapViewStore = Record<IChildState<typeof mapReducer>>({
	initialized: false,
	loading: false,
	data: TreeMapStoreRecord(),
});

export const ChildTableStore = Record<IChildState<typeof tableReducer>>({
	initialized: false,
	loading: false,
	data: TableStore(),
});

const rootReducer = combineReducers(
	{
		auth,
		weatherPanel: weatherReducer,
		stores: createEmbeddedReducer({
			dashboard: {
				reducer: dashboardReducer,
				initialState: DashboardStoreRecord({
					currentWorkspace: 0,
					workspaces: List([{ id: makeId(16), name: 'Default' }]),
				}),
				parser: dashboardParser,
			},
			workspace: {
				reducer: workspaceReducer,
				initialState: WorkspaceStoreRecord(),
				parser: workspaceParser,
			},
			treesTable: {
				reducer: tableReducer,
				initialState: tableStorefromJS(treesTableInitialState as any),
				parser: tableStorefromJS,
			},
			sensorsTable: {
				reducer: tableReducer,
				initialState: tableStorefromJS(sensorsTableInitialState as any),
				parser: tableStorefromJS,
			},
			readingsTable: {
				reducer: tableReducer,
				initialState: tableStorefromJS(
					readingsTableInitialState as any
				),
				parser: tableStorefromJS,
			},
			maintenanceRecordsTable: {
				reducer: tableReducer,
				initialState: tableStorefromJS(maintenanceInitialState as any),
				parser: tableStorefromJS,
			},
			systemLogsTable: {
				reducer: tableReducer,
				initialState: tableStorefromJS(systemLogsInitialState as any),
				parser: tableStorefromJS,
			},
			scenarioChangeLogsTable: {
				reducer: tableReducer,
				initialState: tableStorefromJS(
					scenarioChangeLogsInitialState as any
				),
				parser: tableStorefromJS,
			},
			treeMap: {
				reducer: mapReducer,
				initialState: TreeMapStoreRecord(),
				parser: initMapViewStoreParser(),
			},
		}),
		alertControl: recordsFeedReducer('alertControl'),
		sensorControl: recordsFeedReducer('sensorControl'),
		readings: readingChartsReducer,
		notification: notificationReducer,
		snackbar: snackbarReducer,
	},
	RootState
);

export default (state: RecordOf<IRootState> = RootState(), action: any) => {
	if (action.type === resetApp.type) return rootReducer(undefined, action);
	return rootReducer(state, action);
};

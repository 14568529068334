import React, { useMemo, ReactElement, ComponentType } from 'react';
import { IconTextButtonText as SIconTextButtonText } from './IconTextButtonText.sc';
import useObject from '../../../../Hooks/useObject';
import { ButtonChildrenProps } from '../../Button/interfaces';

export interface IconTextButtonTextProps extends ButtonChildrenProps {
	text?: string | ReactElement;
	CustomText?: ComponentType<ButtonChildrenProps>;
}

function IconTextButtonText(props: IconTextButtonTextProps) {
	const { text, CustomText, ...restProps } = props;
	const _restProps = useObject(restProps);
	return useMemo(() => {
		if (CustomText) return <CustomText {..._restProps} />;
		if (text === undefined) return null;
		if (typeof text === 'string')
			return (
				<SIconTextButtonText x-component="text">
					{text}
				</SIconTextButtonText>
			);
		return text;
	}, [text, _restProps, CustomText]);
}

export default IconTextButtonText;

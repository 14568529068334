import styled from 'styled-components';
import { View } from '../../../../Common/View';
import { TitleText } from '../../../../Common/Text';

export const StandardWindowHeaderLeftWrapper = styled(View)`
	flex: 1 0 0;
	flex-direction: row;
	align-items: center;
`;

export const StandardWindowHeaderRightWrapper = styled(View)`
	flex: 1 0 0;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
`;

export const StandardWindowHeaderTitleWrapper = styled(View)`
	flex: 3 1 0;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	user-select: none;
`;

export const StandardWindowHeaderTitleText = styled(TitleText)`
	color: ${({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => darkBgPrimary};
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 100%;
	text-align: center;
`;

import styled from 'styled-components';
import { View } from '../../../../../../Components/Common/View';
import Button from '../../../../../../Components/Common/Button';
import { ButtonOverlayShape } from '../../../../../../Components/Common/Button/interfaces';
import Icon from '@mdi/react';
import WrappedInput from '../../../../../../Components/Common/WrappedInput';
import { CaptionText } from '../../../../../../Components/Common/Text';

export const FiltersWrapper = styled(View)`
	box-sizing: border-box;
	margin-bottom: 8px;
`;

export const AddFilterWrapper = styled(View)`
	background-color: ${({
		theme: {
			background: { light },
		},
	}) => light};

	margin-bottom: 2px;
	border-radius: 12px;
	box-sizing: border-box;

	${({ theme: { shadow } }) => shadow[2]};
`;

export const AddFilterInputWrapper = styled(View)`
	flex-direction: row;
	align-items: center;
	height: 42px;
	margin-bottom: 2px;
`;

export const AddFilterErrorMessageWrapper = styled(View)`
	padding: 8px;
`;

export const AddFilterErrorMessage = styled(CaptionText)`
	color: ${({
		theme: {
			font: {
				color: { error },
			},
		},
	}) => error};
`;

export const FilterInput = styled(WrappedInput)`
	flex: 1;
	justify-content: center;
	align-items: stretch;
	margin-right: 16px;

	& > [x-component='underline'] {
		background-color: ${({ theme: { background } }) => background.accent};
	}

	& > [x-component='input'] {
		font-size: 14px;
		height: 28px;
		box-sizing: border-box;
		border-bottom: 1px solid
			${({ theme: { background } }) => background.grey};
	}
`;

export const SelectFilterInput = styled(FilterInput)`
	width: 120px;
`;

export const UnitText = styled(CaptionText)`
	color: ${({
		theme: {
			font: {
				color: { lightBgSecondary },
			},
		},
	}) => lightBgSecondary};
`;

export const FilterWrapper = styled(View)`
	background-color: ${({
		theme: {
			background: { accent },
		},
	}) => accent};
	z-index: 1;

	height: 40px;
	align-items: center;
	flex-direction: row;
	margin-bottom: 2px;
	border-radius: 12px;

	padding-left: 16px;
	${({ theme: { shadow } }) => shadow[2]};
`;

export const FilterText = styled(CaptionText)`
	color: ${({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => darkBgPrimary};
	flex: 1;
`;

export const FilterButton = styled(Button).attrs({
	overlayShape: ButtonOverlayShape.circle,
})`
	/* &:before {
		background-color: transparent;
	}
	&:hover {
		&:before {
			background-color: ${({
				theme: {
					background: { darken },
				},
			}) => darken[2]};
		}
	} */
	height: 36px;
	width: 36px;
	align-items: center;
	justify-content: center;
`;

export const FilterIconWrapper = styled(View)`
	height: 36px;
	width: 36px;
	align-items: center;
	justify-content: center;
`;

export const FilterIcon = styled(Icon).attrs(
	({
		theme: {
			font: {
				color: { lightBgSecondary },
			},
		},
	}) => ({
		size: 0.9,
		color: lightBgSecondary,
	})
)``;

export const DisabledFilterIcon = styled(Icon).attrs(
	({
		theme: {
			font: {
				color: { lightBgDisabled },
			},
		},
	}) => ({
		size: 0.9,
		color: lightBgDisabled,
	})
)``;

import React, { useMemo, useEffect, useCallback } from 'react';
import { Transition } from 'react-transition-group';
import {
	SnackbarWrapper,
	SnackbarMessage,
	DismissButton,
	DismissText,
	SpinnerWrapper,
	LoadedIcon,
	SnackbarMessageWrapper,
	SnackbarTitle,
} from './Snackbar.sc';
import { ISnackbarRecord, SnackbarSide } from './reducer';
import { AnyAction } from 'redux';
import { dismissSnackbar } from './actions';
import BounceLoader from 'react-spinners/BounceLoader';
import theme from '../../../theme';
import { mdiCheck } from '@mdi/js';

interface SnackbarProps extends ISnackbarRecord {
	in?: boolean;
	snackbarId: string;
	side: SnackbarSide;
	maxWidth?: number;
	onRequestDispatch(action: AnyAction): any;
}

export default function Snackbar(props: SnackbarProps) {
	const {
		title,
		message,
		side,
		autoDismiss,
		onRequestDispatch,
		snackbarId,
		maxWidth,
		loading,
		...rest
	} = props;

	const handleDismiss = useCallback(() => {
		onRequestDispatch(dismissSnackbar({ snackbarId }));
	}, [onRequestDispatch, snackbarId]);

	useEffect(() => {
		if (typeof autoDismiss === 'number') {
			const timeoutId = setTimeout(handleDismiss, autoDismiss);

			return () => {
				clearTimeout(timeoutId);
			};
		}
	}, [autoDismiss, handleDismiss]);

	return useMemo(
		() => (
			<Transition timeout={200} {...rest}>
				{(status) => {
					return (
						<SnackbarWrapper
							status={status}
							side={side}
							maxWidth={maxWidth}
						>
							{loading !== undefined ? (
								<SpinnerWrapper>
									{loading ? (
										<BounceLoader
											size={30}
											color={theme.background.accent}
										/>
									) : (
										<LoadedIcon path={mdiCheck} />
									)}
								</SpinnerWrapper>
							) : null}
							<SnackbarMessageWrapper>
								<SnackbarTitle>{title}</SnackbarTitle>
								<SnackbarMessage>{message}</SnackbarMessage>
							</SnackbarMessageWrapper>
							<DismissButton onMouseDown={handleDismiss}>
								<DismissText>dismiss</DismissText>
							</DismissButton>
						</SnackbarWrapper>
					);
				}}
			</Transition>
		),
		[message, side, maxWidth, handleDismiss, rest, loading, title]
	);
}

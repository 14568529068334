import { Saga } from 'redux-saga';
import { all, call } from 'redux-saga/effects';
import remoteStoreSaga from './tasks/remoteStore';
import authSaga from './tasks/auth';
import watchDownlinkAction from './tasks/downlinkAction';
import watchUplinkAction from './tasks/uplinkAction';
import watchBoardcastAction from './tasks/broadcastAction';
import watchImportRecordsFile from './tasks/importRecordsFile';
import watchNotification from './tasks/notification';

export default function* rootSaga() {
	const sagas: Saga[] = [
		authSaga,
		remoteStoreSaga,
		watchDownlinkAction,
		watchUplinkAction,
		watchBoardcastAction,
		watchImportRecordsFile,
		watchNotification,
	];
	yield all(sagas.map((saga) => call(saga)));
}

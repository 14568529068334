import styled from 'styled-components';
import { View } from '../../../../Common/View';
import { HeadingText5 } from '../../../../Common/Text';
import IconTextButton from '../../../../Common/IconTextButton';
import WrappedInput from '../../../../Common/WrappedInput';

export const ImportRecordModalContentWrapper = styled(View)`
	width: 600px;
	height: 400px;
	border-radius: 4px;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	padding: 16px;
	background-color: ${({
		theme: {
			background: { light },
		},
	}) => light};
`;
export interface ModalFileDropzoneProps {
	focused: boolean;
	isDragActive: boolean;
	// isDragAccept: boolean;
	// isDragReject: boolean;
}
export const ModalFileDropzone = styled(View)<ModalFileDropzoneProps>`
	align-self: stretch;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	height: 350px;
	border-style: ${({ isDragActive, focused }) =>
		isDragActive || focused ? 'dashed' : 'none'};
	border-width: 2px;
	border-color: ${({
		theme: {
			font: {
				color: { lightBgSecondary },
			},
		},
	}) => lightBgSecondary};
	background-color: ${({
		theme: {
			background: { grey },
		},
	}) => grey};
	&:focus {
		outline: none;
	}
	transition: all 0.1s;
`;

export const ModalFileInputWrapper = styled(View)`
	flex-direction: row;
	align-self: stretch;
	align-items: stretch;
	height: 32px;
`;

export const ModalFileInput = styled(WrappedInput)`
	flex: 1 0 0;
	& > [x-component='input'] {
		box-sizing: border-box;
		border-bottom: 2px solid
			${({
				theme: {
					background: { darker },
				},
			}) => darker};
		padding: 2px;
	}
`;

export const ModalFileDropzoneText = styled(HeadingText5)`
	text-align: center;
	color: ${({
		theme: {
			font: {
				color: { lightBgWatermark },
			},
		},
	}) => lightBgWatermark};
`;

export const ModalImportButton = styled(IconTextButton).attrs(
	({
		theme: {
			font: {
				color: { darkBgPrimary, lightBgSecondary },
			},
		},
		disabled,
	}) => ({
		iconProps: {
			size: 0.7,
			color: disabled ? lightBgSecondary : darkBgPrimary,
		},
	})
)`
	height: 32px;
	margin: 4px;
	background-color: ${({
		theme: {
			background: { accent },
		},
	}) => accent};
	color: ${({
		theme: {
			font: {
				color: { darkBgSecondary },
			},
		},
	}) => darkBgSecondary};
	border-radius: 4px;
	& > [x-component='overlay'] {
		border-radius: 4px;
	}
	&[x-mode~='disabled'] {
		background-color: ${({
			theme: {
				background: { darker },
			},
		}) => darker};
		color: ${({
			theme: {
				font: {
					color: { lightBgSecondary },
				},
			},
		}) => lightBgSecondary};
	}
`;

import { ITableContext } from '../context';

export function getColumnKey(contextObj: ITableContext, columnIndex: number) {
	const { columnKeys } = contextObj;
	return columnKeys.get(columnIndex, '');
}

export function randomColumnKey() {
	return Math.random()
		.toString(36)
		.slice(3, 10);
}

import { Record, RecordOf } from 'immutable';
import decode from 'jwt-decode';

import createReducer from '../../Helpers/createReducer';
import { login, logout, initialize, authenticate } from '../actions/auth';

interface IDecodedToken {
	sub: string;
	exp: number;
	extra: {
		username: string;
		name?: string;
		groups: string[];
		email?: string;
		sms?: string;
		deptId?: string;
	};
}

export interface IUser {
	_id: string;
	username: string;
	name?: string;
	groups: string[];
	email?: string;
	sms?: string;
	deptId?: string;
}

export interface IAuth {
	initialized: boolean;
	loading: boolean;
	token: string | null;
	exp: number | null;
	user: RecordOf<IUser> | null;
	state: any;
}

export const User = Record<IUser>({
	_id: '',
	username: '',
	name: '',
	groups: [],
});

export const Auth = Record<IAuth>({
	initialized: false,
	loading: false,
	token: null,
	exp: null,
	user: null,
	state: null,
});

const initialState = Auth();

export default createReducer(
	initialState,
	{ login, logout, initialize, authenticate },
	{
		login: (state) => state.set('loading', true),
		logout: (state) =>
			state.merge({
				token: null,
				user: null,
			}),
		initialize: (state) => state.set('initialized', true),
		authenticate: (state, { payload: { token, state: _state } }) => {
			let user, exp;
			try {
				const {
					exp: _exp,
					sub,
					extra: { deptId, email, groups, name, sms, username },
				} = decode<IDecodedToken>(token);
				exp = _exp;
				user = User({
					_id: sub,
					deptId,
					email,
					groups,
					name,
					sms,
					username,
				});
			} catch (err) {}

			if (!user) return state;

			return state.merge({
				initialized: true,
				loading: false,
				token,
				exp,
				user,
				state: _state,
			});
		},
	}
);

import createReducer from '../../Helpers/createReducer';
import { ReadingChartsStore } from './recordFactories';

import {
	importChartReading,
	replaceChartReadings,
	ReadingData,
} from './actions';
import { List } from 'immutable';

const initialState = ReadingChartsStore();

function parseReading(readingData: ReadingData) {
	return { ...readingData, x: new Date(readingData.x) };
}

export default createReducer(
	initialState,
	{
		importChartReading,
		replaceChartReadings,
	},
	{
		importChartReading: (state, { payload: { reading } }) =>
			state.update('data', (data) =>
				data.unshift(parseReading(reading)).slice(0, 50)
			),
		replaceChartReadings: (state, { payload: { readings } }) =>
			state.set('data', List(readings.map(parseReading))),
	}
);

import { AnyAction } from 'redux';
import { List, Map, RecordOf, Set, is, fromJS } from 'immutable';
import TableStore, {
	Column,
	Row,
	Cell,
	Filter,
	columnFromJS,
	getDefaultFilterType,
} from './store';
import createReducer from '../../../Helpers/createReducer';
import {
	updateTable,
	dropColumns,
	insertColumns,
	rearrangeColumns,
	resizeColumn,
	resizeRow,
	renameColumn,
	updateColumnDataType,
	updateColumnBooleanFormat,
	updateColumnNumberFormat,
	updateColumnDateFormat,
	updateColumnUnit,
	updateColumnEditable,
	updateColumnReadonly,
	updateColumnSortable,
	dropColumnFilters,
	updateColumnFilterType,
	dropColumnFilterValue,
	updateColumnFilterValue,
	updateColumnFormulaValue,
	dropSorts,
	insertSorts,
	replaceSorts,
	updateSort,
	updatePage,
	updatePageSize,
	dropUnmergedRecords,
	updateUnmergedRecords,
	replaceRecords,
	replaceRecord,
	requestMergeRecords,
	mergeRecordsSuccess,
	mergeRecordsFailure,
	dropCellsEditingBy,
	updateCellsEditingBy,
	updateLoading,
} from './actions';
import { ITableStore, IColumn, MergeStatus } from '../interfaces';

export function createTableReducer(initialState: RecordOf<ITableStore>) {
	return createReducer(
		initialState,
		{
			updateTable,
			dropColumns,
			insertColumns,
			rearrangeColumns,
			resizeColumn,
			resizeRow,
			renameColumn,
			updateColumnDataType,
			updateColumnBooleanFormat,
			updateColumnNumberFormat,
			updateColumnDateFormat,
			updateColumnUnit,
			updateColumnEditable,
			updateColumnReadonly,
			updateColumnSortable,
			dropColumnFilters,
			updateColumnFilterType,
			dropColumnFilterValue,
			updateColumnFilterValue,
			updateColumnFormulaValue,
			dropSorts, // TODO: Query
			insertSorts, // TODO: Query
			replaceSorts, // TODO: Query
			updateSort, // TODO: Query
			updatePage, // TODO: Query
			updatePageSize, // TODO: Query
			dropUnmergedRecords,
			updateUnmergedRecords,
			replaceRecords, // TODO: After Query / Update
			replaceRecord,
			requestMergeRecords, // TODO: Merge
			mergeRecordsSuccess, // TODO: After Merge
			mergeRecordsFailure, // TODO: After Merge
			dropCellsEditingBy,
			updateCellsEditingBy,
			updateLoading,
		},
		{
			updateTable: (state, action) => state.merge(action.payload),
			dropColumns: (state, { payload: { columnKeys } }) => {
				if (columnKeys) {
					const keySet = Set(columnKeys);
					return state.withMutations((s) => {
						s.update('columnKeys', (list) =>
							list.filter((v) => !keySet.has(v))
						);
						columnKeys.forEach((key) => {
							s.deleteIn(['columns', key]);
						});
					});
				}
				return state.withMutations((s) => {
					s.set('columnKeys', List());
					s.set('columns', Map());
				});
			},
			insertColumns: (
				state,
				{ payload: { index, columnKeys, columns } }
			) =>
				state.withMutations((s) => {
					s.update('columnKeys', (list) =>
						index !== null
							? list.splice(index, 0, ...columnKeys)
							: list.push(...columnKeys)
					);

					columnKeys.forEach((key) => {
						s.setIn(
							['columns', key],
							Column(
								columnFromJS(columns ? columns[key] : undefined)
							)
						);
					});
				}),

			rearrangeColumns: (state, { payload: { from, to } }) =>
				state.withMutations((s) => {
					s.update('columnKeys', (list) => {
						const fromKey = list.get(from, '');
						return list.splice(from, 1).splice(to, 0, fromKey);
					});
				}),
			resizeColumn: (state, { payload: { columnKey, width } }) =>
				state.updateIn(['columns', columnKey], (column = Column()) =>
					column.set('width', width)
				),
			resizeRow: (state, { payload: { recordId, height } }) =>
				state.withMutations((s) => {
					s.updateIn(['rows', recordId], (row = Row()) =>
						row.set('height', height)
					);
					s.update('rows', (map) =>
						map.filter((row) => !is(row, Row()))
					);
				}),
			renameColumn: (state, { payload: { columnKey, name } }) =>
				state.updateIn(['columns', columnKey], (column = Column()) =>
					column.set('name', name)
				),
			updateColumnDataType: (
				state,
				{ payload: { columnKey, dataType } }
			) =>
				state.updateIn(
					['columns', columnKey],
					(column: RecordOf<IColumn> = Column()) =>
						column.withMutations((c) => {
							const dataTypeStr = dataType.type;
							const _dataTypeStr = c.dataType.type;
							c.set('dataType', dataType);
							if (dataTypeStr !== _dataTypeStr) {
								const defaultType = getDefaultFilterType(
									dataType
								);
								c.set('filter', Filter({ type: defaultType }));
							}
						})
				),
			updateColumnBooleanFormat: (
				state,
				{ payload: { columnKey, booleanFormat } }
			) =>
				state.updateIn(['columns', columnKey], (column = Column()) =>
					column.setIn(['format', 'booleanFormat'], booleanFormat)
				),
			updateColumnNumberFormat: (
				state,
				{ payload: { columnKey, numberFormat } }
			) =>
				state.updateIn(['columns', columnKey], (column = Column()) =>
					column.setIn(['format', 'numberFormat'], numberFormat)
				),
			updateColumnDateFormat: (
				state,
				{ payload: { columnKey, dateFormat } }
			) =>
				state.updateIn(['columns', columnKey], (column = Column()) =>
					column.setIn(['format', 'dateFormat'], dateFormat)
				),
			updateColumnUnit: (state, { payload: { columnKey, unit } }) =>
				state.updateIn(['columns', columnKey], (column = Column()) =>
					column.setIn(['format', 'unit'], unit)
				),
			updateColumnEditable: (
				state,
				{ payload: { columnKey, editable } }
			) =>
				state.updateIn(['columns', columnKey], (column = Column()) =>
					column.set('editable', editable)
				),
			updateColumnReadonly: (
				state,
				{ payload: { columnKey: key, readonly } }
			) =>
				state.updateIn(['columns', key], (column = Column()) =>
					column.set('readonly', readonly)
				),
			updateColumnSortable: (
				state,
				{ payload: { columnKey: key, sortable } }
			) =>
				state.updateIn(['columns', key], (column = Column()) =>
					column.set('sortable', sortable)
				),
			dropColumnFilters: (state, { payload: { columnKeys } }) => {
				if (columnKeys) {
					return state.withMutations((s) => {
						columnKeys.forEach((columnKey) => {
							const { dataType } = s.columns.get(
								columnKey,
								Column()
							);
							const defaultType = getDefaultFilterType(dataType);
							s.setIn(
								['columns', columnKey, 'filter'],
								Filter({ type: defaultType })
							);
						});
					});
				}
				return state.update('columns', (columns) =>
					columns.map((column) => {
						const { dataType } = column;
						const defaultType = getDefaultFilterType(dataType);
						return column.set(
							'filter',
							Filter({ type: defaultType })
						);
					})
				);
			},
			updateColumnFilterType: (state, { payload: { columnKey, type } }) =>
				state.updateIn(['columns', columnKey], (column = Column()) =>
					column.setIn(['filter', 'type'], type)
				),
			dropColumnFilterValue: (state, { payload: { columnKey, type } }) =>
				state.updateIn(['columns', columnKey], (column = Column()) =>
					column.deleteIn(['filter', 'values', type])
				),
			updateColumnFilterValue: (
				state,
				{ payload: { columnKey, value, type } }
			) =>
				state.updateIn(['columns', columnKey], (column = Column()) =>
					column.setIn(['filter', 'values', type], value)
				),
			updateColumnFormulaValue: (
				state,
				{ payload: { columnKey, value } }
			) =>
				state.updateIn(['columns', columnKey], (column = Column()) =>
					column.setIn(['formula', 'value'], value)
				),
			dropSorts: (state, { payload: { columnKeys } }) => {
				if (columnKeys) {
					const keySet = Set(columnKeys);
					return state.update('sorts', (list) =>
						list.filter(
							(v) => !keySet.has(v.findKey(() => true) || '')
						)
					);
				}
				return state.set('sorts', List());
			},
			insertSorts: (state, { payload: { index, sorts } }) => {
				const _sorts = sorts.map((sort) => Map(sort));
				return state.update('sorts', (list) =>
					index !== null
						? list.splice(index, 0, ..._sorts)
						: list.push(..._sorts)
				);
			},
			replaceSorts: (state, { payload: { sorts } }) => {
				const _sorts = List(sorts.map((sort) => Map(sort)));
				return state.set('sorts', _sorts);
			},
			updateSort: (state, { payload: { sort } }) => {
				const _sort = Map(sort);
				return state.update('sorts', (list) => {
					const index = list.findIndex((v) =>
						is(Set(v.keys()), Set(_sort.keys()))
					);
					if (index !== -1) return list.set(index, _sort);
					return list;
				});
			},
			updatePage: (state, { payload: { page } }) =>
				state.set('page', Math.max(page, 1)),
			updatePageSize: (state, { payload: { pageSize } }) =>
				state.set('pageSize', Math.max(pageSize, 0)),
			dropUnmergedRecords: (state, { payload: { keys } }) => {
				if (keys)
					return state.withMutations((s) => {
						keys.forEach(({ recordId, columnKey }) => {
							s.deleteIn([
								'unmergedRecords',
								recordId,
								columnKey,
							]);
							s.updateIn(
								['cells', recordId, columnKey],
								(cell = Cell()) =>
									cell.set('mergeStatus', MergeStatus.none)
							);
						});
						s.update('unmergedRecords', (records) =>
							records.filter((row) => row.size > 0)
						);
						s.update('cells', (cells) =>
							cells.filter(
								(row) => !row.every((cell) => is(cell, Cell()))
							)
						);
					});
				return state.withMutations((s) => {
					s.set('unmergedRecords', Map());
					s.cells.forEach((row, recordId) => {
						row.forEach((cell, columnKey) => {
							s.setIn(
								['cells', recordId, columnKey],
								cell.set('mergeStatus', MergeStatus.none)
							);
						});
					});
					s.update('cells', (cells) =>
						cells.filter(
							(row) => !row.every((cell) => is(cell, Cell()))
						)
					);
				});
			},
			updateUnmergedRecords: (state, { payload: { changes } }) =>
				state.withMutations((s) => {
					changes.forEach(({ recordId, columnKey, value }) => {
						s.setIn(
							['unmergedRecords', recordId, columnKey],
							value
						);
						s.updateIn(
							['cells', recordId, columnKey],
							(cell = Cell()) =>
								cell.set('mergeStatus', MergeStatus.unmerged)
						);
					});
				}),
			replaceRecords: (
				state,
				{ payload: { recordIds, records, recordCount } }
			) =>
				state.withMutations((s) => {
					s.set('recordIds', List(recordIds));
					s.set('records', fromJS(records));
					s.set('recordCount', recordCount);
				}),
			replaceRecord: (state, { payload: { recordId, record } }) =>
				state.withMutations((s) => {
					s.setIn(['records', recordId], fromJS(record));
				}),
			requestMergeRecords: (state, { payload: { keys } }) => {
				return state.withMutations((s) => {
					if (keys)
						keys.forEach(({ recordId, columnKey }) => {
							s.updateIn(
								['cells', recordId, columnKey],
								(cell = Cell()) =>
									cell.set('mergeStatus', MergeStatus.merging)
							);
						});
					else
						s.unmergedRecords.forEach((row, recordId) => {
							row.forEach((cell, columnKey) => {
								s.updateIn(
									['cells', recordId, columnKey],
									(cell = Cell()) =>
										cell.set(
											'mergeStatus',
											MergeStatus.merging
										)
								);
							});
						});
				});
			},
			mergeRecordsSuccess: (state, { payload: { keys } }) =>
				state.withMutations((s) => {
					keys.forEach(({ recordId, columnKey }) => {
						s.updateIn(
							['cells', recordId, columnKey],
							(cell = Cell()) =>
								cell.set('mergeStatus', MergeStatus.none)
						);
						s.update('cells', (cells) =>
							cells.filter(
								(row) => !row.every((cell) => is(cell, Cell()))
							)
						);
						s.deleteIn(['unmergedRecords', recordId, columnKey]);
						s.update('unmergedRecords', (records) =>
							records.filter((record) => record.size > 0)
						);
					});
				}),
			mergeRecordsFailure: (state, { payload: { keys } }) =>
				state.withMutations((s) => {
					keys.forEach(({ recordId, columnKey }) => {
						s.updateIn(
							['cells', recordId, columnKey],
							(cell = Cell()) =>
								cell.set('mergeStatus', MergeStatus.failed)
						);
					});
				}),
			dropCellsEditingBy: (state, { payload: { keys } }) =>
				state.withMutations((s) =>
					keys.forEach(({ recordId, columnKey }) => {
						s.updateIn(
							['cells', recordId, columnKey],
							(cell = Cell()) => cell.set('editingBy', null)
						);
						s.update('cells', (cells) =>
							cells.filter(
								(row) => !row.every((cell) => is(cell, Cell()))
							)
						);
					})
				),
			updateCellsEditingBy: (state, { payload: { changes } }) =>
				state.withMutations((s) =>
					changes.forEach(({ recordId, columnKey, editingBy }) => {
						s.updateIn(
							['cells', recordId, columnKey],
							(cell = Cell()) => cell.set('editingBy', editingBy)
						);
					})
				),
			updateLoading: (state, { payload: { loading } }) =>
				state.set('loading', loading),
		}
	);
}

const initialState = TableStore();
export const tableReducer = createTableReducer(initialState);

export function debugTableReducer(
	state: RecordOf<ITableStore>,
	action: AnyAction
) {
	const nextState = tableReducer(state, action);
	console.log({
		prevState: state.toJS(),
		action,
		nextState: nextState.toJS(),
	});
	return nextState;
}

export default tableReducer;

import Button from '../../../../Common/Button';
import {
	ButtonProps,
	ButtonOverlayShape,
} from '../../../../Common/Button/interfaces';
import styled from 'styled-components';
import Icon from '@mdi/react';
import { IconProps } from '@mdi/react/dist/IconProps';
import { ButtonText } from '../../../../Common/Text';
import { View } from '../../../../Common/View';
import { LAUNCHER_SIZE, COUNTER_SIZE, EXPANDED_DOCK_SIZE } from '../constants';
import { TransitionStatus } from 'react-transition-group/Transition';
// import { enterAnimation, leaveAnimation } from './DockItem.keyframe';

export const DockItemWrapper = styled(Button).attrs<{}, ButtonProps>({
	overlayShape: ButtonOverlayShape.rectangle,
	lightBg: false,
})`
	align-self: stretch;
	align-items: stretch;
	flex-direction: row;
	flex: 0 0 ${LAUNCHER_SIZE}px;
`;

export const DockItemContentWrapper = styled(View)`
	align-items: center;
	flex-direction: row;
	transition: all 200ms;
	flex: 0 0 ${LAUNCHER_SIZE}px;
`;

export const DockItemIconWrapper = styled(View)`
	align-items: center;
	justify-content: center;
	flex: 0 0 ${LAUNCHER_SIZE}px;
	width: ${LAUNCHER_SIZE}px;
`;

export const DockItemIcon = styled(Icon).attrs<{ path: string }, IconProps>(
	({
		path,
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => ({ path, color: darkBgPrimary, size: 1.2 })
)``;

export interface ILauncherTextProps {
	status: TransitionStatus;
}

export const DockItemText = styled(ButtonText)<ILauncherTextProps>`
	color: ${({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => darkBgPrimary};
	width: ${EXPANDED_DOCK_SIZE - LAUNCHER_SIZE}px;
	/* opacity: ${({ status }) =>
		status === 'entered' ? 1 : 0};
	transition: opacity 0.2s; */
`;

export const TaskCounterWrapper = styled(View)`
	position: absolute;
	right: 8px;
	width: ${COUNTER_SIZE + 2}px;
	height: ${LAUNCHER_SIZE}px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const TaskCounterIndicator = styled(View)`
	width: ${COUNTER_SIZE}px;
	height: ${COUNTER_SIZE}px;
	border-radius: ${COUNTER_SIZE / 2}px;
	background-color: ${({ theme: { background } }) => background.accent};
	margin-top: ${COUNTER_SIZE / 3}px;
	margin-bottom: ${COUNTER_SIZE / 3}px;
`;

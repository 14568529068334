import React, { useEffect, useMemo, ReactNode, useContext } from 'react';
import OlTileLayer, { Options as TileLayerOptions } from 'ol/layer/Tile';
import TileSource, { Options as TileSourceOptions } from 'ol/source/Tile';
import context, { layerContext } from '../context';
import XYZ, { Options as XYZOptions } from 'ol/source/XYZ';

interface Props {
	sourceType: 'xyz' | 'tile';
	layerOptions?: TileLayerOptions;
	sourceOptions?: TileSourceOptions | XYZOptions;
	children?: ReactNode;
}

const { Provider } = layerContext;

const defaultOptions = {};

export default function TileLayer(props: Props) {
	const {
		layerOptions = defaultOptions,
		sourceOptions = defaultOptions,
		children,
		sourceType,
	} = props;

	const Source = sourceType === 'xyz' ? XYZ : TileSource;

	const { map } = useContext(context);

	const source = useMemo(() => {
		return new Source(sourceOptions);
	}, [sourceOptions, Source]);

	const layer = useMemo(() => {
		return new OlTileLayer({ ...layerOptions, source });
	}, [layerOptions, source]);

	useEffect(() => {
		map.addLayer(layer);
		return () => {
			map.removeLayer(layer);
		};
	}, [layer, source, map]);

	return <Provider value={{ layer, source }}>{children}</Provider>;
}

import React, { useMemo } from 'react';
import { StandardWindowProps } from './interfaces';
import Window from '../Window';
import useWindowState from './Hooks/useWindowState';
import useMove from './Hooks/useMove';
import useResize from './Hooks/useResize';

import StandardWindowHeader from './Components/StandardWindowHeader';
import StandardWindowEdges from './Components/StandardWindowEdges';
import WindowFrame from './Components/WindowFrame';
import { MaximizeType } from '../../interfaces';
import { CORNER_SIZE, MOVE_THRESHOLD } from './constants';

export default function StandardWindow(props: StandardWindowProps) {
	const {
		title,
		resizable = true,
		zIndex,
		style,
		minHeight,
		minWidth,
		parentWidth,
		parentHeight,
		maximized,
		leftHeader,
		rightHeader,
		children,
		onRequestMaximized,
		onRequestRestore,
		onRequestMinimized,
		onRequestClose,
		onRequestFocus,
	} = props;

	const [windowState, setWindowState] = useWindowState(props);
	const { left, top, width, height, moving, resizing } = windowState;

	const {
		handleMoveInit,
		handleMoveStart,
		handleMove,
		handleMoveEnd,
		handleMoveCancel,
	} = useMove(props, setWindowState);
	const { handleResizeStart, handleResize, handleResizeEnd } = useResize(
		props,
		setWindowState
	);

	return useMemo(() => {
		return (
			<Window
				left={left}
				top={top}
				width={width}
				height={height}
				minHeight={minHeight}
				minWidth={minWidth}
				onMoveInit={handleMoveInit}
				onMoveStart={handleMoveStart}
				onMove={handleMove}
				onMoveEnd={handleMoveEnd}
				onMoveCancel={handleMoveCancel}
				onResizeStart={handleResizeStart}
				onResize={handleResize}
				onResizeEnd={handleResizeEnd}
				parentWidth={parentWidth}
				parentHeight={parentHeight}
			>
				<WindowFrame
					zIndex={zIndex}
					animation={!moving && !resizing}
					style={style}
					cornerRadius={
						maximized === MaximizeType.REGULAR ? CORNER_SIZE : 0
					}
					onMouseDown={onRequestFocus}
				>
					<StandardWindowHeader
						title={title}
						moving={moving}
						leftHeader={leftHeader}
						rightHeader={rightHeader}
						maximized={maximized}
						onRequestMaximized={onRequestMaximized}
						onRequestRestore={onRequestRestore}
						onRequestMinimized={onRequestMinimized}
						onRequestClose={onRequestClose}
						threshold={
							maximized === MaximizeType.REGULAR
								? 0
								: MOVE_THRESHOLD
						}
					/>
					{children}
					{resizable && !moving ? (
						<StandardWindowEdges maximized={maximized} />
					) : null}
				</WindowFrame>
			</Window>
		);
	}, [
		title,
		children,
		zIndex,
		style,
		moving,
		resizable,
		resizing,
		left,
		top,
		width,
		height,
		maximized,
		minHeight,
		minWidth,
		leftHeader,
		rightHeader,
		handleMoveInit,
		handleMoveStart,
		handleMove,
		handleMoveEnd,
		handleMoveCancel,
		handleResizeStart,
		handleResize,
		handleResizeEnd,
		parentWidth,
		parentHeight,
		onRequestMaximized,
		onRequestRestore,
		onRequestMinimized,
		onRequestClose,
		onRequestFocus,
	]);
}

import { useState, useCallback } from 'react';

export default function usePress() {
	const [pressed, setPressed] = useState(false);
	const handleMouseDown = useCallback(() => {
		setPressed(true);
		const handleMouseUp = () => {
			setPressed(false);
			window.removeEventListener('mouseup', handleMouseUp);
		};
		window.addEventListener('mouseup', handleMouseUp);
	}, []);
	return {
		pressed,
		handleMouseDown,
	};
}

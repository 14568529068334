import { useCallback } from 'react';
import {
	dropSorts,
	insertSorts,
	updateSort,
	replaceSorts,
} from '../Store/actions';
import { SortOrder } from '../interfaces';
import { ITableContext } from '../context';

export function useAllSortsDrop(contextObj: ITableContext) {
	const { onRequestDispatch } = contextObj;
	return useCallback(() => {
		onRequestDispatch(dropSorts({ columnKeys: null }));
	}, [onRequestDispatch]);
}

export function useSortsReplace(contextObj: ITableContext, columnKey: string) {
	const { onRequestDispatch } = contextObj;
	return useCallback(
		(order: SortOrder) => {
			onRequestDispatch(
				replaceSorts({ sorts: [{ [columnKey]: order }] })
			);
		},
		[onRequestDispatch, columnKey]
	);
}

export function useSortPush(contextObj: ITableContext, columnKey: string) {
	const { onRequestDispatch } = contextObj;
	return useCallback(
		(order: SortOrder) => {
			onRequestDispatch(
				insertSorts({
					index: null,
					sorts: [{ [columnKey]: order }],
				})
			);
		},
		[onRequestDispatch, columnKey]
	);
}

export function useSortUpdate(contextObj: ITableContext, columnKey: string) {
	const { onRequestDispatch } = contextObj;
	return useCallback(
		(order: SortOrder) => {
			onRequestDispatch(
				updateSort({
					sort: { [columnKey]: order },
				})
			);
		},
		[onRequestDispatch, columnKey]
	);
}

import React, {
	CSSProperties,
	useMemo,
	useContext,
	useCallback,
	useState,
} from 'react';

import { useImmutableMemo } from '../../../../../Hooks/useImmutable';
import { context } from '../../../../../Components/RecordsFeedGrid/context';
import { Map } from 'immutable';
import { useItemStyles, useToolbarStyles } from './ModeSwitchRecord.sc';
import {
	Toolbar,
	Paper,
	Box,
	TableBody,
	TableRow,
	TableCell,
	Table,
	Typography,
	IconButton,
	Dialog,
	DialogActions,
	Button,
	DialogContent,
	TextField,
} from '@material-ui/core';
import {
	deleteRecord,
	updateRecord,
} from '../../../../../Components/RecordsFeed/actions';
import {
	Pencil,
	TrashCan,
	Undo,
	Remote,
	RemoteOff,
	Check,
} from 'mdi-material-ui';
import { useUplinkDispatch } from '../../../../../Hooks/useUplinkDispatch';

import { useFormik } from 'formik';
import FormulaParser from 'formula-to-mongodb-exp';
import { useBooleanState } from '../../../../../Hooks/useBooleanState';
import Scrollbars from '../../../../../Components/Common/Scrollbars';

type Props = {
	rowIndex: number;
	columnIndex: number;
	style: CSSProperties;
};

const feedId = 'sensorControl';
const collection = 'modeSwitches';

const formulaParser = new FormulaParser();

interface ModeSwitch {
	name: string;
	priority: number;
	schedule: string;
	active: boolean;
	scope: string;
	criteria: string;
	samplingInterval: number;
	sendInterval: number;
	failureRetry: number;
}

export default function ModeSwitchRecord(props: Props) {
	const { rowIndex, columnIndex, style } = props;
	const { recordIds, records, columnCount } = useContext(context);

	const recordId = useImmutableMemo(
		() => recordIds.toArray()[rowIndex * columnCount + columnIndex],
		[recordIds, columnCount, rowIndex, columnIndex]
	);
	const record = useImmutableMemo<ModeSwitch>(() => {
		const record = records.get(recordId, Map());

		const { settings, ...rest } = record.toJS() as any;
		return { ...settings, ...rest } as ModeSwitch;
	}, [records, recordId, rowIndex]);

	const [editing, startEditing, endEditing] = useBooleanState(false);
	const [update, setUpdate] = useState(record);
	const [saveDialogOpened, openSaveDialog, closeSaveDialog] = useBooleanState(
		false
	);
	const [
		removeDialogOpened,
		openRemoveDialog,
		closeRemoveDialog,
	] = useBooleanState(false);

	const itemClasses = useItemStyles();
	const toolbarClasses = useToolbarStyles({ record });

	const dispatch = useUplinkDispatch();

	const handleConfirmRemove = useCallback(() => {
		dispatch(deleteRecord({ collection, feedId, recordId }));
		closeRemoveDialog();
	}, [dispatch, recordId, closeRemoveDialog]);

	const handleUpdate = useCallback(
		(record: ModeSwitch) => {
			const {
				samplingInterval,
				sendInterval,
				failureRetry,
				...rest
			} = record;
			dispatch(
				updateRecord({
					collection,
					feedId,
					recordId,
					record: {
						...rest,
						settings: {
							samplingInterval,
							sendInterval,
							failureRetry,
						},
					},
				})
			);
		},
		[dispatch, recordId]
	);

	const handleActivate = useCallback(() => {
		handleUpdate({ ...record, active: true });
	}, [handleUpdate, record]);

	const handleDeactivate = useCallback(() => {
		handleUpdate({ ...record, active: false });
	}, [handleUpdate, record]);

	const validate = (record: ModeSwitch) => {
		const errors: any = {};
		if (record.criteria) {
			const result = formulaParser.parse(record.criteria);
			if ('err' in result) {
				errors.criteria = 'Syntax Error';
			}
		}

		if (record.scope) {
			const result = formulaParser.parse(record.scope);
			if ('err' in result) {
				errors.scope = 'Syntax Error';
			}
		}

		return errors;
	};

	const {
		touched,
		values,
		handleSubmit,
		handleChange,
		submitForm,
		resetForm,
		errors,
	} = useFormik({
		initialValues: record,
		validate,
		onSubmit: async (record) => {
			setUpdate(record);
			openSaveDialog();
		},
	});

	const handleConfirmSave = useCallback(() => {
		if (touched) handleUpdate(update);
		endEditing();
		closeSaveDialog();
	}, [touched, handleUpdate, update, endEditing, closeSaveDialog]);

	return useMemo(
		() =>
			recordId ? (
				<Box
					key={recordId}
					style={style}
					paddingRight={2}
					boxSizing="border-box"
				>
					<Paper className={itemClasses.root}>
						<form
							className={itemClasses.form}
							onSubmit={handleSubmit}
						>
							<Toolbar
								variant="dense"
								className={toolbarClasses.root}
							>
								<IconButton
									className={toolbarClasses.activateButton}
									onClick={
										record.active
											? handleDeactivate
											: handleActivate
									}
								>
									{record.active ? <Remote /> : <RemoteOff />}
								</IconButton>
								<Typography
									variant="subtitle2"
									className={itemClasses.title}
								>
									{record.name}
								</Typography>

								{editing ? (
									<IconButton
										className={toolbarClasses.iconButton}
										onClick={
											Object.keys(values).some(
												(field) => {
													let _field = field as keyof ModeSwitch;
													return (
														record[_field] !==
														values[_field]
													);
												}
											)
												? submitForm
												: endEditing
										}
									>
										<Check />
									</IconButton>
								) : null}
								{editing ? (
									<IconButton
										className={toolbarClasses.iconButton}
										onClick={() => resetForm()}
									>
										<Undo />
									</IconButton>
								) : (
									<IconButton
										className={toolbarClasses.iconButton}
										onClick={startEditing}
									>
										<Pencil />
									</IconButton>
								)}

								<IconButton
									className={toolbarClasses.iconButton}
									onClick={openRemoveDialog}
								>
									<TrashCan />
								</IconButton>
							</Toolbar>
							<Scrollbars className={itemClasses.tableContainer}>
								<Table className={itemClasses.table}>
									<TableBody
										className={itemClasses.tableBody}
									>
										<TableRow
											className={itemClasses.tableRow}
										>
											<TableCell>
												<Typography
													variant="caption"
													className={
														itemClasses.field
													}
												>
													Name
												</Typography>
											</TableCell>
											<TableCell
												className={
													itemClasses.valueCell
												}
											>
												{editing ? (
													<TextField
														id="name"
														name="name"
														multiline
														fullWidth
														error={!!errors.name}
														helperText={errors.name}
														value={values.name}
														onChange={handleChange}
														inputProps={{
															className:
																itemClasses.input,
														}}
													/>
												) : (
													<Typography
														variant="caption"
														className={
															itemClasses.value
														}
													>
														{record.name}
													</Typography>
												)}
											</TableCell>
										</TableRow>
										<TableRow
											className={itemClasses.tableRow}
										>
											<TableCell>
												<Typography
													variant="caption"
													className={
														itemClasses.field
													}
												>
													Priority
												</Typography>
											</TableCell>
											<TableCell
												className={
													itemClasses.valueCell
												}
											>
												{editing ? (
													<TextField
														id="priority"
														name="priority"
														type="number"
														fullWidth
														error={
															!!errors.priority
														}
														helperText={
															errors.priority
														}
														value={values.priority}
														onChange={handleChange}
														inputProps={{
															className:
																itemClasses.input,
														}}
													/>
												) : (
													<Typography
														variant="caption"
														className={
															itemClasses.value
														}
													>
														{record.priority}
													</Typography>
												)}
											</TableCell>
										</TableRow>
										<TableRow
											className={itemClasses.tableRow}
										>
											<TableCell>
												<Typography
													variant="caption"
													className={
														itemClasses.field
													}
												>
													Check Interval
												</Typography>
											</TableCell>
											<TableCell
												className={
													itemClasses.valueCell
												}
											>
												{editing ? (
													<TextField
														id="schedule"
														name="schedule"
														multiline
														fullWidth
														error={
															!!errors.schedule
														}
														helperText={
															errors.schedule
														}
														value={values.schedule}
														onChange={handleChange}
														inputProps={{
															className:
																itemClasses.input,
														}}
													/>
												) : (
													<Typography
														variant="caption"
														className={
															itemClasses.value
														}
													>
														{record.schedule}
													</Typography>
												)}
											</TableCell>
										</TableRow>
										<TableRow
											className={itemClasses.tableRow}
										>
											<TableCell>
												<Typography
													variant="caption"
													className={
														itemClasses.field
													}
												>
													Target
												</Typography>
											</TableCell>
											<TableCell
												className={
													itemClasses.valueCell
												}
											>
												{editing ? (
													<TextField
														id="scope"
														name="scope"
														multiline
														fullWidth
														error={!!errors.scope}
														helperText={
															errors.scope
														}
														value={values.scope}
														onChange={handleChange}
														inputProps={{
															className:
																itemClasses.input,
														}}
													/>
												) : (
													<Typography
														variant="caption"
														className={
															itemClasses.value
														}
													>
														{record.scope}
													</Typography>
												)}
											</TableCell>
										</TableRow>
										<TableRow
											className={itemClasses.tableRow}
										>
											<TableCell>
												<Typography
													variant="caption"
													className={
														itemClasses.field
													}
												>
													Active When
												</Typography>
											</TableCell>
											<TableCell
												className={
													itemClasses.valueCell
												}
											>
												{editing ? (
													<TextField
														id="criteria"
														name="criteria"
														multiline
														fullWidth
														error={
															!!errors.criteria
														}
														helperText={
															errors.criteria
														}
														value={values.criteria}
														onChange={handleChange}
														inputProps={{
															className:
																itemClasses.input,
														}}
													/>
												) : (
													<Typography
														variant="caption"
														className={
															itemClasses.value
														}
													>
														{record.criteria}
													</Typography>
												)}
											</TableCell>
										</TableRow>
										<TableRow
											className={itemClasses.tableRow}
										>
											<TableCell>
												<Typography
													variant="caption"
													className={
														itemClasses.field
													}
												>
													Sampling Interval
												</Typography>
											</TableCell>
											<TableCell
												className={
													itemClasses.valueCell
												}
											>
												{editing ? (
													<TextField
														id="samplingInterval"
														name="samplingInterval"
														type="number"
														fullWidth
														error={
															!!errors.samplingInterval
														}
														helperText={
															errors.samplingInterval
														}
														value={
															values.samplingInterval
														}
														onChange={handleChange}
														inputProps={{
															className:
																itemClasses.input,
														}}
													/>
												) : (
													<Typography
														variant="caption"
														className={
															itemClasses.value
														}
													>
														{
															record.samplingInterval
														}
													</Typography>
												)}
											</TableCell>
										</TableRow>
										<TableRow
											className={itemClasses.tableRow}
										>
											<TableCell>
												<Typography
													variant="caption"
													className={
														itemClasses.field
													}
												>
													Send Interval
												</Typography>
											</TableCell>
											<TableCell
												className={
													itemClasses.valueCell
												}
											>
												{editing ? (
													<TextField
														id="sendInterval"
														name="sendInterval"
														type="number"
														fullWidth
														error={
															!!errors.sendInterval
														}
														helperText={
															errors.sendInterval
														}
														value={
															values.sendInterval
														}
														onChange={handleChange}
														inputProps={{
															className:
																itemClasses.input,
														}}
													/>
												) : (
													<Typography
														variant="caption"
														className={
															itemClasses.value
														}
													>
														{record.sendInterval}
													</Typography>
												)}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</Scrollbars>
							<Dialog open={saveDialogOpened}>
								<DialogContent>
									<Typography variant="caption">
										Are you sure you want to save the
										changes?
									</Typography>
								</DialogContent>
								<DialogActions>
									<Button
										color="primary"
										onClick={handleConfirmSave}
									>
										YES
									</Button>
									<Button
										color="primary"
										variant="outlined"
										onClick={closeSaveDialog}
									>
										No
									</Button>
								</DialogActions>
							</Dialog>
							<Dialog open={removeDialogOpened}>
								<DialogContent>
									<Typography variant="caption">
										Are you sure you want to delete this
										scenario?
									</Typography>
								</DialogContent>
								<DialogActions>
									<Button
										color="primary"
										onClick={handleConfirmRemove}
									>
										YES
									</Button>
									<Button
										color="primary"
										variant="outlined"
										onClick={closeRemoveDialog}
									>
										No
									</Button>
								</DialogActions>
							</Dialog>
						</form>
					</Paper>
				</Box>
			) : null,
		[
			saveDialogOpened,
			removeDialogOpened,
			handleConfirmRemove,
			closeSaveDialog,
			openRemoveDialog,
			closeRemoveDialog,
			editing,
			endEditing,
			handleActivate,
			handleChange,
			handleConfirmSave,
			handleDeactivate,
			submitForm,
			values,
			errors,
			resetForm,
			startEditing,
			handleSubmit,
			itemClasses,
			toolbarClasses,
			style,
			recordId,
			record,
		]
	);
}

import React, { useMemo, useCallback, useContext } from 'react';
import {
	mdiPageFirst,
	mdiPageLast,
	mdiChevronLeft,
	mdiChevronRight,
} from '@mdi/js';
import { PaginationWrapper, PaginationButton } from './Pagination.sc';
import { usePageUpdate } from '../../../Hooks/Page';
import context from '../../../context';
import constants from '../../../constants';
import PaginationInput from './PaginationInput';
const { INDEX_WIDTH } = constants;

function PrevPageButton() {
	const contextObj = useContext(context);
	const { page } = contextObj;
	const updatePage = usePageUpdate(contextObj);

	const handlePress = useCallback(() => {
		const newPage = Math.max(page - 1, 1);
		if (page !== newPage) updatePage(newPage);
	}, [page, updatePage]);

	return useMemo(() => {
		return (
			<PaginationButton
				tabIndex={0}
				icon={mdiChevronLeft}
				tooltip="Prev Page"
				onPress={handlePress}
			/>
		);
	}, [handlePress]);
}

function NextPageButton() {
	const contextObj = useContext(context);
	const { page, pageSize, recordCount } = contextObj;
	const pageCount =
		recordCount !== null ? Math.ceil(recordCount / pageSize) : Infinity;
	const updatePage = usePageUpdate(contextObj);

	const handlePress = useCallback(() => {
		const newPage = Math.min(page + 1, pageCount);
		if (page !== newPage) updatePage(newPage);
	}, [page, pageCount, updatePage]);

	return useMemo(() => {
		return (
			<PaginationButton
				tabIndex={0}
				icon={mdiChevronRight}
				tooltip="Next Page"
				onPress={handlePress}
			/>
		);
	}, [handlePress]);
}

function FirstPageButton() {
	const contextObj = useContext(context);
	const { page } = contextObj;
	const updatePage = usePageUpdate(contextObj);

	const handlePress = useCallback(() => {
		if (page !== 1) updatePage(1);
	}, [page, updatePage]);

	return useMemo(() => {
		return (
			<PaginationButton
				tabIndex={0}
				icon={mdiPageFirst}
				tooltip="First Page"
				onPress={handlePress}
			/>
		);
	}, [handlePress]);
}

function LastPageButton() {
	const contextObj = useContext(context);
	const { page, recordCount, pageSize } = contextObj;
	const pageCount =
		recordCount !== null ? Math.ceil(recordCount / pageSize) : Infinity;
	const updatePage = usePageUpdate(contextObj);
	const handlePress = useCallback(() => {
		if (page !== pageCount) updatePage(pageCount);
	}, [page, pageCount, updatePage]);

	return useMemo(() => {
		if (pageCount === Infinity) return null;
		return (
			<PaginationButton
				tabIndex={0}
				icon={mdiPageLast}
				tooltip="Last Page"
				onPress={handlePress}
			/>
		);
	}, [pageCount, handlePress]);
}

interface PaginationProps {
	columnWidthSum: number;
}
export default function Pagination(props: PaginationProps) {
	const { columnWidthSum } = props;
	return useMemo(() => {
		return (
			<PaginationWrapper
				style={{ maxWidth: columnWidthSum + INDEX_WIDTH }}
			>
				<FirstPageButton />
				<PrevPageButton />
				<PaginationInput />
				<NextPageButton />
				<LastPageButton />
			</PaginationWrapper>
		);
	}, [columnWidthSum]);
}

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import ActiveSensorChart, { ActiveSensorChartData } from './ActiveSensorChart';
import SensorTypeChart, { SensorTypeChartData } from './SensorTypeChart';
import {
	HomeWrapper,
	MapWrapper,
	OverviewWrapper,
	WeatherWrapper,
	StatisticWrapper,
	StatisticColumnWrapper,
	ControlPanelWrapper,
} from './Home.sc';
import TiltingAngleChart, { TiltingAngleChartData } from './TiltingAngleChart';
import SignalIntervalChart, {
	SignalIntervalChartData,
} from './SignalIntervalChart';
import BatteryLevelChart, { BatteryLevelChartData } from './BatteryLevelChart';
import WeatherPanel from '../../../../WeatherPanel/WeatherPanel';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../Store/reducers/root';
import { RecordOf } from 'immutable';
import { WeatherPanelStore } from '../../../../WeatherPanel/reducer';
import InactiveSensorList, {
	InactiveSensorListData,
} from './InactiveSensorList';
import ScenarioChangeLog, { ScenarioChangeRecord } from './ScenarioChangeLog';
import QuerySelector from './QuerySelector';
import { Suggestion } from '../../../../Common/Autocomplete';
import DistrictMap from './DistrictMap';
import { Feature } from 'ol';
import { FetchOverviewPayload } from '../../Workspace/Store/actions';
import { IUser } from '../../../../../Store/reducers/auth';

interface HomeProps {
	activeSensorData: ActiveSensorChartData;
	sensorTypeData: SensorTypeChartData;
	tiltingAngleData: TiltingAngleChartData;
	signalIntervalData: SignalIntervalChartData;
	batteryLevelData: BatteryLevelChartData;
	inactiveSensorData: InactiveSensorListData;
	scenarioChangeRecords: ScenarioChangeRecord[];
	onRequestFetchOverview: (payload: FetchOverviewPayload) => any;
}

const departments = [
	{ value: null, name: 'Any Departments' },
	{ value: 'poly', name: 'PolyU' },
	{ value: 'jwe', name: 'JWE' },
	{ value: 'tmo', name: 'TMO' },
	{ value: 'lcsd_editor', name: 'LCSD' },
	{ value: 'hyd_editor', name: 'HYD' },
	{ value: 'archsd_editor', name: 'ARCHSD' },
	{ value: 'hd_editor', name: 'HD' },
];

function Home(props: HomeProps) {
	const {
		activeSensorData,
		sensorTypeData,
		tiltingAngleData,
		signalIntervalData,
		batteryLevelData,
		inactiveSensorData,
		scenarioChangeRecords,
		onRequestFetchOverview,
	} = props;

	const { loading, weather } = useSelector((state: RecordOf<IRootState>) =>
		state.get('weatherPanel', WeatherPanelStore())
	);

	const user = useSelector(
		(state: IRootState) => state.auth.user
	) as RecordOf<IUser>;

	const isAdmin =
		user.groups.includes('poly') ||
		user.groups.includes('tmo') ||
		user.groups.includes('admin');

	const filteredDepartments = useMemo(() => {
		return departments.filter((department) => {
			if (isAdmin) return true;

			if (department.value === null) return false;

			const departmentKey = department.value.split('_')[0];
			return user.groups.some((group) => group.startsWith(departmentKey));
		});
	}, [user, isAdmin]);

	const [department, setDepartment] = useState<Suggestion>(
		filteredDepartments[0]
	);
	const [transmissionType, setTransmissionType] = useState<Suggestion>({
		value: null,
		name: 'Any Sensors',
	});
	const [district, setDistrict] = useState<string | null>(null);

	const handleSelectDistrict = useCallback((feature: Feature | null) => {
		setDistrict(feature ? feature.get('ENAME') : feature);
	}, []);

	useEffect(() => {
		onRequestFetchOverview({
			district,
			transmissionType: transmissionType.value,
			deptId: department.value,
		});
		const interval = setInterval(() => {
			onRequestFetchOverview({
				district,
				transmissionType: transmissionType.value,
				deptId: department.value,
			});
		}, 300000);

		return () => {
			clearInterval(interval);
		};
	}, [
		district,
		transmissionType.value,
		department.value,
		onRequestFetchOverview,
	]);

	return (
		<HomeWrapper>
			<ControlPanelWrapper>
				<QuerySelector
					district={district}
					department={department}
					departments={filteredDepartments}
					setDepartment={setDepartment}
					transmissionType={transmissionType}
					transmissionTypes={[
						{ value: '', name: 'Any Sensors' },
						{ value: 'LoRa', name: 'LoRa' },
						{ value: 'NB', name: 'NB' },
					]}
					setTransmissionType={setTransmissionType}
				/>
				<MapWrapper>
					<DistrictMap
						selectedDistrict={district}
						onSelectDistrict={handleSelectDistrict}
					/>
				</MapWrapper>
				<ScenarioChangeLog records={scenarioChangeRecords} />
			</ControlPanelWrapper>
			<OverviewWrapper>
				<WeatherWrapper>
					<WeatherPanel loading={loading} weather={weather} />
				</WeatherWrapper>

				<StatisticWrapper>
					<StatisticColumnWrapper>
						<ActiveSensorChart data={activeSensorData} />
						<TiltingAngleChart data={tiltingAngleData} />
						<BatteryLevelChart data={batteryLevelData} />
					</StatisticColumnWrapper>
					<StatisticColumnWrapper>
						<InactiveSensorList data={inactiveSensorData} />
						<SensorTypeChart data={sensorTypeData} />
						<SignalIntervalChart data={signalIntervalData} />
					</StatisticColumnWrapper>
				</StatisticWrapper>
			</OverviewWrapper>
		</HomeWrapper>
	);
}

export default Home;

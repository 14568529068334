import React, { useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';

import { AppWrapper } from './App.sc';
import theme, { muiTheme } from '../theme';
import configureStore from './Store/configureStore';
import MainScreen from './Scenes/Main';
import { MuiThemeProvider } from '@material-ui/core/styles';

export default function App() {
	const store = configureStore();
	return useMemo(
		() => (
			<Provider store={store}>
				<MuiThemeProvider theme={muiTheme}>
					<ThemeProvider theme={theme}>
						<AppWrapper>
							<BrowserRouter>
								<MainScreen key="main" />
							</BrowserRouter>
						</AppWrapper>
					</ThemeProvider>
				</MuiThemeProvider>
			</Provider>
		),
		[store]
	);
}

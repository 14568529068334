import React, {
	useCallback,
	useEffect,
	useMemo,
	forwardRef,
	Ref,
	MouseEvent as RMouseEvent,
	KeyboardEvent as RKeyboardEvent,
} from 'react';
import { MenuItemIcon, MenuItemWrapper } from './MenuItem.sc';
import preventDefault from '../../../../Helpers/preventDefault';
import useForwardedRef from '../../../../Hooks/useForwardedRef';
import useObject from '../../../../Hooks/useObject';
import useHotKeys from '../../../../Hooks/useHotKeys';
import { ButtonChildrenProps } from '../../Button/interfaces';
import { MenuItemProps } from '../interfaces';

const MenuItem = forwardRef(function(
	props: MenuItemProps,
	ref: Ref<HTMLDivElement>
) {
	const {
		focused,
		closeOnPress = true,
		scrollOnFocus = true,
		icon,
		renderIcon: CustomIcon,
		iconProps,
		onPress,
		onRequestClose,
		...restProps
	} = props;
	const _ref = useForwardedRef(ref);
	const _iconProps = useObject(iconProps);
	const _restProps = useObject(restProps);

	const handlePress = useCallback(
		(
			e:
				| RMouseEvent<HTMLDivElement>
				| RKeyboardEvent<HTMLDivElement>
				| KeyboardEvent
		) => {
			onPress && onPress(e);
			closeOnPress && onRequestClose && onRequestClose();
		},
		[onPress, closeOnPress, onRequestClose]
	);

	const keybindMap = useMemo(
		() => ({
			enter: handlePress,
		}),
		[handlePress]
	);
	useHotKeys(keybindMap, { disabled: !focused });

	useEffect(() => {
		if (scrollOnFocus && focused) {
			const self = _ref.current;
			if (self)
				self.scrollIntoView({
					behavior: 'smooth',
					inline: 'nearest',
					block: 'nearest',
				});
		}
	}, [scrollOnFocus, focused, _ref]);

	const renderIcon = useCallback(
		(props: ButtonChildrenProps) => {
			if (CustomIcon) return <CustomIcon {...props} />;
			if (icon === undefined) return null;
			if (typeof icon === 'string') {
				const { disabled, activated, focused } = props;
				return (
					<MenuItemIcon
						icon={icon}
						disabled={disabled ? 1 : 0}
						activated={activated ? 1 : 0}
						focused={focused ? 1 : 0}
						iconProps={_iconProps}
						x-component="icon"
					/>
				);
			}
			return icon;
		},
		[icon, _iconProps, CustomIcon]
	);

	return useMemo(() => {
		return (
			<MenuItemWrapper
				ref={ref}
				focused={focused}
				renderIcon={renderIcon}
				onPress={handlePress}
				onContextMenu={preventDefault}
				{..._restProps}
			/>
		);
	}, [ref, focused, renderIcon, handlePress, _restProps]);
});

export default MenuItem;

import React, {
	useCallback,
	useMemo,
	forwardRef,
	Ref,
	FocusEvent as RFocusEvent,
	MouseEvent as RMouseEvent,
} from 'react';
import { IconTextButtonWrapper } from './IconTextButton.sc';
import IconTextButtonIcon from './IconTextButtonIcon';
import IconTextButtonText from './IconTextButtonText';
import IconTextButtonTooltip from './IconTextButtonTooltip';
import { usePopoverOnElement } from '../../Popover';
import useForwardedRef from '../../../../Hooks/useForwardedRef';
import useObject from '../../../../Hooks/useObject';
import { IconTextButtonProps } from '../interfaces';
import { ButtonChildrenProps } from '../../Button/interfaces';

// function IconTextButtonChildren(props: ButtonChildrenProps) {
// 	const { text, CustomText, icon, iconProps, CustomIcon, tooltip, tooltipProps, getPopoverProps, CustomTooltip }

// 	return
// 		<>
// 		</>
// }

const IconTextButton = forwardRef(function(
	props: IconTextButtonProps,
	ref: Ref<HTMLDivElement>
) {
	const {
		icon,
		iconProps,
		renderIcon,
		lightBg = true,
		text,
		renderText,
		tooltip,
		tooltipProps,
		renderTooltip,
		useTooltipOptions,
		onFocus,
		onBlur,
		onMouseEnter,
		onMouseLeave,
		...restProps
	} = props;
	const _ref = useForwardedRef(ref);
	const _iconProps = useObject(iconProps);
	const _tooltipProps = useObject(tooltipProps);
	const _restProps = useObject(restProps);

	const {
		open: openTooltip,
		close: closeTooltip,
		getPopoverProps,
	} = usePopoverOnElement(_ref, useTooltipOptions);

	const handleFocus = useCallback(
		(e: RFocusEvent<HTMLDivElement>) => {
			onFocus && onFocus(e);
			openTooltip();
		},
		[onFocus, openTooltip]
	);

	const handleBlur = useCallback(
		(e: RFocusEvent<HTMLDivElement>) => {
			onBlur && onBlur(e);
			closeTooltip();
		},
		[onBlur, closeTooltip]
	);

	const handleMouseEnter = useCallback(
		(e: RMouseEvent<HTMLDivElement>) => {
			onMouseEnter && onMouseEnter(e);
			openTooltip();
		},
		[onMouseEnter, openTooltip]
	);

	const handleMouseLeave = useCallback(
		(e: RMouseEvent<HTMLDivElement>) => {
			onMouseLeave && onMouseLeave(e);
			closeTooltip();
		},
		[onMouseLeave, closeTooltip]
	);

	return useMemo(() => {
		// console.log('render IconTextButton');
		const render = (props: ButtonChildrenProps) => {
			return (
				<>
					<IconTextButtonIcon
						icon={icon}
						iconProps={_iconProps}
						CustomIcon={renderIcon}
						lightBg={lightBg}
						{...props}
					/>
					<IconTextButtonText
						text={text}
						CustomText={renderText}
						{...props}
					/>
				</>
			);
		};
		return (
			<IconTextButtonWrapper
				ref={_ref}
				lightBg={lightBg}
				onFocus={handleFocus}
				onBlur={handleBlur}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				render={render}
				{..._restProps}
			>
				<IconTextButtonTooltip
					tooltip={tooltip}
					tooltipProps={_tooltipProps}
					getPopoverProps={getPopoverProps}
					CustomTooltip={renderTooltip}
				/>
			</IconTextButtonWrapper>
		);
	}, [
		_ref,
		lightBg,
		icon,
		_iconProps,
		renderIcon,
		text,
		renderText,
		tooltip,
		_tooltipProps,
		getPopoverProps,
		renderTooltip,
		handleFocus,
		handleBlur,
		handleMouseEnter,
		handleMouseLeave,
		_restProps,
	]);
});

export default IconTextButton;

import { useSelector, useDispatch } from 'react-redux';
import { RecordOf } from 'immutable';
import { IRootState } from '../../../Store/reducers/root';
import { useCallback } from 'react';
import { AnyAction } from 'redux';
import { uplinkAction } from '../../../Store/actions/remoteStore';
import {
	fetchNotifications,
	markRead,
	unread,
	markAllRead,
} from '../../../Components/Dashboard/Components/NotificationPanel/Store/actions';

export default function useNotifications() {
	const { exhausted, loading, notifications, notificationIds } = useSelector(
		(state: RecordOf<IRootState>) => state.get('notification')
	);
	const dispatch = useDispatch();
	const uplinkDispatch = useCallback(
		(action: AnyAction) => {
			dispatch(uplinkAction({ action }));
		},
		[dispatch]
	);

	const handleFetchNotification = useCallback(() => {
		uplinkDispatch(fetchNotifications({ fetched: notificationIds.size }));
	}, [uplinkDispatch, notificationIds.size]);

	const handleMarkRead = useCallback(
		(notificationId: string) => {
			uplinkDispatch(markRead({ notificationId }));
		},
		[uplinkDispatch]
	);

	const handleMarkUnread = useCallback(
		(notificationId: string) => {
			uplinkDispatch(unread({ notificationId }));
		},
		[uplinkDispatch]
	);

	const handleMarkAllRead = useCallback(() => {
		uplinkDispatch(markAllRead());
	}, [uplinkDispatch]);

	return {
		exhausted,
		loading,
		notifications,
		notificationIds,
		handleFetchNotification,
		handleMarkRead,
		handleMarkUnread,
		handleMarkAllRead,
	};
}

import createActionCreator from '../../Helpers/createActionCreator';
import { IWeather, IWarning } from './interfaces';

type FetchWeatherInfoPayload = {};

export const fetchWeatherInfo = createActionCreator(
	'WEATHER_PANEL/FETCH_WEATHER_INFO',
	{} as FetchWeatherInfoPayload
);

type ImportWeatherInfoPayload = {
	weather: IWeather | null;
	warnings: IWarning;
};

export const importWeatherInfo = createActionCreator(
	'WEATHER_PANEL/IMPORT_WEATHER_INFO',
	{} as ImportWeatherInfoPayload
);

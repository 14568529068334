import styled from 'styled-components';
import { View } from '../../Common/View';
import constants from '../constants';

const { HEADER_HEIGHT } = constants;

export const TableWrapper = styled(View)`
	flex-direction: column;
	flex: 1 0 0px;
	align-self: stretch;
	overflow: hidden;
	user-select: none;
	background-color: ${({
		theme: {
			background: { grey },
		},
	}) => grey};
	&:focus {
		outline: none;
	}
`;

export const TableHeaderWrapper = styled(View)`
	flex-direction: row;
	overflow: hidden;
	height: ${HEADER_HEIGHT}px;
`;

export const TableIndexBodyWrapper = styled(View)`
	flex-direction: row;
	overflow: hidden;
`;

import styled from 'styled-components';
import { View } from '../../../../Common/View';
import { SubtitleText1 } from '../../../../Common/Text';
import LoadingSpinner from '../../../../Common/LoadingSpinner';

export const LoadingModalContentWrapper = styled(View)`
	flex-direction: row;
	height: 48px;
	padding: 16px;
	align-items: center;
	justify-content: center;
	background-color: ${({
		theme: {
			background: { light },
		},
	}) => light};
	${({ theme: { shadow } }) => shadow[4]}
`;

export const SLoadingSpinner = styled(LoadingSpinner).attrs(
	({
		theme: {
			background: { accent, darken },
		},
	}) => ({
		upperColor: accent,
		lowerColor: darken[2],
		size: 1,
	})
)`
	height: 32px;
	width: 32px;
`;

export const LoadingText = styled(SubtitleText1)`
	margin-left: 8px;
	margin-right: 8px;
	color: ${({
		theme: {
			font: {
				color: { lightBgSecondary },
			},
		},
	}) => lightBgSecondary};
	user-select: none;
`;

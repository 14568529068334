import { AnyAction } from 'redux';
import get from 'lodash.get';

export interface ActionWithPayload<T extends string, P> extends AnyAction {
	type: T;
	payload: P;
	metadata?: any;
}

export interface ActionCreator<T extends string, P> {
	(payload?: P, metadata?: any): ActionWithPayload<T, P>;
	type: T;
}

export default function createActionCreator<T extends string, P extends Object>(
	type: T,
	defaultPayload?: P,
	metadata: any = {}
): ActionCreator<T, P> {
	const actionCreator = (payload: Partial<P> = {}, _metadata: any = {}) => ({
		type,
		payload: (defaultPayload
			? { ...defaultPayload, ...payload }
			: payload) as P,
		metadata: {
			...metadata,
			..._metadata,
		},
	});

	actionCreator.type = type;

	return actionCreator;
}

export function actionOf<T extends string, P>(
	action: AnyAction,
	actionCreator: ActionCreator<T, P>
): ActionWithPayload<T, P> | null {
	return action.type === actionCreator.type
		? (action as ActionWithPayload<T, P>)
		: null;
}

export interface ForwardAction extends AnyAction {
	metadata: {
		forwardFor: {
			storeId: string;
			type: string;
		};
		[key: string]: any;
	};
}

export function toForwardAction(action: AnyAction): ForwardAction | null {
	const forwardFor = get(action, 'metadata.forwardFor');
	return forwardFor ? (action as ForwardAction) : null;
}

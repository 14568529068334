import styled from 'styled-components';
import { View } from '../../../../Common/View';
import IconTextButton from '../../../../Common/IconTextButton';
import { TooltipLocation } from '../../../../Common/Tooltip/interfaces';
import constants from '../../../constants';
const { PAGINATION_HEIGHT, MIN_PAGINATION_WIDTH } = constants;

export const PaginationWrapper = styled(View)`
	align-self: stretch;
	border: 1px solid
		${({
			theme: {
				background: { darken },
			},
		}) => darken[1]};
	height: ${PAGINATION_HEIGHT}px;
	min-width: ${MIN_PAGINATION_WIDTH}px;
	flex-direction: row;
	align-items: stretch;
	justify-content: center;
	overflow: hidden;
	user-select: none;
	background-color: ${({
		theme: {
			background: { darker },
		},
	}) => darker};
	box-sizing: border-box;
`;

export const PaginationButton = styled(IconTextButton).attrs({
	tooltipProps: { location: TooltipLocation.top },
})`
	width: ${PAGINATION_HEIGHT}px;
	height: ${PAGINATION_HEIGHT}px;
	padding: 0;
	justify-content: center;
`;

import React, {
	useCallback,
	forwardRef,
	Ref,
	FocusEvent as RFocusEvent,
} from 'react';
import { TextAreaWrapper, TextArea } from './WrappedTextArea.sc';
import TextAreaUnderline from './TextAreaUnderline';
import useFocus from '../../../../Hooks/useFocus';
import { WrappedTextAreaProps } from '../interfaces';

function getXmode(focused: boolean, disabled: boolean) {
	let xMode = '';
	if (focused) xMode += ' focused';
	if (disabled) xMode += ' disabled';
	return xMode;
}

const WrappedTextArea = forwardRef(function(
	props: WrappedTextAreaProps,
	ref: Ref<HTMLTextAreaElement>
) {
	const {
		wrapperRef,
		styles = {},
		tabIndex = -1,
		className,
		lightBg = true,
		disabled = false,
		underline = false,
		onFocus,
		onBlur,
		onWrapperFocus,
		onWrapperMouseDown,
		onWrapperMouseUp,
		onWrapperClick,
		onWrapperDoubleClick,
		onWrapperMouseEnter,
		onWrapperMouseLeave,
		...restProps
	} = props;

	const { focused, handleFocus, handleBlur } = useFocus();

	const _handleFocus = useCallback(
		(e: RFocusEvent<HTMLTextAreaElement>) => {
			handleFocus();
			onFocus && onFocus(e);
		},
		[handleFocus, onFocus]
	);
	const _handleBlur = useCallback(
		(e: RFocusEvent<HTMLTextAreaElement>) => {
			handleBlur();
			onBlur && onBlur(e);
			const selection = window.getSelection();
			if (selection) selection.removeAllRanges();
		},
		[handleBlur, onBlur]
	);

	const xMode = getXmode(focused, disabled);
	return (
		<TextAreaWrapper
			ref={wrapperRef}
			style={styles.wrapper}
			className={className}
			onFocus={onWrapperFocus}
			onMouseDown={onWrapperMouseDown}
			onMouseUp={onWrapperMouseUp}
			onClick={onWrapperClick}
			onDoubleClick={onWrapperDoubleClick}
			onMouseEnter={onWrapperMouseEnter}
			onMouseLeave={onWrapperMouseLeave}
			x-mode={xMode}
		>
			<TextArea
				ref={ref}
				style={styles.textArea}
				tabIndex={tabIndex}
				spellCheck={false}
				lightBg={lightBg}
				disabled={disabled}
				onFocus={_handleFocus}
				onBlur={_handleBlur}
				x-mode={xMode}
				x-component="textarea"
				{...restProps}
			/>
			{underline ? (
				<TextAreaUnderline
					style={styles.underline}
					focused={focused}
					x-mode={xMode}
					x-component="underline"
				/>
			) : null}
		</TextAreaWrapper>
	);
});

export default WrappedTextArea;

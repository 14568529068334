import styled from 'styled-components';
import Scrollbars from '../../../../Common/Scrollbars';
import { View } from '../../../../Common/View';
import { TransitionStatus } from 'react-transition-group/Transition';

export interface WrapperProps {
	status: TransitionStatus;
}

export const ActivitiesScrollWrapper = styled(Scrollbars)<WrapperProps>`
	position: absolute;
	background-color: ${({
		theme: {
			background: { darken },
		},
		status,
	}) => (status === 'entered' ? darken[5] : 'transparent')};

	transition: all 100ms;
`;

export const ActivitiesWrapper = styled(View)`
	flex: 1;
	position: relative;
	margin-bottom: 120px;
`;

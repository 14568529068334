import { WindowBound } from './interfaces';
import { ResizeDirection } from '../../interfaces';

const scales: { [side: string]: [number, number, number, number] } = {
	[ResizeDirection.TOP]: [0, 1, 0, 0],
	[ResizeDirection.BOTTOM]: [0, 0, 0, 1],
	[ResizeDirection.LEFT]: [1, 0, 0, 0],
	[ResizeDirection.RIGHT]: [0, 0, 1, 0],
	[ResizeDirection.TOP_LEFT]: [1, 1, 0, 0],
	[ResizeDirection.TOP_RIGHT]: [0, 1, 1, 0],
	[ResizeDirection.BOTTOM_LEFT]: [1, 0, 0, 1],
	[ResizeDirection.BOTTOM_RIGHT]: [0, 0, 1, 1],
};

export default function resizeWindow(
	direction: ResizeDirection,
	windowBound: WindowBound,
	movement: { x: number; y: number },
	minWidth: number,
	minHeight: number,
	parentWidth: number,
	parentHeight: number
) {
	const { x1, x2, y1, y2 } = windowBound;
	const [sx1, sy1, sx2, sy2] = scales[direction];
	const { x, y } = movement;
	const _x1 = x1 + sx1 * x;
	const _y1 = y1 + sy1 * y;
	const _x2 = x2 + sx2 * x;
	const _y2 = y2 + sy2 * y;

	const _left = Math.max(0, sx1 ? Math.min(_x1, _x2 - minWidth) : _x1);
	const _top = Math.max(0, sy1 ? Math.min(_y1, _y2 - minHeight) : _y1);
	const _width = Math.max(
		minWidth,
		Math.min(sx1 ? _x2 : parentWidth - _x1, _x2 - _x1)
	);

	const _height = Math.max(
		minHeight,
		Math.min(sy1 ? _y2 : parentHeight - _y1, _y2 - _y1)
	);

	return {
		x1: _x1,
		y1: _y1,
		x2: _x2,
		y2: _y2,
		left: _left,
		top: _top,
		width: _width,
		height: _height,
	};
}

import createActionCreator from '../../Helpers/createActionCreator';

export interface ISendSnackbarPayload {
	snackbarId: string;
	message: string;
	title: string;
	loading?: boolean;
	autoDismiss?: number;
}

export const sendSnackbar = createActionCreator(
	'SNACKBAR/SEND',
	{} as ISendSnackbarPayload
);

interface IDismissSnackbarPayload {
	snackbarId: string;
}

export const dismissSnackbar = createActionCreator(
	'SNACKBAR/DISMISS',
	{} as IDismissSnackbarPayload
);

import styled from 'styled-components';
import { View } from '../../../../Common/View';
import WrappedInput from '../../../../Common/WrappedInput';
import { HeadingText5 } from '../../../../Common/Text';

export const QuerySelectorWrapper = styled(View)`
	flex: 0 0 48px;
	padding: 8px;
	margin: 8px;
	border-radius: 8px;

	background-color: ${({
		theme: {
			background: { light },
		},
	}) => light};
`;

export const QueryInputsWrapper = styled(View)`
	flex-direction: row;
`;

export const QueryInput = styled(WrappedInput).attrs({ underline: true })`
	flex: 0 0 160px;
	height: 36px;
	justify-content: center;
	align-items: stretch;
	margin-right: 8px;

	& > [x-component='underline'] {
		background-color: ${({
			theme: {
				background: { accent },
			},
		}) => accent};
	}

	& > [x-component='input'] {
		color: ${({
			theme: {
				font: {
					color: { lightBgSecondary },
				},
			},
		}) => lightBgSecondary};
		font-size: 16px;
		height: 28px;
		box-sizing: border-box;
		font-weight: bold;
		border-bottom: 1px solid
			${({ theme: { background } }) => background.grey};
	}
`;

export const DistrictText = styled(HeadingText5)`
	color: ${({
		theme: {
			font: {
				color: { primary },
			},
		},
	}) => primary};
	margin-right: 16px;
`;

import styled from 'styled-components';
import { View } from './View';

const Underline = styled(View)`
	position: relative;
	transition: all 0.2s;
	height: 2px;
	margin-bottom: -2px;
	background-color: ${({
		theme: {
			background: { accent },
		},
	}) => accent};
`;

export default Underline;

import React, { useMemo, RefObject } from 'react';
import { SnackbarGroupWrapper } from './SnackbarGroup.sc';
import { ISnackbarGroupStore, SnackbarRecord, SnackbarSide } from './reducer';
import { AnyAction } from 'redux';
import Snackbar from './Snackbar';
import { TransitionGroup } from 'react-transition-group';
import { createPortal } from 'react-dom';

interface SnackbarGroupProps extends ISnackbarGroupStore {
	side: SnackbarSide;
	maxWidth?: number;
	containerRef: RefObject<HTMLElement | null>;
	onRequestDispatch(action: AnyAction): any;
}

export default function SnackbarGroup(props: SnackbarGroupProps) {
	const {
		side,
		snackbarIds,
		snackbars,
		maxWidth,
		onRequestDispatch,
		containerRef,
	} = props;
	return useMemo(() => {
		const container = containerRef.current;
		return container
			? createPortal(
					<SnackbarGroupWrapper side={side}>
						<TransitionGroup component={null}>
							{snackbarIds
								.toArray()
								.slice(0, 4)
								.map((snackbarId) => {
									const {
										message,
										loading,
										autoDismiss,
										title,
									} = snackbars.get(
										snackbarId,
										SnackbarRecord()
									);
									return (
										<Snackbar
											key={snackbarId}
											side={side}
											snackbarId={snackbarId}
											title={title}
											message={message}
											loading={loading}
											maxWidth={maxWidth}
											autoDismiss={autoDismiss}
											onRequestDispatch={
												onRequestDispatch
											}
										/>
									);
								})}
						</TransitionGroup>
					</SnackbarGroupWrapper>,
					container
			  )
			: null;
	}, [
		side,
		snackbarIds,
		snackbars,
		maxWidth,
		onRequestDispatch,
		containerRef,
	]);
}

import { Dict } from '../../../types/Common';
import { AnyAction } from 'redux';
import { List, Map, RecordOf } from 'immutable';

export enum FilterType {
	none = 'none',
	eq = 'eq',
	lt = 'lt',
	lte = 'lte',
	gt = 'gt',
	gte = 'gte',
	ne = 'ne',
	in = 'in',
	nin = 'nin',
	between = 'between',
	contain = 'contain',
}

export interface IFilter {
	type: FilterType;
	values: Map<string, any>;
	suggestions: List<any>;
	errorMessage: string;
}

export interface IFormula {
	value: string;
	suggestions: List<string>;
	errorMessage: string;
}

export interface IFormat {
	booleanFormat: string;
	dateFormat: string;
	numberFormat: string;
	unit: string;
}

type PrimitiveTypeStr =
	| 'any'
	| 'undetermined'
	| 'boolean'
	| 'string'
	| 'number'
	| 'date';
export interface PrimitiveType {
	type: PrimitiveTypeStr;
}
export interface ArrayType {
	type: 'array';
	items: DataType;
}
export interface ObjectType {
	type: 'object';
	properties: { [key: string]: DataType };
}
export type DataType = PrimitiveType | ArrayType | ObjectType; // JSON Schema Like Object

export interface IColumn {
	name: string;
	filter: RecordOf<IFilter>;
	formula: RecordOf<IFormula>;
	format: RecordOf<IFormat>;
	dataType: DataType;
	width: number;
	editable: boolean;
	readonly: boolean;
	sortable: boolean;
}

export enum MergeStatus {
	none = 'none',
	unmerged = 'unmerged',
	merging = 'merging',
	failed = 'failed',
}

export interface ICell {
	editingBy: string | null;
	mergeStatus: MergeStatus;
}

export interface IRow {
	name: string;
	height: number;
}

export type SortOrder = 0 | 1 | -1;

export interface ITableStore {
	columnKeys: List<string>;
	recordIds: List<string>;

	columns: Map<string, RecordOf<IColumn>>;
	rows: Map<string, RecordOf<IRow>>;
	cells: Map<string, Map<string, RecordOf<ICell>>>;
	sorts: List<Map<string, SortOrder>>;

	recordCount: number | null;
	records: Map<string, Map<string, any>>;
	unmergedRecords: Map<string, Map<string, any>>;

	page: number;
	pageSize: number;

	loading: boolean;
}

export interface DispatchProps {
	onRequestDispatch(action: AnyAction): any;
}

export interface FilterJS {
	type: FilterType;
	values: Dict<any>;
	suggestions: any[];
	errorMessage: string;
}

export interface FormulaJS {
	value: string;
	suggestions: string[];
	errorMessage: string;
}

export type FormatJS = IFormat;

export interface ColumnJS {
	name: string;
	filter: FilterJS;
	formula: FormulaJS;
	format: FormatJS;
	dataType: DataType;
	width: number;
	editable: boolean;
	readonly: boolean;
	sortable: boolean;
}

export type RowJS = IRow;

export type CellJS = ICell;

export interface TableStoreJS {
	columnKeys: string[];
	recordIds: string[];

	columns: Dict<ColumnJS>;
	rows: Dict<RowJS>;
	cells: Dict<Dict<CellJS>>;
	sorts: Dict<SortOrder>[];

	recordCount: number | null;
	records: Dict<Dict<any>>;
	unmergedRecords: Dict<Dict<any>>;

	page: number;
	pageSize: number;

	loading: boolean;
}

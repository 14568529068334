import { createContext, createRef } from 'react';
import { List, Map } from 'immutable';
import { ITableStore, DispatchProps } from './interfaces';
import { TableState } from './Components/TableProvider';
import noop from '../../Helpers/noop';

export interface ITableContext extends ITableStore, DispatchProps, TableState {
	user: string;
	editable: boolean;
}

export default createContext<ITableContext>({
	user: '',
	editable: true,
	loading: false,
	tableWidth: 0,
	tableHeight: 0,
	tableRef: createRef(),
	bodyGridRef: createRef(),
	headerGridRef: createRef(),
	indexGridRef: createRef(),
	selectionBoxRef: createRef(),
	selected: null,
	selecting: false,
	rearrangingColumnKey: null,
	rearrangingRecordId: null,
	resizingColumnKey: null,
	resizingRecordId: null,
	setTableRef: noop,
	setBodyGridRef: noop,
	setHeaderGridRef: noop,
	setIndexGridRef: noop,
	setSelectionBoxRef: noop,
	setSelecting: noop,
	setRearrangingColumnKey: noop,
	setRearrangingRecordId: noop,
	setResizingColumnKey: noop,
	setResizingRecordId: noop,
	setSelected: noop,
	sorts: List(),
	columnKeys: List(),
	columns: Map(),
	rows: Map(),
	page: 1,
	pageSize: 50,
	records: Map(),
	recordIds: List(),
	cells: Map(),
	recordCount: 0,
	unmergedRecords: Map(),
	onRequestDispatch: (action: any) => action,
});

import { takeEvery, call, select } from 'redux-saga/effects';
import { importRecordsFile } from '../../../Components/Table/Store/actions';
import { uploadRecordsFile } from '../services/uploadRecordsFile';
import { IRootState } from '../../reducers/root';
import { RecordOf } from 'immutable';

export function* handleImportRecordsFile(
	action: ReturnType<typeof importRecordsFile>
) {
	const {
		payload: { file },
		metadata: { collection, keyField },
	} = action;
	const token = yield select(
		(state: RecordOf<IRootState>) => state.auth.token
	);
	yield call(uploadRecordsFile, file, collection, keyField, token);
}

export default function* watchImportRecordsFile() {
	yield takeEvery(importRecordsFile.type, handleImportRecordsFile);
}

import React, { ComponentType } from 'react';
import ReactDOM from 'react-dom';
import { configure } from 'react-hotkeys';
import './index.css';
import * as serviceWorker from './serviceWorker';

import TableDemo from './Examples/TableDemo';
import MapViewDemo from './Examples/MapViewDemo';
import App from './App';
import WindowDemo from './Examples/WindowDemo';
import DashboardDemo from './Examples/DashboardDemo';
import HomeDemo from './Examples/HomeDemo';
import MapTreeDetailsDemo from './Examples/MapTreeDetailsDemo';

configure({ ignoreTags: [] });

const { REACT_APP_EXAMPLE } = process.env;

const examples: { [exampleName: string]: ComponentType } = {
	table: TableDemo,
	map: MapViewDemo,
	window: WindowDemo,
	dashboard: DashboardDemo,
	home: HomeDemo,
	'map-tree-details': MapTreeDetailsDemo,
};

const Example = REACT_APP_EXAMPLE ? examples[REACT_APP_EXAMPLE] : null;

ReactDOM.render(
	Example ? <Example /> : <App />,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, {
	useMemo,
	useCallback,
	useContext,
	ChangeEvent as RChangeEvent,
} from 'react';
import {
	PaginationInputWrapper,
	PaginationInput as SPaginationInput,
	PaginationInputText,
} from './PaginationInput.sc';
import { usePageUpdate } from '../../../Hooks/Page';
import useAsState from '../../../../../Hooks/useAsState';
import context from '../../../context';

function PaginationInput() {
	const contextObj = useContext(context);
	const { page, recordCount, pageSize } = contextObj;
	const pageCount =
		recordCount !== null ? Math.ceil(recordCount / pageSize) : Infinity;
	const [_page, setPage] = useAsState(page);
	const updatePage = usePageUpdate(contextObj);

	const handleChange = useCallback(
		(e: RChangeEvent<HTMLInputElement>) => {
			setPage(Math.min(parseInt(e.target.value) || 0, pageCount));
		},
		[setPage, pageCount]
	);

	const handleBlur = useCallback(() => {
		const __page = Math.min(Math.max(_page, 1), pageCount);
		if (__page !== page) updatePage(__page);
		setPage(__page);
	}, [_page, pageCount, page, updatePage, setPage]);

	return useMemo(() => {
		const inputWidth = 12 * _page.toString().length;
		return (
			<PaginationInputWrapper>
				<SPaginationInput
					underline
					styles={{
						wrapper: { width: inputWidth },
					}}
					tabIndex={0}
					onChange={handleChange}
					onBlur={handleBlur}
					value={_page}
				/>
				{pageCount !== Infinity ? (
					<PaginationInputText>{`/${pageCount}`}</PaginationInputText>
				) : null}
			</PaginationInputWrapper>
		);
	}, [_page, handleChange, handleBlur, pageCount]);
}

export default PaginationInput;

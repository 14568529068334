import styled from 'styled-components';
import { View } from '../../../../Common/View';
import ToggleableInput from '../../../../Common/ToggleableInput';

export const HeaderCellTitleInputWrapper = styled(View)`
	align-self: stretch;
	position: relative;
	justify-content: center;
	flex: 1 0 0;
`;

export const HeaderCellTitleInput = styled(ToggleableInput)`
	& > [x-component='input'] {
		color: ${({
			theme: {
				font: {
					color: { darkBgPrimary },
				},
			},
		}) => darkBgPrimary};
	}
`;

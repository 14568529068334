import React, { useMemo, useRef } from 'react';
import { Transition } from 'react-transition-group';
import {
	DockItemWrapper,
	DockItemContentWrapper,
	DockItemIcon,
	DockItemText,
	DockItemIconWrapper,
	TaskCounterWrapper,
	TaskCounterIndicator,
} from './DockItem.sc';
import { DockItemProps } from '../interfaces';
import Tooltip from '../../../../Common/Tooltip';

import { usePopoverOnElement } from '../../../../Common/Popover';
import { TooltipLocation } from '../../../../Common/Tooltip/interfaces';

export default function DockItem(props: DockItemProps) {
	const { icon, name, expanded, onPress, count = 0 } = props;

	const ref = useRef<HTMLDivElement>(null);

	const {
		open: openTooltip,
		close: closeTooltip,
		getPopoverProps,
	} = usePopoverOnElement(ref);

	const taskIndicators = useMemo(() => {
		const indicators = [];
		for (let i = 0; i < count; i++) {
			indicators.push(<TaskCounterIndicator key={i} />);
		}
		return indicators;
	}, [count]);

	return useMemo(() => {
		return (
			<DockItemWrapper
				ref={ref}
				onPress={onPress}
				onMouseEnter={openTooltip}
				onMouseLeave={closeTooltip}
			>
				<DockItemContentWrapper>
					<DockItemIconWrapper>
						<DockItemIcon path={icon} />
					</DockItemIconWrapper>
					<Transition timeout={200} in={expanded}>
						{(status) => {
							const textStyle =
								status === 'entered'
									? {
											opacity: 1,
									  }
									: { opacity: 0 };
							return (
								<DockItemText status={status} style={textStyle}>
									{name}
								</DockItemText>
							);
						}}
					</Transition>
				</DockItemContentWrapper>
				<TaskCounterWrapper>{taskIndicators}</TaskCounterWrapper>
				{!expanded ? (
					<Tooltip
						location={TooltipLocation.right}
						{...getPopoverProps()}
					>
						{name}
					</Tooltip>
				) : null}
			</DockItemWrapper>
		);
	}, [
		icon,
		taskIndicators,
		name,
		expanded,
		getPopoverProps,
		closeTooltip,
		openTooltip,
		onPress,
	]);
}

import styled from 'styled-components';
import { View } from '../../../../Common/View';
import { CaptionText } from '../../../../Common/Text';

export const FormatModalWrapper = styled(View)`
	width: 300px;
	height: 160px;
	border-radius: 4px;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	padding: 24px;
	background: ${({
		theme: {
			background: { light },
		},
	}) => light};
`;

export const FormatModalInputWrapper = styled(View)`
	margin: 4px;
	flex-direction: row;
	align-self: center;
	align-items: center;
	height: 36px;
`;

export const FormatModalInputText = styled(CaptionText)`
	margin-left: 4px;
	margin-right: 4px;
	width: 100px;
	color: ${({
		theme: {
			font: {
				color: { lightBgSecondary },
			},
		},
	}) => lightBgSecondary};
`;

import createActionCreator from '../../Helpers/createActionCreator';

export interface IFetchRecordsPayload {
	fetched: number;
	search: string;
	searchKey: string;
	feedId: string;
	collection: string;
}

export const fetchRecords = createActionCreator(
	'RECORDSFEED/FETCH',
	{} as IFetchRecordsPayload
);

export interface IFetchRecordsSuccessPayload {
	records: any[];
	recordIds: string[];
	feedId: string;
	collection: string;
}

export const importRecords = createActionCreator(
	'RECORDSFEED/IMPORT_RECORDS',
	{} as IFetchRecordsSuccessPayload
);

export interface IImportRecordPayload {
	record: any;
	recordId: string;
	feedId: string;
	collection: string;
}

export const importRecord = createActionCreator(
	'RECORDSFEED/IMPORT_RECORD',
	{} as IImportRecordPayload
);

export interface IUpdateRecordPayload {
	record: any;
	recordId: string | null;
	feedId: string;
	collection: string;
}

export const updateRecord = createActionCreator(
	'RECORDSFEED/UPDATE_RECORD',
	{} as IUpdateRecordPayload
);

export interface IUpdateRecordResultPayload {
	recordId: string | null;
	feedId: string;
	collection: string;
}

export const updateRecordSuccess = createActionCreator(
	'RECORDSFEED/UPDATE_RECORD_SUCCESS',
	{} as IUpdateRecordResultPayload
);
export const updateRecordFailure = createActionCreator(
	'RECORDSFEED/UPDATE_RECORD_FAILURE',
	{} as IUpdateRecordResultPayload
);

export interface IUpdateSearchTextPayload {
	searchText: string;
	collection: string;
	feedId: string;
	searchKey: string;
}

export const updateSearchText = createActionCreator(
	'RECORDSFEED/UPDATE_SEARCH_TEXT',
	{} as IUpdateSearchTextPayload
);

export interface IDeleteRecordPayload {
	feedId: string;
	collection: string;
	recordId: string;
}

export const deleteRecord = createActionCreator(
	'RECORDSFEED/DELETE_RECORD',
	{} as IDeleteRecordPayload
);

export const deleteRecordSuccess = createActionCreator(
	'RECORDSFEED/DELETE_RECORD_SUCCESS',
	{} as IDeleteRecordPayload
);

export const deleteRecordFailure = createActionCreator(
	'RECORDSFEED/DELETE_RECORD_FAILURE',
	{} as IDeleteRecordPayload
);

import React, { useMemo, useState, useCallback, useContext } from 'react';
import { Transition } from 'react-transition-group';

import { ExpandedDockWrapper, LaunchersWrapper, Separator } from './Dock.sc';
import { DockProps } from '../interfaces';
import Launcher from './Launcher';
import {
	mdiApps,
	mdiViewDashboard,
	mdiBackburger,
	mdiForwardburger,
} from '@mdi/js';
import DockItem from './DockItem';
import Scrollbars from '../../../../Common/Scrollbars';
import workspaceContext from '../../Workspace/context';
import { Application } from '../../../interfaces';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../Store/reducers/root';

export default function Dock(props: DockProps) {
	const { applications } = props;

	const groups = useSelector(
		(state: IRootState): string[] =>
			state.auth.getIn(['user', 'groups']) || []
	);
	const [expanded, setExpanded] = useState(
		localStorage.getItem('dock/expanded') !== 'false'
	);
	const { toggleActivities, toggleHomePage } = useContext(workspaceContext);

	const handleActivitiesPress = useCallback(() => {
		toggleHomePage(false);
		toggleActivities();
	}, [toggleActivities, toggleHomePage]);

	const handleHomePageButtonPress = useCallback(() => {
		toggleHomePage((v) => !v);
	}, [toggleHomePage]);

	const handleExpand = useCallback(() => {
		localStorage.setItem('dock/expanded', 'true');
		setExpanded(true);
	}, [setExpanded]);

	const handleCollapse = useCallback(() => {
		localStorage.setItem('dock/expanded', 'false');
		setExpanded(false);
	}, [setExpanded]);

	return useMemo(() => {
		return (
			<Transition timeout={200} in={expanded}>
				{(status) => {
					return (
						<ExpandedDockWrapper status={status}>
							<DockItem
								expanded={expanded}
								icon={mdiViewDashboard}
								name="HOME PAGE"
								onPress={handleHomePageButtonPress}
							/>
							<Separator />
							<Scrollbars>
								<LaunchersWrapper>
									{applications
										.filter(
											({ allowAccess }: Application) =>
												!allowAccess ||
												groups.includes('root') ||
												!!allowAccess.filter((group) =>
													groups.includes(group)
												).length
										)
										.map((application: Application) => (
											<Launcher
												key={application.key}
												expanded={expanded}
												application={application}
											/>
										))
										.toList()
										.toArray()}
								</LaunchersWrapper>
							</Scrollbars>
							<DockItem
								expanded={expanded}
								icon={
									expanded ? mdiBackburger : mdiForwardburger
								}
								name={
									expanded ? 'MINIMIZE MENU' : 'EXPAND MENU'
								}
								onPress={
									expanded ? handleCollapse : handleExpand
								}
							/>
							<DockItem
								expanded={expanded}
								icon={mdiApps}
								name="ACTIVITIES"
								onPress={handleActivitiesPress}
							/>
						</ExpandedDockWrapper>
					);
				}}
			</Transition>
		);
	}, [
		groups,
		expanded,
		handleExpand,
		handleCollapse,
		applications,
		handleActivitiesPress,
		handleHomePageButtonPress,
	]);
}

import OlDragRotate, { Options } from 'ol/interaction/DragRotate';
import { useContext, useMemo, useEffect } from 'react';
import context from '../context';

interface Props {
	dragRotateOptions?: Options;
}

export default function DragRotate(props: Props) {
	const { dragRotateOptions } = props;

	const { map } = useContext(context);

	const dragRotate = useMemo(() => new OlDragRotate(dragRotateOptions), [
		dragRotateOptions,
	]);

	useEffect(() => {
		map.addInteraction(dragRotate);
		return () => {
			map.removeInteraction(dragRotate);
		};
	}, [dragRotate, map]);

	return null;
}

import { useRef, useCallback } from 'react';
import { resizeColumn } from '../../../Store/actions';
import { useColumn } from '../../../Hooks/Column';
import useAsState from '../../../../../Hooks/useAsState';
import { ITableContext } from '../../../context';
import constants from '../../../constants';
const { MIN_COLUMN_WIDTH } = constants;

export default function useColumnResize(
	contextObj: ITableContext,
	columnKey: string
) {
	const { onRequestDispatch, setResizingColumnKey, setSelected } = contextObj;
	const { width } = useColumn(contextObj, columnKey);
	const [_width, setWidth] = useAsState(width);
	const widthRef = useRef(0);

	const startColumnResize = useCallback(() => {
		setResizingColumnKey(columnKey);
		document.body.style.cursor = 'ew-resize';
		widthRef.current = _width;

		const handleMouseMove = (e: MouseEvent) => {
			const { movementX } = e;
			widthRef.current += movementX;
			setWidth(Math.max(widthRef.current, MIN_COLUMN_WIDTH));
		};
		const handleMouseUp = () => {
			onRequestDispatch(
				resizeColumn({
					columnKey,
					width: Math.max(widthRef.current, MIN_COLUMN_WIDTH),
				})
			);
			setResizingColumnKey(null);
			setSelected(null);
			document.body.style.cursor = 'unset';
			window.removeEventListener('mousemove', handleMouseMove);
			window.removeEventListener('mouseup', handleMouseUp);
		};

		window.addEventListener('mousemove', handleMouseMove);
		window.addEventListener('mouseup', handleMouseUp);
	}, [
		setResizingColumnKey,
		setSelected,
		columnKey,
		widthRef,
		_width,
		setWidth,
		onRequestDispatch,
	]);

	return { width: _width, startColumnResize };
}

export default {
	HEADER_HEIGHT: 60,
	INDEX_WIDTH: 48,
	PANEL_HEIGHT: 36,
	MIN_PANEL_WIDTH: 400,
	PAGINATION_HEIGHT: 36,
	MIN_PAGINATION_WIDTH: 448,
	DEFAULT_COLUMN_WIDTH: 200,
	MIN_COLUMN_WIDTH: 100,
	DEFAULT_ROW_HEIGHT: 36,
	MIN_ROW_HEIGHT: 36,
	DEFAULT_PAGE_SIZE: 50,
};

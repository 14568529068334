import React, { useMemo } from 'react';
import {
	LoadingScreenWrapper,
	EmbeddedLoadingSpinner,
	EmbeddedLoadingText,
} from './EmbeddedLoadingScreen.sc';
import { EmbeddedLoadingProps } from '../interfaces';

export default function EmbeddedLoadingScreen(props: EmbeddedLoadingProps) {
	const { message } = props;
	return useMemo(() => {
		return (
			<LoadingScreenWrapper>
				<EmbeddedLoadingSpinner />
				<EmbeddedLoadingText>
					{message || 'Loading...'}
				</EmbeddedLoadingText>
			</LoadingScreenWrapper>
		);
	}, [message]);
}

import React, {
	useMemo,
	useRef,
	useEffect,
	ReactNode,
	MutableRefObject,
} from 'react';
import { MapViewWrapper, MapWrapper } from './MapView.sc';
import useMeasure from '../../../Hooks/useMeasure';

import useUpdateCenter from '../Hooks/useUpdateCenter';
import useUpdateZoom from '../Hooks/useUpdateZoom';
import useUpdateRotation from '../Hooks/useUpdateRotation';
import context from '../context';
import Map from 'ol/Map';
import { MapOptions } from 'ol/PluggableMap';
import View, { ViewOptions } from 'ol/View';
import noop from '../../../Helpers/noop';

interface Props {
	mapRef?: MutableRefObject<Map | null>;
	viewRef?: MutableRefObject<View | null>;
	children?: ReactNode;
	mapOptions?: MapOptions;
	viewOptions?: ViewOptions;
	zoom: number;
	rotation: number;
	center: number[];
	maxZoom?: number;
	minZoom?: number;
	// onSelect: (feature: GeoJSON.Feature) => any;
	// onBlur: () => any;
	onCenterChange?: (center: number[]) => any;
	onZoom?: (zoom: number) => any;
	onRotate?: (rotation: number) => any;
}

const { Provider } = context;

const defaultOptions = {};
export default function MapView(props: Props) {
	const {
		children,
		mapRef,
		viewRef,
		mapOptions = defaultOptions,
		viewOptions = defaultOptions,
		zoom,
		rotation,
		center,
		onCenterChange = noop,
		onZoom = noop,
		onRotate = noop,
	} = props;

	const containerRef = useRef<HTMLDivElement>(null);
	const outterContainerRef = useRef<HTMLDivElement>(null);

	const mapOptionsRef = useRef(mapOptions);
	const viewOptionsRef = useRef(viewOptions);

	const view = useMemo(() => new View(viewOptionsRef.current), [
		viewOptionsRef,
	]);
	const map = useMemo(() => {
		return new Map({ ...mapOptionsRef.current, view });
	}, [mapOptionsRef, view]);

	useEffect(() => {
		if (viewRef && view) viewRef.current = view;
	}, [view, viewRef]);

	useEffect(() => {
		if (mapRef && map) mapRef.current = map;
	}, [map, mapRef]);

	// useUpdateMinZoom(map, minZoom);
	// useUpdateMaxZoom(map, maxZoom);
	useUpdateCenter(map, center, onCenterChange);
	useUpdateZoom(map, zoom, onZoom);
	useUpdateRotation(map, rotation, onRotate);

	const { height, width } = useMeasure(outterContainerRef, 500);

	useEffect(() => {
		const containerElm = containerRef.current;

		function handleProxyClick(e: any) {
			containerElm &&
				containerElm.dispatchEvent(
					new Event('mousedown', e.originalEvent)
				);
		}
		map.on('click', handleProxyClick);

		if (containerElm) {
			map.setTarget(containerElm);
			map.updateSize();
		}
		return () => {
			map.un('click', handleProxyClick);
			map.setTarget(undefined);
		};
	}, [map]);

	useEffect(() => {
		map.updateSize();
	}, [map, width, height]);

	return (
		<Provider value={{ map, view }}>
			<MapViewWrapper ref={outterContainerRef}>
				<MapWrapper
					data-nopreview="true"
					ref={containerRef}
					style={{
						height,
						width,
					}}
				>
					{children}
				</MapWrapper>
			</MapViewWrapper>
		</Provider>
	);
}

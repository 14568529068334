import { createContext } from 'react';
import { NotificationContext } from './interfaces';
import { Map, OrderedSet } from 'immutable';
import noop from '../../../../Helpers/noop';

export default createContext<NotificationContext>({
	loading: false,
	exhausted: false,
	notifications: Map(),
	notificationIds: OrderedSet(),
	onFetchNotifications: noop,
	onMarkAllNotificationRead: noop,
	onMarkNotificationRead: noop,
	onMarkNotificationUnread: noop,
});

import React, { useContext, useMemo, useRef, CSSProperties } from 'react';

import { IndexCellWrapper, IndexNumber } from './IndexCell.sc';
import IndexCellResizeHandle from './IndexCellResizeHandle';
import { getRecordId } from '../../../Helpers/Record';
import context from '../../../context';

interface IndexCellProps {
	style: CSSProperties;
	data: any;
	rowIndex: number;
	columnIndex: number;
}
export default function IndexCell(props: IndexCellProps) {
	const ref = useRef<HTMLDivElement>(null);
	const {
		style: { left, top, width, height },
		rowIndex,
	} = props;
	const contextObj = useContext(context);
	const { page, pageSize, resizingRecordId } = contextObj;
	const recordId = getRecordId(contextObj, rowIndex);

	return useMemo(() => {
		const resizing = resizingRecordId === recordId;
		const rowNumber = (page - 1) * pageSize + rowIndex + 1;
		return (
			<IndexCellWrapper
				ref={ref}
				style={{ left, top, width, height }}
				resizing={resizing}
			>
				<IndexNumber>{rowNumber}</IndexNumber>
				<IndexCellResizeHandle
					indexCellRef={ref}
					recordId={recordId}
					resizing={resizing}
				/>
			</IndexCellWrapper>
		);
	}, [
		recordId,
		rowIndex,
		left,
		top,
		width,
		height,
		page,
		pageSize,
		resizingRecordId,
	]);
}

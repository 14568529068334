import React, { useMemo } from 'react';
import { WindowHeaderWrapper } from './WindowHeader.sc';
import { WindowHeaderProps } from '../interfaces';
import useStandardMoveHandle from '../../Window/Hooks/useStandardMoveHandle';

export default function WindowHeader(props: WindowHeaderProps) {
	const {
		children,
		onRequestToggleMaximize,
		moving = false,
		...moveHandleProps
	} = props;
	const handleMouseDown = useStandardMoveHandle(moveHandleProps);

	return useMemo(() => {
		return (
			<WindowHeaderWrapper
				moving={moving}
				onMouseDown={handleMouseDown}
				onDoubleClick={onRequestToggleMaximize}
			>
				{children}
			</WindowHeaderWrapper>
		);
	}, [children, moving, handleMouseDown, onRequestToggleMaximize]);
}

import { Selected } from '../../TableProvider';

export default function orderSelected(
	selected: NonNullable<Selected>
): [[number, number], [number, number]] {
	const { start, end } = selected;
	let tlrIndex, tlcIndex, brrIndex, brcIndex;
	if (end[0] > start[0]) {
		tlrIndex = start[0];
		brrIndex = end[0];
	} else {
		tlrIndex = end[0];
		brrIndex = start[0];
	}
	if (end[1] > start[1]) {
		tlcIndex = start[1];
		brcIndex = end[1];
	} else {
		tlcIndex = end[1];
		brcIndex = start[1];
	}
	return [[tlrIndex, tlcIndex], [brrIndex, brcIndex]];
}

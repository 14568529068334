import styled from 'styled-components';
import { View } from '../../../../Common/View';
import {
	HeadingText6,
	HeadingText3,
	CaptionText,
	BodyText2,
} from '../../../../Common/Text';
import IconTextButton from '../../../../Common/IconTextButton';
import { ButtonOverlayShape } from '../../../../Common/Button/interfaces';
import Scrollbars from '../../../../Common/Scrollbars';
import Icon from '@mdi/react';

export const ListWrapper = styled(View)`
	flex: 1 1 0;
	margin: 8px;
	border-radius: 8px;
	background-color: ${({
		theme: {
			background: { error },
		},
	}) => error};
	${({ theme: { shadow } }) => shadow[2]};
`;

export const ListHeaderWrapper = styled(View)`
	padding: 8px 12px 0px 12px;
	flex: 0 0 0;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
`;

export const ListBodyWrapper = styled(Scrollbars)`
	flex: 1;
	box-sizing: border-box;
	padding: 0px 8px 8px 8px;
`;

export const ListItemsWrappper = styled(View)`
	flex-direction: column;
	width: 100%;
`;

export const ListItemWrapper = styled(View)`
	flex-direction: row;
	align-items: center;
	box-sizing: border-box;
	margin: 8px 12px 0 12px;
	padding: 8px;
	border-radius: 4px;
	background-color: ${({
		theme: {
			background: { darken },
		},
	}) => darken[1]};

	&:hover {
		background-color: ${({
			theme: {
				background: { darken },
			},
		}) => darken[2]};
	}
`;

export const ListItemPartWrapper = styled(View)`
	flex-direction: row;
	flex: 1;
`;

export const ListItemIcon = styled(Icon).attrs(
	({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => ({
		size: 0.6,
		color: darkBgPrimary,
	})
)``;

export const ListItemText = styled(BodyText2)`
	color: ${({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => darkBgPrimary};
`;

export const ListTitleWrapper = styled(View)`
	flex-direction: row;
	flex: 1;
`;

export const ListTitleTextWrapper = styled(View)``;

export const ListTitleText = styled(HeadingText6)`
	flex: 1;
	color: ${({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => darkBgPrimary};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const ListSubtitleText = styled(BodyText2)`
	flex: 1;
	color: ${({
		theme: {
			font: {
				color: { darkBgSecondary },
			},
		},
	}) => darkBgSecondary};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const ListCountTextWrapper = styled(View)``;

export const ListCountText = styled(HeadingText3)`
	color: ${({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => darkBgPrimary};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const ListNoDataWrapper = styled(View)`
	flex: 1;
	align-self: stretch;
	align-items: center;
	justify-content: center;
`;

export const ListNoDataText = styled(CaptionText)`
	color: ${({
		theme: {
			font: {
				color: { darkBgDisabled },
			},
		},
	}) => darkBgDisabled};
`;

export const ListIcon = styled(IconTextButton).attrs({
	overlayShape: ButtonOverlayShape.none,
	lightBg: false,
	iconProps: {
		size: 1,
	},
})`
	cursor: unset;
`;

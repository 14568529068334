import {
	useRef,
	useState,
	useCallback,
	RefObject,
	MouseEvent as RMouseEvent,
} from 'react';
import { usePopoverOptions, PopoverControlProps } from '../interfaces';

export function usePopoverAtCursor(options: usePopoverOptions = {}) {
	const { containerRef, leftOffset = 0, topOffset = 0 } = options;
	const leftRef = useRef(0);
	const topRef = useRef(0);
	const [opened, setOpened] = useState(false);

	const open = useCallback(
		(e: RMouseEvent) => {
			e.preventDefault();
			leftRef.current = e.clientX;
			topRef.current = e.clientY;
			const container = containerRef
				? containerRef.current
				: document.body;
			if (container) {
				const { left, top } = container.getBoundingClientRect();
				leftRef.current -= left;
				topRef.current -= top;
			}
			setOpened(true);
		},
		[containerRef]
	);

	const close = useCallback(() => {
		setOpened(false);
	}, []);

	const getPopoverProps = useCallback(
		(): PopoverControlProps => ({
			opened,
			left: leftRef.current + leftOffset,
			top: topRef.current + topOffset,
			onRequestClose: close,
		}),
		[opened, leftOffset, topOffset, close]
	);

	return {
		opened,
		open,
		close,
		getPopoverProps,
	};
}

export function usePopoverAtCenter(options: usePopoverOptions = {}) {
	const { containerRef, leftOffset = 0, topOffset = 0 } = options;
	const leftRef = useRef(0);
	const topRef = useRef(0);
	const [opened, setOpened] = useState(false);

	const open = useCallback(() => {
		const container = containerRef ? containerRef.current : document.body;
		if (container) {
			const { width, height } = container.getBoundingClientRect();
			leftRef.current = width / 2;
			topRef.current = height / 2;
		}
		setOpened(true);
	}, [containerRef]);

	const close = useCallback(() => {
		setOpened(false);
	}, []);

	const getPopoverProps = useCallback(
		(): PopoverControlProps => ({
			opened,
			left: leftRef.current + leftOffset,
			top: topRef.current + topOffset,
			onRequestClose: close,
		}),
		[opened, leftOffset, topOffset, close]
	);

	return {
		opened,
		open,
		close,
		getPopoverProps,
	};
}

export function usePopoverOnElement(
	elementRef: RefObject<HTMLElement>,
	options: usePopoverOptions = {}
) {
	const { containerRef, leftOffset = 0, topOffset = 0 } = options;
	const leftRef = useRef(0);
	const topRef = useRef(0);
	const widthRef = useRef(0);
	const heightRef = useRef(0);
	const [opened, setOpened] = useState(false);

	const open = useCallback(() => {
		const element = elementRef.current;
		if (element) {
			const {
				left,
				top,
				width,
				height,
			} = element.getBoundingClientRect();
			leftRef.current = left;
			topRef.current = top;
			widthRef.current = width;
			heightRef.current = height;
		}
		const container = containerRef ? containerRef.current : document.body;
		if (container) {
			const { left, top } = container.getBoundingClientRect();
			leftRef.current -= left;
			topRef.current -= top;
		}
		setOpened(true);
	}, [elementRef, containerRef]);

	const close = useCallback(() => {
		setOpened(false);
	}, []);

	const getPopoverProps = useCallback(
		(): PopoverControlProps => ({
			opened,
			left: leftRef.current + leftOffset,
			top: topRef.current + topOffset,
			width: widthRef.current,
			height: heightRef.current,
			onRequestClose: close,
		}),
		[opened, leftOffset, topOffset, close]
	);

	return {
		opened,
		open,
		close,
		getPopoverProps,
	};
}

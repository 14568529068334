import React, { useContext, useMemo } from 'react';
import { PanelWrapper, PanelSeparator } from './Panel.sc';
import PanelTableControl from './PanelTableControl';
import PanelColumnControl from './PanelColumnControl';
import { useSelectedColumnKey } from '../../../Hooks/Column';
import context from '../../../context';

interface PanelProps {
	columnWidthSum: number;
}
export default function Panel(props: PanelProps) {
	const { columnWidthSum } = props;
	const contextObj = useContext(context);
	const columnKey = useSelectedColumnKey(contextObj);

	return useMemo(() => {
		return (
			<PanelWrapper style={{ maxWidth: columnWidthSum }}>
				<PanelTableControl />
				<PanelSeparator />
				<PanelColumnControl columnKey={columnKey} />
			</PanelWrapper>
		);
	}, [columnWidthSum, columnKey]);
}

import { createContext } from 'react';
import { DesktopContext } from './interfaces';
import { Map, List } from 'immutable';
import noop from '../../../../Helpers/noop';

export default createContext<DesktopContext>({
	windows: Map(),
	windowIndice: List(),
	containerRef: { current: null },
	desktopWidth: 0,
	desktopHeight: 0,
	onWindowChange: noop,
	onWindowFocus: noop,
	setTilePreview: noop,
});

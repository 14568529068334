import React, {
	useCallback,
	useMemo,
	ChangeEvent as RChangeEvent,
} from 'react';
import {
	ExportRecordModalFilenameInput,
	ExportRecordModalFileFormatInput,
} from './ExportRecordModalFileInput.sc';
import { ModalText } from './ExportRecordModal.sc';
import Autocomplete, {
	Suggestion,
	AutocompleteMenu,
} from '../../../../Common/Autocomplete';

const extensionSuggestions = [
	{ name: 'json', value: 'json' },
	{ name: 'csv', value: 'csv' },
];

interface ExportModalFileInputProps {
	filename: string;
	extension: string;
	setFilename(filename: string): void;
	setExtension(extension: string): void;
}

function ExportModalFileInput(props: ExportModalFileInputProps) {
	const { filename, extension, setFilename, setExtension } = props;

	const handleFilenameInputChange = useCallback(
		(e: RChangeEvent<HTMLInputElement>) => {
			setFilename(e.target.value);
		},
		[setFilename]
	);
	const handleFileFormatChange = useCallback(
		(e: RChangeEvent<HTMLInputElement>) => {
			setExtension(e.target.value);
		},
		[setExtension]
	);
	const handleFileFormatSelect = useCallback(
		(suggestion: Suggestion) => {
			setExtension(suggestion.value);
		},
		[setExtension]
	);
	return useMemo(() => {
		return (
			<>
				<ExportRecordModalFilenameInput
					tabIndex={0}
					autoFocus
					value={filename}
					onChange={handleFilenameInputChange}
				/>
				<ModalText>.</ModalText>
				<Autocomplete
					suggestions={extensionSuggestions}
					onSelect={handleFileFormatSelect}
				>
					{({ ref, openMenu, closeMenu, menuProps }) => (
						<>
							<ExportRecordModalFileFormatInput
								ref={ref}
								tabIndex={0}
								value={extension}
								onChange={handleFileFormatChange}
								onClick={openMenu}
								onFocus={openMenu}
								onBlur={closeMenu}
							/>
							<AutocompleteMenu {...menuProps} />
						</>
					)}
				</Autocomplete>
			</>
		);
	}, [
		filename,
		extension,
		handleFilenameInputChange,
		handleFileFormatSelect,
		handleFileFormatChange,
	]);
}

export default ExportModalFileInput;

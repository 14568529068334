import { useEffect, useContext, useCallback } from 'react';
import context from '../context';
import { LinkedInitialState } from '../Store/createEmbeddedReducer';
import { initLink } from '../Store/actions';
import { EmbeddedState, EmbeddedHook } from '../interfaces';
import set from 'lodash.set';
import { AnyAction } from 'redux';
import { ForwardAction } from '../../../Helpers/createActionCreator';

export default function useEmbedded<S>(
	storeId: string,
	type: string,
	message: string = 'Loading...',
	initialState?: any
): EmbeddedHook<S> {
	const { stores, dispatch } = useContext(context);
	// const linkedDispatch = useLinkedDispatch(type, storeId);
	const forwardAction = useCallback(
		(action: AnyAction) => {
			return set(action, 'metadata.forwardFor', {
				storeId,
				type,
			}) as ForwardAction;
		},
		[storeId, type]
	);

	const linkedState: EmbeddedState<S> = stores.get(
		storeId,
		LinkedInitialState<S>()
	);
	const { initialized, loading } = linkedState;

	useEffect(() => {
		if (!initialized && !loading) {
			dispatch(initLink({ storeId, type, message, initialState }));
		}
	}, [initialized, loading, dispatch, storeId, type, message, initialState]);

	return {
		dispatch,
		linkedState,
		forwardAction,
	};
}

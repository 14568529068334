import { useCallback, MutableRefObject } from 'react';
import { Column } from '../../../Store/store';
import { updateUnmergedRecords } from '../../../Store/actions';
import { parseRecord } from '../../../Helpers/Record';
import { ITableContext } from '../../../context';

async function getClipboardRecordArray(): Promise<string[][]> {
	try {
		const permissionStatus = await (navigator.permissions as any).query({
			name: 'clipboard-read',
		});
		if (permissionStatus.state === 'granted')
			return navigator.clipboard.readText().then((str) => {
				return str
					.trim()
					.split(/\r?\n/)
					.map((s) => s.split('\t'));
			});
	} catch (e) {}
	return [[]];
}

export default function useSelectedCellsPaste(
	contextObjRef: MutableRefObject<ITableContext>
) {
	return useCallback(async () => {
		const {
			selected,
			recordIds,
			columnKeys,
			columns,
			onRequestDispatch,
		} = contextObjRef.current;
		if (!selected) return;
		const [startR, startC] = selected.start;
		const recordArray = await getClipboardRecordArray();
		const rowCount = recordArray.length;
		const maxColumnCount = columnKeys.size;
		const columnCount = recordArray.reduce(
			(l, a) => Math.min(a.length, l),
			maxColumnCount
		);
		const changes = [];
		for (let i = 0; i < rowCount; ++i) {
			const r = startR + i;
			const recordId = recordIds.get(r, '');
			for (let j = 0; j < columnCount; ++j) {
				const c = startC + j;
				const columnKey = columnKeys.get(c, '');
				const { editable, readonly, dataType } = columns.get(
					columnKey,
					Column()
				);
				const value = parseRecord(recordArray[i][j], dataType);
				if (
					editable &&
					!readonly &&
					recordId &&
					columnKey &&
					value !== undefined
				)
					changes.push({ recordId, columnKey, value });
			}
		}
		if (changes.length)
			onRequestDispatch(updateUnmergedRecords({ changes }));
	}, [contextObjRef]);
}

import { FixedSizeGrid, GridOnItemsRenderedProps } from 'react-window';
import React, {
	useCallback,
	useState,
	useEffect,
	useMemo,
	useContext,
	useRef,
	ChangeEvent,
} from 'react';
import { useImmutableMemo } from '../../Hooks/useImmutable';
import { context } from './context';
import Scrollbars from '../Common/Scrollbars';
import {
	RecordsFeedGridWrapper,
	LoadingIndicatorIcon,
	LoadingIndicatorLowerIcon,
	LoadingIndicatorWrapper,
	NoRecordsWrapper,
	NoRecordsText,
	useSearchBarStyles,
} from './RecordsFeedGrid.sc';
import { mdiCircleOutline, mdiLoading } from '@mdi/js';
import useMeasure from '../../Hooks/useMeasure';
import { IRecordsFeedGridContainerProps } from './interfaces';

import { Box, IconButton, Paper, InputBase, Button } from '@material-ui/core';
import { Magnify, Plus } from 'mdi-material-ui';

const { Provider } = context;

function useInfiniteScroll() {
	const ctx = useContext(context);
	const { columnCount, recordIds, onEndReach } = ctx;
	const [endReached, setEndReached] = useState<boolean>(true);
	const detectEndReached = useCallback(
		({ visibleRowStopIndex }: GridOnItemsRenderedProps) => {
			if (
				visibleRowStopIndex === Math.floor(recordIds.size / columnCount)
			)
				setEndReached(true);
			else setEndReached(false);
		},
		[setEndReached, columnCount, recordIds.size]
	);

	useEffect(() => {
		if (endReached) onEndReach();
	}, [endReached, onEndReach]);

	return {
		detectEndReached,
	};
}

const RecordsFeedGrid = function NotificationList() {
	const ctx = useContext(context);
	const {
		width,
		height,
		columnCount,
		containerRef,
		recordIds,
		loading,
		children,
		itemHeight,
		searchPlaceholder,
		search,
		addButtonText,
		onAdd,
		onSearchTextChange,
	} = ctx;
	const { detectEndReached } = useInfiniteScroll();

	const ids = useImmutableMemo(() => recordIds.toArray(), [recordIds]);

	const getItemKey = useCallback(
		({
			rowIndex,
			columnIndex,
		}: {
			columnIndex: number;
			rowIndex: number;
		}) =>
			ids[rowIndex * columnCount + columnIndex] ||
			`padding-${rowIndex * columnCount + columnIndex}`,
		[ids, columnCount]
	);
	const count = recordIds.size;

	const handleSearchTextChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			onSearchTextChange(value);
		},
		[onSearchTextChange]
	);

	const searchBarStyles = useSearchBarStyles();

	return useMemo(() => {
		return (
			<RecordsFeedGridWrapper ref={containerRef}>
				<FixedSizeGrid
					style={{ marginTop: 64, marginLeft: 12 }}
					outerElementType={Scrollbars}
					columnCount={columnCount}
					columnWidth={width / columnCount}
					rowCount={Math.ceil(count / columnCount)}
					rowHeight={itemHeight}
					height={height - 64}
					width={width}
					itemKey={getItemKey}
					onItemsRendered={detectEndReached}
				>
					{children}
				</FixedSizeGrid>
				{loading ? (
					<LoadingIndicatorWrapper>
						<LoadingIndicatorLowerIcon path={mdiCircleOutline} />
						<LoadingIndicatorIcon path={mdiLoading} spin />
					</LoadingIndicatorWrapper>
				) : null}
				{recordIds.size === 0 ? (
					<NoRecordsWrapper>
						<NoRecordsText>NO RECORDS</NoRecordsText>
					</NoRecordsWrapper>
				) : null}
				<Box
					position="absolute"
					height={64}
					width="100%"
					paddingX={2}
					paddingY={1}
					boxSizing="border-box"
					flexDirection="row"
					display="flex"
				>
					<Paper component="form" className={searchBarStyles.root}>
						<InputBase
							value={search}
							className={searchBarStyles.input}
							placeholder={searchPlaceholder}
							onChange={handleSearchTextChange}
						/>
						<IconButton
							disabled
							className={searchBarStyles.iconButton}
						>
							<Magnify />
						</IconButton>
					</Paper>
					{onAdd ? (
						<Button
							startIcon={<Plus />}
							variant="contained"
							color="primary"
							className={searchBarStyles.addButton}
							onClick={onAdd}
						>
							{addButtonText}
						</Button>
					) : null}
				</Box>
			</RecordsFeedGridWrapper>
		);
	}, [
		containerRef,
		recordIds.size,
		loading,
		detectEndReached,
		count,
		getItemKey,
		children,
		height,
		width,
		itemHeight,
		searchPlaceholder,
		handleSearchTextChange,
		search,
		columnCount,
		searchBarStyles,
		addButtonText,
		onAdd,
	]);
};

export default function RecordsFeedGridContainer(
	props: IRecordsFeedGridContainerProps
) {
	const { xsCount, smCount, mdCount, lgCount, xlCount, ...rest } = props;
	const containerRef = useRef<HTMLDivElement>(null);
	const { width, height } = useMeasure(containerRef);
	const columnCount =
		width <= 600
			? xsCount
			: width <= 960
			? smCount
			: width <= 1280
			? mdCount
			: width <= 1920
			? lgCount
			: xlCount;

	return (
		<Provider
			value={{
				width,
				height,
				columnCount,
				containerRef,
				...rest,
			}}
		>
			<RecordsFeedGrid />
		</Provider>
	);
}

import numeral from 'numeral';
import {
	format as dfnsFormat,
	parseISO as dfnsParseISO,
	isValid as dfnsIsValid,
} from 'date-fns';
import { RecordOf } from 'immutable';
import { DataType, ArrayType, ObjectType, IFormat } from '../interfaces';
import { ITableContext } from '../context';

export function getRecordId(contextObj: ITableContext, rowIndex: number) {
	const { recordIds } = contextObj;
	return recordIds.get(rowIndex, '');
}

export function getRecord(
	contextObj: ITableContext,
	recordId: string,
	columnKey: string
) {
	const { records } = contextObj;
	return records.getIn([recordId, columnKey]);
}

export function getUnmergedRecord(
	contextObj: ITableContext,
	recordId: string,
	columnKey: string
) {
	const { unmergedRecords } = contextObj;
	return unmergedRecords.getIn([recordId, columnKey]);
}

export function stringifyRecord(value: any): string {
	try {
		if (value === undefined) return '';
		if (typeof value === 'string') return value;
		return JSON.stringify(value);
	} catch {
		return '';
	}
}

export class FormatRecordError extends Error {
	details: {
		str: string;
		dataType: DataType;
		format: RecordOf<IFormat>;
	};

	constructor(str: string, dataType: DataType, format: RecordOf<IFormat>) {
		super('Format Record Error');
		this.details = {
			str,
			dataType,
			format,
		};
	}
}

function formatString(value: string): string {
	return value;
}
function formatNumber(value: number, format: RecordOf<IFormat>): string {
	try {
		const { numberFormat, unit } = format;
		return `${numeral(value).format(numberFormat)} ${unit}`;
	} catch (e) {
		return '';
	}
}
function formatDate(value: string, format: RecordOf<IFormat>): string {
	try {
		const { dateFormat } = format;
		return dfnsFormat(dfnsParseISO(value.trim()), dateFormat);
	} catch (e) {
		return '';
	}
}
function formatBoolean(value: boolean, format: RecordOf<IFormat>): string {
	const { booleanFormat } = format;
	const trueFalseStrs = booleanFormat.split('/');
	if (trueFalseStrs.length === 2) {
		const [trueStr, falseStr] = trueFalseStrs;
		return value ? trueStr : falseStr;
	}
	return '';
}
function formatArray(
	value: any[],
	dataType: ArrayType,
	format: RecordOf<IFormat>
) {
	const elemsStr = value
		.map((v) => _formatRecord(v, dataType.items, format))
		.join(', ');
	return `[${elemsStr}]`;
}
function formatObject(
	value: any,
	dataType: ObjectType,
	format: RecordOf<IFormat>
) {
	const keys = Object.keys(value);
	const entriesStr = keys.reduce((s, k, i) => {
		const v = value[k];
		const vDataType = dataType.properties[k];
		const entryStr = `${k}: ${_formatRecord(v, vDataType, format)}`;
		if (i === 0) return entryStr;
		return `${s}, ${entryStr}`;
	}, '');
	return `{${entriesStr}}`;
}

function _formatRecord(
	value: any,
	dataType: DataType,
	format: RecordOf<IFormat>
): string {
	switch (dataType.type) {
		case 'string': {
			return formatString(value);
		}
		case 'number': {
			return formatNumber(value, format);
		}
		case 'date': {
			return formatDate(value, format);
		}
		case 'boolean': {
			return formatBoolean(value, format);
		}
		case 'array': {
			return formatArray(value, dataType, format);
		}
		case 'object': {
			return formatObject(value, dataType, format);
		}
		default:
			return '';
	}
}
export function formatRecord(
	str: string,
	dataType: DataType,
	format: RecordOf<IFormat>
): string {
	try {
		if (str === 'null' || str === '') return str;
		const value =
			dataType.type === 'string' || dataType.type === 'date'
				? str
				: JSON.parse(str);
		return _formatRecord(value, dataType, format);
	} catch (e) {
		throw new FormatRecordError(str, dataType, format);
	}
}

export class ParseRecordError extends Error {
	details: {
		str: string;
		dataType: DataType;
	};

	constructor(str: string, dataType: DataType) {
		super('Parse Record Error');
		this.details = { str, dataType };
	}
}

function parseString(str: string): string {
	return str;
}
function parseNumber(str: string): number | null | undefined {
	const _str = str.trim();
	if (_str === '') return undefined;
	const n = numeral(_str).value();
	return n !== null && !isNaN(n) ? n : undefined;
}
function parseDate(str: string): string | null | undefined {
	try {
		const parsed = dfnsParseISO(str.trim());
		if (dfnsIsValid(parsed)) return parsed.toISOString();
		return undefined;
	} catch (e) {
		return undefined;
	}
}
function parseBoolean(str: string): boolean | null | undefined {
	const _str = str.trim().toLowerCase();
	return _str === 'true' ? true : _str === 'false' ? false : undefined;
}
function parseArray(str: string): any[] {
	let _str = str.trim();
	if (!_str.startsWith('[')) _str = '[' + _str;
	if (!_str.endsWith(']')) _str = _str + ']';
	return JSON.parse(_str);
}
function parseObject(str: string): any {
	if (str === '') return undefined;
	return JSON.parse(str);
}
export function parseRecord(str: string, dataType: DataType): any {
	try {
		if (str === 'null') return null;
		switch (dataType.type) {
			case 'string': {
				return parseString(str);
			}
			case 'number': {
				return parseNumber(str);
			}
			case 'date': {
				return parseDate(str);
			}
			case 'boolean': {
				return parseBoolean(str);
			}
			case 'array': {
				return parseArray(str);
			}
			case 'object': {
				return parseObject(str);
			}
			default:
				return JSON.parse(str);
		}
	} catch (e) {
		throw new ParseRecordError(str, dataType);
	}
}

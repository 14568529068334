import { List, Record, Map, OrderedSet } from 'immutable';
import createReducer from '../../Helpers/createReducer';
import {
	IRecipient,
	IFile,
	IMessageRecord,
	INotificationStore,
	IMessage,
} from './interfaces';
import {
	receivedNotification,
	fetchNotificationsSuccess,
	fetchNotifications,
} from './actions';

export const Recipient = Record<IRecipient>({
	channel: 'dashboard',
	target: '',
});

export const File = Record<IFile>({
	contentType: '',
	filename: '',
	path: '',
	ready: true,
	url: '',
});

export const Message = Record<IMessageRecord>({
	_id: '',
	content: '',
	delivered: false,
	recipient: Recipient(),
	title: '',
	trial: 0,
	attachments: List(),
	createdAt: new Date(),
});

export const NotificationStore = Record<INotificationStore>({
	loading: false,
	exhausted: false,
	notifications: Map(),
	notificationIds: OrderedSet(),
});

const initialState = NotificationStore();

function createNotificationRecord(notification: IMessage) {
	const { recipient, attachments = [], createdAt, ...rest } = notification;
	return Message({
		recipient: Recipient(recipient),
		attachments: List(attachments.map((attachment) => File(attachment))),
		createdAt: new Date(createdAt),
		...rest,
	});
}

export const notificationReducer = createReducer(
	initialState,
	{
		fetchNotifications,
		fetchNotificationsSuccess,
		receivedNotification,
	},
	{
		fetchNotifications: (state) => state.set('loading', true),
		fetchNotificationsSuccess: (state, { payload: { notifications } }) => {
			return state.withMutations((s) => {
				if (notifications.length === 0) {
					s.set('exhausted', true);
				} else {
					notifications.forEach((notification) => {
						s.setIn(
							['notifications', notification._id],
							createNotificationRecord(notification)
						);
						s.update('notificationIds', (notificationIds) =>
							notificationIds.add(notification._id)
						);
					});
					s.update('notificationIds', (notificationIds) =>
						notificationIds.sortBy(
							(notificationId) =>
								-s.notifications.get(notificationId, Message())
									.createdAt
						)
					);
				}
				s.set('loading', false);
			});
		},
		receivedNotification: (state, { payload: { notification } }) =>
			state.withMutations((s) => {
				s.setIn(
					['notifications', notification._id],
					createNotificationRecord(notification)
				);
				s.update('notificationIds', (notificationIds) =>
					notificationIds
						.add(notification._id)
						.sortBy(
							(notificationId) =>
								-s.notifications.get(notificationId, Message())
									.createdAt
						)
				);
			}),
	}
);

export default notificationReducer;

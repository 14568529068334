import { call } from 'redux-saga/effects';

export function getSessionId() {
	return call([localStorage, localStorage.getItem], 'sessionId');
}

export function setSessionId(sessionId: string) {
	return call([localStorage, localStorage.setItem], 'sessionId', sessionId);
}

export function getSession(sessionId: string) {
	return call([localStorage, localStorage.getItem], `session:${sessionId}`);
}

export function setSession(sessionId: string, value: string | Object) {
	return call(
		[localStorage, localStorage.setItem],
		`session:${sessionId}`,
		typeof value === 'string' ? value : JSON.stringify(value)
	);
}

export function removeSession(sessionId: string) {
	return call(
		[localStorage, localStorage.removeItem],
		`session:${sessionId}`
	);
}

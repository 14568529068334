import styled from 'styled-components';
import { View } from '../../../../Common/View';
import Scrollbars from '../../../../Common/Scrollbars';
import { HeadingText6, CaptionText } from '../../../../Common/Text';
import Button from '../../../../Common/Button';
import IconTextButton from '../../../../Common/IconTextButton';
import { ButtonOverlayShape } from '../../../../Common/Button/interfaces';
import Icon from '@mdi/react';

export const ChartWrapper = styled(View)`
	flex: 1 1 0;
	margin: 8px;
	border-radius: 8px;
	background-color: ${({
		theme: {
			background: { frame },
		},
	}) => frame};
	${({ theme: { shadow } }) => shadow[2]}
`;

export const ChartScrollWrapper = styled(Scrollbars)`
	flex: 1 0 200px;
`;

export const ChartHeaderWrapper = styled(View)`
	padding: 8px 12px 0px 12px;
	flex: 0 0 0;
	align-items: center;
	flex-direction: row;
`;

export const ChartBodyWrapper = styled(View)`
	flex: 1 0 0;
	padding: 0px 8px 8px 8px;
`;

export const ChartTitleTextWrapper = styled(View)`
	flex: 1;
`;

export const ChartTitleText = styled(HeadingText6)`
	flex: 1;
	color: ${({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => darkBgPrimary};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const ChartNoDataWrapper = styled(View)`
	align-self: stretch;
	height: 250px;
	align-items: center;
	justify-content: center;
`;

export const ChartNoDataText = styled(CaptionText)`
	color: ${({
		theme: {
			font: {
				color: { darkBgDisabled },
			},
		},
	}) => darkBgDisabled};
`;

export const ChartIcon = styled(IconTextButton).attrs({
	overlayShape: ButtonOverlayShape.none,
	lightBg: false,
	iconProps: {
		size: 1,
	},
})`
	cursor: unset;
`;

export const ChartLegendToggleButton = styled(Button).attrs({
	overlayShape: ButtonOverlayShape.circle,
	lightBg: false,
})`
	width: 36px;
	height: 36px;
	flex: 0 0 36px;
	align-items: center;
	justify-content: center;
`;

export const ChartLegendToggleIcon = styled(Icon).attrs(
	({
		theme: {
			font: {
				color: { darkBgSecondary },
			},
		},
	}) => ({
		size: 0.7,
		color: darkBgSecondary,
	})
)``;

import React, {
	useContext,
	useMemo,
	useCallback,
	useRef,
	RefObject,
} from 'react';
import { createPortal } from 'react-dom';
import {
	HeaderCellResizeHandle as SHeaderCellResizeHandle,
	HeaderCellResizeHandleShadow as SHeaderCellResizeHandleShadow,
} from './HeaderCellResizeHandle.sc';
import useColumnResize from '../Hooks/useColumnResize';
import context from '../../../context';

interface IndexCellResizeHandleShadowProps {
	tableRef: RefObject<HTMLDivElement>;
	resizing: boolean;
	left: number;
}
function IndexCellResizeHandleShadow(props: IndexCellResizeHandleShadowProps) {
	const { tableRef, resizing, left } = props;
	return useMemo(() => {
		const table = tableRef.current;
		if (!resizing || !table) return null;
		return createPortal(
			<SHeaderCellResizeHandleShadow style={{ left }} />,
			table
		);
	}, [tableRef, resizing, left]);
}

interface HeaderCellResizeHandleProps {
	headerCellRef: RefObject<HTMLDivElement>;
	resizing: boolean;
	columnKey: string;
}
function HeaderCellResizeHandle(props: HeaderCellResizeHandleProps) {
	const { headerCellRef, resizing, columnKey } = props;
	const contextObj = useContext(context);
	const { tableRef } = contextObj;
	const { width, startColumnResize } = useColumnResize(contextObj, columnKey);
	const headerCellLeftRef = useRef(0);

	const handleMouseDown = useCallback(() => {
		const table = tableRef.current;
		const headerCell = headerCellRef.current;
		if (table && headerCell)
			headerCellLeftRef.current =
				headerCell.getBoundingClientRect().left -
				table.getBoundingClientRect().left;
		startColumnResize();
	}, [tableRef, headerCellRef, startColumnResize]);

	return useMemo(() => {
		const left = headerCellLeftRef.current + width;
		return (
			<>
				<SHeaderCellResizeHandle onMouseDown={handleMouseDown} />
				<IndexCellResizeHandleShadow
					tableRef={tableRef}
					resizing={resizing}
					left={left}
				/>
			</>
		);
	}, [tableRef, resizing, width, handleMouseDown]);
}

export default HeaderCellResizeHandle;

import React, {
	useContext,
	useMemo,
	useEffect,
	useRef,
	RefObject,
	CSSProperties,
} from 'react';
import { VariableSizeGrid } from 'react-window';
import {
	BodySelectionBox as SBodySelectionBox,
	BodySelectionCell,
} from './BodySelectionBox.sc';
import { Selected } from '../../TableProvider';
import useObject from '../../../../../Hooks/useObject';
import useHotKeys from '../../../../../Hooks/useHotKeys';
import orderSelected from '../Helpers/orderSelected';
import useContextWithRef from '../../../../../Hooks/useContextWithRef';
import useSelectedCellsCopy from '../Hooks/useSelectedCellsCopy';
import useSelectedCellsPaste from '../Hooks/useSelectedCellsPaste';
import useSelectedUnmergedRecordsDrop from '../Hooks/useSelectedUnmergedRecordsDrop';
import useCellSelectKeyBindMap from '../Hooks/useCellSelectKeyBindMap';
import context from '../../../context';

function getCellStyle(
	rowIndex: number,
	columnIndex: number,
	bodyGrid: VariableSizeGrid | null
) {
	if (!(bodyGrid instanceof VariableSizeGrid)) return null;
	return (bodyGrid as any)._getItemStyle(rowIndex, columnIndex);
}

function getSelectionBoxStyle(
	selected: NonNullable<Selected>,
	bodyGridRef: RefObject<VariableSizeGrid>
): CSSProperties {
	const [tl, br] = orderSelected(selected);
	const bodyGrid = bodyGridRef.current;
	const tlCellStyle = getCellStyle(tl[0], tl[1], bodyGrid);
	const brCellStyle = getCellStyle(br[0], br[1], bodyGrid);
	if (!tlCellStyle || !brCellStyle) return {};
	const { left, top } = tlCellStyle;
	const { left: brLeft, top: brTop, width, height } = brCellStyle;
	return {
		left,
		top,
		width: brLeft + width - left,
		height: brTop + height - top,
	};
}

function getSelectionCellStyle(
	selected: NonNullable<Selected>,
	bodyGridRef: RefObject<VariableSizeGrid>
): CSSProperties {
	const [rowIndex, columnIndex] = selected.start;
	const bodyGrid = bodyGridRef.current;
	const selectionCellStyle = getCellStyle(rowIndex, columnIndex, bodyGrid);
	if (!selectionCellStyle) return {};
	const { left, top, width, height } = selectionCellStyle;
	return { left, top, width, height };
}

interface useBodySelectionBoxHotKeysOptions {
	selected: Selected;
	ref: RefObject<HTMLDivElement>;
}

function useBodySelectionBoxHotKeys(
	options: useBodySelectionBoxHotKeysOptions
) {
	const { selected, ref } = options;
	const { contextObjRef } = useContextWithRef(context);
	const copy = useSelectedCellsCopy(contextObjRef);
	const paste = useSelectedCellsPaste(contextObjRef);
	const dropUnmerged = useSelectedUnmergedRecordsDrop(contextObjRef);
	const keySelect = useCellSelectKeyBindMap(contextObjRef);
	const keyBindMap = useMemo(
		() => ({
			'ctrl+c': copy,
			'ctrl+v': paste,
			backspace: dropUnmerged,
			del: dropUnmerged,
			...keySelect,
		}),
		[copy, paste, dropUnmerged, keySelect]
	);
	useHotKeys(keyBindMap, { ref, disabled: !selected, preventDefault: true });
}

const BodySelectionBox = function() {
	const { bodyGridRef, selected, setSelectionBoxRef } = useContext(context);
	const _selected = useObject(selected);
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setSelectionBoxRef(ref);
	}, [setSelectionBoxRef]);

	useBodySelectionBoxHotKeys({ ref, selected: _selected });

	return useMemo(() => {
		// console.log('render BodySelectionBox');
		if (!_selected) return null;
		const boxStyle = getSelectionBoxStyle(_selected, bodyGridRef);
		const cellStyle = getSelectionCellStyle(_selected, bodyGridRef);
		return (
			<>
				<SBodySelectionBox tabIndex={0} ref={ref} style={boxStyle} />
				<BodySelectionCell style={cellStyle} />
			</>
		);
	}, [_selected, bodyGridRef]);
};

export default BodySelectionBox;

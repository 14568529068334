import styled, { css } from 'styled-components';
import Button from '../../../../Common/Button';
import Icon from '@mdi/react';
import { ButtonText } from '../../../../Common/Text';
import { WindowHeaderButtonProps } from '../interfaces';

const BorderedStyle = css`
	border: 1px solid
		${({
			theme: {
				background: { darken },
			},
		}) => darken[4]};

	border-radius: 3px;
	background-color: ${({
		theme: {
			background: { frameHighlight },
		},
	}) => frameHighlight};
`;

export const HeaderButtonWrapper = styled(Button)<WindowHeaderButtonProps>`
	flex: 0 0 28px;
	height: 28px;
	width: 28px;
	align-items: center;
	justify-content: center;
	margin-left: 4px;
	margin-right: 4px;

	${({ bordered }) => (bordered ? BorderedStyle : '')}
`;

export const HeaderButtonIcon = styled(Icon).attrs(
	({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => ({ color: darkBgPrimary, size: 0.7 })
)``;

export const HeaderButtonText = styled(ButtonText)``;

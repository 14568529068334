import React, { useMemo, useCallback } from 'react';
import MapView, {
	DragRotate,
	Select,
	VectorLayer,
	Feature,
} from '../../../../MapView';
import * as proj from 'ol/proj';
import { altKeyOnly, click } from 'ol/events/condition';
import districtGeoJSON from '../../../../../Assets/Hong_Kong_18_Districts.json';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import theme from '../../../../../../theme';
import Stroke from 'ol/style/Stroke';
import Text from 'ol/style/Text';
import OlFeature from 'ol/Feature';
import noop from '../../../../../Helpers/noop';

interface DistrictFeatureProps {
	OBJECTID: number;
	TCNAME: string;
	ENAME: string;
	Shape__Are: number;
	Shape__Len: number;
}
const _districtGeoJSON = districtGeoJSON as GeoJSON.FeatureCollection<
	GeoJSON.Polygon,
	DistrictFeatureProps
>;

const dragRotateOptions = {
	condition: altKeyOnly,
};

const selectOptions = {
	condition: click,
};

const centerOfHK = [12707975.92093638, 2545840.017349782];
const zoom = 11.5;
const viewOptions = { projection: proj.get('EPSG:3857') };

function selectColor(colorNum: number, colors: number, alpha: number) {
	return `hsla(${((colorNum + 1) * (360 / colors)) %
		360}, 100%, 40%, ${alpha})`;
}

const distictFeatureStyle = (feature: any, i: number) =>
	new Style({
		fill: new Fill({ color: selectColor(i, 18, 0.5) }),
		stroke: new Stroke({ width: 2, color: selectColor(i, 18, 1) }),
		text: new Text({
			scale: 1.5,
			fill: new Fill({ color: selectColor(i, 18, 1) }),
			stroke: new Stroke({
				color: theme.font.color.darkBgPrimary,
				width: 4,
			}),
			text: feature.get('ENAME'),
		}),
	});

const selectedDistictFeatureStyle = (feature: any, i: number) =>
	new Style({
		fill: new Fill({ color: selectColor(i, 18, 0.5) }),
		stroke: new Stroke({ width: 4, color: selectColor(i, 18, 1) }),
		text: new Text({
			scale: 1.8,
			fill: new Fill({ color: selectColor(i, 18, 0.5) }),
			stroke: new Stroke({
				color: theme.font.color.darkBgPrimary,
				width: 4,
			}),
			text: feature.get('ENAME'),
		}),
	});

const vectorLayerOptions = { zIndex: 2 };

interface Props {
	onSelectDistrict?: (feature: OlFeature | null) => any;
	selectedDistrict: string | null;
}

export default function DistrictMap(props: Props) {
	const { onSelectDistrict = noop, selectedDistrict } = props;

	const handleDistrictSelect = useCallback(
		(features: OlFeature[]) => {
			if (features.length > 0) {
				onSelectDistrict(features[0]);
			} else {
				onSelectDistrict(null);
			}
		},
		[onSelectDistrict]
	);

	return useMemo(() => {
		return (
			<MapView
				center={centerOfHK}
				zoom={zoom}
				// maxZoom={zoom}
				// minZoom={zoom}
				rotation={0}
				viewOptions={viewOptions}
			>
				<VectorLayer layerOptions={vectorLayerOptions}>
					{_districtGeoJSON.features.map((feature, i) => {
						const {
							properties: { ENAME },
						} = feature;
						return (
							<Feature
								key={ENAME}
								feature={feature}
								style={
									selectedDistrict === ENAME
										? (feature: any) =>
												selectedDistictFeatureStyle(
													feature,
													i
												)
										: (feature: any) =>
												distictFeatureStyle(feature, i)
								}
							/>
						);
					})}
				</VectorLayer>
				<Select
					selectOptions={selectOptions}
					onSelect={handleDistrictSelect}
				/>
				<DragRotate dragRotateOptions={dragRotateOptions} />
			</MapView>
		);
	}, [handleDistrictSelect, selectedDistrict]);
}

import styled from 'styled-components';

export const ContentText = styled.div``;

export const UIText = styled.span`
	position: relative;
`;

export const HeadingText1 = styled(ContentText)`
	letter-spacing: -1.5px;
	font-size: ${({
		theme: {
			font: { size },
		},
	}) => size.base * 96}rem;
	font-weight: ${({
		theme: {
			font: { weight },
		},
	}) => weight.light};
`;

export const HeadingText2 = styled(ContentText)`
	letter-spacing: -0.5px;
	font-size: ${({
		theme: {
			font: { size },
		},
	}) => size.base * 60}rem;
	font-weight: ${({
		theme: {
			font: { weight },
		},
	}) => weight.light};
`;

export const HeadingText3 = styled(ContentText)`
	letter-spacing: 0;
	font-size: ${({
		theme: {
			font: { size },
		},
	}) => size.base * 48}rem;
	font-weight: ${({
		theme: {
			font: { weight },
		},
	}) => weight.regular};
`;

export const HeadingText4 = styled(ContentText)`
	letter-spacing: 0.25px;
	font-size: ${({
		theme: {
			font: { size },
		},
	}) => size.base * 34}rem;
	font-weight: ${({
		theme: {
			font: { weight },
		},
	}) => weight.regular};
`;

export const HeadingText5 = styled(ContentText)`
	letter-spacing: 0px;
	font-size: ${({
		theme: {
			font: { size },
		},
	}) => size.base * 24}rem;
	font-weight: ${({
		theme: {
			font: { weight },
		},
	}) => weight.regular};
`;

export const HeadingText6 = styled(ContentText)`
	letter-spacing: 0.15px;
	font-size: ${({
		theme: {
			font: { size },
		},
	}) => size.base * 20}rem;
	font-weight: ${({
		theme: {
			font: { weight },
		},
	}) => weight.medium};
`;

export const TitleText = styled(ContentText)`
	letter-spacing: 0px;
	font-size: ${({
		theme: {
			font: { size },
		},
	}) => size.base * 16}rem;
	font-weight: ${({
		theme: {
			font: { weight },
		},
	}) => weight.medium};
`;

export const SubtitleText1 = styled(UIText)`
	letter-spacing: 0.15px;
	font-size: ${({
		theme: {
			font: { size },
		},
	}) => size.base * 16}rem;
	font-weight: ${({
		theme: {
			font: { weight },
		},
	}) => weight.regular};
`;

export const SubtitleText2 = styled(UIText)`
	letter-spacing: 0.1px;
	font-size: ${({
		theme: {
			font: { size },
		},
	}) => size.base * 14}rem;
	font-weight: ${({
		theme: {
			font: { weight },
		},
	}) => weight.medium};
`;

export const BodyText1 = styled(ContentText)`
	letter-spacing: 0.5px;
	font-size: ${({
		theme: {
			font: { size },
		},
	}) => size.base * 16}rem;
	font-weight: ${({
		theme: {
			font: { weight },
		},
	}) => weight.regular};
`;

export const BodyText2 = styled(ContentText)`
	letter-spacing: 0.25px;
	font-size: ${({
		theme: {
			font: { size },
		},
	}) => size.base * 14}rem;
	font-weight: ${({
		theme: {
			font: { weight },
		},
	}) => weight.regular};
`;

export const ButtonText = styled(UIText)`
	letter-spacing: 1.25px;
	font-size: ${({
		theme: {
			font: { size },
		},
	}) => size.base * 14}rem;
	font-weight: ${({
		theme: {
			font: { weight },
		},
	}) => weight.medium};
	text-transform: uppercase;
`;

export const CaptionText = styled(UIText)`
	letter-spacing: 0.4px;
	font-size: ${({
		theme: {
			font: { size },
		},
	}) => size.base * 12}rem;
	font-weight: ${({
		theme: {
			font: { weight },
		},
	}) => weight.regular};
`;

export const OverlineText = styled(UIText)`
	letter-spacing: 1.5px;
	font-size: ${({
		theme: {
			font: { size },
		},
	}) => size.base * 10}rem;
	font-weight: ${({
		theme: {
			font: { weight },
		},
	}) => weight.regular};
	text-transform: uppercase;
`;

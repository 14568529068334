import styled from 'styled-components';
import Button from '../../../../Common/Button';
import { ButtonOverlayShape } from '../../../../Common/Button/interfaces';
import { View } from '../../../../Common/View';
import { USER_ICON_SIZE } from '../constants';
import stringToHSL from '../../../../../Helpers/stringToHSL';
import { HeadingText5, ButtonText } from '../../../../Common/Text';

export const UserPanelButtonWrapper = styled(Button).attrs({
	overlayShape: ButtonOverlayShape.rectangle,
	lightBg: false,
})`
	align-self: stretch;
	align-items: center;
	flex-direction: row;
	padding-left: 12px;
	padding-right: 12px;
	box-sizing: border-box;
`;

export interface UserIconWrapperProps {
	username: string;
}

export const UserIconWrapper = styled(View)<UserIconWrapperProps>`
	width: ${USER_ICON_SIZE}px;
	height: ${USER_ICON_SIZE}px;
	border-radius: 50%;
	background-color: ${({ username }) => stringToHSL(username, 90, 90)};
	margin-right: 8px;
	align-items: center;
	justify-content: center;
`;

export const UserIconText = styled(HeadingText5)`
	color: ${({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => darkBgPrimary};
`;

export const UserNameText = styled(ButtonText)`
	color: ${({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => darkBgPrimary};
`;

import styled from 'styled-components';
import Button from '../../../../../Components/Common/Button';
import { ButtonOverlayShape } from '../../../../../Components/Common/Button/interfaces';
import Icon from '@mdi/react';
import { View } from '../../../../../Components/Common/View';

export const SensorControlWrapper = styled(View)`
	flex: 1;
	align-self: stretch;
`;

export const AddRecordButton = styled(Button).attrs({
	overlayShape: ButtonOverlayShape.circle,
	// light: true,
})`
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
`;
export const AddRecordButtonIcon = styled(Icon).attrs(
	({
		theme: {
			font: {
				color: { lightBgSecondary },
			},
		},
	}) => ({ color: lightBgSecondary, size: 1 })
)``;

export const EditFormWrapper = styled(View)`
	width: 400px;
	height: 350px;
	transform: translate3d(-50%, -50%, 0);
	border-radius: 4px;
	overflow: hidden;
	${({ theme: { shadow } }) => shadow[3]}
`;

import { useMemo } from 'react';
import { is, Set } from 'immutable';
import { useImmutable } from '../../../../../Hooks/useImmutable';
import { SortOrder } from '../../../interfaces';
import { ITableContext } from '../../../context';

export function useSortInfo(contextObj: ITableContext, columnKey: string) {
	const { sorts } = contextObj;
	const _sorts = useImmutable(sorts);
	return useMemo(() => {
		const multiSorted = _sorts.size > 1;
		const priority = _sorts.findIndex((v) =>
			is(Set(v.keys()), Set([columnKey]))
		);
		const order: SortOrder =
			priority !== -1 ? _sorts.getIn([priority, columnKey], 0) : 0;
		return { multiSorted, priority, order };
	}, [_sorts, columnKey]);
}

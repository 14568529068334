import React, {
	useMemo,
	useCallback,
	useContext,
	FocusEvent as RFocusEvent,
	ChangeEvent as RChangeEvent,
} from 'react';

import {
	HeaderCellTitleInputWrapper,
	HeaderCellTitleInput as SHeaderCellTitleInput,
} from './HeaderCellTitleInput.sc';
import { useColumnRename, useColumn } from '../../../Hooks/Column';
import useAsState from '../../../../../Hooks/useAsState';
import context from '../../../context';

interface HeaderCellTitleInputProps {
	columnKey: string;
}
function HeaderCellTitleInput(props: HeaderCellTitleInputProps) {
	const { columnKey } = props;
	const contextObj = useContext(context);
	const { name } = useColumn(contextObj, columnKey);
	const [_name, setName] = useAsState(name);
	const renameColumn = useColumnRename(contextObj, columnKey);

	const handleInputChange = useCallback(
		(e: RChangeEvent<HTMLInputElement>) => {
			setName(e.target.value);
		},
		[setName]
	);

	const handleInputBlur = useCallback(
		(e: RFocusEvent<HTMLInputElement>) => {
			const newName = e.target.value;
			if (newName !== name) renameColumn(e.target.value);
		},
		[renameColumn, name]
	);
	return useMemo(
		() => (
			<HeaderCellTitleInputWrapper>
				<SHeaderCellTitleInput
					underline
					tabIndex={0}
					value={_name}
					onChange={handleInputChange}
					onBlur={handleInputBlur}
				/>
			</HeaderCellTitleInputWrapper>
		),
		[_name, handleInputChange, handleInputBlur]
	);
}

export default HeaderCellTitleInput;

import React, { useMemo, useCallback, useState, useEffect } from 'react';
import workspaceContext from '../context';
import { WorkspaceProps } from '../interfaces';

import { WorkspaceWrapper } from './Workspace.sc';
import Dock from '../../Dock';
import { ResponsiveDesktop } from '../../../../Layouts';
import Activities from '../../Activities/Components/Activities';
import { Activity } from '../../../interfaces';
import Home from '../../Home';
import noop from '../../../../../Helpers/noop';
import { parse } from 'query-string';
import { useLocation } from 'react-router';

const { Provider } = workspaceContext;

export default function Workspace(props: WorkspaceProps) {
	const {
		applications,
		activities,
		onWindowChange,
		onWindowFocus,
		windowIndice,
		windows,
		updateTitle,
		closeActivity: closeApplication,
		startApplication,
		onWindowMoveStart,
		onWindowMoveEnd,
		onWindowResizeStart,
		onWindowResizeEnd,
		onRequestFetchOverview = noop,
		overview,
	} = props;

	const { search } = useLocation();

	const [showPreview, setShowPreview] = useState(false);
	const [showHomePage, setShowHomePage] = useState(true);

	useEffect(() => {
		const startupApplication = parse(search).startup_application as
			| string
			| undefined;

		console.log(startupApplication);
		if (typeof startupApplication === 'string') {
			startApplication &&
				startApplication({
					key: startupApplication,
					name: startupApplication,
				});
			setShowHomePage(false);
		}
	}, [search, startApplication]);

	const handleToggleActivities = useCallback(() => {
		setShowPreview((v) => !v);
	}, [setShowPreview]);

	const handleSwitchActivity = useCallback(
		(activity: Activity) => {
			onWindowFocus && onWindowFocus(activity.id);
			const { minimized, originalLeft, left } = windows.get(activity.id, {
				minimized: false,
				originalLeft: 0,
				left: 0,
			});
			minimized &&
				onWindowChange &&
				onWindowChange(activity.id, {
					minimized: false,
					left:
						typeof originalLeft === 'number' ? originalLeft : left,
				});
		},
		[onWindowFocus, onWindowChange, windows]
	);

	const handleWindowMoveStart = useCallback(
		(windowId: string) => {
			onWindowMoveStart && onWindowMoveStart(windowId);
		},
		[onWindowMoveStart]
	);

	const handleWindowMoveEnd = useCallback(
		(windowId: string) => {
			onWindowMoveEnd && onWindowMoveEnd(windowId);
		},
		[onWindowMoveEnd]
	);

	const handleWindowResizeStart = useCallback(
		(windowId: string) => {
			onWindowResizeStart && onWindowResizeStart(windowId);
		},
		[onWindowResizeStart]
	);

	const handleWindowResizeEnd = useCallback(
		(windowId: string) => {
			onWindowResizeEnd && onWindowResizeEnd(windowId);
		},
		[onWindowResizeEnd]
	);

	return useMemo(() => {
		return (
			<Provider
				value={{
					showPreview,
					applications,
					activities,
					updateTitle,
					startApplication,
					closeApplication,
					switchActivity: handleSwitchActivity,
					toggleActivities: handleToggleActivities,
					toggleHomePage: setShowHomePage,
				}}
			>
				<WorkspaceWrapper>
					<Dock applications={applications} activities={activities} />
					<ResponsiveDesktop
						windows={windows}
						windowIndice={windowIndice}
						onWindowMoveStart={handleWindowMoveStart}
						onWindowMoveEnd={handleWindowMoveEnd}
						onWindowResizeStart={handleWindowResizeStart}
						onWindowResizeEnd={handleWindowResizeEnd}
						onWindowChange={onWindowChange}
						onWindowFocus={onWindowFocus}
					>
						{showHomePage ? (
							<Home
								{...overview}
								onRequestFetchOverview={onRequestFetchOverview}
							/>
						) : (
							<Activities show={showPreview}>
								{activities
									.map((activity) => {
										const { key, id } = activity;
										const application = applications.get(
											key
										);
										if (!application) return null;
										const {
											component: Component,
										} = application;
										return (
											<Component
												key={id}
												activity={activity}
											/>
										);
									})
									.toArray()}
							</Activities>
						)}
					</ResponsiveDesktop>
				</WorkspaceWrapper>
			</Provider>
		);
	}, [
		showPreview,
		showHomePage,
		activities,
		windows,
		windowIndice,
		applications,
		updateTitle,
		startApplication,
		closeApplication,
		handleToggleActivities,
		setShowHomePage,
		handleSwitchActivity,
		handleWindowMoveStart,
		handleWindowMoveEnd,
		handleWindowResizeStart,
		handleWindowResizeEnd,
		onWindowChange,
		onWindowFocus,
		onRequestFetchOverview,
		overview,
	]);
}

import { createContext } from 'react';
import { List, Map } from 'immutable';

import { WorkspaceContext } from './interfaces';
import noop from '../../../../Helpers/noop';

export default createContext<WorkspaceContext>({
	showPreview: false,
	applications: Map(),
	activities: List(),
	updateTitle: noop,
	startApplication: noop,
	closeApplication: noop,
	switchActivity: noop,
	toggleActivities: noop,
	toggleHomePage: noop,
});

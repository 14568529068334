import React, { useReducer, useCallback, useMemo } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import { AnyAction } from 'redux';
import { ThemeProvider } from 'styled-components';
import { List, Map, Range } from 'immutable';
import Table from '../App/Components/Table';
import TableStore, {
	Column,
	Cell,
	Filter,
	Formula,
	Format,
} from '../App/Components/Table/Store/store';
import { debugTableReducer } from '../App/Components/Table/Store/reducer';
import { useImmutable } from '../App/Hooks/useImmutable';
import { FilterType } from '../App/Components/Table/interfaces';
import theme from '../theme';

const initState = TableStore({
	columnKeys: List(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']),
	columns: Map({
		A: Column({
			name: 'demoA',
			dataType: { type: 'string' },
			filter: Filter({ type: FilterType.contain }),
			readonly: false,
			sortable: true,
		}),
		B: Column({
			name: 'demoB',
			dataType: { type: 'string' },
			filter: Filter({ type: FilterType.contain }),
			formula: Formula({
				value: 'abc',
			}),
			editable: true,
			readonly: false,
		}),
		C: Column({
			name: 'demoC',
			dataType: { type: 'string' },
			filter: Filter({ type: FilterType.contain }),
			sortable: true,
		}),
		D: Column({
			name: 'demoD',
			dataType: { type: 'number' },
			filter: Filter({ type: FilterType.eq }),
			format: Format({ unit: 'mm' }),
			sortable: true,
			editable: true,
			readonly: false,
		}),
		E: Column({
			name: 'demoE',
			dataType: { type: 'boolean' },
			filter: Filter({ type: FilterType.eq }),
			sortable: true,
			editable: true,
			readonly: false,
		}),
		F: Column({
			name: 'demoF',
			dataType: { type: 'date' },
			filter: Filter({ type: FilterType.between }),
			sortable: true,
			editable: true,
			readonly: false,
		}),
		G: Column({
			name: 'demoG',
			dataType: {
				type: 'array',
				items: { type: 'number' },
			},
			filter: Filter({ type: FilterType.none }),
			sortable: true,
			editable: true,
			readonly: false,
		}),
		H: Column({
			name: 'demoH',
			dataType: {
				type: 'object',
				properties: {
					a: { type: 'string' },
					b: { type: 'number' },
					c: {
						type: 'object',
						properties: {
							d: { type: 'number' },
						},
					},
				},
			},
			filter: Filter({ type: FilterType.none }),
			sortable: true,
			editable: true,
			readonly: false,
		}),
	}),
	cells: Map({
		3: Map({
			A: Cell({ editingBy: 'EditorA' }),
			B: Cell({ editingBy: 'EditorB' }),
			C: Cell({ editingBy: 'EditorC' }),
		}),
	}),
	recordIds: List(Range(0, 50).map((i) => i.toString())),
	records: Map({
		0: Map({
			A: 'a1',
			B: 'b2',
			C: 'c3',
			D: 123,
			E: true,
			F: new Date().toISOString(),
			G: [1, 2, 3],
			H: { a: 'abc', b: 123, c: { d: 1 } },
		}),
		1: Map({
			A: 'd4',
			B: 'e5',
			C: 'f6',
			D: 456.12345,
			E: true,
			F: new Date().toISOString(),
			G: [4, 5, 6],
			H: { a: 'abc', b: 123, c: { d: 1 } },
		}),
		2: Map({
			A: 'g7',
			B: 'h8',
			C: 'i9',
			D: 789.12345678,
			E: false,
			F: new Date().toISOString(),
			G: [9, 8, 7, 6],
			H: { a: 'abc', b: 123, c: { d: 1 } },
		}),
		3: Map({
			A: 'A',
			B: 'B',
			C: 'C',
			D: null,
			E: null,
			F: null,
			G: null,
			H: null,
		}),
	}),
	recordCount: 75,
	page: 1,
	pageSize: 50,
	loading: false,
});

const demoUser = 'Demo';

function TableDemo() {
	const [state, dispatch] = useReducer(debugTableReducer, initState);
	const _state = useImmutable(state);

	const handleRequestDispatch = useCallback(
		<A extends AnyAction>(action: A) => {
			dispatch(action);
			return action;
		},
		[dispatch]
	);

	return useMemo(
		() => (
			<ThemeProvider theme={theme}>
				<div
					style={{
						display: 'flex',
						flex: '1 0 0',
						overflow: 'hidden',
					}}
				>
					<Table
						user={demoUser}
						// loading={false}
						onRequestDispatch={handleRequestDispatch}
						{..._state.toObject()}
					/>
				</div>
			</ThemeProvider>
		),
		[_state, handleRequestDispatch]
	);
}

export default TableDemo;

import React, { useMemo, useCallback, useContext, RefObject } from 'react';
import Menu, { MenuChildren } from '../../../../Common/Menu';
import { useColumn, useColumnFilterTypeUpdate } from '../../../Hooks/Column';
import focusTargetWithRef from '../../../../../Helpers/focusTargetWithRef';
import { MenuProps, MenuItemProps } from '../../../../Common/Menu/interfaces';
import { PopoverStyleProps } from '../../../../Common/Popover/interfaces';
import { FilterType } from '../../../interfaces';
import { filterIconDict } from './HeaderCellFilter';
import context from '../../../context';

function getMenuStyle({ width, height = 0 }: PopoverStyleProps) {
	return { width, transform: `translateY(${height + 2}px)` };
}

interface HeaderFilterMenuProps extends Omit<MenuProps, 'data' | 'render'> {
	inputRef: RefObject<HTMLInputElement>;
	columnKey: string;
}

function HeaderFilterMenu(props: HeaderFilterMenuProps) {
	const { inputRef, columnKey, style, ...restProps } = props;
	const contextObj = useContext(context);
	const { dataType } = useColumn(contextObj, columnKey);
	const dataTypeStr = dataType.type;

	const selectEq = useColumnFilterTypeUpdate(
		contextObj,
		columnKey,
		FilterType.eq
	);
	const _selectEq = useCallback(() => {
		selectEq();
		focusTargetWithRef(inputRef);
	}, [inputRef, selectEq]);

	const selectLt = useColumnFilterTypeUpdate(
		contextObj,
		columnKey,
		FilterType.lt
	);
	const _selectLt = useCallback(() => {
		selectLt();
		focusTargetWithRef(inputRef);
	}, [inputRef, selectLt]);

	const selectLte = useColumnFilterTypeUpdate(
		contextObj,
		columnKey,
		FilterType.lte
	);
	const _selectLte = useCallback(() => {
		selectLte();
		focusTargetWithRef(inputRef);
	}, [inputRef, selectLte]);

	const selectGt = useColumnFilterTypeUpdate(
		contextObj,
		columnKey,
		FilterType.gt
	);
	const _selectGt = useCallback(() => {
		selectGt();
		focusTargetWithRef(inputRef);
	}, [inputRef, selectGt]);

	const selectGte = useColumnFilterTypeUpdate(
		contextObj,
		columnKey,
		FilterType.gte
	);
	const _selectGte = useCallback(() => {
		selectGte();
		focusTargetWithRef(inputRef);
	}, [inputRef, selectGte]);

	const selectNe = useColumnFilterTypeUpdate(
		contextObj,
		columnKey,
		FilterType.ne
	);
	const _selectNe = useCallback(() => {
		selectNe();
		focusTargetWithRef(inputRef);
	}, [inputRef, selectNe]);

	const selectBetween = useColumnFilterTypeUpdate(
		contextObj,
		columnKey,
		FilterType.between
	);
	const _selectBetween = useCallback(() => {
		selectBetween();
		focusTargetWithRef(inputRef);
	}, [inputRef, selectBetween]);

	const selectContain = useColumnFilterTypeUpdate(
		contextObj,
		columnKey,
		FilterType.contain
	);
	const _selectContain = useCallback(() => {
		selectContain();
		focusTargetWithRef(inputRef);
	}, [inputRef, selectContain]);

	const data: MenuItemProps[] = useMemo(() => {
		const noneItemProps = {
			icon: filterIconDict[FilterType.none],
			text: 'NO AVAILABLE FILTER',
		};
		const eqItemProps = {
			icon: filterIconDict[FilterType.eq],
			text: 'EQ',
			onPress: _selectEq,
		};
		const ltItemProps = {
			icon: filterIconDict[FilterType.lt],
			text: 'LT',
			onPress: _selectLt,
		};
		const lteItemProps = {
			icon: filterIconDict[FilterType.lte],
			text: 'LTE',
			onPress: _selectLte,
		};
		const gtItemProps = {
			icon: filterIconDict[FilterType.gt],
			text: 'GT',
			onPress: _selectGt,
		};
		const gteItemProps = {
			icon: filterIconDict[FilterType.gte],
			text: 'GTE',
			onPress: _selectGte,
		};
		const neItemProps = {
			icon: filterIconDict[FilterType.ne],
			text: 'NE',
			onPress: _selectNe,
		};
		const betweenItemProps = {
			icon: filterIconDict[FilterType.between],
			text: 'BETWEEN',
			onPress: _selectBetween,
		};
		const containItemProps = {
			icon: filterIconDict[FilterType.contain],
			text: 'CONTAIN',
			onPress: _selectContain,
		};

		switch (dataTypeStr) {
			case 'string':
				return [eqItemProps, containItemProps];
			case 'number':
				return [
					eqItemProps,
					ltItemProps,
					lteItemProps,
					gtItemProps,
					gteItemProps,
					neItemProps,
					betweenItemProps,
				];
			case 'boolean':
				return [];
			case 'date':
				return [lteItemProps, gteItemProps, betweenItemProps];
			case 'any':
				return [
					eqItemProps,
					ltItemProps,
					lteItemProps,
					gtItemProps,
					gteItemProps,
					neItemProps,
					betweenItemProps,
					containItemProps,
				];
			default:
				return [noneItemProps];
		}
	}, [
		dataTypeStr,
		_selectEq,
		_selectLt,
		_selectLte,
		_selectGt,
		_selectGte,
		_selectNe,
		_selectBetween,
		_selectContain,
	]);

	return (
		<Menu
			data={data}
			style={{ ...getMenuStyle(restProps), ...style }}
			render={MenuChildren}
			{...restProps}
		/>
	);
}

export default HeaderFilterMenu;

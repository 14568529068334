import React, { useMemo, useContext } from 'react';
import { VariableSizeGrid } from 'react-window';

import IndexInnerWrapper from './IndexInnerWrapper';
import context from '../../../context';
import constants from '../../../constants';
import IndexCell from './IndexCell';

const {
	HEADER_HEIGHT,
	INDEX_WIDTH,
	PANEL_HEIGHT,
	PAGINATION_HEIGHT,
} = constants;

const getIndexWidth = () => INDEX_WIDTH;

interface IndexGridProps {
	rowHeight(index: number): number;
}

function IndexGrid(props: IndexGridProps) {
	const { rowHeight } = props;
	const { indexGridRef, tableHeight, pageSize } = useContext(context);

	return useMemo(() => {
		const bodyHeight =
			tableHeight - HEADER_HEIGHT - PANEL_HEIGHT - PAGINATION_HEIGHT;
		return (
			<VariableSizeGrid
				style={{ overflow: 'hidden' }}
				ref={indexGridRef}
				innerElementType={IndexInnerWrapper}
				rowCount={pageSize}
				columnCount={1}
				rowHeight={rowHeight}
				columnWidth={getIndexWidth}
				height={bodyHeight}
				width={INDEX_WIDTH}
			>
				{IndexCell}
			</VariableSizeGrid>
		);
	}, [indexGridRef, tableHeight, pageSize, rowHeight]);
}

export default IndexGrid;

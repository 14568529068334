import styled from 'styled-components';
import Button from '../../../../Common/Button';
import { ButtonOverlayShape } from '../../../../Common/Button/interfaces';
import Icon from '@mdi/react';
import { IconProps } from '@mdi/react/dist/IconProps';
import { NOTIFICATION_BUTTON_SIZE } from '../constants';

export const NotificationPanelButtonWrapper = styled(Button).attrs({
	overlayShape: ButtonOverlayShape.rectangle,
	lightBg: false,
})`
	align-self: stretch;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	padding-left: 12px;
	padding-right: 12px;
	box-sizing: border-box;
	flex: 0 0 ${NOTIFICATION_BUTTON_SIZE}px;
	width: ${NOTIFICATION_BUTTON_SIZE}px;
	height: ${NOTIFICATION_BUTTON_SIZE}px;
`;

export const NotificationPanelButtonIcon = styled(Icon).attrs<
	{},
	Partial<IconProps>
>(({ theme: { font: { color: { darkBgPrimary } } } }) => ({
	size: 0.8,
	color: darkBgPrimary,
}))``;

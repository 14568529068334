import React, {
	useMemo,
	useState,
	useCallback,
	ChangeEvent as RChangeEvent,
	useEffect,
} from 'react';
import {
	FiltersWrapper,
	AddFilterWrapper,
	FilterInput,
	FilterButton,
	FilterIcon,
	FilterWrapper,
	FilterText,
	FilterIconWrapper,
	SelectFilterInput,
	UnitText,
	AddFilterInputWrapper,
	AddFilterErrorMessageWrapper,
	AddFilterErrorMessage,
} from './TreeFilters.sc';
import { mdiPlus, mdiMinus, mdiLayers } from '@mdi/js';
import { Map, List, RecordOf } from 'immutable';
import { AnyAction } from 'redux';
import {
	addFilter,
	removeFilter,
	fetchFilterFields,
	selectFeature,
	countFeatures,
} from '../../Store/actions';
import makeId from '../../../../../../Helpers/makeId';
import Autocomplete, {
	AutocompleteMenu,
	Suggestion,
} from '../../../../../../Components/Common/Autocomplete';
// import useAsRef from '../../../../../../Hooks/useAsRef';
import { FilterField } from '../../interfaces';
import { PopoverProps } from '../../../../../../Components/Common/Popover/interfaces';
import { Dict } from '../../../../../../../types/Common';

type FilterProps = {
	filterKey: string;
	name: string;
	handleRemoveFilter: (index: string) => any;
};

function FilterItem(props: FilterProps) {
	const { name, filterKey, handleRemoveFilter } = props;

	const handleRemove = useCallback(() => {
		handleRemoveFilter(filterKey);
	}, [filterKey, handleRemoveFilter]);

	return useMemo(
		() => (
			<FilterWrapper>
				<FilterText>{name}</FilterText>
				<FilterButton onMouseDown={handleRemove}>
					<FilterIcon path={mdiMinus} />
				</FilterButton>
			</FilterWrapper>
		),
		[handleRemove, name]
	);
}

type Props = {
	filters: Map<string, string>;
	filterNames: Map<string, string>;
	filterFields: List<RecordOf<FilterField>>;
	onBoardcast(action: AnyAction): any;
	onUplink(action: AnyAction): any;
	onRequestBlur: () => any;
};

function getAutocompleteMenuStyle({ height = 0 }: PopoverProps) {
	return {
		width: 'auto',
		transform: `translateY(${height + 2}px)`,
	};
}

const operatorSuggestions: (Suggestion & { types: string[] })[] = [
	{ name: '=', value: '==', types: ['string', 'number'] },
	{ name: 'contains', value: 'in', types: ['string'] },
	{ name: '>', value: '>', types: ['number'] },
	{ name: '>=', value: '>=', types: ['number'] },
	{ name: '<', value: '<', types: ['number'] },
	{ name: '<=', value: '<=', types: ['number'] },
];

export default function TreeFilters(props: Props) {
	const {
		filters,
		filterNames,
		filterFields,
		onBoardcast,
		onUplink,
		onRequestBlur,
	} = props;

	const fieldsDict = useMemo(
		() =>
			filterFields.reduce((r, field) => {
				return r.set(field.name, field);
			}, Map<string, Suggestion>()),
		[filterFields]
	);

	const [filterFormula, setFilterFormula] = useState<string>('');
	const [filterName, setFilterName] = useState<string>('');
	const [fieldText, setFieldText] = useState<string>('');
	const [operatorText, setOperatorText] = useState<string>('');
	const [criteriaText, setCriteriaText] = useState<string>('');
	const [advanced] = useState<boolean>(false);
	const [errMsg, setErrMsg] = useState<string | null>(null);

	// const handleToggleAdvanced = useCallback(() => {
	// 	setAdvanced((advanced) => !advanced);
	// }, [setAdvanced]);

	useEffect(() => {
		onBoardcast(fetchFilterFields());
	}, [onBoardcast]);

	useEffect(() => {
		onUplink(
			countFeatures({
				collection: 'trees',
				filters: filters.toJS() as Dict<string>,
				locationField: 'location',
				select: { treeId: 1 },
			})
		);
	}, [onUplink, filters]);

	const handleAddFilter = useCallback(() => {
		let _filterFormula = filterFormula,
			_filterName = filterName;

		if (!advanced) {
			const field = fieldsDict.get(fieldText);
			const operator = operatorSuggestions.find(
				({ name }) => name === operatorText
			);

			if (!fieldText || !field) {
				setErrMsg('Target property is missing');
				return;
			}

			if (!operator) {
				setErrMsg('Filter operator is missing');
				return;
			}

			if (!criteriaText) {
				setErrMsg('Criteria is missing');
				return;
			}

			if (
				field.value.type === 'number' &&
				isNaN(parseFloat(criteriaText))
			) {
				setErrMsg('Criteria must by valid number');
				return;
			}

			_filterFormula = `${field.value.formula} ${operator.value} ${
				field.value.type === 'string'
					? operator.value === 'in'
						? `[${criteriaText
								.split(',')
								.map((t) => `"${t.trim()}"`)
								.join(',')}]`
						: JSON.stringify(criteriaText)
					: criteriaText
			}`;

			_filterName = `${fieldText} ${operatorText} ${criteriaText}`;
		}

		if (_filterName === '' || _filterFormula === '') return;

		onBoardcast(
			addFilter({
				filterFormula: _filterFormula,
				filterName: _filterName,
				key: makeId(10),
			})
		);
		onBoardcast(selectFeature({ feature: null }));

		onRequestBlur();
		setFilterFormula('');
		setFieldText('');
		setOperatorText('');
		setCriteriaText('');
		setErrMsg(null);
	}, [
		filterName,
		filterFormula,
		fieldText,
		operatorText,
		criteriaText,
		advanced,
		fieldsDict,
		onBoardcast,
		onRequestBlur,
	]);

	const handleRemoveFilter = useCallback(
		(key: string) => {
			onBoardcast(removeFilter({ key }));
			setFilterFormula('');
		},
		[onBoardcast]
	);

	const handleSelectField = useCallback((field: Suggestion) => {
		setFieldText(field.name);
		const activeElement = document.activeElement;
		if (activeElement instanceof HTMLInputElement) activeElement.blur();
	}, []);

	const handleFieldTextChange = useCallback(
		(e: RChangeEvent<HTMLInputElement>) => {
			setFieldText(e.target.value);
		},
		[]
	);

	const handleSelectOperator = useCallback((operator: Suggestion) => {
		setOperatorText(operator.name);
	}, []);

	const handleOperationTextChange = useCallback(
		(e: RChangeEvent<HTMLInputElement>) => {
			setOperatorText(e.target.value);
		},
		[]
	);

	const handleSelectCriteria = useCallback((criteria: Suggestion) => {
		setCriteriaText(criteria.name);
	}, []);

	const handleCriteriaTextChange = useCallback(
		(e: RChangeEvent<HTMLInputElement>) => {
			setCriteriaText(e.target.value);
		},
		[]
	);

	return useMemo(() => {
		const fieldSuggestions = filterFields
			.filter(({ name }) => new RegExp(fieldText).test(name))
			.toArray();
		const field = fieldsDict.get(fieldText);
		let valueSuggestions: Suggestion[] =
			(field && field.value && field.value.suggestions) || [];

		valueSuggestions = valueSuggestions.filter(({ name }) =>
			new RegExp(criteriaText).test(name)
		);
		return (
			<FiltersWrapper>
				<AddFilterWrapper>
					<AddFilterInputWrapper>
						<FilterIconWrapper>
							<FilterIcon path={mdiLayers} />
						</FilterIconWrapper>
						{advanced ? (
							<>
								<FilterInput
									value={filterName}
									onChange={(e) => {
										setFilterName(e.target.value);
									}}
									placeholder="Filter Name"
								/>
								<FilterInput
									value={filterFormula}
									onChange={(e) => {
										setFilterFormula(e.target.value);
									}}
									placeholder="Filter Formula"
								/>
							</>
						) : (
							<>
								<Autocomplete
									suggestions={fieldSuggestions}
									onSelect={handleSelectField}
								>
									{({
										ref,
										openMenu,
										closeMenu,
										menuProps,
									}) => (
										<>
											<SelectFilterInput
												ref={ref}
												value={fieldText}
												onFocus={openMenu}
												onBlur={closeMenu}
												onChange={handleFieldTextChange}
												placeholder="Fieldname"
											/>
											<AutocompleteMenu
												{...menuProps}
												style={getAutocompleteMenuStyle(
													menuProps
												)}
												withBackdrop={false}
											/>
										</>
									)}
								</Autocomplete>
								<Autocomplete
									suggestions={operatorSuggestions.filter(
										({ types }) =>
											field &&
											field.value &&
											!!~types.indexOf(field.value.type)
									)}
									onSelect={handleSelectOperator}
								>
									{({
										ref,
										openMenu,
										closeMenu,
										menuProps,
									}) => (
										<>
											<SelectFilterInput
												ref={ref}
												value={operatorText}
												onFocus={openMenu}
												onBlur={closeMenu}
												onChange={
													handleOperationTextChange
												}
												placeholder="Operator"
											/>
											<AutocompleteMenu
												{...menuProps}
												style={getAutocompleteMenuStyle(
													menuProps
												)}
												withBackdrop={false}
											/>
										</>
									)}
								</Autocomplete>
								<Autocomplete
									suggestions={valueSuggestions}
									onSelect={handleSelectCriteria}
								>
									{({
										ref,
										openMenu,
										closeMenu,
										menuProps,
									}) => (
										<>
											<SelectFilterInput
												ref={ref}
												value={criteriaText}
												placeholder="Criteria"
												onChange={
													handleCriteriaTextChange
												}
												onFocus={openMenu}
												onBlur={closeMenu}
											/>
											<AutocompleteMenu
												{...menuProps}
												style={getAutocompleteMenuStyle(
													menuProps
												)}
												withBackdrop={false}
											/>
										</>
									)}
								</Autocomplete>

								{field && field.value && field.value.unit ? (
									<UnitText>{field.value.unit}</UnitText>
								) : null}
							</>
						)}

						{/* <FilterButton onMouseDown={handleToggleAdvanced}>
							<FilterIcon
								path={
									advanced
										? mdiSelectPlace
										: mdiDeveloperBoard
								}
							/>
						</FilterButton> */}
						<FilterButton onMouseDown={handleAddFilter}>
							<FilterIcon path={mdiPlus} />
						</FilterButton>
					</AddFilterInputWrapper>
					{errMsg ? (
						<AddFilterErrorMessageWrapper>
							<AddFilterErrorMessage>
								{errMsg}
							</AddFilterErrorMessage>
						</AddFilterErrorMessageWrapper>
					) : null}
				</AddFilterWrapper>

				{filterNames
					.map((name, filterKey) => (
						<FilterItem
							key={filterKey}
							name={name}
							filterKey={filterKey}
							handleRemoveFilter={handleRemoveFilter}
						/>
					))
					.toList()
					.toArray()}
			</FiltersWrapper>
		);
	}, [
		filterFields,
		fieldsDict,
		handleAddFilter,
		handleRemoveFilter,
		filterFormula,
		advanced,
		// handleToggleAdvanced,
		fieldText,
		handleSelectField,
		operatorText,
		handleSelectOperator,
		handleCriteriaTextChange,
		handleFieldTextChange,
		handleOperationTextChange,
		criteriaText,
		handleSelectCriteria,
		filterName,
		filterNames,
		errMsg,
	]);
}

import OlVectorLayer, { Options as VectorLayerOptions } from 'ol/layer/Vector';
import VectorSource, { Options as VectorSourceOptions } from 'ol/source/Vector';

import React, { useEffect, useMemo, ReactNode, useContext } from 'react';
import context, { layerContext } from '../context';

interface Props {
	layerOptions?: VectorLayerOptions;
	sourceOptions?: VectorSourceOptions;
	children: ReactNode;
}

const { Provider } = layerContext;

export default function VectorLayer(props: Props) {
	const { layerOptions, sourceOptions, children } = props;

	const { map } = useContext(context);

	const source = useMemo(() => {
		return new VectorSource(sourceOptions);
	}, [sourceOptions]);

	const layer = useMemo(() => {
		return new OlVectorLayer({
			...layerOptions,
			source,
		});
	}, [layerOptions, source]);

	useEffect(() => {
		map.addLayer(layer);
		return () => {
			map.removeLayer(layer);
		};
	}, [layer, source, map]);

	return <Provider value={{ layer, source }}>{children}</Provider>;
}

import { useCallback } from 'react';
import { updatePage, updatePageSize } from '../Store/actions';
import { ITableContext } from '../context';

export function usePageUpdate(contextObj: ITableContext) {
	const { onRequestDispatch } = contextObj;
	return useCallback(
		(page: number) => {
			onRequestDispatch(updatePage({ page }));
		},
		[onRequestDispatch]
	);
}

export function usePageSizeUpdate(contextObj: ITableContext) {
	const { onRequestDispatch } = contextObj;
	return useCallback(
		(pageSize: number) => {
			onRequestDispatch(updatePageSize({ pageSize }));
		},
		[onRequestDispatch]
	);
}

import React, { useMemo, forwardRef, ReactNode, Ref, RefObject } from 'react';
import styled from 'styled-components';
import useForwardedRef from '../../Hooks/useForwardedRef';
import Menu, { MenuChildren } from './Menu';
import { usePopoverOnElement } from './Popover';
import { MenuProps, MenuItemProps } from './Menu/interfaces';

export interface Suggestion {
	name: string;
	value: any;
}

export interface AutocompleteChildrenProps {
	ref: RefObject<HTMLInputElement>;
	menuOpened: boolean;
	openMenu: () => void;
	closeMenu: () => void;
	menuProps: MenuProps;
}

export interface AutocompleteProps {
	suggestions: Suggestion[];
	menuOptions?: Partial<MenuProps>;
	onSelect: (suggestion: Suggestion) => void;
	children: (props: AutocompleteChildrenProps) => ReactNode;
}

export const AutocompleteMenuChildren = styled(MenuChildren)`
	& > [x-component='text'] {
		text-transform: unset;
	}
`;

export interface AutocompleteMenuProps {
	width?: number;
	height?: number;
}
export const AutocompleteMenu = styled(Menu)<AutocompleteMenuProps>`
	width: ${({ width = 0 }) => width}px;
	transform: ${({ height = 0 }) => `translateY(${height + 2}px)`};
`;

const Autocomplete = forwardRef(function (
	props: AutocompleteProps,
	ref: Ref<HTMLInputElement>
) {
	const { suggestions, menuOptions, onSelect, children } = props;
	const _ref = useForwardedRef(ref);

	const data: MenuItemProps[] = useMemo(() => {
		return suggestions.map((s) => ({
			text: s.name,
			onPress: () => {
				onSelect(s);
			},
		}));
	}, [suggestions, onSelect]);

	const {
		opened: menuOpened,
		open: openMenu,
		close: closeMenu,
		getPopoverProps,
	} = usePopoverOnElement(_ref, menuOptions);

	return (
		<>
			{children({
				ref: _ref,
				menuOpened,
				openMenu,
				closeMenu,
				menuProps: {
					data,
					focusTargetRef: _ref,
					render: AutocompleteMenuChildren,
					...getPopoverProps(),
				},
			})}
		</>
	);
});

export default Autocomplete;

import React, {
	useMemo,
	useContext,
	useRef,
	CSSProperties,
	HTMLAttributes,
	Ref,
} from 'react';
import { SortableElement } from 'react-sortable-hoc';

import { HeaderCellWrapper as SHeaderCellWrapper } from './HeaderCell.sc';
import HeaderCellTitle from './HeaderCellTitle';
import HeaderCellFilter from './HeaderCellFilter';
import HeaderCellResizeHandle from './HeaderCellResizeHandle';
import { getColumnKey } from '../../../Helpers/Column';
import context from '../../../context';

interface SSHeaderCellWrapperProps
	extends Omit<HTMLAttributes<HTMLDivElement>, 'ref'> {
	forwardedRef: Ref<HTMLDivElement>;
	index: number;
	rearranging: boolean;
}

const SSHeaderCellWrapper = SortableElement(
	({ forwardedRef, ...restProps }: SSHeaderCellWrapperProps) => (
		<SHeaderCellWrapper ref={forwardedRef} {...restProps} />
	)
);

interface HeaderCellProps {
	style: CSSProperties;
	data: any;
	rowIndex: number;
	columnIndex: number;
}
export default function HeaderCell(props: HeaderCellProps) {
	const ref = useRef<HTMLDivElement>(null);
	const {
		style: { left, top, width, height },
		columnIndex,
	} = props;
	const contextObj = useContext(context);
	const { rearrangingColumnKey, resizingColumnKey } = contextObj;
	const columnKey = getColumnKey(contextObj, columnIndex);

	return useMemo(() => {
		const rearranging = rearrangingColumnKey === columnKey;
		const resizing = resizingColumnKey === columnKey;
		return (
			<SSHeaderCellWrapper
				forwardedRef={ref}
				index={columnIndex}
				style={{ left, top, width, height }}
				rearranging={rearranging}
			>
				<HeaderCellTitle columnKey={columnKey} />
				<HeaderCellFilter columnKey={columnKey} />
				<HeaderCellResizeHandle
					headerCellRef={ref}
					resizing={resizing}
					columnKey={columnKey}
				/>
			</SSHeaderCellWrapper>
		);
	}, [
		left,
		top,
		width,
		height,
		rearrangingColumnKey,
		resizingColumnKey,
		columnIndex,
		columnKey,
	]);
}

import React, {
	useCallback,
	forwardRef,
	Ref,
	FocusEvent as RFocusEvent,
	KeyboardEvent as RKeyboardEvent,
} from 'react';
import { InputWrapper, Input } from './WrappedInput.sc';
import InputUnderline from './InputUnderline';
import useForwardedRef from '../../../../Hooks/useForwardedRef';
import useFocus from '../../../../Hooks/useFocus';
import blurTargetWithRef from '../../../../Helpers/blurTargetWithRef';
import { WrappedInputProps } from '../interfaces';

function getXmode(focused: boolean, disabled: boolean) {
	let xMode = '';
	if (focused) xMode += ' focused';
	if (disabled) xMode += ' disabled';
	return xMode;
}

const WrappedInput = forwardRef(function(
	props: WrappedInputProps,
	ref: Ref<HTMLInputElement>
) {
	const {
		wrapperRef,
		styles = {},
		tabIndex = -1,
		className,
		lightBg = true,
		disabled = false,
		underline = false,
		blurOnEnterPressed = true,
		onKeyDown,
		onFocus,
		onBlur,
		onWrapperFocus,
		onWrapperMouseDown,
		onWrapperMouseUp,
		onWrapperClick,
		onWrapperDoubleClick,
		onWrapperMouseEnter,
		onWrapperMouseLeave,
		...restProps
	} = props;
	const _ref = useForwardedRef(ref);
	const { focused, handleFocus, handleBlur } = useFocus();

	const _handleFocus = useCallback(
		(e: RFocusEvent<HTMLInputElement>) => {
			handleFocus();
			onFocus && onFocus(e);
		},
		[handleFocus, onFocus]
	);
	const _handleBlur = useCallback(
		(e: RFocusEvent<HTMLInputElement>) => {
			handleBlur();
			onBlur && onBlur(e);
			const selection = window.getSelection();
			if (selection) selection.removeAllRanges();
		},
		[handleBlur, onBlur]
	);
	const handleKeyDown = useCallback(
		(e: RKeyboardEvent<HTMLInputElement>) => {
			onKeyDown && onKeyDown(e);
			if (blurOnEnterPressed && e.key === 'Enter')
				blurTargetWithRef(_ref);
		},
		[_ref, blurOnEnterPressed, onKeyDown]
	);

	const xMode = getXmode(focused, disabled);
	return (
		<InputWrapper
			ref={wrapperRef}
			style={styles.wrapper}
			className={className}
			onFocus={onWrapperFocus}
			onMouseDown={onWrapperMouseDown}
			onMouseUp={onWrapperMouseUp}
			onClick={onWrapperClick}
			onDoubleClick={onWrapperDoubleClick}
			onMouseEnter={onWrapperMouseEnter}
			onMouseLeave={onWrapperMouseLeave}
			x-mode={xMode}
		>
			<Input
				ref={_ref}
				style={styles.input}
				tabIndex={tabIndex}
				spellCheck={false}
				lightBg={lightBg}
				disabled={disabled}
				onFocus={_handleFocus}
				onBlur={_handleBlur}
				onKeyDown={handleKeyDown}
				x-mode={xMode}
				x-component="input"
				{...restProps}
			/>
			{underline ? (
				<InputUnderline
					style={styles.underline}
					focused={focused}
					x-mode={xMode}
					x-component="underline"
				/>
			) : null}
		</InputWrapper>
	);
});

export default WrappedInput;

import styled from 'styled-components';
import { ButtonOverlayShape } from '../../../../Common/Button/interfaces';
import LoadingSpinner from '../../../../Common/LoadingSpinner';
import IconTextButton from '../../../../Common/IconTextButton';

export const BodyCellStatusIndicatorLoadingSpinner = styled(
	LoadingSpinner
).attrs(({ theme: { background: { accent, darken } } }) => ({
	upperColor: accent,
	lowerColor: darken[1],
}))`
	position: absolute;
	justify-content: center;
	right: 6px;
	width: 24px;
	height: 24px;
`;

export const BodyCellStatusIndicatorButton = styled(IconTextButton).attrs(
	({
		theme: {
			font: {
				color: { lightBgWatermark },
			},
		},
	}) => ({
		overlayShape: ButtonOverlayShape.circle,
		iconProps: {
			color: lightBgWatermark,
		},
	})
)`
	position: absolute;
	justify-content: center;
	right: 10px;
	padding: 0;
`;

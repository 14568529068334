import styled from 'styled-components';
import WrappedInput from '../../WrappedInput';

export interface ToggleableInputProps {
	editing: boolean;
}

export const ToggleableInput = styled(WrappedInput)<ToggleableInputProps>`
	& > [x-component='input'] {
		cursor: ${({ editing }) => (editing ? 'auto' : 'default')};
	}
`;

import styled from 'styled-components';
import { View } from '../../Common/View';

export const MapViewWrapper = styled(View)`
	flex: 1;
	overflow: hidden;
`;

export const MapWrapper = styled(View)`
	background-color: ${({ theme: { background } }) => background.grey};
	overflow: hidden;
`;

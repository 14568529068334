import React, { CSSProperties, useCallback } from 'react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import { TextAreaUnderline as SInputUnderline } from './TextAreaUnderline.sc';
import useObject from '../../../../Hooks/useObject';

interface TextAreaUnderlineProps {
	style?: CSSProperties;
	focused: boolean;
}
function getStyle(status: TransitionStatus): CSSProperties {
	if (status === 'entered')
		return {
			width: '100%',
			opacity: 1,
		};
	return {
		width: 0,
		opacity: 0,
	};
}
function TextAreaUnderline(props: TextAreaUnderlineProps) {
	const { focused, style } = props;
	const _style = useObject(style);
	const render = useCallback(
		(status: TransitionStatus) => {
			return (
				<SInputUnderline style={{ ...getStyle(status), ..._style }} />
			);
		},
		[_style]
	);
	return (
		<Transition in={focused} timeout={200} unmountOnExit>
			{render}
		</Transition>
	);
}

export default TextAreaUnderline;

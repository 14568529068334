import createReducer from '../../../Helpers/createReducer';
import { Map, Record } from 'immutable';
import { Reducer, AnyAction } from 'redux';
import { EmbeddedState } from '../interfaces';
import {
	// createLink,
	initLink,
	initLinkSuccess,
	initLinkFailure,
	// forwardAction,
} from './actions';

interface StateDict {
	[key: string]: any;
}

export const LinkedInitialState = <S>(state?: Partial<EmbeddedState<S>>) =>
	Record<EmbeddedState<S>>({
		loading: false,
		initialized: false,
		message: null,
		state: null,
	})(state);

export default function createEmbeddedReducer<
	SD extends StateDict,
	SSD extends StateDict
>(
	reducers: {
		[K in keyof SD]: {
			reducer: Reducer<SD[K]>;
			initialState: SD[K];
			parser: K extends keyof SSD ? (serialized: SSD[K]) => SD[K] : never;
		};
	}
): Reducer<Map<string, EmbeddedState<SD[keyof SD]>>> {
	const rootState = Map<string, EmbeddedState<any>>();
	const linkedReducer = createReducer(
		rootState,
		{
			initLink,
			initLinkSuccess,
			initLinkFailure,
		},
		{
			initLink: (state, { payload: { storeId, message = null } }) =>
				state.update(storeId, (embeddedState) => ({
					...embeddedState,
					loading: true,
					message,
				})),
			initLinkSuccess: (
				state,
				{ payload: { storeId, type, state: serialized } }
			) => {
				const reducerConfig = reducers[type];
				if (!reducerConfig) return state;
				const { parser } = reducerConfig;
				return state.update(storeId, (embeddedState) => ({
					...embeddedState,
					loading: false,
					initialized: true,
					message: 'done',
					state: parser(serialized),
				}));
			},
			initLinkFailure: (
				state,
				{ payload: { storeId, message = null } }
			) =>
				state.update(storeId, (embeddedState) => ({
					...embeddedState,
					loading: false,
					initialized: true,
					message,
				})),
		}
	);

	return function(
		state: Map<string, EmbeddedState<SD[keyof SD]>> = rootState,
		action: AnyAction
	) {
		const { metadata } = action;
		if (metadata && metadata.forwardFor) {
			const { storeId, type } = metadata.forwardFor;
			const reducerConfig = reducers[type];
			if (!reducerConfig) return state;
			const { reducer } = reducerConfig;
			return state.updateIn([storeId, 'state'], (s: any) =>
				reducer(s, action)
			);
		}

		return linkedReducer(state, action);
	};
}

import { PopoverProps } from '../Popover/interfaces';

export enum TooltipType {
	warning = 'warning',
	error = 'error',
	info = 'info',
	default = 'default',
}

export enum TooltipLocation {
	center = 'center',
	top = 'top',
	left = 'left',
	right = 'right',
	bottom = 'bottom',
}

export interface TooltipProps
	extends Omit<
		PopoverProps,
		'withBackdrop' | 'closeOnMouseDownOutside' | 'blurOnMouseDownOutside'
	> {
	type?: TooltipType;
	location?: TooltipLocation;
}

import Workspace from './Components/Workspace';
import workspaceReducer, {
	WorkspaceStoreRecord,
	workspaceParser,
} from './Store/reducer';
import {
	focusActivity,
	updateTitle,
	startApplication,
	closeActivity,
	changeWindowState,
} from './Store/actions';
import {
	notificationReducer,
	parseNotificationState,
} from '../NotificationPanel';
import useStartApplication from './Hooks/useStartApplication';
import useToggleHomePage from './Hooks/useToggleHomePage';

export default Workspace;
export {
	workspaceReducer,
	focusActivity,
	updateTitle,
	startApplication,
	closeActivity,
	changeWindowState,
	workspaceParser,
	WorkspaceStoreRecord,
	notificationReducer,
	parseNotificationState,
	useStartApplication,
	useToggleHomePage,
};

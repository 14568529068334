import styled from 'styled-components';
import { View } from '../../../../Common/View';

export const HeaderCellResizeHandle = styled(View)`
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	width: 20px;
	transform: translateX(10px);
	cursor: ew-resize;
	z-index: 999;
`;

export const HeaderCellResizeHandleShadow = styled(View)`
	position: absolute;
	top: 0;
	bottom: 0;
	width: 2px;
	transform: translateX(-1px);
	cursor: ew-resize;
	z-index: 999;
	background: ${({
		theme: {
			background: { darken },
		},
	}) => darken[4]};
`;

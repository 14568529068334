import OlMap from 'ol/Map';
import { useEffect, useRef } from 'react';

export default function useUpdateRotation(
	olMap: OlMap,
	rotation: number,
	onRotate: (rotation: number) => any
) {
	useEffect(() => {
		if (olMap.getView().getRotation() !== rotation)
			olMap.getView().setRotation(rotation);
	}, [rotation, olMap]);

	const rotationRef = useRef(rotation);
	useEffect(() => {
		rotationRef.current = rotation;
	});

	useEffect(() => {
		function updateRotationProps() {
			const view = olMap.getView();
			const newRotation = view.getRotation();
			if (
				typeof newRotation === 'number' &&
				newRotation !== rotationRef.current
			)
				onRotate(newRotation);

			return true;
		}
		olMap.on('moveend', updateRotationProps);
		return () => {
			olMap.un('moveend', updateRotationProps);
		};
	}, [olMap, onRotate]);
}

import createActionCreator from '../../Helpers/createActionCreator';

export interface ReadingData {
	x: string;
	roll: number;
	pitch: number;
	inclination: number;
}

interface FetchLineChartDataPayload {
	treeId: string;
}

export const fetchChartReadings = createActionCreator(
	'READING_CHARTS/FETCH_READINGS',
	{} as FetchLineChartDataPayload
);

interface ReplaceReadingChartsDataPayload {
	readings: ReadingData[];
}

export const replaceChartReadings = createActionCreator(
	'READING_CHARTS/REPLACE_READINGS',
	{} as ReplaceReadingChartsDataPayload
);

interface IImportChartReadings {
	reading: ReadingData;
}

export const importChartReading = createActionCreator(
	'READING_CHARTS/IMPORT_READING',
	{} as IImportChartReadings
);

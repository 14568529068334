import React, { useMemo, useCallback, useContext } from 'react';
import { mdiTableEdit, mdiFormatPaint } from '@mdi/js';
import { PanelButton } from './Panel.sc';
import { useColumn, useColumnEditableUpdate } from '../../../Hooks/Column';
import FormatModal from '../../FormatModal';
import PanelFormula from './PanelFormula';
import { usePopoverAtCenter } from '../../../../Common/Popover';
import context from '../../../context';

interface PanelColumnControlButtonProps {
	columnKey: string;
}
function EditButton(props: PanelColumnControlButtonProps) {
	const { columnKey } = props;
	const contextObj = useContext(context);
	const { editable, readonly } = useColumn(contextObj, columnKey);
	const updateColumnEditable = useColumnEditableUpdate(contextObj, columnKey);

	const toggleEditable = useCallback(() => {
		if (!readonly) updateColumnEditable(!editable);
	}, [readonly, updateColumnEditable, editable]);

	return useMemo(() => {
		const tooltip = editable ? 'Disable Edit' : 'Enable Edit';
		return (
			<PanelButton
				tabIndex={0}
				icon={mdiTableEdit}
				tooltip={tooltip}
				activated={editable}
				disabled={readonly}
				onPress={toggleEditable}
			/>
		);
	}, [readonly, editable, toggleEditable]);
}

function FormatButton(props: PanelColumnControlButtonProps) {
	const { columnKey } = props;
	const { tableRef } = useContext(context);
	const { open, getPopoverProps } = usePopoverAtCenter({
		containerRef: tableRef,
	});
	return useMemo(
		() => (
			<>
				<PanelButton
					tabIndex={0}
					icon={mdiFormatPaint}
					tooltip="Format"
					disabled={!columnKey}
					onPress={open}
				/>
				<FormatModal
					columnKey={columnKey}
					containerRef={tableRef}
					{...getPopoverProps()}
				/>
			</>
		),
		[tableRef, columnKey, open, getPopoverProps]
	);
}

interface PanelColumnControlProps {
	columnKey: string;
}
function PanelColumnControl(props: PanelColumnControlProps) {
	const { columnKey } = props;
	return useMemo(() => {
		return (
			<>
				<EditButton columnKey={columnKey} />
				<FormatButton columnKey={columnKey} />
				<PanelFormula columnKey={columnKey} />
			</>
		);
	}, [columnKey]);
}

export default PanelColumnControl;

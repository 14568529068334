import styled from 'styled-components';
import { View } from '../../../../Common/View';
import { CaptionText } from '../../../../Common/Text';

export const ActivityPreviewWrapper = styled(View)`
	position: absolute;
	z-index: 999;
	box-sizing: content-box;
	margin-left: -4px;
	margin-top: -4px;
	transition: all 200ms;
	border: 4px solid
		${({
			theme: {
				background: { frameHighlight },
			},
		}) => frameHighlight};
	border-radius: 8px;
	opacity: 0;
	align-items: center;
	justify-content: center;

	&:hover {
		opacity: 1;
	}
`;

export const ActivityTitleWrapper = styled(View)`
	background-color: ${({
		theme: {
			background: { darken },
		},
	}) => darken[5]};
	padding: 8px;
	border-radius: 8px;
`;

export const ActivityTitleText = styled(CaptionText)`
	color: ${({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => darkBgPrimary};
`;

import React from 'react';

import styled from 'styled-components';
import { View } from './View';
import Icon from '@mdi/react';
import { mdiCircleOutline, mdiLoading } from '@mdi/js';
import { CSSProperties } from 'react';

export const LoadingSpinnerWrapper = styled(View)`
	align-items: center;
	justify-content: center;
`;

export const LoadingSpinnerUpperIcon = styled(Icon).attrs<LoadingSpinnerProps>(
	({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
		color,
	}) => ({
		color: color || darkBgPrimary,
	})
)`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
`;
export const LoadingSpinnerLowerIcon = styled(Icon).attrs<LoadingSpinnerProps>(
	({
		theme: {
			font: {
				color: { darkBgWatermark },
			},
		},
		color,
	}) => ({
		color: color || darkBgWatermark,
	})
)`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
`;

export interface LoadingSpinnerProps {
	style?: CSSProperties;
	className?: string;
	size?: number | string | null;
	lowerColor?: string;
	upperColor?: string;
}

export default function LoadingSpinner(props: LoadingSpinnerProps) {
	const { style, className, size = 0.8, lowerColor, upperColor } = props;
	return (
		<LoadingSpinnerWrapper style={style} className={className}>
			<LoadingSpinnerLowerIcon
				size={size}
				path={mdiCircleOutline}
				color={lowerColor}
				x-component="lowericon"
			/>
			<LoadingSpinnerUpperIcon
				size={size}
				path={mdiLoading}
				color={upperColor}
				spin
				x-component="uppericon"
			/>
		</LoadingSpinnerWrapper>
	);
}

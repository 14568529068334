import React, { useMemo, ReactElement, ComponentType } from 'react';
import Tooltip from '../../Tooltip';
import { TooltipProps } from '../../Tooltip/interfaces';
import { PopoverControlProps } from '../../Popover/interfaces';

export interface IconTextButtonTooltipProps {
	tooltip?: string | ReactElement;
	tooltipProps?: Partial<TooltipProps>;
	getPopoverProps(): PopoverControlProps;
	CustomTooltip?: ComponentType<PopoverControlProps>;
}

function IconTextButtonTooltip(props: IconTextButtonTooltipProps) {
	const { tooltip, tooltipProps, getPopoverProps, CustomTooltip } = props;
	return useMemo(() => {
		if (CustomTooltip) return <CustomTooltip {...getPopoverProps()} />;
		if (tooltip === undefined) return null;
		if (typeof tooltip === 'string')
			return (
				<Tooltip
					{...getPopoverProps()}
					{...tooltipProps}
					x-component="tooltip"
				>
					{tooltip}
				</Tooltip>
			);
		return tooltip;
	}, [tooltip, tooltipProps, getPopoverProps, CustomTooltip]);
}

export default IconTextButtonTooltip;

import React, {
	CSSProperties,
	useMemo,
	useContext,
	useCallback,
	useState,
	ReactElement,
	ChangeEvent,
} from 'react';

import { useImmutableMemo } from '../../../../../Hooks/useImmutable';
import { context } from '../../../../../Components/RecordsFeedGrid/context';
import { Map } from 'immutable';
import { useItemStyles, useToolbarStyles } from './AlertRuleRecord.sc';
import {
	Toolbar,
	Paper,
	Box,
	TableBody,
	TableRow,
	TableCell,
	Table,
	Typography,
	IconButton,
	Dialog,
	DialogActions,
	Button,
	DialogContent,
	TextField,
	Chip,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
} from '@material-ui/core';
import {
	deleteRecord,
	updateRecord,
} from '../../../../../Components/RecordsFeed/actions';
import {
	Pencil,
	TrashCan,
	Undo,
	Bell,
	BellOff,
	DesktopMacDashboard,
	Email,
	Message,
	Check,
} from 'mdi-material-ui';
import { useUplinkDispatch } from '../../../../../Hooks/useUplinkDispatch';

import { useFormik } from 'formik';
import FormulaParser from 'formula-to-mongodb-exp';
import { useBooleanState } from '../../../../../Hooks/useBooleanState';
import Scrollbars from '../../../../../Components/Common/Scrollbars';

type Props = {
	rowIndex: number;
	columnIndex: number;
	style: CSSProperties;
};

const feedId = 'alertControl';
const collection = 'alertRules';

const formulaParser = new FormulaParser();

enum Channel {
	EMAIL = 'email',
	SMS = 'sms',
	DASHBOARD = 'dashboard',
}

interface Recipient {
	channel: Channel;
	target: string;
}

interface AlertRule {
	name: string;
	active: boolean;
	scope: string;
	activation: string;
	messageTemplate: string;
	recipients?: Recipient[];
}

const channelIcons: { [key: string]: ReactElement } = {
	[Channel.EMAIL]: <Email />,
	[Channel.DASHBOARD]: <DesktopMacDashboard />,
	[Channel.SMS]: <Message />,
};

export default function AlertRuleRecord(props: Props) {
	const { rowIndex, columnIndex, style } = props;
	const { recordIds, records, columnCount } = useContext(context);

	const recordId = useImmutableMemo(
		() => recordIds.toArray()[rowIndex * columnCount + columnIndex],
		[recordIds, columnCount, rowIndex, columnIndex]
	);
	const record = useImmutableMemo<AlertRule>(() => {
		const record = records.get(recordId, Map());
		return record.toJS() as AlertRule;
	}, [records, recordId, rowIndex]);

	const [editing, startEditing, endEditing] = useBooleanState(false);
	const [update, setUpdate] = useState(record);
	const [saveDialogOpened, openSaveDialog, closeSaveDialog] = useBooleanState(
		false
	);
	const [
		removeDialogOpened,
		openRemoveDialog,
		closeRemoveDialog,
	] = useBooleanState(false);
	const [selectedChannel, setSelectedChannel] = useState<Channel>(
		Channel.EMAIL
	);
	const [recipient, setRecipient] = useState('');

	const itemClasses = useItemStyles();
	const toolbarClasses = useToolbarStyles({ record });

	const dispatch = useUplinkDispatch();

	const handleConfirmRemove = useCallback(() => {
		dispatch(deleteRecord({ collection, feedId, recordId }));
		closeRemoveDialog();
	}, [dispatch, recordId, closeRemoveDialog]);

	const handleUpdate = useCallback(
		(record: AlertRule) => {
			dispatch(updateRecord({ collection, feedId, recordId, record }));
		},
		[dispatch, recordId]
	);

	const handleActivate = useCallback(() => {
		handleUpdate({ ...record, active: true });
	}, [handleUpdate, record]);

	const handleDeactivate = useCallback(() => {
		handleUpdate({ ...record, active: false });
	}, [handleUpdate, record]);

	const validate = (record: AlertRule) => {
		const errors: any = {};
		if (record.activation) {
			const result = formulaParser.parse(record.activation);
			if ('err' in result) {
				errors.activation = 'Syntax Error';
			}
		}

		if (record.scope) {
			const result = formulaParser.parse(record.scope);
			if ('err' in result) {
				errors.scope = 'Syntax Error';
			}
		}

		return errors;
	};

	const {
		touched,
		values,
		handleSubmit,
		handleChange,
		submitForm,
		setFieldValue,
		resetForm,
		errors,
	} = useFormik({
		initialValues: record,
		validate,
		onSubmit: async (record) => {
			setUpdate(record);
			openSaveDialog();
		},
	});

	const handleConfirmSave = useCallback(() => {
		if (touched) handleUpdate(update);
		endEditing();
		closeSaveDialog();
	}, [touched, handleUpdate, update, endEditing, closeSaveDialog]);

	const handleRemoveRecipient = useCallback(
		(i: number) => {
			const newRecipients = [...(values.recipients || [])];
			newRecipients.splice(i, 1);
			setFieldValue('recipients', newRecipients, true);
		},
		[values.recipients, setFieldValue]
	);

	const handleAddRecipient = useCallback(() => {
		const newRecipients = [
			...(values.recipients || []),
			{ channel: selectedChannel, target: recipient },
		];
		setFieldValue('recipients', newRecipients, true);
	}, [values.recipients, setFieldValue, selectedChannel, recipient]);

	const handleChannelChange = useCallback((e: ChangeEvent<any>) => {
		setSelectedChannel(e.target.value as Channel);
	}, []);

	const handleRecipientChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setRecipient(e.target.value);
		},
		[]
	);

	return useMemo(
		() =>
			recordId ? (
				<Box
					key={recordId}
					style={style}
					paddingRight={2}
					boxSizing="border-box"
				>
					<Paper className={itemClasses.root}>
						<form
							className={itemClasses.form}
							onSubmit={handleSubmit}
						>
							<Toolbar
								variant="dense"
								className={toolbarClasses.root}
							>
								<IconButton
									className={toolbarClasses.activateButton}
									onClick={
										record.active
											? handleDeactivate
											: handleActivate
									}
								>
									{record.active ? <Bell /> : <BellOff />}
								</IconButton>
								<Typography
									variant="subtitle2"
									className={itemClasses.title}
								>
									{record.name}
								</Typography>

								{editing ? (
									<IconButton
										className={toolbarClasses.iconButton}
										onClick={
											Object.keys(values).some(
												(field) => {
													let _field = field as keyof AlertRule;
													return (
														record[_field] !==
														values[_field]
													);
												}
											)
												? submitForm
												: endEditing
										}
									>
										<Check />
									</IconButton>
								) : null}
								{editing ? (
									<IconButton
										className={toolbarClasses.iconButton}
										onClick={() => resetForm()}
									>
										<Undo />
									</IconButton>
								) : (
									<IconButton
										className={toolbarClasses.iconButton}
										onClick={startEditing}
									>
										<Pencil />
									</IconButton>
								)}

								<IconButton
									className={toolbarClasses.iconButton}
									onClick={openRemoveDialog}
								>
									<TrashCan />
								</IconButton>
							</Toolbar>
							<Scrollbars className={itemClasses.tableContainer}>
								<Table className={itemClasses.table}>
									<TableBody
										className={itemClasses.tableBody}
									>
										<TableRow
											className={itemClasses.tableRow}
										>
											<TableCell>
												<Typography
													variant="caption"
													className={
														itemClasses.field
													}
												>
													Name
												</Typography>
											</TableCell>
											<TableCell
												className={
													itemClasses.valueCell
												}
											>
												{editing ? (
													<TextField
														id="name"
														name="name"
														multiline
														fullWidth
														error={!!errors.name}
														helperText={errors.name}
														value={values.name}
														onChange={handleChange}
														inputProps={{
															className:
																itemClasses.input,
														}}
													/>
												) : (
													<Typography
														variant="caption"
														className={
															itemClasses.value
														}
													>
														{record.name}
													</Typography>
												)}
											</TableCell>
										</TableRow>
										<TableRow
											className={itemClasses.tableRow}
										>
											<TableCell>
												<Typography
													variant="caption"
													className={
														itemClasses.field
													}
												>
													Target
												</Typography>
											</TableCell>
											<TableCell
												className={
													itemClasses.valueCell
												}
											>
												{editing ? (
													<TextField
														id="scope"
														name="scope"
														multiline
														fullWidth
														error={!!errors.scope}
														helperText={
															errors.scope
														}
														value={values.scope}
														onChange={handleChange}
														inputProps={{
															className:
																itemClasses.input,
														}}
													/>
												) : (
													<Typography
														variant="caption"
														className={
															itemClasses.value
														}
													>
														{record.scope}
													</Typography>
												)}
											</TableCell>
										</TableRow>
										<TableRow
											className={itemClasses.tableRow}
										>
											<TableCell>
												<Typography
													variant="caption"
													className={
														itemClasses.field
													}
												>
													Active When
												</Typography>
											</TableCell>
											<TableCell
												className={
													itemClasses.valueCell
												}
											>
												{editing ? (
													<TextField
														id="activation"
														name="activation"
														multiline
														fullWidth
														error={
															!!errors.activation
														}
														helperText={
															errors.activation
														}
														value={
															values.activation
														}
														onChange={handleChange}
														inputProps={{
															className:
																itemClasses.input,
														}}
													/>
												) : (
													<Typography
														variant="caption"
														className={
															itemClasses.value
														}
													>
														{record.activation}
													</Typography>
												)}
											</TableCell>
										</TableRow>
										<TableRow
											className={itemClasses.tableRow}
										>
											<TableCell>
												<Typography
													variant="caption"
													className={
														itemClasses.field
													}
												>
													Message
												</Typography>
											</TableCell>
											<TableCell
												className={
													itemClasses.valueCell
												}
											>
												{editing ? (
													<TextField
														id="messageTemplate"
														name="messageTemplate"
														multiline
														fullWidth
														error={
															!!errors.messageTemplate
														}
														helperText={
															errors.messageTemplate
														}
														value={
															values.messageTemplate
														}
														onChange={handleChange}
														inputProps={{
															className:
																itemClasses.input,
														}}
													/>
												) : (
													<Typography
														variant="caption"
														className={
															itemClasses.value
														}
													>
														{record.messageTemplate}
													</Typography>
												)}
											</TableCell>
										</TableRow>
										<TableRow
											className={itemClasses.tableRow}
										>
											<TableCell>
												<Typography
													variant="caption"
													className={
														itemClasses.field
													}
												>
													Recipients
												</Typography>
											</TableCell>
											<TableCell
												className={
													itemClasses.valueCell
												}
											>
												<Box
													className={
														itemClasses.chipList
													}
												>
													{(
														(editing
															? values.recipients
															: record.recipients) ||
														[]
													).map((recipient, i) => (
														<li key={i}>
															<Chip
																icon={
																	channelIcons[
																		recipient
																			.channel
																	]
																}
																label={
																	recipient.target
																}
																onDelete={
																	editing
																		? () =>
																				handleRemoveRecipient(
																					i
																				)
																		: undefined
																}
																className={
																	itemClasses.chip
																}
															/>
														</li>
													))}
												</Box>
												{editing ? (
													<Box
														display="flex"
														style={{
															flex: 1,
															alignItems:
																'stretch',
														}}
														flexDirection="row"
													>
														<FormControl
															variant="filled"
															style={{
																flex:
																	'1 0 150px',
															}}
														>
															<InputLabel
																id="channel-select-label"
																className={
																	itemClasses.select
																}
															>
																Channel
															</InputLabel>
															<Select
																labelId="channel-select-label"
																id="channel-select"
																variant="filled"
																value={
																	selectedChannel
																}
																style={{
																	height: 56,
																	marginRight: 4,
																}}
																inputProps={{
																	className:
																		itemClasses.select,
																}}
																onChange={
																	handleChannelChange
																}
															>
																<MenuItem
																	value={
																		Channel.EMAIL
																	}
																>
																	Email
																</MenuItem>
																<MenuItem
																	value={
																		Channel.DASHBOARD
																	}
																>
																	Dashboard
																</MenuItem>
																<MenuItem
																	value={
																		Channel.SMS
																	}
																>
																	SMS
																</MenuItem>
															</Select>
														</FormControl>
														<TextField
															variant="filled"
															label="Recipient"
															value={recipient}
															onChange={
																handleRecipientChange
															}
															className={
																itemClasses.textField
															}
															InputLabelProps={{
																className:
																	itemClasses.inputLabel,
															}}
															InputProps={{
																classes: {
																	root:
																		itemClasses.input,
																	underline:
																		itemClasses.inputUnderline,
																},
															}}
														/>
														<Button
															variant="outlined"
															color="primary"
															className={
																itemClasses.button
															}
															onClick={
																handleAddRecipient
															}
														>
															Add
														</Button>
													</Box>
												) : null}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</Scrollbars>
							<Dialog open={saveDialogOpened}>
								<DialogContent>
									<Typography variant="caption">
										Are you sure you want to save the
										changes?
									</Typography>
								</DialogContent>
								<DialogActions>
									<Button
										color="primary"
										onClick={handleConfirmSave}
									>
										YES
									</Button>
									<Button
										color="primary"
										variant="outlined"
										onClick={closeSaveDialog}
									>
										No
									</Button>
								</DialogActions>
							</Dialog>
							<Dialog open={removeDialogOpened}>
								<DialogContent>
									<Typography variant="caption">
										Are you sure you want to delete this
										alert rule?
									</Typography>
								</DialogContent>
								<DialogActions>
									<Button
										color="primary"
										onClick={handleConfirmRemove}
									>
										YES
									</Button>
									<Button
										color="primary"
										variant="outlined"
										onClick={closeRemoveDialog}
									>
										No
									</Button>
								</DialogActions>
							</Dialog>
						</form>
					</Paper>
				</Box>
			) : null,
		[
			saveDialogOpened,
			removeDialogOpened,
			handleConfirmRemove,
			closeSaveDialog,
			openRemoveDialog,
			closeRemoveDialog,
			editing,
			endEditing,
			handleActivate,
			handleChange,
			handleConfirmSave,
			handleDeactivate,
			handleAddRecipient,
			handleRemoveRecipient,
			recipient,
			selectedChannel,
			handleChannelChange,
			handleRecipientChange,
			submitForm,
			values,
			errors,
			resetForm,
			startEditing,
			handleSubmit,
			itemClasses,
			toolbarClasses,
			style,
			recordId,
			record,
		]
	);
}

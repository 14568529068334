import createActionCreator from '../../../Helpers/createActionCreator';
import { AnyAction } from 'redux';

// interface CreateLinkPayload {
// 	initialState: any;
// 	type: string;
// 	storeId: string;
// }

// export const createLink = createActionCreator(
// 	'EMBEDDED/CREATE_LINK',
// 	{} as CreateLinkPayload
// );

interface InitLinkPayload {
	storeId: string;
	type: string;
	message?: string;
	initialState?: any;
}

export const initLink = createActionCreator(
	'EMBEDDED/INIT_LINK',
	{} as InitLinkPayload
);

interface InitLinkSuccessPayload {
	state: any;
	type: string;
	storeId: string;
}

export const initLinkSuccess = createActionCreator(
	'EMBEDDED/INIT_LINK_SUCCESS',
	{} as InitLinkSuccessPayload
);

interface InitLinkFailurePayload {
	message?: string;
	type: string;
	storeId: string;
}

export const initLinkFailure = createActionCreator(
	'EMBEDDED/LOAD_STORE_FAILURE',
	{} as InitLinkFailurePayload
);

interface ForwardActionPayload {
	action: AnyAction;
	type: string;
	storeId: string;
}

export const forwardAction = createActionCreator(
	'EMBEDDED/FORWARD_ACTION',
	{} as ForwardActionPayload
);

import { createContext } from 'react';
import { Map, View } from 'ol';
import Layer from 'ol/layer/Layer';
import Source from 'ol/source/Source';

interface MapViewContext {
	map: Map;
	view: View;
}

export default createContext<MapViewContext>({
	map: new Map({}),
	view: new View(),
});

interface LayerContext {
	layer: Layer;
	source: Source;
}

export const layerContext = createContext<LayerContext>({
	layer: new Layer({}),
	source: new Source({}),
});

import React, { useMemo } from 'react';
import { IWeather } from './interfaces';
import {
	WeatherPanelWrapper,
	WeatherInfoIcon,
	WeatherInfoText,
	WeatherHumidityWrapper,
	WeatherTemperatureWrapper,
	WeatherRainfallWrapper,
	WeatherWindSpeedWrapper,
} from './WeatherPanel.sc';
import {
	mdiWaterPercent,
	mdiWeatherRainy,
	mdiThermometer,
	mdiWeatherWindy,
} from '@mdi/js';
import LoadingSpinner from '../Common/LoadingSpinner';

interface WeatherPanelProps {
	weather: IWeather;
	loading: boolean;
}

export default function WeatherPanel(props: WeatherPanelProps) {
	const {
		loading,
		weather: { humidity, rainfall, temperature },
	} = props;

	const avgHumidity = humidity.data.length
		? humidity.data.reduce((r, { value }) => r + value, 0) /
		  humidity.data.length
		: null;

	const avgRainfall = rainfall.data.length
		? rainfall.data.reduce((r, { max, min }) => r + (max + min) / 2, 0) /
		  rainfall.data.length
		: null;

	const avgTemperature = temperature.data.length
		? temperature.data.reduce((r, { value }) => r + value, 0) /
		  temperature.data.length
		: null;

	return useMemo(
		() => (
			<WeatherPanelWrapper>
				<WeatherWindSpeedWrapper>
					<WeatherInfoIcon path={mdiWeatherWindy} />
					{loading ? (
						<LoadingSpinner />
					) : (
						<WeatherInfoText>
							{Math.round(Math.random() * 6) + 10} km/h
						</WeatherInfoText>
					)}
				</WeatherWindSpeedWrapper>
				<WeatherTemperatureWrapper>
					<WeatherInfoIcon path={mdiThermometer} />
					{loading ? (
						<LoadingSpinner />
					) : (
						<WeatherInfoText>
							{avgTemperature
								? `${Math.round(avgTemperature * 10) / 10}°C`
								: '-'}
						</WeatherInfoText>
					)}
				</WeatherTemperatureWrapper>
				<WeatherHumidityWrapper>
					<WeatherInfoIcon path={mdiWaterPercent} />
					{loading ? (
						<LoadingSpinner />
					) : (
						<WeatherInfoText>
							{avgHumidity
								? `${Math.round(avgHumidity * 10) / 10}%`
								: '-'}
						</WeatherInfoText>
					)}
				</WeatherHumidityWrapper>
				<WeatherRainfallWrapper>
					<WeatherInfoIcon path={mdiWeatherRainy} />
					{loading ? (
						<LoadingSpinner />
					) : (
						<WeatherInfoText>
							{avgRainfall
								? `${Math.round(avgRainfall * 10) / 10}mm`
								: '-'}
						</WeatherInfoText>
					)}
				</WeatherRainfallWrapper>
			</WeatherPanelWrapper>
		),
		[loading, avgHumidity, avgRainfall, avgTemperature]
	);
}

import React, { useMemo, useRef } from 'react';
import { mdiWifi } from '@mdi/js';
import { VictoryPie, VictoryLegend, VictoryContainer } from 'victory';

import {
	ChartWrapper,
	ChartHeaderWrapper,
	ChartBodyWrapper,
	ChartNoDataWrapper,
	ChartNoDataText,
	ChartIcon,
	ChartTitleText,
} from './SignalIntervalChart.sc';
import { chartDarkTheme, stackColors } from '../../../../../chartTheme';
import useMeasure from '../../../../../Hooks/useMeasure';
import { PADDING, LABEL_SIZE, CHART_WIDTH } from '../constant';
import { MouseOverIcon } from './MouseOverIcon';

export interface SignalIntervalChartDatum {
	x: number;
	count: number;
}
export type SignalIntervalChartData = SignalIntervalChartDatum[];

interface SignalIntervalChartProps {
	data: SignalIntervalChartData;
}

const getInvterval = ({ x }: any) => {
	return typeof x === 'number' ? x : '';
};

const getLabel = ({ datum }: any) => {
	return datum.count.toString();
};

function SignalIntervalChart(props: SignalIntervalChartProps) {
	const { data } = props;

	const containerRef = useRef(null);
	const { width, height } = useMeasure(containerRef);

	return useMemo(() => {
		const dataCount = data.length;
		return (
			<ChartWrapper>
				<ChartHeaderWrapper>
					<ChartIcon icon={mdiWifi} />
					<ChartTitleText>SIGNAL INTERVAL</ChartTitleText>
					<MouseOverIcon />
				</ChartHeaderWrapper>
				<ChartBodyWrapper ref={containerRef}>
					{dataCount > 0 ? (
						<VictoryContainer width={width} height={height}>
							<VictoryPie
								standalone={false}
								x={getInvterval}
								width={height}
								height={height}
								padding={PADDING}
								theme={chartDarkTheme}
								data={data}
								y="count"
								labels={getLabel}
								style={{
									labels: { fontSize: LABEL_SIZE },
									data: {
										fill: ({
											datum: { x },
										}: {
											datum: SignalIntervalChartDatum;
										}) =>
											(({
												'5': '#EC6767',
												'60': '#EB9C40',
												'180': '#38B10D',
												'300': '#6794EC',
											} as any)[x] || 'grey'),
									},
								}}
								labelRadius={height / 2 - PADDING + 5}
								innerRadius={height / 2 - PADDING - CHART_WIDTH}
								radius={height / 2 - PADDING}
								padAngle={5}
							/>
							<VictoryLegend
								theme={chartDarkTheme}
								colorScale={stackColors}
								standalone={false}
								x={height + PADDING}
								y={height / 2 - (LABEL_SIZE * data.length) / 2}
								style={{
									labels: { fontSize: LABEL_SIZE },
									data: {
										fill: ({
											datum: { name },
										}: {
											datum: { name: string };
										}) =>
											(({
												'5 mins': '#EC6767',
												'1 hour': '#EB9C40',
												'3 hours': '#38B10D',
												'5 hours': '#6794EC',
												OTHER: 'grey',
											} as any)[name]),
									},
								}}
								data={data.map(({ x }) => {
									let name: string;
									switch (x) {
										case 5:
											name = '5 mins';
											break;
										case 60:
											name = '1 hour';
											break;
										case 180:
											name = '3 hours';
											break;
										case 300:
											name = '5 hours';
											break;
										default:
											name = 'OTHER';
									}
									return { name };
								})}
							/>
						</VictoryContainer>
					) : (
						<ChartNoDataWrapper>
							<ChartNoDataText>NO DATA</ChartNoDataText>
						</ChartNoDataWrapper>
					)}
				</ChartBodyWrapper>
			</ChartWrapper>
		);
	}, [data, containerRef, width, height]);
}

export default SignalIntervalChart;

import React, { useMemo, forwardRef, Ref } from 'react';
import MenuItem from './MenuItem';
import useGetMenuItemProps from '../Hooks/useGetMenuItemProps';
import { MenuChildrenProps } from '../interfaces';

const MenuChildren = forwardRef(function(
	props: MenuChildrenProps,
	ref: Ref<HTMLDivElement>
) {
	const getMenuItemProps = useGetMenuItemProps(props);
	return useMemo(() => <MenuItem ref={ref} {...getMenuItemProps()} />, [
		ref,
		getMenuItemProps,
	]);
});

export default MenuChildren;

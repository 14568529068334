export const treesTableInitialState = {
	columns: {
		treeId: {
			name: 'PolyU Tree ID',
			formula: { value: 'treeId' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		maintenance: {
			name: 'Maintenance In Progress',
			formula: { value: 'details.maintenance' },
			dataType: { type: 'boolean' },
			filter: { type: 'eq' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		departmentId: {
			name: 'Department Tree ID',
			formula: { value: 'details.departmentId' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		deptId: {
			name: 'Department',
			formula: { value: 'details.deptId' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		species: {
			name: 'Species',
			formula: {
				value: 'details.species',
			},
			filter: { type: 'contain' },
			dataType: { type: 'string' },
			editable: true,
			readonly: false,
			sortable: true,
		},
		treeLocation: {
			name: 'Location/ Road name',
			formula: {
				value: 'details.treeLocation',
			},
			filter: { type: 'contain' },
			dataType: { type: 'string' },
			editable: true,
			readonly: false,
			sortable: true,
		},
		dbh: {
			name: 'DBH (mm)',
			formula: {
				value: 'details.DBH',
			},
			filter: { type: 'eq' },
			dataType: { type: 'number' },
			format: {
				numberFormat: '0,0.00',
				unit: 'mm',
			},
			editable: true,
			readonly: false,
			sortable: true,
		},
		crownSpreadN: {
			name: 'Crown Spread N (m)',
			formula: {
				value: 'details.crownSpreadN',
			},
			filter: { type: 'eq' },
			dataType: { type: 'number' },
			editable: true,
			readonly: false,
			sortable: true,
			format: {
				numberFormat: '0,0.00',
				unit: 'm',
			},
		},
		crownSpreadS: {
			name: 'Crown Spread S (m)',
			formula: {
				value: 'details.crownSpreadS',
			},
			filter: { type: 'eq' },
			dataType: { type: 'number' },
			editable: true,
			readonly: false,
			sortable: true,
			format: {
				numberFormat: '0,0.00',
				unit: 'm',
			},
		},
		crownSpreadE: {
			name: 'Crown Spread E (m)',
			formula: {
				value: 'details.crownSpreadE',
			},
			filter: { type: 'eq' },
			dataType: { type: 'number' },
			editable: true,
			readonly: false,
			sortable: true,
			format: {
				numberFormat: '0,0.00',
				unit: 'm',
			},
		},
		crownSpreadW: {
			name: 'Crown Spread W (m)',
			formula: {
				value: 'details.crownSpreadW',
			},
			filter: { type: 'eq' },
			dataType: { type: 'number' },
			editable: true,
			readonly: false,
			sortable: true,
			format: {
				numberFormat: '0,0.00',
				unit: 'm',
			},
		},
		height: {
			name: 'Height (m)',
			formula: {
				value: 'details.height',
			},
			filter: { type: 'eq' },
			dataType: { type: 'number' },
			editable: true,
			readonly: false,
			sortable: true,
			format: {
				numberFormat: '0,0.00',
				unit: 'm',
			},
		},
		coorX: {
			name: 'CoorX',
			formula: {
				value: 'details.coorX',
			},
			dataType: { type: 'number' },
			filter: { type: 'eq' },
			format: {
				numberFormat: '0,0.0000000',
			},
			editable: false,
			readonly: false,
			sortable: true,
		},
		coorY: {
			name: 'CoorY',
			formula: {
				value: 'details.coorY',
			},
			dataType: { type: 'number' },
			filter: { type: 'eq' },
			format: {
				numberFormat: '0,0.0000000',
			},
			editable: false,
			readonly: false,
			sortable: true,
		},
		vegId: {
			name: 'VEG ID',
			formula: {
				value: 'details.vegId',
			},
			filter: { type: 'contain' },
			dataType: { type: 'string' },
			editable: true,
			readonly: false,
			sortable: true,
		},
		featureTy: {
			name: 'Feature Ty',
			formula: {
				value: 'details.featureTy',
			},
			filter: { type: 'contain' },
			dataType: { type: 'string' },
			editable: true,
			readonly: false,
			sortable: true,
		},
		ovtId: {
			name: 'OVT ID',
			formula: {
				value: 'details.ovtId',
			},
			filter: { type: 'contain' },
			dataType: { type: 'string' },
			editable: true,
			readonly: false,
			sortable: true,
		},
		zone: {
			name: 'Zone',
			formula: {
				value: 'details.zone',
			},
			filter: { type: 'contain' },
			dataType: { type: 'string' },
			editable: true,
			readonly: false,
			sortable: true,
		},
		lastProjectInspectionDate: {
			name: 'Last Inpection Date by Project',
			formula: {
				value: 'details.lastProjectInspectionDate',
			},
			dataType: { type: 'date' },
			filter: { type: 'between' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		lastGovInspectionDate: {
			name: 'Last Inspection Date by Department',
			formula: {
				value: 'details.lastGovInspectionDate',
			},
			dataType: { type: 'date' },
			filter: { type: 'between' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		trLevel: {
			name: 'TR Level',
			formula: {
				value: 'details.trLevel',
			},
			filter: { type: 'contain' },
			dataType: { type: 'string' },
			editable: true,
			readonly: false,
			sortable: true,
		},
		shotcrete: {
			name: 'Shotcrete',
			formula: {
				value: 'details.shotcrete',
			},
			filter: { type: 'contain' },
			dataType: { type: 'string' },
			editable: true,
			readonly: false,
			sortable: true,
		},
		contactPerson: {
			name: 'Contact Person',
			formula: {
				value: 'details.contactPerson',
			},
			filter: { type: 'contain' },
			dataType: { type: 'string' },
			editable: true,
			readonly: false,
			sortable: true,
		},
		post: {
			name: 'Post',
			formula: {
				value: 'details.post',
			},
			filter: { type: 'contain' },
			dataType: { type: 'string' },
			editable: true,
			readonly: false,
			sortable: true,
		},
		phoneNo: {
			name: 'Phone No.',
			formula: {
				value: 'details.phoneNo',
			},
			filter: { type: 'contain' },
			dataType: { type: 'string' },
			editable: true,
			readonly: false,
			sortable: true,
		},
		long: {
			name: 'Longitude (°)',
			formula: {
				value: 'location.coordinates[0]',
			},
			dataType: { type: 'number' },
			filter: { type: 'eq' },
			format: {
				numberFormat: '0,0.0000000',
				unit: '°',
			},
			editable: false,
			readonly: false,
			sortable: true,
		},
		lat: {
			name: 'Latitude (°)',
			formula: {
				value: 'location.coordinates[1]',
			},
			dataType: { type: 'number' },
			filter: { type: 'eq' },
			format: {
				numberFormat: '0,0.0000000',
				unit: '°',
			},
			editable: false,
			readonly: false,
			sortable: true,
		},
		district: {
			name: 'District',
			formula: {
				value: 'details.district',
			},
			filter: { type: 'contain' },
			dataType: { type: 'string' },
			editable: true,
			readonly: false,
			sortable: true,
		},
		tiltingAngle: {
			name: 'Tilting Angle (°)',
			formula: {
				// value:
				// 	'MAX(MAP(recentReadings, "sensorReading", ABS(sensorReading.readings[0].treeForm.inclination - FILTER(naturalForm, "sensorForm", sensorForm.sensorId == sensorReading.sensorId)[0].treeForm.inclination) ?= 0) ?= []) ?= 0',
				value:
					'MAX(MAP(recentReadings, "sensorReading", ABS(sensorReading.readings[0].tiltingAngle ?= 0)))',
			},
			filter: { type: 'eq' },
			dataType: { type: 'number' },
			editable: false,
			readonly: false,
			sortable: true,
			unit: '°',
		},
		isLean: {
			name: 'Lean',
			formula: {
				value: 'details.isLean',
			},
			filter: { type: 'eq' },
			dataType: { type: 'boolean' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		leanDirection: {
			name: 'Lean Direction',
			formula: {
				value: 'details.leanDirection',
			},
			filter: { type: 'contain' },
			dataType: { type: 'string' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		leanAngle: {
			name: 'Lean Angle',
			formula: {
				value: 'details.leanAngle',
			},
			filter: { type: 'contain' },
			dataType: { type: 'string' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		leanReason: {
			name: 'Lean Reason',
			formula: {
				value: 'details.leanReason',
			},
			filter: { type: 'contain' },
			dataType: { type: 'string' },
			editable: false,
			readonly: false,
			sortable: true,
		},
	},
	columnKeys: [
		'treeId',
		'maintenance',
		'departmentId',
		'deptId',
		'species',
		'treeLocation',
		'dbh',
		'crownSpreadN',
		'crownSpreadS',
		'crownSpreadE',
		'crownSpreadW',
		'height',
		'coorX',
		'coorY',
		'vegId',
		'featureTy',
		'ovtId',
		'zone',
		'lastProjectInspectionDate',
		'lastGovInspectionDate',
		'trLevel',
		'shotcrete',
		'contactPerson',
		'post',
		'phoneNo',
		'long',
		'lat',
		'district',
		'tiltingAngle',
		'isLean',
		'leanDirection',
		'leanAngle',
		'leanReason',
	],
};
export const sensorsTableInitialState = {
	columns: {
		sensorId: {
			name: 'Sensor ID',
			formula: { value: 'sensorId' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		installedOn: {
			name: 'Tree ID',
			formula: { value: 'installedOn' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		district: {
			name: 'District',
			formula: {
				value: 'details.district',
			},
			filter: { type: 'contain' },
			dataType: { type: 'string' },
			editable: true,
			readonly: false,
			sortable: true,
		},
		deviceId: {
			name: 'Device ID',
			formula: { value: 'deviceId' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		hardwareId: {
			name: 'Hardware ID',
			formula: { value: 'hardwareId' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		platform: {
			name: 'Platform',
			formula: { value: 'platform' },
			filter: { type: 'contain' },
			dataType: { type: 'string' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		lastMessageReceivedAt: {
			name: 'Last Message Time',
			formula: { value: 'lastMessageReceivedAt' },
			dataType: { type: 'date' },
			filter: { type: 'between' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		production: {
			name: 'Production',
			formula: { value: 'production' },
			dataType: { type: 'boolean' },
			filter: { type: 'eq' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		installDate: {
			name: 'Installation Date',
			formula: { value: 'details.installDate' },
			dataType: { type: 'date' },
			filter: { type: 'between' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		activeMode: {
			name: 'Active Mode',
			formula: { value: 'activeMode.name' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: true,
			sortable: true,
		},
		sendInterval: {
			name: 'Send Interval',
			formula: { value: 'activeMode.settings.sendInterval' },
			dataType: { type: 'number' },
			filter: { type: 'eq' },
			editable: false,
			readonly: true,
			sortable: true,
		},
		samplingInterval: {
			name: 'Sampling Interval',
			formula: { value: 'activeMode.settings.samplingInterval' },
			dataType: { type: 'number' },
			filter: { type: 'eq' },
			editable: false,
			readonly: true,
			sortable: true,
		},
		voltage: {
			name: 'Voltage (mV)',
			formula: { value: 'voltage' },
			dataType: { type: 'number' },
			filter: { type: 'eq' },
			editable: false,
			readonly: false,
			sortable: true,
			unit: 'mV',
		},
		strength: {
			name: 'Strength (mdB)',
			formula: { value: 'strength' },
			dataType: { type: 'number' },
			filter: { type: 'eq' },
			editable: false,
			readonly: false,
			sortable: true,
			unit: 'mdB',
		},
		height: {
			name: 'Height (m)',
			formula: { value: 'details.height' },
			dataType: { type: 'number' },
			filter: { type: 'eq' },
			editable: true,
			readonly: false,
			sortable: true,
			unit: 'm',
		},
		bearing: {
			name: 'Bearing (°)',
			formula: { value: 'details.bearing' },
			dataType: { type: 'number' },
			filter: { type: 'eq' },
			editable: true,
			readonly: false,
			sortable: true,
			unit: '°',
		},
	},
	columnKeys: [
		'sensorId',
		'installedOn',
		'district',
		'deviceId',
		'hardwareId',
		'platform',
		'lastMessageReceivedAt',
		'production',
		'installDate',
		'activeMode',
		'sendInterval',
		'samplingInterval',
		'voltage',
		'strength',
		'height',
		'bearing',
	],
};

export const readingsTableInitialState = {
	columns: {
		recordedAt: {
			name: 'Record Time',
			formula: { value: 'recordedAt' },
			dataType: { type: 'date' },
			filter: { type: 'between' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		sensorId: {
			name: 'Sensor ID',
			formula: { value: 'sensorId' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		treeId: {
			name: 'Tree ID',
			formula: { value: 'treeId' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		roll: {
			name: 'Roll (°)',
			formula: { value: 'treeForm.roll' },
			dataType: { type: 'number' },
			filter: { type: 'eq' },
			editable: false,
			readonly: false,
			sortable: false,
			unit: '°',
		},
		pitch: {
			name: 'Pitch (°)',
			formula: { value: 'treeForm.pitch' },
			dataType: { type: 'number' },
			filter: { type: 'eq' },
			editable: false,
			readonly: false,
			sortable: false,
			unit: '°',
		},
		yaw: {
			name: 'Yaw (°)',
			formula: { value: 'treeForm.yaw' },
			dataType: { type: 'number' },
			filter: { type: 'eq' },
			editable: false,
			readonly: false,
			sortable: false,
			unit: '°',
		},
		rawTilting: {
			name: 'Raw Tilting (°)',
			formula: { value: 'treeForm.inclination' },
			dataType: { type: 'number' },
			filter: { type: 'eq' },
			editable: false,
			readonly: false,
			sortable: false,
			unit: '°',
		},
		rawLeaning: {
			name: 'Raw Leaning (°)',
			formula: { value: 'treeForm.leaning' },
			dataType: { type: 'number' },
			filter: { type: 'eq' },
			editable: false,
			readonly: false,
			sortable: false,
			unit: '°',
		},
		tiltingAngle: {
			name: 'TiltingAngle (°)',
			formula: { value: 'tiltingAngle' },
			dataType: { type: 'number' },
			filter: { type: 'eq' },
			editable: false,
			readonly: false,
			sortable: false,
			unit: '°',
		},
		leaning: {
			name: 'Leaning (°)',
			formula: { value: 'treeForm.leaning' },
			dataType: { type: 'number' },
			filter: { type: 'eq' },
			editable: false,
			readonly: false,
			sortable: false,
			unit: '°',
		},
		temperature: {
			name: 'temperature (°C)',
			formula: { value: 'temperature' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: false,
			unit: '°C',
		},
		voltage: {
			name: 'voltage (mV)',
			formula: { value: 'voltage' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: false,
			unit: 'mV',
		},
		vibration: {
			name: 'vibration',
			formula: { value: 'vibration' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: false,
		},
	},
	columnKeys: [
		'recordedAt',
		'sensorId',
		'treeId',
		'roll',
		'pitch',
		'yaw',
		'rawTilting',
		'rawLeaning',
		'tiltingAngle',
		'leaning',
		'temperature',
		'voltage',
		'vibration',
	],
};

export const maintenanceInitialState = {
	columns: {
		maintenanceDate: {
			name: 'Maintenance Time',
			formula: { value: 'maintenanceDate' },
			dataType: { type: 'date' },
			filter: { type: 'between' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		maintenanceId: {
			name: 'Maintenance ID',
			formula: { value: 'maintenanceId' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		treeId: {
			name: 'Tree ID',
			formula: { value: 'treeId' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		from: {
			name: 'Original Sensor ID',
			formula: { value: 'from' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		to: {
			name: 'New Sensor ID',
			formula: { value: 'to' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		action: {
			name: 'Action',
			formula: { value: 'action' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		remark: {
			name: 'Remark',
			formula: { value: 'remark' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
	},
	columnKeys: [
		'maintenanceDate',
		'maintenanceId',
		'treeId',
		'from',
		'to',
		'action',
		'remark',
	],
};

export const systemLogsInitialState = {
	columns: {
		createdAt: {
			name: 'Time',
			formula: { value: 'createdAt' },
			dataType: { type: 'date' },
			filter: { type: 'between' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		username: {
			name: 'Username',
			formula: { value: 'username' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		ip: {
			name: 'IP',
			formula: { value: 'ip' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		deptId: {
			name: 'Department ID',
			formula: { value: 'deptId' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		application: {
			name: 'Application',
			formula: { value: 'application' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		action: {
			name: 'Action',
			formula: { value: 'action' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		target: {
			name: 'Target',
			formula: { value: 'target' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		result: {
			name: 'Result',
			formula: { value: 'result' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
	},
	columnKeys: [
		'createdAt',
		'username',
		'ip',
		'deptId',
		'application',
		'action',
		'target',
		'result',
	],
};

export const scenarioChangeLogsInitialState = {
	columns: {
		createdAt: {
			name: 'Time',
			formula: { value: 'createdAt' },
			dataType: { type: 'date' },
			filter: { type: 'between' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		from: {
			name: 'Change From',
			formula: { value: 'from' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		to: {
			name: 'Change To',
			formula: { value: 'to' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		sensorId: {
			name: 'Sensor ID',
			formula: { value: 'sensorId' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
		installedOn: {
			name: 'Tree ID',
			formula: { value: 'installedOn' },
			dataType: { type: 'string' },
			filter: { type: 'contain' },
			editable: false,
			readonly: false,
			sortable: true,
		},
	},
	columnKeys: ['createdAt', 'from', 'to', 'sensorId', 'installedOn'],
};

import styled from 'styled-components';
import { View } from '../../../../Common/View';
import { CaptionText } from '../../../../Common/Text';
import WrappedIcon from '../../../../Common/WrappedIcon';
import Button from '../../../../Common/Button';
import { ButtonOverlayShape } from '../../../../Common/Button/interfaces';
import { SortOrder } from '../../../interfaces';
import constants from '../../../constants';

const { HEADER_HEIGHT } = constants;

export interface HeaderCellTitleWrapperProps {
	resizing: boolean;
}
export const HeaderCellTitleWrapper = styled(View)<HeaderCellTitleWrapperProps>`
	flex-direction: row;
	align-items: stretch;
	height: ${HEADER_HEIGHT / 2}px;
	padding: 0 4px;
	background-color: ${({
		theme: {
			background: { frameHighlight, frame },
		},
		resizing,
	}) => (resizing ? frameHighlight : frame)};
`;

export const RearrangeIcon = styled(WrappedIcon).attrs(
	({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => ({
		iconProps: {
			color: darkBgPrimary,
		},
	})
)`
	width: ${HEADER_HEIGHT / 2}px;
	height: ${HEADER_HEIGHT / 2}px;
	margin-left: 2px;
	cursor: move;
`;

export const SortButton = styled(Button).attrs({
	lightBg: false,
	overlayShape: ButtonOverlayShape.circle,
})`
	justify-content: center;
	width: ${HEADER_HEIGHT / 2}px;
	height: ${HEADER_HEIGHT / 2}px;
	margin-right: 2px;
	& > [x-component='icon'] {
		& > [x-component='icon'] {
			transition: all 0.2s;
		}
	}
`;

interface SortIconProps {
	order: SortOrder;
}
export const SortIcon = styled(WrappedIcon).attrs<SortIconProps>(
	({
		theme: {
			font: {
				color: { darkBgPrimary, darkBgDisabled },
			},
		},
		order,
	}) => ({
		iconProps: {
			size: 1,
			color: !!order ? darkBgPrimary : darkBgDisabled,
			rotate: order * -90,
		},
	})
)<SortIconProps>`
	& > [x-component='icon'] {
		transition: all 0.2s;
	}
`;

export const SortPriorityText = styled(CaptionText)`
	position: absolute;
	right: 0;
	bottom: 0;
	color: ${({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => darkBgPrimary};
`;

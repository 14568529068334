import styled from 'styled-components';
import { View } from '../../../../Common/View';
import WrappedInput from '../../../../Common/WrappedInput';
import { CaptionText } from '../../../../Common/Text';
import constants from '../../../constants';
const { PAGINATION_HEIGHT } = constants;

export const PaginationInputWrapper = styled(View)`
	/* width: ${PAGINATION_HEIGHT * 2}px; */
	flex-direction: row;
	align-items: center;
`;

export const PaginationInput = styled(WrappedInput)`
	min-width: ${PAGINATION_HEIGHT}px;
	max-width: ${PAGINATION_HEIGHT * 4}px;
	& > [x-component='input'] {
		color: ${({
			theme: {
				font: {
					color: { lightBgSecondary },
				},
			},
		}) => lightBgSecondary};
		text-align: center;
	}
	& > [x-component='underline'] {
		background-color: ${({
			theme: {
				background: { darken },
			},
		}) => darken[2]};
	}
`;

export const PaginationInputText = styled(CaptionText)`
	margin-left: 8px;
	margin-right: 8px;
`;

import React, { useMemo, useCallback, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ObjectId from 'bson-objectid';

import RecordsFeedGrid from '../../../../../Components/RecordsFeedGrid/RecordsFeedGrid';
import { RecordOf } from 'immutable';
import { IRootState } from '../../../../../Store/reducers/root';
import { AlertControlWrapper } from './AlertControl.sc';
import {
	fetchRecords,
	updateSearchText,
	updateRecord,
} from '../../../../../Components/RecordsFeed/actions';
import AlertRuleRecord from './AlertRuleRecord';
import { ActivityWindow } from '../../../../../Components/Dashboard';
import { Activity } from '../../../../../Components/Dashboard/interfaces';
import { useUplinkDispatch } from '../../../../../Hooks/useUplinkDispatch';

type Props = {
	activity: Activity;
};

function useAlertControlStore() {
	return useSelector((state: RecordOf<IRootState>) => state.alertControl);
}

function getDefaultAlertRule() {
	return {
		_id: new ObjectId().toHexString(),
		name: 'New Alert Rule',
		active: false,
		scope: 'true',
		activation: 'true',
		messageTemplate: '{{treeId}}',
	};
}
const feedId = 'alertControl';
const collection = 'alertRules';
export default function WindowedAlertControl(props: Props) {
	const {
		activity: { id },
	} = props;

	return (
		<ActivityWindow activityId={id}>
			<AlertControl />
		</ActivityWindow>
	);
}

export function AlertControl() {
	const alertControlStore = useAlertControlStore();
	const {
		exhausted,
		loading,
		recordIds,
		records,
		search,
		updating,
	} = alertControlStore;

	const containerRef = useRef<HTMLDivElement>(null);

	const dispatch = useUplinkDispatch();

	const handleAdd = useCallback(() => {
		const newRule = getDefaultAlertRule();
		dispatch(
			updateRecord({
				collection,
				feedId,
				record: newRule,
				recordId: newRule._id,
			})
		);
	}, [dispatch]);

	const fetchData = useCallback(() => {
		if (!loading && !exhausted)
			dispatch(
				fetchRecords({
					fetched: recordIds.size,
					collection,
					search,
					searchKey: 'name',
					feedId,
				})
			);
	}, [loading, exhausted, recordIds.size, search, dispatch]);

	useEffect(() => {
		fetchData();
	}, [search, fetchData]);

	const handleSearchTextChange = useCallback(
		(search: string) => {
			dispatch(
				updateSearchText({
					feedId,
					collection,
					searchKey: 'name',
					searchText: search,
				})
			);
		},
		[dispatch]
	);

	return useMemo(
		() => (
			<AlertControlWrapper ref={containerRef}>
				<RecordsFeedGrid
					updating={updating}
					search={search}
					loading={loading}
					exhausted={exhausted}
					addButtonText="Add Rule"
					searchPlaceholder="Search with alert rule name"
					itemHeight={360}
					xsCount={1}
					smCount={1}
					mdCount={2}
					lgCount={3}
					xlCount={3}
					recordIds={recordIds}
					records={records}
					onAdd={handleAdd}
					onEndReach={fetchData}
					onSearchTextChange={handleSearchTextChange}
				>
					{AlertRuleRecord}
				</RecordsFeedGrid>
			</AlertControlWrapper>
		),
		[
			exhausted,
			loading,
			recordIds,
			records,
			search,
			updating,
			fetchData,
			handleAdd,
			handleSearchTextChange,
		]
	);
}

import OlMap from 'ol/Map';
import { useEffect, useRef } from 'react';

export default function useUpdateCenter(
	olMap: OlMap,
	center: number[],
	onCenterChange: (center: number[]) => any
) {
	useEffect(() => {
		const currentCenter = olMap.getView().getCenter();
		if (
			!currentCenter ||
			(currentCenter[0] !== center[0] || currentCenter[1] !== center[1])
		)
			olMap.getView().animate({ center });
	}, [olMap, center]);

	const centerRef = useRef(center);

	useEffect(() => {
		centerRef.current = center;
	});

	useEffect(() => {
		function updateCenterProps() {
			const view = olMap.getView();
			const newCenter = view.getCenter();
			const prevCenter = centerRef.current;
			if (
				newCenter &&
				(newCenter[0] !== prevCenter[0] ||
					newCenter[1] !== prevCenter[1])
			) {
				onCenterChange(newCenter);
			}
			return true;
		}
		olMap.on('moveend', updateCenterProps);

		return () => {
			olMap.un('moveend', updateCenterProps);
		};
	}, [olMap, onCenterChange]);
}

import styled from 'styled-components';
import { CaptionText } from '../../../../Common/Text';

export const TimestampText = styled(CaptionText)`
	color: ${({
		theme: {
			font: {
				color: { lightBgSecondary },
			},
		},
	}) => lightBgSecondary};
	margin-bottom: 8px;
	text-align: right;
`;

import React, { useMemo } from 'react';
import { Transition } from 'react-transition-group';
import { ActivitiesScrollWrapper, ActivitiesWrapper } from './Activities.sc';
import { ActivitiesProps } from '../interfaces';

export default function Activities(props: ActivitiesProps) {
	const { show, children } = props;
	return useMemo(() => {
		return (
			<Transition in={show} timeout={100}>
				{(status) => {
					return (
						<ActivitiesScrollWrapper status={status}>
							<ActivitiesWrapper>{children}</ActivitiesWrapper>
						</ActivitiesScrollWrapper>
					);
				}}
			</Transition>
		);
	}, [show, children]);
}

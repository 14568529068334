import { DesktopEdge, DesktopWindowState } from '../interfaces';
import { EDGE_SIZE } from '../constant';
import useDropZones from '../../../Hooks/useDropZones';
import desktopContext from '../context';
import { useContext, useCallback } from 'react';
import { MaximizeType, DropZone } from '../../../interfaces';

export default function useDropOnEdge(
	windowId: string,
	windowState: DesktopWindowState
) {
	const {
		left,
		top,
		width,
		height,
		originalLeft,
		originalTop,
		originalWidth,
		originalHeight,
	} = windowState;

	const {
		containerRef,
		desktopWidth,
		desktopHeight,
		setTilePreview,
		onWindowChange,
	} = useContext(desktopContext);

	const containerElm = containerRef.current;

	const { left: desktopLeft, top: desktopTop } = containerElm
		? containerElm.getBoundingClientRect()
		: { left: 0, top: 0 };

	const handleEnterEdge = useCallback(
		(zone: DropZone, e: MouseEvent) => {
			const { clientX, clientY } = e;
			setTilePreview({
				edge: zone.id,
				x: clientX - desktopLeft,
				y: clientY - desktopTop,
			});
		},
		[setTilePreview, desktopLeft, desktopTop]
	);

	const handleLeaveEdges = useCallback(
		(e: MouseEvent) => {
			const { clientX, clientY } = e;
			setTilePreview({
				edge: DesktopEdge.NULL,
				x: clientX - desktopLeft,
				y: clientY - desktopTop,
			});
		},
		[setTilePreview, desktopLeft, desktopTop]
	);

	const handleDropOnEdge = useCallback(
		(zone: DropZone, e: MouseEvent) => {
			const { clientX, clientY } = e;
			const newState: Partial<DesktopWindowState> =
				zone.id === DesktopEdge.LEFT
					? {
							maximized: MaximizeType.LEFT,
							left: 0,
							top: 0,
							width: desktopWidth / 2,
							height: desktopHeight,
							originalLeft: originalLeft || left,
							originalTop: originalTop || top,
							originalWidth: originalWidth || width,
							originalHeight: originalHeight || height,
					  }
					: zone.id === DesktopEdge.RIGHT
					? {
							maximized: MaximizeType.RIGHT,
							left: desktopWidth / 2,
							top: 0,
							width: desktopWidth / 2,
							height: desktopHeight,
							originalLeft: originalLeft || left,
							originalTop: originalTop || top,
							originalWidth: originalWidth || width,
							originalHeight: originalHeight || height,
					  }
					: zone.id === DesktopEdge.TOP
					? {
							maximized: MaximizeType.FULL,
							left: 0,
							top: 0,
							width: desktopWidth,
							height: desktopHeight,
							originalLeft: originalLeft || left,
							originalTop: originalTop || top,
							originalWidth: originalWidth || width,
							originalHeight: originalHeight || height,
					  }
					: {};
			setTilePreview({
				edge: DesktopEdge.NULL,
				x: clientX - desktopLeft,
				y: clientY - desktopTop,
			});
			onWindowChange && onWindowChange(windowId, newState);
		},
		[
			windowId,
			left,
			top,
			width,
			height,
			originalLeft,
			originalTop,
			originalWidth,
			originalHeight,
			desktopWidth,
			desktopHeight,
			onWindowChange,
			setTilePreview,
			desktopLeft,
			desktopTop,
		]
	);

	return useDropZones({
		dropZones: [
			{
				id: DesktopEdge.LEFT,
				x1: -Infinity,
				x2: desktopLeft + EDGE_SIZE,
				y1: desktopTop + EDGE_SIZE,
				y2: Infinity,
			},
			{
				id: DesktopEdge.RIGHT,
				x1: desktopLeft + desktopWidth - EDGE_SIZE,
				x2: Infinity,
				y1: 10,
				y2: Infinity,
			},
			{
				id: DesktopEdge.TOP,
				x1: desktopLeft + EDGE_SIZE,
				x2: desktopLeft + desktopWidth - EDGE_SIZE,
				y1: -Infinity,
				y2: desktopTop + EDGE_SIZE,
			},
		],
		onEnterZone: handleEnterEdge,
		onLeaveZones: handleLeaveEdges,
		onDrop: handleDropOnEdge,
	});
}

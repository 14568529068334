import { useCallback } from 'react';
import {
	updateUnmergedRecords,
	dropUnmergedRecords,
	requestMergeRecords,
	exportRecords,
	importRecordsFile,
} from '../Store/actions';
import { getUnmergedRecord } from '../Helpers/Record';
import { ITableContext } from '../context';

export function useUnmergedRecordDrop(
	contextObj: ITableContext,
	recordId: string,
	columnKey: string
) {
	const { onRequestDispatch } = contextObj;
	const unmergedRecord = getUnmergedRecord(contextObj, recordId, columnKey);
	return useCallback(() => {
		if (unmergedRecord !== undefined)
			onRequestDispatch(
				dropUnmergedRecords({
					keys: [{ recordId, columnKey }],
				})
			);
	}, [unmergedRecord, onRequestDispatch, recordId, columnKey]);
}

export function useAllUnmergedRecordDrop(contextObj: ITableContext) {
	const { onRequestDispatch } = contextObj;
	return useCallback(() => {
		onRequestDispatch(
			dropUnmergedRecords({
				keys: null,
			})
		);
	}, [onRequestDispatch]);
}

export function useUnmergedRecordUpdate(
	contextObj: ITableContext,
	recordId: string,
	columnKey: string
) {
	const { onRequestDispatch } = contextObj;
	const unmergedRecord = getUnmergedRecord(contextObj, recordId, columnKey);
	return useCallback(
		(value: any) => {
			if (value !== undefined && value !== unmergedRecord)
				onRequestDispatch(
					updateUnmergedRecords({
						changes: [{ recordId, columnKey, value }],
					})
				);
		},
		[onRequestDispatch, unmergedRecord, recordId, columnKey]
	);
}

export function useAllRecordMergeRequest(contextObj: ITableContext) {
	const { onRequestDispatch } = contextObj;
	return useCallback(() => {
		onRequestDispatch(requestMergeRecords({ keys: null }));
	}, [onRequestDispatch]);
}

export function useRecordsFileImport(contextObj: ITableContext) {
	const { onRequestDispatch } = contextObj;
	return useCallback(
		(file: File) => {
			onRequestDispatch(importRecordsFile({ file }));
		},
		[onRequestDispatch]
	);
}

export function useRecordsExport(contextObj: ITableContext) {
	const { onRequestDispatch } = contextObj;
	return useCallback(
		(
			filename: string,
			extension: string,
			from: number,
			to: number,
			all: boolean
		) => {
			onRequestDispatch(
				exportRecords({ filename, extension, from, to, all })
			);
		},
		[onRequestDispatch]
	);
}

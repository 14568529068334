import styled from 'styled-components';
import { View } from '../../View';
import { ButtonOverlayShape } from '../interfaces';

export interface ButtonWrapperProps {
	disabled: boolean;
}

export const ButtonWrapper = styled(View)<ButtonWrapperProps>`
	flex-direction: row;
	align-items: stretch;
	background-color: transparent;
	cursor: pointer;
	user-select: none;
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'unset')};
	&:focus {
		outline: none;
	}
`;

export interface ButtonOverlayProps {
	hovered: boolean;
	focused: boolean;
	pressed: boolean;
	activated: boolean;
	disabled: boolean;
	lightBg: boolean;
	overlayShape: ButtonOverlayShape;
}

export const ButtonOverlay = styled(View)<ButtonOverlayProps>`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: ${({
		theme: {
			background: { darken, lighten, primaryAlpha },
		},
		lightBg,
		hovered,
		focused,
		pressed,
		activated,
	}) =>
		activated
			? pressed
				? primaryAlpha[3]
				: focused
				? primaryAlpha[2]
				: hovered
				? primaryAlpha[1]
				: 'transparent'
			: lightBg
			? pressed
				? darken[3]
				: focused
				? darken[2]
				: hovered
				? darken[1]
				: 'transparent'
			: pressed
			? lighten[3]
			: focused
			? lighten[2]
			: hovered
			? lighten[1]
			: 'transparent'};
	border-radius: ${({ overlayShape }) =>
		overlayShape === 'circle' ? '50%' : 0};
	transition: all 150ms;
	pointer-events: none;
`;

import createActionCreator from '../../Helpers/createActionCreator';
import { AnyAction } from 'redux';
import WebSocketClient from '../sagas/services/WebSocketClient';

export interface IInitChildStorePayload {
	storeId: string;
	type: string;
}

export const initChildStore = createActionCreator(
	'STORE/INIT',
	{} as IInitChildStorePayload
);

export interface IInitChildStoreSuccessPayload {
	storeId: string;
	type: string;
	data: any;
}
export const initChildStoreSuccess = createActionCreator(
	'STORE/INIT_SUCCESS',
	{} as IInitChildStoreSuccessPayload
);

export interface IInitChildStoreFailurePayload {
	storeId: string;
	type: string;
}

export const initChildStoreFailure = createActionCreator(
	'STORE/INIT_FAILURE',
	{} as IInitChildStoreFailurePayload
);

interface IBroadcastActionPayload {
	action: {
		type: string;
		payload?: any;
		metadata: {
			forwardFor: {
				type: string;
				storeId: string;
			};
			[key: string]: any;
		};
	};
	// action: AnyAction;
}

export const boardcastAction = createActionCreator(
	'STORE/BROADCAST',
	{} as IBroadcastActionPayload
);

interface IDownlinkActionPayload {
	action: AnyAction;
}

export const downlinkAction = createActionCreator(
	'STORE/DOWNLINK',
	{} as IDownlinkActionPayload
);

interface IUplinkActionPayload {
	action: AnyAction;
}

export const uplinkAction = createActionCreator(
	'STORE/UPLINK',
	{} as IUplinkActionPayload
);

interface ISubscribePayload {
	storeId: string;
	type: string;
}

export const subscribe = createActionCreator(
	'STORE/SUBSCRIBE',
	{} as ISubscribePayload
);

interface IWebSocketReadyPayload {
	client: WebSocketClient;
}

export const webSocketReady = createActionCreator(
	'STORE/WS_READY',
	{} as IWebSocketReadyPayload
);

export const closeWebsocket = createActionCreator('STORE/WS_CLOSE');

import React, {
	useMemo,
	useCallback,
	MouseEvent as RMouseEvent,
	KeyboardEvent as RKeyboardEvent,
} from 'react';
import { mdiCloseCircle } from '@mdi/js';
import {
	BodyCellStatusIndicatorLoadingSpinner,
	BodyCellStatusIndicatorButton,
} from './BodyCellStatusIndicator.sc';
import { MergeStatus } from '../../../interfaces';

interface BodyCellStatusIndicatorProps {
	mergeStatus: MergeStatus;
	dropUnmergedRecord(): void;
}
function BodyCellStatusIndicator(props: BodyCellStatusIndicatorProps) {
	const { mergeStatus, dropUnmergedRecord } = props;
	const handlePress = useCallback(
		(e: RMouseEvent<HTMLDivElement> | RKeyboardEvent<HTMLDivElement>) => {
			e.preventDefault();
			dropUnmergedRecord();
		},
		[dropUnmergedRecord]
	);
	return useMemo(() => {
		if (mergeStatus === 'merging')
			return <BodyCellStatusIndicatorLoadingSpinner />;
		if (mergeStatus === 'unmerged' || mergeStatus === 'failed')
			return (
				<BodyCellStatusIndicatorButton
					icon={mdiCloseCircle}
					onPress={handlePress}
				/>
			);
		return null;
	}, [mergeStatus, handlePress]);
}

export default BodyCellStatusIndicator;

import React, { useMemo, useCallback } from 'react';
import { mdiSignalOff, mdiTree, mdiChip } from '@mdi/js';

import {
	ListWrapper,
	ListHeaderWrapper,
	ListBodyWrapper,
	ListNoDataWrapper,
	ListNoDataText,
	ListIcon,
	ListTitleText,
	ListTitleTextWrapper,
	ListCountTextWrapper,
	ListCountText,
	ListItemsWrappper,
	ListSubtitleText,
	ListTitleWrapper,
	ListItemWrapper,
	ListItemIcon,
	ListItemPartWrapper,
	ListItemText,
} from './InactiveSensorList.sc';
import { useToggleHomePage, useStartApplication } from '../../Workspace';
import { FilterType } from '../../../../Table/interfaces';

export interface InactiveSensorListDatum {
	installedOn: string;
	sensorId: string;
}
export type InactiveSensorListData = InactiveSensorListDatum[];

interface InactiveSensorListProps {
	data: InactiveSensorListData;
}

interface InactiveSensorItemProps {
	installedOn: string;
	sensorId: string;
}

function InactiveSensorItem(props: InactiveSensorItemProps) {
	const { installedOn, sensorId } = props;
	const toggleHomePage = useToggleHomePage();
	const openApplication = useStartApplication();
	const handleShowDetail = useCallback(() => {
		toggleHomePage(false);
		openApplication &&
			openApplication(
				{
					key: 'sensorsTable',
					name: `Inactive Sensor Details - ${sensorId}`,
				},
				{
					columns: {
						sensorId: {
							filter: {
								values: { eq: sensorId },
								type: FilterType.eq,
							},
						},
					},
				}
			);
	}, [openApplication, toggleHomePage, sensorId]);
	return (
		<ListItemWrapper onClick={handleShowDetail}>
			<ListItemPartWrapper>
				<ListItemIcon path={mdiTree} />
				<ListItemText>{installedOn}</ListItemText>
			</ListItemPartWrapper>
			<ListItemPartWrapper>
				<ListItemIcon path={mdiChip} />
				<ListItemText>{sensorId}</ListItemText>
			</ListItemPartWrapper>
		</ListItemWrapper>
	);
}

export default function InactiveSensorList(props: InactiveSensorListProps) {
	const { data } = props;
	return useMemo(() => {
		const dataCount = data.length;
		return (
			<ListWrapper>
				<ListHeaderWrapper>
					<ListTitleWrapper>
						<ListIcon icon={mdiSignalOff} />
						<ListTitleTextWrapper>
							<ListTitleText>INACTIVE SENSOR</ListTitleText>
							<ListSubtitleText>
								NO SIGNAL ARE RECEIVED IN 7 DAYS
							</ListSubtitleText>
						</ListTitleTextWrapper>
					</ListTitleWrapper>
					<ListCountTextWrapper>
						<ListCountText>{dataCount}</ListCountText>
					</ListCountTextWrapper>
				</ListHeaderWrapper>
				{dataCount > 0 ? (
					<ListBodyWrapper>
						<ListItemsWrappper>
							{data.map(({ installedOn, sensorId }, i) => {
								return (
									<InactiveSensorItem
										key={i}
										installedOn={installedOn}
										sensorId={sensorId}
									/>
								);
							})}
						</ListItemsWrappper>
					</ListBodyWrapper>
				) : (
					<ListNoDataWrapper>
						<ListNoDataText>NO DATA</ListNoDataText>
					</ListNoDataWrapper>
				)}
			</ListWrapper>
		);
	}, [data]);
}

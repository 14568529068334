import styled from 'styled-components';
import { View } from '../../../../Common/View';

import { NOTIFICATION_ITEM_HEIGHT } from '../constants';
import Icon from '@mdi/react';
import Button from '../../../../Common/Button';
import { ButtonOverlayShape } from '../../../../Common/Button/interfaces';
import { BodyText1, BodyText2 } from '../../../../Common/Text';

export interface INotiItemWrapperProps {
	delivered: boolean;
}

export const NotificationItemWrapper = styled(View)<INotiItemWrapperProps>`
	height: ${NOTIFICATION_ITEM_HEIGHT}px;
	padding: 16px;
	box-sizing: border-box;
	align-items: center;
	flex-direction: row;
	overflow: hidden;
	user-select: none;

	background-color: ${({
		delivered,
		theme: {
			background: { primaryAlpha },
		},
	}) => (delivered ? 'transparent' : primaryAlpha[1])};

	&:hover {
		background-color: ${({ theme: { background } }) =>
			background.primaryAlpha[2]};
	}

	transition: all 0.2s;
`;

export const ReadButton = styled(Button).attrs({
	overlayShape: ButtonOverlayShape.circle,
})`
	width: 32px;
	height: 32px;
	align-items: center;
	justify-content: center;
	margin-right: 8px;
`;

export const ReadButtonIcon = styled(Icon).attrs(
	({
		theme: {
			font: {
				color: { lightBgSecondary },
			},
		},
	}) => ({
		size: 1,
		color: lightBgSecondary,
	})
)``;

export const NotificaitonContentWrapper = styled(View)`
	flex: 1;
	justify-content: center;
`;
export const NotificationTitle = styled(BodyText1)`
	color: ${({
		theme: {
			font: {
				color: { lightBgPrimary },
			},
		},
	}) => lightBgPrimary};
`;

export const NotificationText = styled(BodyText2)`
	flex: 1;
	color: ${({
		theme: {
			font: {
				color: { lightBgSecondary },
			},
		},
	}) => lightBgSecondary};
`;

export const AttachmentButton = styled.a`
	display: flex;
	width: 32px;
	height: 32px;
	align-items: center;
	justify-content: center;
	margin-right: 8px;

	&:before {
		content: '';
		position: absolute;
		background-color: ${({
			theme: {
				background: { darken },
			},
		}) => darken[2]};
		opacity: 0;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		transition: all 0.2s;
	}

	&:hover {
		:before {
			opacity: 1;
		}
	}
`;

export const AttachmentIcon = styled(Icon).attrs(
	({
		theme: {
			font: {
				color: { lightBgSecondary },
			},
		},
	}) => ({
		color: lightBgSecondary,
		size: 1,
	})
)``;
export const DisabledAttachmentIcon = styled(Icon).attrs(
	({
		theme: {
			font: {
				color: { lightBgDisabled },
			},
		},
	}) => ({
		color: lightBgDisabled,
		size: 1,
	})
)``;

export const InfoWrapper = styled(View)`
	flex: 0 0 80px;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
`;

export const ReadIndictorIcon = styled(Icon).attrs(
	({
		theme: {
			font: {
				color: { lightBgSecondary },
			},
		},
	}) => ({
		size: 0.5,
		color: lightBgSecondary,
	})
)``;

import { useEffect, useRef, useState, useCallback, RefObject } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import debounce from 'lodash.debounce';

export default function useMeasure(
	ref: RefObject<HTMLElement | null>,
	debounceMs?: number
) {
	const [left, setLeft] = useState<number>(0);
	const [top, setTop] = useState<number>(0);
	const [width, setWidth] = useState<number>(0);
	const [height, setHeight] = useState<number>(0);

	const updateBoundingRect = useCallback(
		debounce((left: number, top: number, width: number, height: number) => {
			setLeft(left);
			setTop(top);
			setWidth(width);
			setHeight(height);
		}, debounceMs),
		[]
	);

	const observerRef = useRef<ResizeObserver>();

	useEffect(() => {
		function getObserver() {
			if (!observerRef.current)
				observerRef.current = new ResizeObserver((entries) => {
					const {
						contentRect: { left, top, width, height },
					} = entries[0];

					updateBoundingRect(left, top, width, height);
				});

			return observerRef.current;
		}
		const elm = ref.current;
		if (elm) getObserver().observe(elm);

		return () => {
			if (elm) getObserver().unobserve(elm);
		};
	}, [ref, observerRef, updateBoundingRect]);

	return {
		left,
		top,
		width,
		height,
		observer: observerRef.current,
	};
}

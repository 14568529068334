import { take, call, put } from 'redux-saga/effects';
import { logout } from '../../actions/auth';
import { setSession, setSessionId } from '../effects/sessionStorage';
import { removeItem } from '../effects/localStorage';
import { closeWebsocket } from '../../actions/remoteStore';

import configs from '../../../configs';
import querystring from 'query-string';
import makeId from '../../../Helpers/makeId';

function wait(time: number) {
	return new Promise((resolve) => setTimeout(resolve, time));
}

const { AUTH_PORTA_LOGOUT, APP_ID } = configs;

export function* handleLogout() {
	yield removeItem('auth');
	yield put(closeWebsocket());

	const sessionId = makeId(10);
	yield setSessionId(sessionId);
	yield setSession(sessionId, {
		location: window.location,
	});
	const portalURI = querystring.stringifyUrl(
		{
			url: AUTH_PORTA_LOGOUT,
			query: {
				response_type: 'token',
				redirect_uri: window.location.origin,
				client_id: APP_ID,
				provider_id: APP_ID,
				scope: 'openid',
				state: sessionId,
			},
		},
		{
			encode: true,
		}
	);
	window.location.replace(portalURI);
	yield call(wait, 1000);
}

export default function* watchLogout() {
	yield take(logout.type);
	yield call(handleLogout);
}

import { StandardWindowProps } from '../interfaces';
import { useCallback } from 'react';
import { ResizeMovement, StandardWindowState } from '../../../interfaces';
import { resizeWindow } from '../../Window';
import noop from '../../../../../Helpers/noop';

export default function useResize(
	props: StandardWindowProps,
	setWindowState: React.Dispatch<React.SetStateAction<StandardWindowState>>
) {
	const {
		minHeight = 50,
		minWidth = 50,
		parentHeight,
		parentWidth,
		onChange = noop,
		onResizeStart,
		onResize,
		onResizeEnd,
	} = props;
	const handleResizeStart = useCallback(
		(movement: ResizeMovement) => {
			const { direction, accumX, accumY } = movement;
			onResizeStart && onResizeStart(movement);
			setWindowState((s) => {
				const {
					height,
					left,
					top,
					width,
					x1,
					x2,
					y1,
					y2,
				} = resizeWindow(
					direction,
					s,
					{ x: accumX, y: accumY },
					minWidth,
					minHeight,
					parentWidth,
					parentHeight
				);
				return {
					...s,
					resizing: true,
					height,
					left,
					top,
					width,
					x1,
					x2,
					y1,
					y2,
				};
			});
		},
		[
			setWindowState,
			minWidth,
			minHeight,
			parentWidth,
			parentHeight,
			onResizeStart,
		]
	);

	const handleResize = useCallback(
		(movement: ResizeMovement) => {
			const { direction, movementX, movementY } = movement;
			onResize && onResize(movement);
			setWindowState((s) => {
				const {
					height,
					left,
					top,
					width,
					x1,
					x2,
					y1,
					y2,
				} = resizeWindow(
					direction,
					s,
					{ x: movementX, y: movementY },
					minWidth,
					minHeight,
					parentWidth,
					parentHeight
				);
				return {
					...s,
					height,
					left,
					top,
					width,
					x1,
					x2,
					y1,
					y2,
				};
			});
		},
		[
			setWindowState,
			minWidth,
			minHeight,
			parentWidth,
			parentHeight,
			onResize,
		]
	);

	const handleResizeEnd = useCallback(
		(movement: ResizeMovement) => {
			onResizeEnd && onResizeEnd(movement);
			setWindowState((s) => {
				const newState = {
					...s,
					resizing: false,
					x1: s.left,
					x2: s.left + s.width,
					y1: s.top,
					y2: s.top + s.height,
				};
				onChange(newState);
				return newState;
			});
		},
		[setWindowState, onResizeEnd, onChange]
	);

	return {
		handleResizeStart,
		handleResize,
		handleResizeEnd,
	};
}

import styled from 'styled-components';
import { View } from '../../../../Common/View';

export const IndexCellResizeHandle = styled(View)`
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 20px;
	transform: translateY(10px);
	cursor: ns-resize;
	z-index: 999;
`;

export const IndexCellResizeHandleShadow = styled(View)`
	position: absolute;
	left: 0;
	right: 0;
	height: 2px;
	transform: translateY(-1px);
	cursor: ns-resize;
	z-index: 999;
	background: ${({
		theme: {
			background: { darken },
		},
	}) => darken[4]};
`;

import React, { useMemo } from 'react';
import { mdiSignal } from '@mdi/js';
import {
	VictoryLine,
	VictoryScatter,
	VictoryTooltip,
	VictoryGroup,
	VictoryChart,
	VictoryArea,
} from 'victory';

import {
	ChartWrapper,
	ChartHeaderWrapper,
	ChartBodyWrapper,
	ChartNoDataWrapper,
	ChartNoDataText,
	ChartIcon,
	ChartTitleText,
	ChartSubtitleText,
	ChartTitleTextWrapper,
	ChartLatestCountTextWrapper,
	ChartLatestCountText,
	ChartTitleWrapper,
} from './ActiveSensorChart.sc';
import { chartDarkTheme } from '../../../../../chartTheme';
import moment from 'moment';
import { MouseOverIcon } from './MouseOverIcon';

export interface ActiveSensorChartDatum {
	x: Date;
	count: number;
}
export type ActiveSensorChartData = ActiveSensorChartDatum[];

interface ActiveSensorChartProps {
	data: ActiveSensorChartData;
}

function ActiveSensorChart(props: ActiveSensorChartProps) {
	const { data } = props;
	return useMemo(() => {
		const dataCount = data.length;
		const max = data.reduce(
			(_max, { count }) => (_max < count ? count : _max),
			0
		);
		const min = data.reduce(
			(_min, { count }) => (_min > count ? count : _min),
			9999
		);
		return (
			<ChartWrapper>
				<ChartHeaderWrapper>
					<ChartTitleWrapper>
						<ChartIcon icon={mdiSignal} />
						<ChartTitleTextWrapper>
							<ChartTitleText>ACTIVE SENSOR</ChartTitleText>
							<ChartSubtitleText>
								SIGNAL ARE RECEIVED IN 7 DAYS
							</ChartSubtitleText>
						</ChartTitleTextWrapper>
					</ChartTitleWrapper>
					<ChartLatestCountTextWrapper>
						<ChartLatestCountText>
							{dataCount > 0 ? data[dataCount - 1].count : 0}
						</ChartLatestCountText>
					</ChartLatestCountTextWrapper>
					<MouseOverIcon />
				</ChartHeaderWrapper>
				<ChartBodyWrapper>
					{dataCount > 1 ? (
						<VictoryChart
							theme={chartDarkTheme}
							domainPadding={{ y: 0.1 }}
						>
							<VictoryGroup
								data={data}
								x="x"
								y="count"
								domain={{ y: [Math.max(min - 5, 0), max + 5] }}
								scale={{ x: 'time' }}
								labels={({ datum }) =>
									`${moment(datum.x).format(
										'MMM DD HH:mm'
									)}, ${datum.count}`
								}
								labelComponent={
									<VictoryTooltip style={{ fontSize: 24 }} />
								}
							>
								<VictoryLine />
								<VictoryScatter size={8} />
								<VictoryArea />
							</VictoryGroup>
						</VictoryChart>
					) : (
						<ChartNoDataWrapper>
							<ChartNoDataText>NO DATA</ChartNoDataText>
						</ChartNoDataWrapper>
					)}
				</ChartBodyWrapper>
			</ChartWrapper>
		);
	}, [data]);
}

export default ActiveSensorChart;

import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { uplinkAction } from '../Store/actions/remoteStore';
import { useCallback } from 'react';

export function useUplinkDispatch() {
	const dispatch = useDispatch();

	return useCallback(
		(action: AnyAction) => dispatch(uplinkAction({ action })),
		[dispatch]
	);
}

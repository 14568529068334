import styled from 'styled-components';
import { View } from '../../../../Common/View';
import Scrollbars from '../../../../Common/Scrollbars';
import { CaptionText, HeadingText5 } from '../../../../Common/Text';
import IconTextButton from '../../../../Common/IconTextButton';

export const ScenarioChangeLogWrapper = styled(View)`
	flex: 0 1 180px;
	margin: 8px;
	padding: 12px;
	user-select: none;
	background-color: ${({
		theme: {
			background: { frame },
		},
	}) => frame};

	border-radius: 8px;
	${({ theme: { shadow } }) => shadow[2]};
`;

export const HeaderWrapper = styled(View)`
	flex-direction: row;
	align-items: center;
	flex: 0 0 36px;
`;

export const HeaderText = styled(HeadingText5)`
	flex: 1 0 auto;
	color: ${({
		theme: {
			font: {
				color: { darkBgSecondary },
			},
		},
	}) => darkBgSecondary};
`;

export const ListScrollView = styled(Scrollbars)`
	min-height: 64px;
`;

export const ListWrapper = styled(View)``;

export const ListItemWrapper = styled(View)`
	padding: 4px;
`;

export const ListItemText = styled(CaptionText)`
	color: ${({
		theme: {
			font: {
				color: { darkBgWatermark },
			},
		},
	}) => darkBgWatermark};
`;

export const MoreButton = styled(IconTextButton).attrs({ lightBg: false })`
	background-color: ${({
		theme: {
			background: { accent },
		},
	}) => accent};
	border-radius: 4px;
	& > [x-component='overlay'] {
		border-radius: 4px;
	}

	flex: 0 0 auto;
`;

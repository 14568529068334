import { ActivityPreviewProps } from '../../Activities/interfaces';
import React, { useMemo } from 'react';
import {
	ActivityPreviewWrapper,
	ActivityTitleWrapper,
	ActivityTitleText,
} from './ActivityPreview.sc';

export default function ActivityPreview(props: ActivityPreviewProps) {
	const {
		title,
		width,
		height,
		scale,
		offsetX,
		offsetY,
		onFocusActivity,
	} = props;

	return useMemo(() => {
		return (
			<ActivityPreviewWrapper
				style={{
					width: width * scale,
					height: height * scale,
					left: offsetX,
					top: offsetY,
				}}
				onMouseDown={onFocusActivity}
			>
				<ActivityTitleWrapper>
					<ActivityTitleText>{title}</ActivityTitleText>
				</ActivityTitleWrapper>
			</ActivityPreviewWrapper>
		);
	}, [title, width, height, scale, offsetX, offsetY, onFocusActivity]);
}

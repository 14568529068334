import { useCallback, MouseEvent as RMouseEvent } from 'react';
import useObject from '../../../../Hooks/useObject';
import { MenuChildrenProps } from '../interfaces';

export default function useGetMenuItemProps(options: MenuChildrenProps) {
	const {
		data,
		index,
		focusedIndex,
		setFocusedIndex,
		...restOptions
	} = options;
	const _restOptions = useObject(restOptions);
	const { onMouseEnter, onMouseLeave, ...restItemProps } = data[index];
	const _restItemProps = useObject(restItemProps);

	const handleMouseEnter = useCallback(
		(e: RMouseEvent<HTMLDivElement>) => {
			onMouseEnter && onMouseEnter(e);
			setFocusedIndex(index);
		},
		[index, setFocusedIndex, onMouseEnter]
	);
	const handleMouseLeave = useCallback(
		(e: RMouseEvent<HTMLDivElement>) => {
			onMouseLeave && onMouseLeave(e);
			setFocusedIndex(null);
		},
		[setFocusedIndex, onMouseLeave]
	);

	return useCallback(
		() => ({
			focused: index === focusedIndex,
			onMouseEnter: handleMouseEnter,
			onMouseLeave: handleMouseLeave,
			..._restOptions,
			..._restItemProps,
		}),
		[
			index,
			focusedIndex,
			handleMouseEnter,
			handleMouseLeave,
			_restOptions,
			_restItemProps,
		]
	);
}

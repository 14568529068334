import styled from 'styled-components';
import Popover from '../../Popover/Components/Popover';
import { CaptionText } from '../../Text';
import { TooltipType } from '../interfaces';

export interface TooltipWrapperProps {
	type: TooltipType;
}

export const TooltipWrapper = styled(Popover)<TooltipWrapperProps>`
	padding: 8px;
	border-radius: 4px;
	background-color: ${({
		theme: {
			background: { error, frameDarker },
		},
		type,
	}) => (type === TooltipType.error ? error : frameDarker)};
	color: ${({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => darkBgPrimary};
	${({ theme: { shadow } }) => shadow[2]};
	transform: translateX(-50%) translateY(-50%);
	pointer-events: none;
`;

export const TooltipText = styled(CaptionText)``;

import styled from 'styled-components';
import { View } from '../../../../Common/View';
import WrappedInput from '../../../../Common/WrappedInput';
import { DatePicker, DateRangePicker } from '../../../../Common/DatePicker';
import Checkbox from '../../../../Common/Checkbox';
import { ButtonOverlayShape } from '../../../../Common/Button/interfaces';
import constants from '../../../constants';

const { HEADER_HEIGHT } = constants;

export const HeaderCellFilterInputWrapper = styled(View)`
	flex: 1 0 0;
	flex-direction: row;
	align-items: center;
`;

export const HeaderCellFilterBaseInput = styled(WrappedInput)`
	flex: 1 0 0;
	& > [x-component='input'] {
		box-sizing: border-box;
		color: ${({
			theme: {
				font: {
					color: { darkBgPrimary },
				},
			},
		}) => darkBgPrimary};
		::placeholder {
			color: ${({
				theme: {
					font: {
						color: { darkBgSecondary },
					},
				},
			}) => darkBgSecondary};
			opacity: 1;
		}
	}

	& > [x-component='underline'] {
		background-color: ${({
			theme: {
				background: { light },
			},
		}) => light};
	}
`;

export const HeaderCellFilterBooleanCheckbox = styled(Checkbox).attrs({
	lightBg: false,
})`
	height: ${HEADER_HEIGHT / 2}px;
	width: ${HEADER_HEIGHT / 2}px;
	padding: 0;
	margin-left: 2px;
`;

export const HeaderCellFilterDatePicker = styled(DatePicker).attrs({
	lightBg: false,
	overlayShape: ButtonOverlayShape.circle,
})`
	height: ${HEADER_HEIGHT / 2}px;
	width: ${HEADER_HEIGHT / 2}px;
	padding: 0;
`;

export const HeaderCellFilterDateRangePicker = styled(DateRangePicker).attrs({
	lightBg: false,
	overlayShape: ButtonOverlayShape.circle,
})`
	height: ${HEADER_HEIGHT / 2}px;
	width: ${HEADER_HEIGHT / 2}px;
	padding: 0;
`;

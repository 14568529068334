import React, { useMemo } from 'react';
import { StandardWindowHeaderProps } from '../interfaces';
import {
	StandardWindowHeaderLeftWrapper,
	StandardWindowHeaderTitleWrapper,
	StandardWindowHeaderTitleText,
	StandardWindowHeaderRightWrapper,
} from './StandardWindowHeader.sc';
import {
	mdiWindowMinimize,
	mdiWindowRestore,
	mdiWindowMaximize,
	mdiClose,
} from '@mdi/js';
import WindowHeaderButton from './WindowHeaderButton';
import { MaximizeType } from '../../../interfaces';
import WindowHeader from './WindowHeader';

export default function StandardWindowHeader(props: StandardWindowHeaderProps) {
	const {
		moving,
		leftHeader,
		rightHeader,
		title,
		threshold,
		timeout,
		modifyKey,
		maximized,
		onRequestMaximized,
		onRequestRestore,
		onRequestMinimized,
		onRequestClose,
	} = props;

	return useMemo(() => {
		return (
			<WindowHeader
				moving={moving}
				threshold={threshold}
				timeout={timeout}
				modifyKey={modifyKey}
				onRequestToggleMaximize={
					maximized === MaximizeType.FULL
						? onRequestRestore
						: onRequestMaximized
				}
			>
				<StandardWindowHeaderLeftWrapper>
					{leftHeader}
				</StandardWindowHeaderLeftWrapper>
				<StandardWindowHeaderTitleWrapper>
					<StandardWindowHeaderTitleText>
						{title}
					</StandardWindowHeaderTitleText>
				</StandardWindowHeaderTitleWrapper>
				<StandardWindowHeaderRightWrapper>
					{rightHeader}
					<WindowHeaderButton
						icon={mdiWindowMinimize}
						onPress={onRequestMinimized}
					/>
					<WindowHeaderButton
						icon={
							maximized === MaximizeType.FULL
								? mdiWindowRestore
								: mdiWindowMaximize
						}
						onPress={
							maximized === MaximizeType.FULL
								? onRequestRestore
								: onRequestMaximized
						}
					/>
					<WindowHeaderButton
						icon={mdiClose}
						onPress={onRequestClose}
					/>
				</StandardWindowHeaderRightWrapper>
			</WindowHeader>
		);
	}, [
		moving,
		leftHeader,
		rightHeader,
		title,
		maximized,
		modifyKey,
		threshold,
		timeout,
		onRequestMaximized,
		onRequestRestore,
		onRequestMinimized,
		onRequestClose,
	]);
}

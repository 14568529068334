import React, {
	useState,
	useCallback,
	forwardRef,
	Ref,
	SyntheticEvent as REvent,
	useRef,
	MouseEvent as RMouseEvent,
	KeyboardEvent as RKeyboardEvent,
	useMemo,
} from 'react';
import RDatePicker, {
	ReactDatePickerProps as RDatePickerProps,
} from 'react-datepicker';
import styled from 'styled-components';
import { mdiCalendar, mdiCalendarRange } from '@mdi/js';
import Popover, { usePopoverOnElement } from './Popover';
import IconTextButton from './IconTextButton';
import useForwardedRef from '../../Hooks/useForwardedRef';
import useHotKeys from '../../Hooks/useHotKeys';
import { PopoverProps } from './Popover/interfaces';
import { IconTextButtonProps } from './IconTextButton/interfaces';

interface DatePickerPanelProps
	extends Omit<
		RDatePickerProps,
		| 'calendarClassName'
		| 'wrapperClassName'
		| 'popperClassName'
		| 'inline'
		| 'popperContainer'
		| 'onClickOutside'
		| 'onChangeRaw'
		| 'placeholderText'
		| 'selected'
		| 'startDate'
		| 'endDate'
		| 'minDate'
		| 'maxDate'
		| 'selectsStart'
		| 'selectsEnd'
	> {
	date?: Date | string | number | null;
}

const UnstyledDatePickerPanel = forwardRef(function (
	props: DatePickerPanelProps,
	ref: Ref<RDatePicker>
) {
	const { className, date, ...restProps } = props;
	const selected =
		date !== undefined && date !== null ? new Date(date) : date;
	return (
		<RDatePicker
			ref={ref}
			inline
			calendarClassName={className}
			showTimeSelect={false}
			timeFormat="HH:mm"
			selected={selected}
			{...restProps}
		/>
	);
});

interface DateRangePickerPanelProps
	extends Omit<
		RDatePickerProps,
		| 'calendarClassName'
		| 'wrapperClassName'
		| 'popperClassName'
		| 'inline'
		| 'popperContainer'
		| 'onClickOutside'
		| 'onChangeRaw'
		| 'placeholderText'
		| 'selected'
		| 'startDate'
		| 'endDate'
		| 'minDate'
		| 'maxDate'
		| 'selectsStart'
		| 'selectsEnd'
		| 'onChange'
	> {
	dateRange?: [
		Date | string | number | null | undefined,
		Date | string | number | null | undefined
	];
	onChange?(
		dateRange: [Date | null | undefined, Date | null | undefined],
		e: REvent
	): void;
}

const UnstyledDateRangePickerPanel = forwardRef(function (
	props: DateRangePickerPanelProps,
	ref: Ref<RDatePicker>
) {
	const {
		className,
		dateRange = [undefined, undefined] as [undefined, undefined],
		onChange,
		onSelect,
		...restProps
	} = props;
	const [selectsStart, setSelectsStart] = useState(true);
	const [startDate, endDate] = dateRange.map((date) =>
		date !== undefined && date !== null ? new Date(date) : date
	);
	const selected = selectsStart ? startDate : endDate;

	const handleSelect = useCallback(
		(date: Date, e: REvent) => {
			onSelect && onSelect(date, e);
			setSelectsStart(!selectsStart);
		},
		[onSelect, selectsStart]
	);

	const handleChange = useCallback(
		(date: Date | null, e: REvent) => {
			if (onChange) {
				if (selectsStart) onChange([date, endDate], e);
				else onChange([startDate, date], e);
			}
		},
		[onChange, selectsStart, startDate, endDate]
	);
	let minDate: Date | null | undefined, maxDate: Date | null | undefined;
	if (selectsStart) maxDate = endDate;
	else minDate = startDate;

	return (
		<RDatePicker
			ref={ref}
			inline
			calendarClassName={className}
			showTimeSelect={false}
			timeFormat="HH:mm"
			shouldCloseOnSelect={false}
			selected={selected}
			startDate={startDate}
			endDate={endDate}
			minDate={minDate}
			maxDate={maxDate}
			onSelect={handleSelect}
			selectsStart={selectsStart}
			selectsEnd={!selectsStart}
			onChange={handleChange}
			{...restProps}
		/>
	);
});

export const DatePickerPanel = styled(UnstyledDatePickerPanel)`
	font-family: 'LiGothic', 'FangSong', -apple-system, BlinkMacSystemFont,
		'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif !important;
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
	& [class*='--selected'] {
		background-color: ${({
			theme: {
				background: { accent },
			},
		}) => accent} !important;
	}
`;

export const DateRangePickerPanel = styled(UnstyledDateRangePickerPanel)`
	font-family: 'LiGothic', 'FangSong', -apple-system, BlinkMacSystemFont,
		'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif !important;
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
	& [class*='--selected'] {
		background-color: ${({
			theme: {
				background: { accent },
			},
		}) => accent} !important;
	}
	& [class*='--in-range'] {
		background-color: ${({
			theme: {
				background: { primaryAlpha },
			},
		}) => primaryAlpha[6]} !important;
		color: ${({
			theme: {
				font: {
					color: { darkBgPrimary },
				},
			},
		}) => darkBgPrimary} !important;
	}
	& [class*='--range-start'] {
		background-color: ${({
			theme: {
				background: { accent },
			},
		}) => accent} !important;
		font-weight: bold;
	}
	& [class*='--range-end'] {
		background-color: ${({
			theme: {
				background: { accent },
			},
		}) => accent} !important;
		font-weight: bold;
	}
	& [class*='--in-selecting-range'] {
		background-color: ${({
			theme: {
				background: { darken },
			},
		}) => darken[4]} !important;
		color: ${({
			theme: {
				font: {
					color: { darkBgPrimary },
				},
			},
		}) => darkBgPrimary} !important;
	}
	& [class*='--in-selecting-range'][class*='--selecting-range-start'] {
		background-color: ${({
			theme: {
				background: { frame },
			},
		}) => frame} !important;
		font-weight: bold;
	}
	& [class*='--in-selecting-range'][class*='--selecting-range-end'] {
		background-color: ${({
			theme: {
				background: { frame },
			},
		}) => frame} !important;
		font-weight: bold;
	}
`;

export interface DatePickerProps extends Omit<IconTextButtonProps, 'icon'> {
	date?: Date | string | number | null;
	panelProps?: DatePickerPanelProps;
	popoverOptions?: Partial<PopoverProps>;
}

const DatePickerButton = styled(IconTextButton)`
	justify-content: center;
`;

export const DatePicker = forwardRef(function (
	props: DatePickerProps,
	ref: Ref<HTMLDivElement>
) {
	const {
		date,
		panelProps = {} as DatePickerPanelProps,
		popoverOptions,
		onPress,
		...restProps
	} = props;
	const _ref = useForwardedRef(ref);
	const popoverRef = useRef<HTMLDivElement>(null);
	const { opened, open, close, getPopoverProps } = usePopoverOnElement(
		_ref,
		popoverOptions
	);
	const handlePress = useCallback(
		(e: RMouseEvent<HTMLDivElement> | RKeyboardEvent<HTMLDivElement>) => {
			onPress && onPress(e);
			open();
		},
		[onPress, open]
	);

	const _closePopover = useCallback(() => {
		const popover = popoverRef.current;
		if (popover) popover.blur();
		close();
	}, [close]);

	const keyBindMap = useMemo(
		() => ({
			enter: _closePopover,
			tab: _closePopover,
		}),
		[_closePopover]
	);

	useHotKeys(keyBindMap, {
		ref: popoverRef,
		disabled: !opened,
		stopPropagation: true,
	});

	const popoverProps = getPopoverProps();
	const { height = 0 } = popoverProps;
	const style = { transform: `translateY(${height + 4}px)` };

	return (
		<>
			<DatePickerButton
				ref={_ref}
				icon={mdiCalendar}
				onPress={handlePress}
				{...restProps}
			/>
			<Popover
				ref={popoverRef}
				style={style}
				edgeDodging
				focusOnOpen
				{...popoverProps}
			>
				<DatePickerPanel {...panelProps} date={date} />
			</Popover>
		</>
	);
});

export interface DateRangePickerProps
	extends Omit<IconTextButtonProps, 'icon'> {
	dateRange?: [
		Date | string | number | null | undefined,
		Date | string | number | null | undefined
	];
	panelProps?: DateRangePickerPanelProps;
	popoverOptions?: Partial<PopoverProps>;
}

const DateRangePickerButton = styled(IconTextButton)`
	justify-content: center;
`;

export const DateRangePicker = forwardRef(function (
	props: DateRangePickerProps,
	ref: Ref<HTMLDivElement>
) {
	const {
		dateRange,
		panelProps = {} as DateRangePickerPanelProps,
		popoverOptions,
		onPress,
		...restProps
	} = props;
	const _ref = useForwardedRef(ref);
	const popoverRef = useRef<HTMLDivElement>(null);
	const { opened, open, close, getPopoverProps } = usePopoverOnElement(
		_ref,
		popoverOptions
	);
	const handlePress = useCallback(
		(e: RMouseEvent<HTMLDivElement> | RKeyboardEvent<HTMLDivElement>) => {
			onPress && onPress(e);
			open();
		},
		[onPress, open]
	);

	const _closePopover = useCallback(() => {
		const popover = popoverRef.current;
		if (popover) popover.blur();
		close();
	}, [close]);

	const keyBindMap = useMemo(
		() => ({
			enter: _closePopover,
			tab: _closePopover,
		}),
		[_closePopover]
	);

	useHotKeys(keyBindMap, {
		ref: popoverRef,
		disabled: !opened,
		stopPropagation: true,
	});

	const popoverProps = getPopoverProps();
	const { height = 0 } = popoverProps;
	const style = { transform: `translateY(${height + 4}px)` };

	return (
		<>
			<DateRangePickerButton
				ref={_ref}
				icon={mdiCalendarRange}
				onPress={handlePress}
				{...restProps}
			/>
			<Popover
				ref={popoverRef}
				style={style}
				edgeDodging
				focusOnOpen
				{...popoverProps}
			>
				<DateRangePickerPanel {...panelProps} dateRange={dateRange} />
			</Popover>
		</>
	);
});

import React, { useContext, useCallback, useMemo } from 'react';
import useDesktopWindow from '../../../../Layouts/Components/Desktop/Hooks/useDesktopWindow';
import workspaceContext from '../../Workspace/context';
import useActivity from '../Hooks/useActivity';
import { CSSProperties } from 'styled-components';
import { StandardWindow } from '../../../../Layouts';
import ActivityPreview from '../../Activities';
import { ActivityWindowProps } from '../interfaces';

export default function ActivityWindow(props: ActivityWindowProps) {
	const { activityId, children } = props;

	const {
		left,
		top,
		width,
		height,
		zIndex,
		maximized,
		parentWidth,
		parentHeight,
		onChange,
		onFocus,
		onMaximize,
		onMinimize,
		onRestore,
		onMoveStart,
		onMoveEnd,
		onResizeStart,
		onResizeEnd,
	} = useDesktopWindow(activityId);

	const { toggleActivities } = useContext(workspaceContext);

	const {
		scale,
		offsetX,
		offsetY,
		showPreview,
		activity: { name, title },
		handleCloseActivity,
	} = useActivity(activityId);

	const handleFocusActivity = useCallback(() => {
		toggleActivities();
		onFocus();
	}, [onFocus, toggleActivities]);

	const windowStyle: CSSProperties = useMemo(() => {
		return showPreview
			? {
					left: offsetX,
					top: offsetY,
					transform: `scale(${scale})`,
					pointerEvents: 'none',
					transformOrigin: 'top left',
			  }
			: {};
	}, [showPreview, scale, offsetX, offsetY]);

	return useMemo(() => {
		return (
			<>
				<StandardWindow
					title={title}
					left={left}
					top={top}
					style={windowStyle}
					width={width}
					height={height}
					zIndex={zIndex}
					parentWidth={parentWidth}
					parentHeight={parentHeight}
					minWidth={200}
					minHeight={200}
					maximized={maximized}
					onChange={onChange}
					onMoveStart={onMoveStart}
					onMoveEnd={onMoveEnd}
					onResizeStart={onResizeStart}
					onResizeEnd={onResizeEnd}
					onRequestClose={handleCloseActivity}
					onRequestMaximized={onMaximize}
					onRequestRestore={onRestore}
					onRequestFocus={onFocus}
					onRequestMinimized={onMinimize}
				>
					{children}
				</StandardWindow>
				{showPreview ? (
					<ActivityPreview
						height={height}
						name={name}
						offsetX={offsetX}
						offsetY={offsetY}
						onFocusActivity={handleFocusActivity}
						scale={scale}
						title={title}
						width={width}
					/>
				) : null}
			</>
		);
	}, [
		title,
		left,
		top,
		offsetX,
		offsetY,
		windowStyle,
		width,
		height,
		zIndex,
		maximized,
		parentWidth,
		parentHeight,
		onFocus,
		onChange,
		onMoveStart,
		onMoveEnd,
		onResizeStart,
		onResizeEnd,
		onMaximize,
		onMinimize,
		onRestore,
		handleFocusActivity,
		handleCloseActivity,
		name,
		scale,
		showPreview,
		children,
	]);
}

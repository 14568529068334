import styled from 'styled-components';
import { View } from '../../../../Common/View';

export const HomeWrapper = styled(View)`
	flex: 1 1 0;
	flex-direction: row;
	overflow: auto;
`;

export const ControlPanelWrapper = styled(View)`
	flex: 1 0 500px;
`;

export const MapWrapper = styled(View)`
	flex: 1 0 0;
	padding: 8px;
`;

export const ScenarioChangeLogWrapper = styled(View)`
	flex: 0 1 300px;
`;

export const OverviewWrapper = styled(View)`
	flex: 1 0 500px;
	flex-direction: column;
`;

export const WeatherWrapper = styled(View)`
	flex: 0 0 0;
`;

export const StatisticWrapper = styled(View)`
	flex: 1 1 0;
	flex-direction: row;
`;

export const StatisticColumnWrapper = styled(View)`
	flex: 1 0 0;
	align-items: stretch;
	flex-direction: column;
`;

import createReducer from '../../Helpers/createReducer';
import { Record, OrderedSet } from 'immutable';
import {
	changeActiveWorkspace,
	createWorkspace,
	removeWorkspace,
} from './actions';

export interface ISerializedVirtualDesktopStore {
	activeWorkspace: string;
	workspaces: string[];
}

export interface IVirtualDesktopStore {
	activeWorkspace: string;
	workspaces: OrderedSet<string>;
}

export const VirtualDesktopStore = Record<IVirtualDesktopStore>({
	activeWorkspace: 'Default',
	workspaces: OrderedSet(['Default']),
});

const initialState = VirtualDesktopStore();

export const virtualDesktopReducer = createReducer(
	initialState,
	{ changeActiveWorkspace, createWorkspace, removeWorkspace },
	{
		changeActiveWorkspace: (state, { payload: { name } }) =>
			state.set('activeWorkspace', name),
		createWorkspace: (state, { payload: { name } }) =>
			state.merge({
				activeWorkspace: name,
				workspaces: state.workspaces.add(name),
			}),
		removeWorkspace: (state, { payload: { name } }) =>
			state.withMutations((s) => {
				s.update('workspaces', (workspaces) => workspaces.delete(name));
				s.set('activeWorkspace', s.workspaces.first());
			}),
	}
);

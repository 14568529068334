import styled from 'styled-components';
import { View } from '../../../../Common/View';

export const BodySelectionBox = styled(View)`
	z-index: 999;
	position: absolute;
	border: 1px solid
		${({
			theme: {
				font: {
					color: { primary },
				},
			},
		}) => primary};
	background-color: ${({
		theme: {
			background: { primaryAlpha },
		},
	}) => primaryAlpha[1]};
	pointer-events: none;
	box-sizing: border-box;
	&:focus {
		outline: none;
		background-color: ${({
			theme: {
				background: { primaryAlpha },
			},
		}) => primaryAlpha[2]};
	}
`;

export const BodySelectionCell = styled(View)`
	z-index: 1000;
	position: absolute;
	border: 2px solid
		${({
			theme: {
				font: {
					color: { primary },
				},
			},
		}) => primary};
	pointer-events: none;
	box-sizing: border-box;
`;

import { useCallback, MutableRefObject } from 'react';
import { List, Map } from 'immutable';
import { Selected } from '../../TableProvider';
import { ITableContext } from '../../../context';
import orderSelected from '../Helpers/orderSelected';
import { stringifyRecord } from '../../../Helpers/Record';

function getSelectedCellsStr(
	selected: NonNullable<Selected>,
	recordIds: List<string>,
	columnKeys: List<string>,
	records: Map<string, Map<string, any>>
) {
	let str = '';
	const [tl, br] = orderSelected(selected);
	for (let i = tl[0]; i <= br[0]; ++i) {
		for (let j = tl[1]; j <= br[1]; ++j) {
			const recordId = recordIds.get(i, '');
			const columnKey = columnKeys.get(j, '');
			str += stringifyRecord(records.getIn([recordId, columnKey], ''));
			if (j < br[1]) str += '\t';
			if (j === br[1] && i < br[0]) str += '\n';
		}
	}
	return str;
}

export default function useSelectedCellsCopy(
	contextObjRef: MutableRefObject<ITableContext>
) {
	return useCallback(() => {
		const {
			columnKeys,
			recordIds,
			records,
			selected,
		} = contextObjRef.current;
		if (!selected) return;
		navigator.clipboard.writeText(
			getSelectedCellsStr(selected, recordIds, columnKeys, records)
		);
	}, [contextObjRef]);
}

import styled from 'styled-components';
import { View } from '../../../../Common/View';
import { CaptionText } from '../../../../Common/Text';

export interface IndexCellWrapperProps {
	resizing?: boolean;
}

export const IndexCellWrapper = styled(View)<IndexCellWrapperProps>`
	position: absolute;
	align-items: center;
	justify-content: center;
	user-select: none;
	box-sizing: border-box;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-right: 1px solid rgba(0, 0, 0, 0.1);
	background-color: ${({ theme: { background }, resizing }) =>
		resizing ? background.primaryAlpha[4] : 'transparent'};
	color: ${({ theme: { font } }) => font.color.lightBgSecondary};

	&:hover {
		background-color: ${({ theme: { background }, resizing }) =>
			resizing ? background.primaryAlpha[4] : background.darken[2]};
	}
`;

export const IndexNumber = styled(CaptionText)``;

import React, { useMemo, useCallback, useRef, useEffect } from 'react';
import { Transition } from 'react-transition-group';
import { Route, Switch } from 'react-router';
import { RecordOf } from 'immutable';
import { TransitionStatus } from 'react-transition-group/Transition';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from '../../../Store/reducers/root';
import { IUser } from '../../../Store/reducers/auth';
import SnackbarGroup from '../../../Components/Snackbar/SnackbarGroup';
import { SnackbarSide } from '../../../Components/Snackbar/reducer';
import LinkedDashboard from '../../../Components/Dashboard/Components/LinkedDashboard';
import { EmbeddedProvider } from '../../../Components/Embedded';

import { logout } from '../../../Store/actions/auth';
import { Applications } from './Applications';
import context from '../../../Components/Dashboard/Components/NotificationPanel/context';
import useNotifications from '../Hooks/useNotifications';
import { useUplinkDispatch } from '../../../Hooks/useUplinkDispatch';
import LoadingScreen from './LoadingScreen';
import { AlertControl } from './AlertControl';
import { SensorControl } from './SensorControl';
import { useHistory } from 'react-router-dom';

type Props = {
	status: TransitionStatus;
	user: RecordOf<IUser>;
};

function useSnackbar() {
	const snackbar = useSelector(
		(state: RecordOf<IRootState>) => state.snackbar
	);

	return snackbar;
}

const { Provider } = context;

function Main(props: Props) {
	const { user } = props;
	const { username, _id, name, groups } = user;

	const { snackbars, snackbarIds } = useSnackbar();
	const dashboardRef = useRef<HTMLDivElement>(null);

	const dispatch = useDispatch();

	const handleLogout = useCallback(() => {
		dispatch(logout());
	}, [dispatch]);

	const uplinkDispatch = useUplinkDispatch();

	const stores = useSelector((state: RecordOf<IRootState>) => state.stores);

	const {
		exhausted,
		loading,
		notificationIds,
		notifications,
		handleMarkRead,
		handleMarkUnread,
		handleFetchNotification,
		handleMarkAllRead,
	} = useNotifications();

	return useMemo(
		() => (
			<Provider
				value={{
					exhausted,
					loading,
					notificationIds,
					notifications,
					onFetchNotifications: handleFetchNotification,
					onMarkAllNotificationRead: handleMarkAllRead,
					onMarkNotificationRead: handleMarkRead,
					onMarkNotificationUnread: handleMarkUnread,
				}}
			>
				<EmbeddedProvider value={{ stores, dispatch: uplinkDispatch }}>
					<LinkedDashboard
						ref={dashboardRef}
						userId={_id}
						username={name || username}
						groups={groups}
						dashboardId={_id}
						applications={Applications}
						onRequestLogout={handleLogout}
					/>
					<SnackbarGroup
						containerRef={dashboardRef}
						maxWidth={600}
						side={SnackbarSide.right}
						snackbarIds={snackbarIds}
						snackbars={snackbars}
						onRequestDispatch={dispatch}
					/>
				</EmbeddedProvider>
			</Provider>
		),
		[
			_id,
			name,
			stores,
			username,
			groups,
			uplinkDispatch,
			dispatch,
			snackbarIds,
			snackbars,
			handleLogout,
			exhausted,
			loading,
			notificationIds,
			notifications,
			handleMarkRead,
			handleMarkUnread,
			handleFetchNotification,
			handleMarkAllRead,
		]
	);
}

export default function MainScreen() {
	const { token, user, initialized, state } = useSelector(
		(state: RecordOf<IRootState>) => state.auth
	);

	const history = useHistory();

	useEffect(() => {
		if (state) {
			console.log(state.location.pathname + state.location.search);
			history.replace(state.location.pathname + state.location.search);
		}
	}, [state, history]);

	return useMemo(
		() =>
			token && user ? (
				<Switch>
					<Route path="/" exact>
						{({ match }) => (
							<Transition
								in={!!match}
								timeout={300}
								unmountOnExit
								appear
							>
								{(status) => (
									<Main status={status} user={user} />
								)}
							</Transition>
						)}
					</Route>
					<Route path="/alertControl">
						<AlertControl />
					</Route>
					<Route path="/sensorControl">
						<SensorControl />
					</Route>
				</Switch>
			) : initialized ? (
				<LoadingScreen />
			) : null,
		[token, user, initialized]
	);
}

import styled from 'styled-components';
import { View } from '../../../../Common/View';

export const HeaderInnerWrapper = styled(View)`
	border-left: 1px solid
		${({
			theme: {
				background: { darken },
			},
		}) => darken[1]};
	border-right: 1px solid
		${({
			theme: {
				background: { darken },
			},
		}) => darken[1]};
	background-color: ${({
		theme: {
			background: { grey },
		},
	}) => grey};
`;

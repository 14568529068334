import { eventChannel, buffers } from 'redux-saga';
import WebSocketClient from '../services/WebSocketClient';

export default function createDownlinkActionChannel(client: WebSocketClient) {
	return eventChannel((emit) => {
		function handleMessage(msg: string) {
			emit(JSON.parse(msg));
		}
		client.on('message', handleMessage);

		return () => {
			client.removeListener('message', handleMessage);
		};
	}, buffers.expanding());
}

import styled from 'styled-components';
import { View } from '../Common/View';
import Icon from '@mdi/react';
import { CaptionText } from '../Common/Text';

export const SearchBarWrapper = styled(View)`
	background-color: ${({
		theme: {
			background: { light },
		},
	}) => light};
	border-radius: 12px;
	align-items: stretch;
	overflow: hidden;
	max-height: 480px;
	${({ theme: { shadow } }) => shadow[2]}

	transition: all 0.2s;
`;

export const OpenedSearchBarWrapper = styled(SearchBarWrapper)`
	${({ theme: { shadow } }) => shadow[4]};
`;

export const SearchInputWrapper = styled(View)`
	flex-direction: row;
	align-items: center;
`;

export const SearchInput = styled.input`
	outline: none;
	border: none;
	flex: 1;
	height: 42px;
	padding: 0;
`;

export const Separator = styled(View)`
	height: 1px;
	margin-left: 8px;
	margin-right: 8px;
	background-color: ${({
		theme: {
			background: { grey },
		},
	}) => grey};
`;

export const SearchBarIconWrapper = styled(View)`
	width: 36px;
	height: 36px;

	align-items: center;
	justify-content: center;

	transition: all 0.2s;
`;

export const SearchBarIcon = styled(Icon).attrs(
	({
		theme: {
			font: {
				color: { lightBgDisabled },
			},
		},
	}) => ({
		color: lightBgDisabled,
		size: 0.8,
	})
)``;

export const SearchTextWrapper = styled(View)`
	flex: 1;
	cursor: text;
	flex-direction: row;
	align-items: center;

	height: 42px;
`;

export const SearchText = styled(CaptionText)`
	color: ${({
		theme: {
			font: {
				color: { lightBgSecondary },
			},
		},
	}) => lightBgSecondary};
`;

export const SearchSuggestionsWrapper = styled(View)`
	flex-direction: column;
	align-items: stretch;
`;

export interface SuggestionWrapperProps {
	focused: boolean;
}

export const SuggestionWrapper = styled(View)<SuggestionWrapperProps>`
	flex-direction: row;
	align-items: center;

	background-color: ${({
		focused,
		theme: {
			background: { darken },
		},
	}) => (focused ? darken[1] : 'transparent')};

	color: ${({
		theme: {
			font: {
				color: { lightBgPrimary },
			},
		},
	}) => lightBgPrimary};

	&:hover {
		background-color: ${({
			theme: {
				background: { darken },
			},
		}) => darken[1]};
	}
`;

export const SuggestonText = styled(CaptionText)`
	cursor: default;
`;

export const SearchResultsCounterText = styled(CaptionText)`
	margin-right: 4px;
	margin-left: 4px;
	color: ${({
		theme: {
			font: {
				color: { lightBgSecondary, lightBgDisabled },
			},
		},
	}) => lightBgDisabled};

	margin-right: 16px;
`;

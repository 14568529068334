import { useState, useEffect } from 'react';

export default function useAsState<T>(value: T) {
	const _valueSetValue = useState(value);
	const setValue = _valueSetValue[1];
	useEffect(() => {
		setValue(value);
	}, [setValue, value]);
	return _valueSetValue;
}

import { List, Record, Map, OrderedSet } from 'immutable';
import {
	Recipient,
	File,
	MessageDocument,
	NotificationStore,
	Message,
	SerializedNotificationState,
} from '../interfaces';
import {
	receivedNotification,
	fetchNotificationsSuccess,
	fetchNotifications,
} from './actions';
import createReducer from '../../../../../Helpers/createReducer';

export const RecipientRecord = Record<Recipient>({
	channel: 'dashboard',
	target: '',
});

export const FileRecord = Record<File>({
	contentType: '',
	filename: '',
	path: '',
	ready: true,
	url: '',
});

export const MessageRecord = Record<MessageDocument>({
	_id: '',
	content: '',
	delivered: false,
	recipient: RecipientRecord(),
	title: '',
	trial: 0,
	attachments: List(),
	createdAt: new Date(),
});

export const NotificationStoreRecord = Record<NotificationStore>({
	loading: false,
	exhausted: false,
	notifications: Map(),
	notificationIds: OrderedSet(),
});

const initialState = NotificationStoreRecord();

function createNotificationRecord(notification: Message) {
	const { recipient, attachments = [], createdAt, ...rest } = notification;
	return MessageRecord({
		recipient: RecipientRecord(recipient),
		attachments: List(
			attachments.map((attachment) => FileRecord(attachment))
		),
		createdAt: new Date(createdAt),
		...rest,
	});
}

export default createReducer(
	initialState,
	{
		fetchNotifications,
		fetchNotificationsSuccess,
		receivedNotification,
	},
	{
		fetchNotifications: (state) => state.set('loading', true),
		fetchNotificationsSuccess: (state, { payload: { notifications } }) => {
			return state.withMutations((s) => {
				if (notifications.length === 0) {
					s.set('exhausted', true);
				} else {
					notifications.forEach((notification) => {
						s.setIn(
							['notifications', notification._id],
							createNotificationRecord(notification)
						);
						s.update('notificationIds', (notificationIds) =>
							notificationIds.add(notification._id)
						);
					});
					s.update('notificationIds', (notificationIds) =>
						notificationIds.sortBy(
							(notificationId) =>
								-s.notifications.get(
									notificationId,
									MessageRecord()
								).createdAt
						)
					);
				}
				s.set('loading', false);
			});
		},
		receivedNotification: (state, { payload: { notification } }) =>
			state.withMutations((s) => {
				s.setIn(
					['notifications', notification._id],
					createNotificationRecord(notification)
				);
				s.update('notificationIds', (notificationIds) =>
					notificationIds
						.add(notification._id)
						.sortBy(
							(notificationId) =>
								-s.notifications.get(
									notificationId,
									MessageRecord()
								).createdAt
						)
				);
			}),
	}
);

export const parseNotificationState = (
	{
		exhausted,
		loading,
		notificationIds,
		notifications,
	}: SerializedNotificationState = {
		exhausted: false,
		loading: false,
		notifications: {},
		notificationIds: [],
	}
) =>
	NotificationStoreRecord({
		exhausted,
		loading,
		notificationIds: OrderedSet(notificationIds),
		notifications: Map(notifications).map(createNotificationRecord),
	});

import React, { useMemo, useCallback, forwardRef, Ref } from 'react';
import {
	EmbeddedDashboardProps,
	LinkedDashboardProps,
	DashboardStore,
} from '../interfaces';
import Dashboard from './Dashboard';
import Embedded from '../../Embedded';
import { AnyAction } from 'redux';
import {
	createWorkspace,
	removeWorkspace,
	renameWorkspace,
	selectWorkspace,
} from '../Store/actions';
import makeId from '../../../Helpers/makeId';
import { EmbeddedComponentProps } from '../../Embedded/interfaces';
import useForwardedRef from '../../../Hooks/useForwardedRef';
import { DashboardStoreRecord } from '../Store/reducer';
import { List } from 'immutable';

function EmbeddedDashboard(props: EmbeddedDashboardProps) {
	const {
		state,
		dispatch: uplinkDispatch,
		username,
		groups,
		applications,
		onRequestLogout,
		forwardAction,
		dashboardRef,
	} = props;

	const { currentWorkspace, workspaces } = state;

	const dispatch = useCallback(
		(action: AnyAction) => {
			uplinkDispatch(forwardAction(action));
		},
		[uplinkDispatch, forwardAction]
	);

	const handleCreateWorkspace = useCallback(
		(name: string) => {
			dispatch(createWorkspace({ id: makeId(16), name }));
		},
		[dispatch]
	);
	const handleRemoveWorkspace = useCallback(
		(index: number, id: string) => {
			dispatch(removeWorkspace({ id, index }));
		},
		[dispatch]
	);
	const handleRenameWorkspace = useCallback(
		(index: number, name: string) => {
			dispatch(renameWorkspace({ index, name }));
		},
		[dispatch]
	);
	const handleSelectWorkspace = useCallback(
		(index: number) => {
			dispatch(selectWorkspace({ index }));
		},
		[dispatch]
	);

	return useMemo(() => {
		return (
			<Dashboard
				ref={dashboardRef}
				username={username}
				groups={groups}
				applications={applications}
				currentWorkspace={currentWorkspace}
				workspaces={workspaces}
				onCreateWorkspace={handleCreateWorkspace}
				onRemoveWorkspace={handleRemoveWorkspace}
				onRenameWorkspace={handleRenameWorkspace}
				onSelectWorkspace={handleSelectWorkspace}
				onRequestLogout={onRequestLogout}
			/>
		);
	}, [
		dashboardRef,
		username,
		groups,
		applications,
		currentWorkspace,
		workspaces,
		handleCreateWorkspace,
		handleRemoveWorkspace,
		handleRenameWorkspace,
		handleSelectWorkspace,
		onRequestLogout,
	]);
}

export default forwardRef(function LinkedDashboard(
	props: LinkedDashboardProps,
	ref: Ref<HTMLDivElement>
) {
	const {
		dashboardId,
		onRequestLogout,
		applications,
		username,
		groups,
		userId,
	} = props;
	const _ref = useForwardedRef(ref);

	const initialState = new DashboardStoreRecord({
		currentWorkspace: 0,
		workspaces: List([
			{
				id: `${userId}-default-workspace`,
				name: 'DEFAULT',
			},
		]),
	});
	return useMemo(() => {
		return (
			<Embedded
				initialState={initialState}
				storeId={dashboardId}
				type="dashboard"
				component={({
					dispatch,
					state,
					forwardAction,
				}: EmbeddedComponentProps<DashboardStore>) => (
					<EmbeddedDashboard
						dashboardRef={_ref}
						dispatch={dispatch}
						forwardAction={forwardAction}
						state={state}
						username={username}
						groups={groups}
						onRequestLogout={onRequestLogout}
						applications={applications}
					/>
				)}
			/>
		);
	}, [
		applications,
		groups,
		dashboardId,
		username,
		onRequestLogout,
		initialState,
		_ref,
	]);
});

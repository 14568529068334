import { useContext, useEffect, useMemo } from 'react';
import { layerContext } from '../context';
import Style, { StyleFunction } from 'ol/style/Style';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { ReadOptions } from 'ol/format/Feature';

interface Props {
	feature: GeoJSON.Feature;
	style?: Style | Style[] | StyleFunction;
	readOptions?: ReadOptions;
}

const geojson = new GeoJSON();

export default function Feature(props: Props) {
	const { feature, readOptions, style } = props;
	const { source } = useContext(layerContext);

	const olFeature = useMemo(() => geojson.readFeature(feature, readOptions), [
		feature,
		readOptions,
	]);

	useEffect(() => {
		style && olFeature.setStyle(style);
	}, [style, olFeature]);

	useEffect(() => {
		if (!(source instanceof VectorSource)) return;
		source.addFeature(olFeature);
		return () => {
			source.removeFeature(olFeature);
		};
	}, [source, olFeature]);

	return null;
}

import styled from 'styled-components';
import Popover from '../../Popover/Components/Popover';

export const MenuWrapper = styled(Popover)`
	background-color: ${({
		theme: {
			background: { light },
		},
	}) => light};
	border-radius: 4px;
	overflow: hidden;
	${({ theme: { shadow } }) => shadow[4]}
`;

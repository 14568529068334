import styled from 'styled-components';
import { View } from '../../../../Common/View';
import WrappedTextArea from '../../../../Common/WrappedTextArea';
import { PanelButton } from './Panel.sc';
import { ButtonOverlayShape } from '../../../../Common/Button/interfaces';

export const PanelFormulaWrapper = styled(View)`
	flex-direction: row;
	flex: 1 0 0;
	align-items: stretch;
`;

export const FormulaButton = styled(PanelButton).attrs({
	overlayShape: ButtonOverlayShape.none,
})`
	cursor: unset;
`;

export const PanelFormulaTextArea = styled(WrappedTextArea)`
	flex: 1 0 0;
	box-sizing: border-box;
	margin: 0;
	border: 1px solid
		${({
			theme: {
				background: { darker },
			},
		}) => darker};
	&[x-mode~='focused'] {
		border: 1px solid
			${({
				theme: {
					background: { accent },
				},
			}) => accent};
	}
	& > [x-component='textarea'] {
		box-sizing: border-box;
		height: 100%;
		line-height: 22px;
		vertical-align: middle;
		padding: 6px;
		margin: 0;
		background: ${({
			theme: {
				background: { light },
			},
		}) => light};
		color: ${({
			theme: {
				font: {
					color: { lightBgPrimary },
				},
			},
		}) => lightBgPrimary};
		resize: none;
		outline: none;
		overflow: visible;
		transition: all 0.1s;
		&[x-mode~='disabled'] {
			background: ${({
				theme: {
					background: { darker },
				},
			}) => darker};
		}
	}
`;

import styled from 'styled-components';
import { View } from '../Common/View';
import { BodyText2 } from '../Common/Text';
import Icon from '@mdi/react';

export const WeatherPanelWrapper = styled(View)`
	flex-direction: row;
	border-radius: 12px;
	align-self: stretch;
	user-select: none;
`;

export const WeatherInfoWrapper = styled(View)`
	flex: 1 1 0;
	border-radius: 4px;
	height: 42px;
	margin: 8px;
	padding-left: 12px;
	padding-right: 12px;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	box-sizing: border-box;

	${({ theme: { shadow } }) => shadow[2]}
`;

// '#6DB351',
// '#50DA83',
// '#40AADD',
// '#A1C831',
// '#F3D559',
// '#F4813E',
// '#EF4430',

export const WeatherWindSpeedWrapper = styled(WeatherInfoWrapper)`
	background-color: #4f9e43;
`;

export const WeatherTemperatureWrapper = styled(WeatherInfoWrapper)`
	background-color: #de2d2d;
`;

export const WeatherHumidityWrapper = styled(WeatherInfoWrapper)`
	background-color: #a436d8;
`;

export const WeatherRainfallWrapper = styled(WeatherInfoWrapper)`
	background-color: #3f76e0;
`;

export const WeatherInfoText = styled(BodyText2)`
	color: ${({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => darkBgPrimary};
`;

export const WeatherInfoIcon = styled(Icon).attrs(
	({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => ({ color: darkBgPrimary, size: 0.9 })
)``;

import styled from 'styled-components';
import Button from '../../Button';

export const IconTextButtonWrapper = styled(Button)`
	box-sizing: border-box;
	align-items: center;
	padding: 8px;
	color: ${({
		theme: {
			font: {
				color: { lightBgSecondary, darkBgSecondary },
			},
		},
		lightBg,
	}) => (lightBg ? lightBgSecondary : darkBgSecondary)};

	&[x-mode~='activated'] {
		color: ${({
			theme: {
				font: {
					color: { primary, darkBgPrimary },
				},
			},
			lightBg,
		}) => (lightBg ? primary : darkBgPrimary)};
	}

	&[x-mode~='disabled'] {
		color: ${({
			theme: {
				font: {
					color: { lightBgDisabled, darkBgDisabled },
				},
			},
			lightBg,
		}) => (lightBg ? lightBgDisabled : darkBgDisabled)};
	}
`;

import { List, RecordOf, Record, Map } from 'immutable';
import constants from '../constants';
import {
	FilterType,
	MergeStatus,
	IFilter,
	IFormula,
	IFormat,
	IColumn,
	IRow,
	ICell,
	ITableStore,
	FilterJS,
	FormulaJS,
	SortOrder,
	DataType,
	TableStoreJS,
	ColumnJS,
} from '../interfaces';

const {
	DEFAULT_ROW_HEIGHT,
	DEFAULT_COLUMN_WIDTH,
	DEFAULT_PAGE_SIZE,
} = constants;

export const Filter = Record<IFilter>({
	type: FilterType.contain,
	values: Map(),
	suggestions: List(),
	errorMessage: '',
});

export const Formula = Record<IFormula>({
	value: '',
	suggestions: List(),
	errorMessage: '',
});

export const Format = Record<IFormat>({
	booleanFormat: 'true/false',
	dateFormat: 'yyyy-MM-dd HH:mm',
	numberFormat: '0.00',
	unit: '',
});

export const Column = Record<IColumn>({
	name: '',
	filter: Filter(),
	formula: Formula(),
	format: Format(),
	dataType: { type: 'any' },
	width: DEFAULT_COLUMN_WIDTH,
	editable: false,
	readonly: true,
	sortable: false,
});

export const Row = Record<IRow>({
	name: '',
	height: DEFAULT_ROW_HEIGHT,
});

export const Cell = Record<ICell>({
	editingBy: null,
	mergeStatus: MergeStatus.none,
});

const TableStore = Record<ITableStore>({
	columnKeys: List(),
	columns: Map(),
	recordIds: List(),
	recordCount: null,
	records: Map(),
	cells: Map(),
	unmergedRecords: Map(),
	rows: Map(),
	sorts: List(),
	page: 1,
	pageSize: DEFAULT_PAGE_SIZE,
	loading: false,
});

export function filterFromJS(filterJs: Partial<FilterJS> = {}) {
	const { suggestions = [], values = {}, ...rest } = filterJs;
	const _suggestions = List(suggestions);
	const _values = Map(values);
	return Filter({
		suggestions: _suggestions,
		values: _values,
		...rest,
	});
}

export function formulaFromJS(formulaJs: Partial<FormulaJS> = {}) {
	const { suggestions = [], ...rest } = formulaJs;
	const _suggestions = List(suggestions);
	return Formula({ suggestions: _suggestions, ...rest });
}

export function columnFromJS(columnJs: Partial<ColumnJS> = {}) {
	const { filter = {}, formula = {}, format = {}, ...rest } = columnJs;
	const _filter = filterFromJS(filter);
	const _formula = formulaFromJS(formula);
	const _format = Format(format);
	return Column({
		filter: _filter,
		formula: _formula,
		format: _format,
		...rest,
	});
}

export function tableStorefromJS(
	tableJs: Partial<TableStoreJS> = {}
): RecordOf<ITableStore> {
	const {
		columnKeys = [],
		recordIds = [],
		columns = {},
		rows = {},
		cells = {},
		sorts = [],
		records = {},
		unmergedRecords = {},
		...rest
	} = tableJs;
	const _columnKeys = List(columnKeys);
	const _recordIds = List(recordIds);

	const _columns = Map<string, RecordOf<IColumn>>().withMutations((m) => {
		Object.keys(columns).forEach((columnKey) => {
			m.set(columnKey, columnFromJS(columns[columnKey]));
		});
	});
	const _rows = Map<string, RecordOf<IRow>>().withMutations((m) => {
		Object.keys(rows).forEach((recordId) => {
			m.set(recordId, Row(rows[recordId]));
		});
	});
	const _cells = Map<string, Map<string, RecordOf<ICell>>>().withMutations(
		(m) => {
			Object.keys(cells).forEach((recordId) => {
				const row = cells[recordId];
				Object.keys(row).forEach((columnKey) => {
					const cell = row[columnKey];
					m.setIn([recordId, columnKey], Cell(cell));
				});
			});
		}
	);
	const _sorts = List<Map<string, SortOrder>>().withMutations((l) => {
		sorts.forEach((sort) => {
			l.push(Map(sort));
		});
	});
	const _records = Map(records).withMutations((m) => {
		m.forEach((record, recordId) => {
			m.set(recordId, Map(record));
		});
	}) as Map<string, Map<string, any>>;
	const _unmergedRecords = Map(unmergedRecords).withMutations((m) => {
		m.forEach((record, recordId) => {
			m.set(recordId, Map(record));
		});
	}) as Map<string, Map<string, any>>;

	return TableStore({
		columnKeys: _columnKeys,
		recordIds: _recordIds,
		columns: _columns,
		rows: _rows,
		cells: _cells,
		sorts: _sorts,
		records: _records,
		unmergedRecords: _unmergedRecords,
		...rest,
	});
}

export function getDefaultFilterType(dataType: DataType): FilterType {
	const dataTypeStr = dataType.type;
	if (dataTypeStr === 'any' || dataTypeStr === 'string')
		return FilterType.contain;
	else if (dataTypeStr === 'date') return FilterType.between;
	else if (dataTypeStr === 'number' || dataTypeStr === 'boolean')
		return FilterType.eq;
	else return FilterType.none;
}

export default TableStore;

import { useState, useCallback, useEffect, RefObject } from 'react';
import noop from '../Helpers/noop';

export interface useEditOptions<T extends HTMLElement> {
	targetRef?: RefObject<T>;
	selectOnEditStart?: boolean;
	disabled?: boolean;
}

export default function useEdit<T extends HTMLElement>(
	options: useEditOptions<T> = {}
) {
	const { targetRef, selectOnEditStart = true, disabled = false } = options;
	const [needFocus, setNeedFocus] = useState(false);
	const [editing, setEditing] = useState(false);
	const startEdit = useCallback(() => {
		setEditing(true);
		setNeedFocus(true);
	}, []);

	const endEdit = useCallback(() => {
		setEditing(false);
	}, []);

	useEffect(() => {
		if (disabled) return;
		if (needFocus) {
			if (!targetRef) return;
			const target = targetRef.current;
			if (target) {
				target.focus();
				setNeedFocus(false);
				if (
					selectOnEditStart &&
					(target instanceof HTMLInputElement ||
						target instanceof HTMLTextAreaElement)
				)
					setTimeout(() => {
						if (target === document.activeElement) target.select();
					}, 0);
			}
		}
	}, [disabled, targetRef, needFocus, selectOnEditStart]);

	if (disabled)
		return {
			editing: true,
			startEdit: noop,
			endEdit: noop,
		};

	return {
		editing,
		startEdit,
		endEdit,
	};
}

import styled from 'styled-components';
import { ExportRecordModalInput } from './ExportRecordModal.sc';

export const ExportRecordModalFilenameInput = styled(ExportRecordModalInput)`
	display: flex;
	flex: 1 0 0;
	margin-left: 8px;
`;
export const ExportRecordModalFileFormatInput = styled(ExportRecordModalInput)`
	width: 72px;
`;

import { useContext, useCallback } from 'react';
import workspaceContext from '../../Workspace/context';
import desktopContext from '../../../../Layouts/Components/Desktop/context';
import { ACTIVITIES_VIEW_MARGIN } from '../../../constants';

export default function useActivity(activityId: string) {
	const { windows, desktopWidth } = useContext(desktopContext);
	const { showPreview, activities, closeApplication } = useContext(
		workspaceContext
	);
	const numOfActivities = activities.size;
	const numOfColumn = Math.min(3, numOfActivities);

	const index = activities.findIndex(({ id }) => id === activityId);

	const activity = activities.get(index, {
		id: '',
		key: '',
		name: '',
		title: '',
	});

	const rowIndex = Math.floor(index / numOfColumn);
	const columnIndex = index % numOfColumn;

	const { rowsWidth, rowsHeight } = activities.reduce(
		(r: { rowsWidth: number[]; rowsHeight: number[] }, { id }, i) => {
			const row = Math.floor(i / numOfColumn);

			const { width, height } = windows.get(id, {
				width: 0,
				height: 0,
			});

			r.rowsWidth[row] = r.rowsWidth[row]
				? r.rowsWidth[row] + width
				: width;

			r.rowsHeight[row] = r.rowsHeight[row]
				? Math.max(r.rowsHeight[row], height)
				: height;

			return r;
		},
		{
			rowsWidth: [],
			rowsHeight: [],
		}
	);

	const maxWidth = Math.max(...rowsWidth);
	const gridScale = Math.min(
		(desktopWidth - ACTIVITIES_VIEW_MARGIN * 2) / maxWidth,
		0.9
	);
	const scale = gridScale * 0.85;
	let offsetX =
		ACTIVITIES_VIEW_MARGIN +
		((maxWidth - rowsWidth[rowIndex]) / 2) * gridScale;
	for (
		let i = rowIndex * numOfColumn;
		i < rowIndex * numOfColumn + columnIndex;
		i++
	) {
		const { id } = activities.get(i, { id: '' });
		const { width } = windows.get(id, { width: 0 });
		offsetX += width * gridScale;
	}

	const { height } = windows.get(activityId, { height: 0 });

	let offsetY =
		ACTIVITIES_VIEW_MARGIN + (rowsHeight[rowIndex] - height) * scale;

	for (let i = 0; i < rowIndex; i++) {
		offsetY += rowsHeight[i] * gridScale;
	}

	const handleCloseActivity = useCallback(() => {
		closeApplication && closeApplication(activityId);
	}, [closeApplication, activityId]);

	return {
		showPreview,
		index,
		numOfColumn,
		activity,
		scale,
		offsetX,
		offsetY,
		handleCloseActivity,
	};
}

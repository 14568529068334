import { useState, useCallback } from 'react';

export default function useHover() {
	const [hovered, setHovered] = useState(false);
	const handleMouseEnter = useCallback(() => {
		setHovered(true);
	}, []);
	const handleMouseLeave = useCallback(() => {
		setHovered(false);
	}, []);
	return {
		hovered,
		handleMouseEnter,
		handleMouseLeave,
	};
}

import { useState, useCallback } from 'react';

export default function useFocus() {
	const [focused, setFocused] = useState(false);
	const handleFocus = useCallback(() => {
		setFocused(true);
	}, []);
	const handleBlur = useCallback(() => {
		setFocused(false);
	}, []);
	return {
		focused,
		handleFocus,
		handleBlur,
	};
}

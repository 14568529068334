import React, { useMemo, useRef, useCallback } from 'react';
import useMeasure from '../../../../../Hooks/useMeasure';
import { Desktop } from '../../Desktop';
import { ResponsiveDesktopProps } from '../interfaces';
import { DesktopWindowState } from '../../Desktop/interfaces';

const xAxis = ['left', 'width', 'originalLeft', 'originalWidth'];
const yAxis = ['top', 'height', 'originalTop', 'originalHeight'];

export default function ResponsiveDesktop(props: ResponsiveDesktopProps) {
	const {
		children,
		onWindowChange,
		onWindowFocus,
		onWindowMoveStart,
		onWindowMoveEnd,
		onWindowResizeStart,
		onWindowResizeEnd,
		windowIndice,
		windows,
	} = props;

	const desktopRef = useRef<HTMLDivElement>(null);

	const { width: desktopWidth, height: desktopHeight } = useMeasure(
		desktopRef
	);

	const handleWindowChange = useCallback(
		(windowId: string, windowState: Partial<DesktopWindowState>) => {
			const keys = Object.keys(windowState) as Array<
				keyof typeof windowState
			>;
			onWindowChange &&
				onWindowChange(
					windowId,
					keys.reduce(
						(
							r: Partial<DesktopWindowState>,
							key: keyof typeof windowState
						) => {
							const value = windowState[key];
							return {
								...r,
								...{
									[key]:
										typeof value === 'number'
											? !!~xAxis.indexOf(key)
												? value / desktopWidth
												: !!~yAxis.indexOf(key)
												? value / desktopHeight
												: value
											: value,
								},
							};
						},
						{}
					)
				);
		},
		[onWindowChange, desktopWidth, desktopHeight]
	);

	return useMemo(() => {
		return (
			<Desktop
				ref={desktopRef}
				width={desktopWidth}
				height={desktopHeight}
				windowIndice={windowIndice}
				windows={windows.map(
					({
						maximized,
						minimized,
						left,
						top,
						width,
						height,
						originalLeft,
						originalTop,
						originalWidth,
						originalHeight,
					}) => ({
						maximized,
						minimized,
						left: left ? left * desktopWidth : left,
						top: top ? top * desktopHeight : top,
						width: width ? width * desktopWidth : width,
						height: height ? height * desktopHeight : height,
						originalLeft: originalLeft
							? originalLeft * desktopWidth
							: originalLeft,
						originalTop: originalTop
							? originalTop * desktopHeight
							: originalTop,
						originalWidth: originalWidth
							? originalWidth * desktopWidth
							: originalWidth,
						originalHeight: originalHeight
							? originalHeight * desktopHeight
							: originalHeight,
					})
				)}
				onWindowMoveStart={onWindowMoveStart}
				onWindowMoveEnd={onWindowMoveEnd}
				onWindowResizeStart={onWindowResizeStart}
				onWindowResizeEnd={onWindowResizeEnd}
				onWindowChange={handleWindowChange}
				onWindowFocus={onWindowFocus}
			>
				{children}
			</Desktop>
		);
	}, [
		children,
		windows,
		windowIndice,
		desktopWidth,
		desktopHeight,
		handleWindowChange,
		onWindowFocus,
		onWindowMoveStart,
		onWindowMoveEnd,
		onWindowResizeStart,
		onWindowResizeEnd,
	]);
}

import React, { useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import theme from '../theme';
import TreeDetails from '../App/Scenes/Main/Components/TreeMap/Components/TreeDetails';

const demoTreeDetailsProps = {
	tree: {
		treeId: 'Demo TreeId',
		district: 'Kwun Tong',
		species: 'Acacia auriculiformis',
		department: 'Hightways',
		leaning: 30,
		tiltingAngle: 1.67,
		height: 1.67,
		DSH: 280.2,
	},
	sensors: [
		{
			sensorId: 'Demo SensorId 1',
			transmissionType: 'LoRa',
			voltage: 3000,
			samplingInterval: 5,
			sendInterval: 30,
			lastMessageReceivedAt: new Date('2019-1-4'),
		},
		{
			sensorId: 'Demo SensorId 2',
			transmissionType: 'LoRa',
			voltage: 2000,
			samplingInterval: 10,
			sendInterval: 30,
			lastMessageReceivedAt: new Date('2019-1-4'),
		},
		{
			sensorId: 'Demo SensorId 3',
			transmissionType: 'NB',
			voltage: 3000,
			samplingInterval: 5,
			sendInterval: 30,
			lastMessageReceivedAt: new Date('2019-1-4'),
		},
		{
			sensorId: 'Demo SensorId 4',
			transmissionType: 'LoRa',
			voltage: 2300,
			samplingInterval: 10,
			sendInterval: 60,
			lastMessageReceivedAt: new Date('2019-1-4'),
		},
	],
	tiltingAngleData: [
		{
			sensorId: 'test',
			data: [
				{
					x: new Date('2019-1-1'),
					tiltingAngle: 1.64,
				},
				{
					x: new Date('2019-1-2'),
					tiltingAngle: 1.63,
				},
				{
					x: new Date('2019-1-3'),
					tiltingAngle: 1.65,
				},
				{
					x: new Date('2019-1-4'),
					tiltingAngle: 1.67,
				},
			],
		},
	],
};

function MapTreeDetailsDemo() {
	return useMemo(
		() => (
			<ThemeProvider theme={theme}>
				<TreeDetails {...demoTreeDetailsProps} />
			</ThemeProvider>
		),
		[]
	);
}

export default MapTreeDetailsDemo;

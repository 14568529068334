import React, { useState, useCallback, useMemo, useContext } from 'react';
import { mdiDownload, mdiClose } from '@mdi/js';
import {
	ExportRecordModalContentWrapper,
	ModalFirstRowWrapper,
	ModalSecondRowWrapper,
	ModalThirdRowWrapper,
	ModalRowWrapper,
	ModalColumnWrapper,
	ModalText,
	ModalRadiobox,
	ModalExportButton,
	ModalResetButton,
} from './ExportRecordModal.sc';
import ExportRecordModalFileInput from './ExportRecordModalFileInput';
import ExportRecordModalRangeInput from './ExportRecordModalRangeInput';
import Modal from '../../../../Common/Modal';
import { useRecordsExport } from '../../../Hooks/Record';
import context from '../../../context';
import {
	ModalProps,
	ModalChildrenProps,
} from '../../../../Common/Modal/interfaces';

function ExportModalLabels() {
	return useMemo(
		() => (
			<ModalColumnWrapper>
				<ModalFirstRowWrapper>
					<ModalText>Filename:</ModalText>
				</ModalFirstRowWrapper>
				<ModalSecondRowWrapper>
					<ModalText>Rows:</ModalText>
				</ModalSecondRowWrapper>
			</ModalColumnWrapper>
		),
		[]
	);
}

interface ExportButtonProps {
	filename: string;
	extension: string;
	from: number;
	to: number;
	all: boolean;
	exportRecords(
		filename: string,
		extension: string,
		from: number,
		to: number,
		all: boolean
	): void;
	onRequestClose(): void;
}

function ExportButton(props: ExportButtonProps) {
	const {
		filename,
		extension,
		from,
		to,
		all,
		exportRecords,
		onRequestClose,
	} = props;

	const handlePress = useCallback(() => {
		exportRecords(filename, extension, from, to, all);
		onRequestClose();
	}, [filename, extension, from, to, all, exportRecords, onRequestClose]);

	const disabled = !filename || !extension || from > to;

	return useMemo(() => {
		return (
			<ModalExportButton
				tabIndex={0}
				disabled={disabled}
				icon={mdiDownload}
				text="EXPORT"
				onPress={handlePress}
			/>
		);
	}, [disabled, handlePress]);
}

interface ResetButtonProps {
	setFilename(filename: string): void;
	setExtension(extension: string): void;
	setFrom(from: number): void;
	setTo(to: number): void;
	setAll(all: boolean): void;
}
function ResetButton(props: ResetButtonProps) {
	const { setFilename, setExtension, setFrom, setTo, setAll } = props;

	const handlePress = useCallback(() => {
		setFilename('');
		setExtension('');
		setFrom(1);
		setTo(1);
		setAll(false);
	}, [setFilename, setExtension, setFrom, setTo, setAll]);

	return useMemo(() => {
		return (
			<ModalResetButton
				tabIndex={0}
				icon={mdiClose}
				text="RESET"
				onPress={handlePress}
			/>
		);
	}, [handlePress]);
}

type ExportRecordModalContentProps = ModalChildrenProps;
function ExportRecordModalContent(props: ExportRecordModalContentProps) {
	const { onRequestClose } = props;
	const [filename, setFilename] = useState('');
	const [extension, setExtension] = useState('');
	const [from, setFrom] = useState(1);
	const [to, setTo] = useState(1);
	const [all, setAll] = useState(false);
	const contextObj = useContext(context);
	const exportRecords = useRecordsExport(contextObj);

	const handleSelectAll = useCallback(() => {
		setAll(true);
	}, []);

	const handleSelectRange = useCallback(() => {
		setAll(false);
	}, []);

	return useMemo(() => {
		return (
			<ExportRecordModalContentWrapper>
				<ModalRowWrapper>
					<ExportModalLabels />
					<ModalColumnWrapper>
						<ModalFirstRowWrapper>
							<ExportRecordModalFileInput
								filename={filename}
								extension={extension}
								setFilename={setFilename}
								setExtension={setExtension}
							/>
						</ModalFirstRowWrapper>
						<ModalSecondRowWrapper>
							<ModalColumnWrapper>
								<ModalRowWrapper>
									<ModalRadiobox
										tabIndex={0}
										checked={!all}
										onPress={handleSelectRange}
									/>
									<ExportRecordModalRangeInput
										disabled={all}
										from={from}
										to={to}
										setFrom={setFrom}
										setTo={setTo}
									/>
								</ModalRowWrapper>
								<ModalRowWrapper>
									<ModalRadiobox
										tabIndex={0}
										checked={all}
										onPress={handleSelectAll}
									/>
									<ModalText>All</ModalText>
								</ModalRowWrapper>
							</ModalColumnWrapper>
						</ModalSecondRowWrapper>
					</ModalColumnWrapper>
				</ModalRowWrapper>
				<ModalThirdRowWrapper>
					<ExportButton
						filename={filename}
						extension={extension}
						from={from}
						to={to}
						all={all}
						exportRecords={exportRecords}
						onRequestClose={onRequestClose}
					/>
					<ResetButton
						setFilename={setFilename}
						setExtension={setExtension}
						setFrom={setFrom}
						setTo={setTo}
						setAll={setAll}
					/>
				</ModalThirdRowWrapper>
			</ExportRecordModalContentWrapper>
		);
	}, [
		filename,
		extension,
		from,
		to,
		all,
		handleSelectAll,
		handleSelectRange,
		exportRecords,
		onRequestClose,
	]);
}

type ExportRecordModalProps = Omit<ModalProps, 'render' | 'children'>;

function ExportRecordModal(props: ExportRecordModalProps) {
	return (
		<Modal
			title="Export Record"
			render={ExportRecordModalContent}
			{...props}
		/>
	);
}

export default ExportRecordModal;

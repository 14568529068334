import { DesktopWindowState } from '../interfaces';
import desktopContext from '../context';
import { useContext, useCallback, useMemo } from 'react';
import { MaximizeType, Movement } from '../../../interfaces';
import useMoveRestore from './useMoveRestore';
import useDropOnEdge from './useDropOnEdge';

export default function useDesktopWindow(windowId: string) {
	const {
		windows,
		windowIndice,
		desktopWidth,
		desktopHeight,
		onWindowChange,
		onWindowFocus,
		onWindowMoveStart,
		onWindowMoveEnd,
		onWindowResizeStart,
		onWindowResizeEnd,
	} = useContext(desktopContext);

	const windowState = windows.get(windowId, {
		maximized: MaximizeType.REGULAR,
		minimized: false,
		left: 0,
		top: 0,
		width: 0,
		height: 0,
		originalLeft: 0,
		originalTop: 0,
		originalWidth: 0,
		originalHeight: 0,
	});

	const {
		left,
		top,
		width,
		height,
		maximized,
		minimized,
		originalLeft,
		originalTop,
		originalWidth,
		originalHeight,
	} = windowState;

	const handleChange = useCallback(
		(windowState: Partial<DesktopWindowState>) => {
			onWindowChange && onWindowChange(windowId, windowState);
		},
		[windowId, onWindowChange]
	);

	const handleFocus = useCallback(() => {
		onWindowFocus && onWindowFocus(windowId);
		minimized &&
			handleChange({
				minimized: false,
				left: typeof originalLeft === 'number' ? originalLeft : left,
			});
	}, [windowId, onWindowFocus, handleChange, originalLeft, left, minimized]);

	const handleDropOnEdges = useDropOnEdge(windowId, windowState);

	const handleMoveRestore = useMoveRestore(windowId, windowState);

	const handleMoveStart = useCallback(
		(movement: Movement) => {
			onWindowMoveStart && onWindowMoveStart(windowId);
			handleDropOnEdges();
			handleMoveRestore(movement);
		},
		[windowId, onWindowMoveStart, handleDropOnEdges, handleMoveRestore]
	);

	const handleMoveEnd = useCallback(() => {
		onWindowMoveEnd && onWindowMoveEnd(windowId);
	}, [windowId, onWindowMoveEnd]);

	const handleResizeStart = useCallback(() => {
		onWindowResizeStart && onWindowResizeStart(windowId);
	}, [windowId, onWindowResizeStart]);

	const handleResizeEnd = useCallback(() => {
		onWindowResizeEnd && onWindowResizeEnd(windowId);
	}, [windowId, onWindowResizeEnd]);

	const onMaximize = useCallback(() => {
		handleChange({
			originalLeft: originalLeft || left,
			originalTop: originalTop || top,
			originalWidth: originalWidth || width,
			originalHeight: originalHeight || height,
			left: 0,
			top: 0,
			width: desktopWidth,
			height: desktopHeight,
			maximized: MaximizeType.FULL,
		});
	}, [
		handleChange,
		left,
		top,
		width,
		height,
		desktopWidth,
		desktopHeight,
		originalLeft,
		originalTop,
		originalWidth,
		originalHeight,
	]);

	const onRestore = useCallback(() => {
		handleChange({
			left: originalLeft || 0,
			top: originalTop || 0,
			width: originalWidth || 0,
			height: originalHeight || 0,
			maximized: MaximizeType.REGULAR,
		});
	}, [
		handleChange,
		originalLeft,
		originalTop,
		originalWidth,
		originalHeight,
	]);

	const onMinimize = useCallback(() => {
		handleChange({
			left: -width - 100,
			originalLeft: left,
			minimized: true,
		});
	}, [handleChange, left, width]);

	const zIndex = useMemo(
		() => windowIndice.findIndex((id) => id === windowId) + 2,
		[windowIndice, windowId]
	);

	return {
		left,
		top,
		width,
		height,
		maximized,
		zIndex,
		originalLeft,
		originalTop,
		originalWidth,
		originalHeight,
		parentWidth: desktopWidth,
		parentHeight: desktopHeight,
		onChange: handleChange,
		onFocus: handleFocus,
		onMoveStart: handleMoveStart,
		onMoveEnd: handleMoveEnd,
		onResizeStart: handleResizeStart,
		onResizeEnd: handleResizeEnd,
		onMaximize,
		onRestore,
		onMinimize,
	};
}

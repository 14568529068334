import OlMap from 'ol/Map';
import { useEffect, useRef } from 'react';

export default function useUpdateZoom(
	olMap: OlMap,
	zoom: number,
	onZoom: (zoom: number) => any
) {
	useEffect(() => {
		if (olMap.getView().getZoom() !== zoom) olMap.getView().setZoom(zoom);
	}, [zoom, olMap]);

	const zoomRef = useRef(zoom);
	useEffect(() => {
		zoomRef.current = zoom;
	});

	useEffect(() => {
		function updateZoomProps() {
			const view = olMap.getView();
			const newZoom = view.getZoom();
			if (typeof newZoom === 'number' && newZoom !== zoomRef.current)
				onZoom(newZoom);

			return true;
		}
		olMap.on('moveend', updateZoomProps);
		return () => {
			olMap.un('moveend', updateZoomProps);
		};
	}, [olMap, zoom, onZoom]);
}

import styled from 'styled-components';
import IconTextButton from '../../IconTextButton';
import WrappedIcon from '../../WrappedIcon';

export const MenuItemWrapper = styled(IconTextButton)`
	height: 32px;
	padding: 6px;
	color: ${({
		theme: {
			font: {
				color: { lightBgSecondary },
			},
		},
	}) => lightBgSecondary};

	& > [x-component='icon'] {
		margin-left: 6px;
		margin-right: 8px;
	}

	& > [x-component='text'] {
		margin-left: 8px;
		margin-right: 8px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&[x-mode~='focused'] {
		color: ${({
			theme: {
				font: {
					color: { darkBgPrimary },
				},
			},
		}) => darkBgPrimary};
		& > [x-component='overlay'] {
			background-color: ${({
				theme: {
					background: { accent },
				},
			}) => accent};
		}
	}

	&[x-mode~='disabled'] {
		color: ${({
			theme: {
				font: {
					color: { lightBgDisabled },
				},
			},
		}) => lightBgDisabled};
	}
`;

interface MenuItemIconProps {
	disabled: 0 | 1;
	activated: 0 | 1;
	focused: 0 | 1;
}
export const MenuItemIcon = styled(WrappedIcon).attrs<MenuItemIconProps>(
	({
		theme: {
			font: {
				color: {
					lightBgDisabled,
					primary,
					darkBgSecondary,
					lightBgSecondary,
				},
			},
		},
		disabled,
		activated,
		focused,
	}) => ({
		iconProps: {
			color: disabled
				? lightBgDisabled
				: activated
				? primary
				: focused
				? darkBgSecondary
				: lightBgSecondary,
		},
	})
)<MenuItemIconProps>``;

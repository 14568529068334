import { useCallback, useMemo } from 'react';
import {
	renameColumn,
	updateColumnFilterType,
	updateColumnFilterValue,
	updateColumnFormulaValue,
	updateColumnEditable,
	dropColumnFilters,
	dropColumnFilterValue,
	updateColumnBooleanFormat,
	updateColumnDateFormat,
	updateColumnNumberFormat,
	updateColumnUnit,
} from '../Store/actions';
import { Column } from '../Store/store';
import { useImmutable } from '../../../Hooks/useImmutable';
import { FilterType } from '../interfaces';
import { ITableContext } from '../context';

export function useSelectedColumnKey(contextObj: ITableContext) {
	const { columnKeys, selected } = contextObj;
	const _columnKeys = useImmutable(columnKeys);
	const selectedColumnIndex = selected ? selected.start[1] : -1;
	return useMemo(() => {
		if (selectedColumnIndex === -1) return '';
		return _columnKeys.get(selectedColumnIndex, '');
	}, [_columnKeys, selectedColumnIndex]);
}

export function useColumn(contextObj: ITableContext, columnKey: string) {
	const { columns } = contextObj;
	return useImmutable(columns.get(columnKey, Column()));
}

export function useColumnRename(contextObj: ITableContext, columnKey: string) {
	const { onRequestDispatch } = contextObj;
	return useCallback(
		(name: string) => {
			onRequestDispatch(renameColumn({ columnKey, name }));
		},
		[onRequestDispatch, columnKey]
	);
}

export function useColumnEditableUpdate(
	contextObj: ITableContext,
	columnKey: string
) {
	const { onRequestDispatch } = contextObj;
	return useCallback(
		(editable: boolean) => {
			onRequestDispatch(updateColumnEditable({ columnKey, editable }));
		},
		[onRequestDispatch, columnKey]
	);
}

export function useAllColumnFiltersDrop(contextObj: ITableContext) {
	const { onRequestDispatch } = contextObj;
	return useCallback(() => {
		onRequestDispatch(dropColumnFilters({ columnKeys: null }));
	}, [onRequestDispatch]);
}

export function useColumnFilterTypeUpdate(
	contextObj: ITableContext,
	columnKey: string,
	type: FilterType
) {
	const { onRequestDispatch } = contextObj;
	return useCallback(() => {
		onRequestDispatch(updateColumnFilterType({ columnKey, type }));
	}, [onRequestDispatch, columnKey, type]);
}

export function useColumnFilterValueDrop(
	contextObj: ITableContext,
	columnKey: string
) {
	const { onRequestDispatch } = contextObj;
	const {
		filter: { type },
	} = useColumn(contextObj, columnKey);
	return useCallback(() => {
		onRequestDispatch(
			dropColumnFilterValue({
				columnKey,
				type,
			})
		);
	}, [onRequestDispatch, columnKey, type]);
}

export function useColumnFilterValueUpdate(
	contextObj: ITableContext,
	columnKey: string
) {
	const { onRequestDispatch } = contextObj;
	const {
		filter: { type },
	} = useColumn(contextObj, columnKey);
	return useCallback(
		(value: any) => {
			onRequestDispatch(
				updateColumnFilterValue({
					columnKey,
					type,
					value,
				})
			);
		},
		[onRequestDispatch, columnKey, type]
	);
}

export function useColumnFormulaValueUpdate(
	contextObj: ITableContext,
	columnKey: string
) {
	const { onRequestDispatch } = contextObj;
	return useCallback(
		(value: string) => {
			if (columnKey)
				onRequestDispatch(
					updateColumnFormulaValue({ columnKey, value })
				);
		},
		[onRequestDispatch, columnKey]
	);
}

export function useColumnBooleanFormatUpdate(
	contextObj: ITableContext,
	columnKey: string
) {
	const { onRequestDispatch } = contextObj;
	return useCallback(
		(booleanFormat: string) => {
			if (columnKey)
				onRequestDispatch(
					updateColumnBooleanFormat({ columnKey, booleanFormat })
				);
		},
		[onRequestDispatch, columnKey]
	);
}

export function useColumnDateFormatUpdate(
	contextObj: ITableContext,
	columnKey: string
) {
	const { onRequestDispatch } = contextObj;
	return useCallback(
		(dateFormat: string) => {
			if (columnKey)
				onRequestDispatch(
					updateColumnDateFormat({ columnKey, dateFormat })
				);
		},
		[onRequestDispatch, columnKey]
	);
}

export function useColumnNumberFormatUpdate(
	contextObj: ITableContext,
	columnKey: string
) {
	const { onRequestDispatch } = contextObj;
	return useCallback(
		(numberFormat: string) => {
			if (columnKey)
				onRequestDispatch(
					updateColumnNumberFormat({ columnKey, numberFormat })
				);
		},
		[onRequestDispatch, columnKey]
	);
}

export function useColumnUnitUpdate(
	contextObj: ITableContext,
	columnKey: string
) {
	const { onRequestDispatch } = contextObj;
	return useCallback(
		(unit: string) => {
			if (columnKey)
				onRequestDispatch(updateColumnUnit({ columnKey, unit }));
		},
		[onRequestDispatch, columnKey]
	);
}

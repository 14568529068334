import styled from 'styled-components';
import { View } from '../../../../Common/View';
import IconTextButton from '../../../../Common/IconTextButton';
import { TooltipLocation } from '../../../../Common/Tooltip/interfaces';
import constant from '../../../constants';
const { PANEL_HEIGHT, MIN_PANEL_WIDTH, INDEX_WIDTH } = constant;

export const PanelWrapper = styled(View)`
	align-self: stretch;
	height: ${PANEL_HEIGHT}px;
	min-width: ${MIN_PANEL_WIDTH}px;
	flex-direction: row;
	align-items: stretch;
	justify-content: stretch;
	overflow: hidden;
	user-select: none;
	background: ${({
		theme: {
			background: { light },
		},
	}) => light};
	margin-left: ${INDEX_WIDTH}px;
	border-left: 1px solid
		${({
			theme: {
				background: { darken },
			},
		}) => darken[1]};
	border-right: 1px solid
		${({
			theme: {
				background: { darken },
			},
		}) => darken[1]};
	border-top: 1px solid
		${({
			theme: {
				background: { darken },
			},
		}) => darken[1]};
	overflow: visible;
`;

export const PanelSeparator = styled(View)`
	margin-left: 4px;
	margin-right: 4px;
	width: 1px;
	height: ${PANEL_HEIGHT}px;
	background: ${({
		theme: {
			background: { darker },
		},
	}) => darker};
`;

export const PanelButton = styled(IconTextButton).attrs({
	iconProps: { size: 0.7 },
	tooltipProps: { location: TooltipLocation.bottom },
})`
	width: ${PANEL_HEIGHT}px;
	height: ${PANEL_HEIGHT}px;
	padding: 0;
	justify-content: center;
`;

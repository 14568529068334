import {
	useRef,
	useCallback,
	RefObject,
	MouseEvent as RMouseEvent,
} from 'react';

// Reference: https://www.bennadel.com/blog/3460-automatically-scroll-the-window-when-the-user-approaches-the-viewport-edge-in-javascript.htm

interface UseScrollOnMouseAtEdgeOptions {
	containerRef?: RefObject<HTMLElement>;
	edgeSize?: number;
	stepSize?: number;
	timeout?: number;
}

export default function useScrollOnMouseAtEdge(
	options: UseScrollOnMouseAtEdgeOptions = {}
) {
	const { containerRef, edgeSize = 30, stepSize = 30, timeout = 5 } = options;

	const timeoutIdRef = useRef<number | undefined>(undefined);

	const handleMouseMove = useCallback(
		(e: MouseEvent | RMouseEvent) => {
			const container = containerRef
				? containerRef.current
				: document.body;
			if (!container) return;
			const {
				left: containerX,
				top: containerY,
				width: containerWidth,
				height: containerHeight,
			} = container.getBoundingClientRect();

			const offsetX = e.clientX - containerX;
			const offsetY = e.clientY - containerY;

			const edgeL = edgeSize;
			const edgeR = containerWidth - edgeSize;
			const edgeT = edgeSize;
			const edgeB = containerHeight - edgeSize;

			const inEdgeL = offsetX < edgeL;
			const inEdgeR = offsetX > edgeR;
			const inEdgeT = offsetY < edgeT;
			const inEdgeB = offsetY > edgeB;

			if (!(inEdgeL || inEdgeR || inEdgeT || inEdgeB)) {
				clearTimeout(timeoutIdRef.current);
				return;
			}

			(function keepScroll() {
				clearTimeout(timeoutIdRef.current);
				scroll();
				timeoutIdRef.current = setTimeout(keepScroll, timeout);
			})();

			function scroll() {
				if (!container) return;
				const { scrollLeft: scrollX, scrollTop: scrollY } = container;
				let stepX = 0;
				let stepY = 0;
				if (inEdgeL) stepX = (offsetX - edgeL) / edgeSize;
				else if (inEdgeR) stepX = (offsetX - edgeR) / edgeSize;
				if (inEdgeT) stepY = (offsetY - edgeT) / edgeSize;
				else if (inEdgeB) stepY = (offsetY - edgeB) / edgeSize;
				const nextScrollX = scrollX + stepSize * stepX;
				const nextScrollY = scrollY + stepSize * stepY;
				container.scrollTo({
					left: nextScrollX,
					top: nextScrollY,
					behavior: 'auto',
				});
			}
		},
		[containerRef, edgeSize, stepSize, timeout]
	);

	const stopScroll = useCallback(() => {
		clearTimeout(timeoutIdRef.current);
	}, []);

	return {
		handleMouseMove,
		stopScroll,
	};
}

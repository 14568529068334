import styled from 'styled-components';
import { View } from '../../../../Common/View';
import { CaptionText } from '../../../../Common/Text';
import stringToHSL from '../../../../../Helpers/stringToHSL';
import { MergeStatus } from '../../../interfaces';
import constants from '../../../constants';

const { MIN_COLUMN_WIDTH, MIN_ROW_HEIGHT } = constants;

export interface BodyCellWrapperProps {
	focused: boolean;
	mergeStatus: MergeStatus;
}

export interface BodyCellEditorTextProps {
	editingBy: string | null;
}

export const BodyCellWrapper = styled(View)<BodyCellWrapperProps>`
	position: absolute;
	border-style: solid;
	border-color: ${({
		theme: {
			background: { darken, accent },
		},
		focused,
	}) => (focused ? accent : darken[1])};
	border-top-width: ${({ focused }) => (focused ? '2px' : '1px')};
	border-left-width: ${({ focused }) => (focused ? '2px' : '1px')};
	border-right-width: ${({ focused }) => (focused ? '2px' : '0')};
	border-bottom-width: ${({ focused }) => (focused ? '2px' : '0')};
	flex-direction: column;
	align-items: stretch;
	justify-content: center;
	overflow: hidden;
	user-select: none;
	${({ theme: { shadow }, focused }) => (focused ? shadow[2] : '')}
	z-index: ${({ focused }) => (focused ? '1000' : 'auto')};
	background-color: ${({
		theme: {
			background: { light, grey, error },
		},
		focused,
		mergeStatus,
	}) =>
		focused
			? light
			: mergeStatus === 'unmerged' || mergeStatus === 'merging'
			? grey
			: mergeStatus === 'failed'
			? error
			: light};
	box-sizing: border-box;
	&:focus {
		outline: none;
	}
`;

export const BodyCellEditorText = styled(CaptionText)<BodyCellEditorTextProps>`
	box-sizing: border-box;
	position: absolute;
	top: 0;
	right: 0;
	height: ${MIN_ROW_HEIGHT / 2}px;
	padding: 2px;
	max-width: ${MIN_COLUMN_WIDTH}px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	color: ${({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => darkBgPrimary};
	background-color: ${({ editingBy }) =>
		editingBy ? stringToHSL(editingBy, 50, 50) : 'transparent'};
	transition: all 0.2s;
`;

import React, { useMemo } from 'react';
import {
	mdiContentCopy,
	mdiContentPaste,
	mdiBroom,
	mdiTableColumnRemove,
	mdiTableColumnPlusAfter,
	mdiTableColumnPlusBefore,
} from '@mdi/js';

import Menu, { MenuChildren } from '../../../../Common/Menu';
import useSelectedCellsCopy from '../Hooks/useSelectedCellsCopy';
import useSelectedCellsPaste from '../Hooks/useSelectedCellsPaste';
import useSelectedUnmergedRecordsDrop from '../Hooks/useSelectedUnmergedRecordsDrop';
import useSelectedColumnsDrop from '../Hooks/useSelectedColumnsDrop';
import {
	useSelectedColumnsRightAdd,
	useSelectedColumnsLeftAdd,
} from '../Hooks/useSelectedColumnsAdd';
import useContextWithRef from '../../../../../Hooks/useContextWithRef';
import { MenuProps, MenuItemProps } from '../../../../Common/Menu/interfaces';
import context from '../../../context';

type BodyContextMenuProps = Omit<MenuProps, 'data' | 'render'>;

function BodyContextMenu(props: BodyContextMenuProps) {
	const { contextObjRef } = useContextWithRef(context);
	const copyCells = useSelectedCellsCopy(contextObjRef);
	const pasteCells = useSelectedCellsPaste(contextObjRef);
	const dropUnmergedRecords = useSelectedUnmergedRecordsDrop(contextObjRef);
	const dropColumns = useSelectedColumnsDrop(contextObjRef);
	const addColumnsRight = useSelectedColumnsRightAdd(contextObjRef);
	const addColumnsLeft = useSelectedColumnsLeftAdd(contextObjRef);
	const data: MenuItemProps[] = useMemo(
		() => [
			{
				text: 'COPY',
				icon: mdiContentCopy,
				onPress: copyCells,
			},
			{
				text: 'PASTE',
				icon: mdiContentPaste,
				onPress: pasteCells,
			},
			{
				text: 'CLEAR UNSAVE',
				icon: mdiBroom,
				onPress: dropUnmergedRecords,
			},
			{
				text: 'DROP COLUMN(S)',
				icon: mdiTableColumnRemove,
				onPress: dropColumns,
			},
			{
				text: 'ADD COLUNM(S) TO THE RIGHT',
				icon: mdiTableColumnPlusAfter,
				onPress: addColumnsRight,
			},
			{
				text: 'ADD COLUNM(S) TO THE LEFT',
				icon: mdiTableColumnPlusBefore,
				onPress: addColumnsLeft,
			},
		],
		[
			copyCells,
			pasteCells,
			dropUnmergedRecords,
			dropColumns,
			addColumnsRight,
			addColumnsLeft,
		]
	);
	return <Menu data={data} render={MenuChildren} {...props} />;
}

export default BodyContextMenu;

import createActionCreator from '../../Helpers/createActionCreator';
import { IMessage } from './interfaces';

export interface IFetchNotificationsPayload {
	fetched: number;
}

export const fetchNotifications = createActionCreator(
	'NOTIFICATION/FETCH',
	{} as IFetchNotificationsPayload
);

export interface IFetchNotificationsSuccessPayload {
	notifications: IMessage[];
}

export const fetchNotificationsSuccess = createActionCreator(
	'NOTIFICATION/FETCH_SUCCESS',
	{
		notifications: [],
	} as IFetchNotificationsSuccessPayload
);

export interface IReceivedNewNotification {
	notification: IMessage;
}

export const receivedNotification = createActionCreator(
	'NOTIFICATION/RECEIVED_NEW',
	{} as IReceivedNewNotification
);

export interface IMarkReadPayload {
	notificationId: string;
}

export const markRead = createActionCreator(
	'NOTIFICATION/READ',
	{} as IMarkReadPayload
);

export interface IUnreadPayload {
	notificationId: string;
}

export const unread = createActionCreator(
	'NOTIFICATION/READ',
	{} as IUnreadPayload
);

export const markAllRead = createActionCreator('NOTIFICATION/READ_ALL');

import React, { useMemo, useRef } from 'react';
import { mdiShape } from '@mdi/js';
import { VictoryPie, VictoryLegend, VictoryContainer } from 'victory';

import {
	ChartWrapper,
	ChartHeaderWrapper,
	ChartBodyWrapper,
	ChartNoDataWrapper,
	ChartNoDataText,
	ChartIcon,
	ChartTitleText,
} from './SensorTypeChart.sc';
import { chartDarkTheme } from '../../../../../chartTheme';
import useMeasure from '../../../../../Hooks/useMeasure';
import { LABEL_SIZE, PADDING, CHART_WIDTH } from '../constant';
import { MouseOverIcon } from './MouseOverIcon';

export interface SensorTypeChartDatum {
	x: string;
	count: number;
}
export type SensorTypeChartData = SensorTypeChartDatum[];

interface SensorTypeChartProps {
	data: SensorTypeChartData;
}

const getSensorType = ({ x }: any) => x || 'UNKNOWN';
const getLabel = ({ datum }: any) => `${datum.count}`;

function SensorTypeChart(props: SensorTypeChartProps) {
	const { data } = props;

	const containerRef = useRef(null);
	const { width, height } = useMeasure(containerRef);

	return useMemo(() => {
		const dataCount = data.length;
		return (
			<ChartWrapper>
				<ChartHeaderWrapper>
					<ChartIcon icon={mdiShape} />
					<ChartTitleText>SENSOR TYPE</ChartTitleText>
					<MouseOverIcon />
				</ChartHeaderWrapper>
				<ChartBodyWrapper ref={containerRef}>
					{dataCount > 0 ? (
						<VictoryContainer width={width} height={height}>
							<VictoryPie
								standalone={false}
								width={height}
								height={height}
								padding={PADDING}
								// colorScale={[]}
								theme={chartDarkTheme}
								data={data}
								x={getSensorType}
								y="count"
								labels={getLabel}
								style={{
									labels: { fontSize: LABEL_SIZE },
									data: {
										fill: ({ datum: { x } }) =>
											x === 'NB'
												? '#38B10D'
												: x === 'LoRa'
												? '#6794EC'
												: 'grey',
									},
								}}
								labelRadius={height / 2 - PADDING + 5}
								innerRadius={height / 2 - PADDING - CHART_WIDTH}
								radius={height / 2 - PADDING}
								padAngle={5}
							/>
							<VictoryLegend
								theme={chartDarkTheme}
								standalone={false}
								x={height + PADDING}
								y={height / 2 - (LABEL_SIZE * data.length) / 2}
								// borderPadding={4}
								style={{
									labels: { fontSize: LABEL_SIZE },
									data: {
										fill: ({ datum: { name } }) =>
											name === 'NB'
												? '#38B10D'
												: name === 'LoRa'
												? '#6794EC'
												: 'grey',
									},
								}}
								data={data.map(({ x }) => ({
									name: x || 'UNKNOWN',
								}))}
							/>
						</VictoryContainer>
					) : (
						<ChartNoDataWrapper>
							<ChartNoDataText>NO DATA</ChartNoDataText>
						</ChartNoDataWrapper>
					)}
				</ChartBodyWrapper>
			</ChartWrapper>
		);
	}, [data, containerRef, width, height]);
}

export default SensorTypeChart;

import React, { useMemo, useContext, forwardRef, Ref } from 'react';
import { WindowFrameWrapper } from './WindowFrame.sc';
import { WindowFrameProps } from '../interfaces';
import windowContext from '../../Window/context';

export default forwardRef(function WindowFrame(
	props: WindowFrameProps,
	ref: Ref<HTMLDivElement>
) {
	const {
		children,
		cornerRadius,
		animation,
		zIndex = 1,
		onMouseDown,
		style,
	} = props;
	const { width, height, top, left } = useContext(windowContext);

	return useMemo(() => {
		return (
			<WindowFrameWrapper
				ref={ref}
				style={{
					borderRadius: cornerRadius,
					width,
					height,
					top,
					left,
					zIndex,
					...(animation ? { transition: 'all 200ms' } : {}),
					...style,
				}}
				onMouseDown={onMouseDown}
			>
				{children}
			</WindowFrameWrapper>
		);
	}, [
		ref,
		children,
		width,
		height,
		top,
		left,
		zIndex,
		style,
		cornerRadius,
		animation,
		onMouseDown,
	]);
});

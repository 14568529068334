import assign from 'lodash.assign';
import Color from 'color';

import theme from '../theme';

const baseColors = theme.background.accent;

const colors = Array.from({ length: 10 }).map((v, i) =>
	Color(baseColors)
		.lighten(i * 0.1)
		.alpha(0.9)
		.toString()
);

const darkColors = Array.from({ length: 10 }).map((v, i) =>
	Color(baseColors)
		.darken(i * 0.1)
		.alpha(0.6)
		.toString()
);
export const stackColors = [
	// theme.background.accent,
	// '#6DB351',
	'#50DA83',
	'#EF4430',
	'#A1C831',
	'#F3D559',
	'#F4813E',
].map((c) =>
	Color(c)
		.alpha(0.8)
		.toString()
);

const charcoal = '#252525';
const grey = '#969696';
const light = '#EEEEEE';
// *
// * Typography
// *
const sansSerif =
	"'Gill Sans', 'Gill Sans MT', 'Seravek', 'Trebuchet MS', sans-serif, Roboto";
const letterSpacing = 'normal';
const fontSize = 20;
// *
// * Layout
// *
const baseProps = {
	width: 600,
	height: 300,
	padding: { top: 50, left: 60, right: 20, bottom: 20 },
	colorScale: colors,
};
const darkBaseProps = {
	width: 700,
	height: 300,
	padding: { top: 50, left: 60, right: 35, bottom: 35 },
	colorScale: darkColors,
};
// *
// * Labels
// *
const baseLabelStyles = {
	fontFamily: sansSerif,
	fontSize,
	letterSpacing,
	padding: 8,
	fill: charcoal,
	stroke: 'transparent',
};

const darkBaseLabelStyles = {
	fontFamily: sansSerif,
	fontSize,
	letterSpacing,
	padding: 8,
	fill: light,
	stroke: 'transparent',
};

const centeredLabelStyles = assign({ textAnchor: 'middle' }, baseLabelStyles);
// *
// * Strokes
// *
const strokeLinecap = 'round';
const strokeLinejoin = 'round';

export const chartTheme = {
	area: assign(
		{
			style: {
				data: {
					fill: charcoal,
				},
				labels: baseLabelStyles,
			},
		},
		baseProps
	),
	axis: assign(
		{
			style: {
				axis: {
					fill: 'transparent',
					stroke: charcoal,
					strokeWidth: 1,
					strokeLinecap,
					strokeLinejoin,
				},
				axisLabel: assign({}, centeredLabelStyles, {
					padding: 10,
				}),
				grid: {
					fill: 'none',
					stroke: 'none',
					pointerEvents: 'painted',
				},
				ticks: {
					fill: 'transparent',
					size: 1,
					stroke: 'transparent',
				},
				tickLabels: baseLabelStyles,
			},
		},
		baseProps
	),
	bar: assign(
		{
			style: {
				data: {
					fill: charcoal,
					padding: 8,
					strokeWidth: 0,
				},
				labels: baseLabelStyles,
			},
		},
		baseProps
	),
	boxplot: assign(
		{
			style: {
				max: { padding: 8, stroke: charcoal, strokeWidth: 1 },
				maxLabels: baseLabelStyles,
				median: { padding: 8, stroke: charcoal, strokeWidth: 1 },
				medianLabels: baseLabelStyles,
				min: { padding: 8, stroke: charcoal, strokeWidth: 1 },
				minLabels: baseLabelStyles,
				q1: { padding: 8, fill: grey },
				q1Labels: baseLabelStyles,
				q3: { padding: 8, fill: grey },
				q3Labels: baseLabelStyles,
			},
			boxWidth: 20,
		},
		baseProps
	),
	candlestick: assign(
		{
			style: {
				data: {
					stroke: charcoal,
					strokeWidth: 1,
				},
				labels: baseLabelStyles,
			},
			candleColors: {
				positive: '#ffffff',
				negative: charcoal,
			},
		},
		baseProps
	),
	chart: baseProps,
	errorbar: assign(
		{
			borderWidth: 8,
			style: {
				data: {
					fill: 'transparent',
					stroke: charcoal,
					strokeWidth: 2,
				},
				labels: baseLabelStyles,
			},
		},
		baseProps
	),
	group: assign(
		{
			colorScale: colors,
		},
		baseProps
	),
	legend: {
		colorScale: colors,
		gutter: 10,
		orientation: 'vertical',
		titleOrientation: 'top',
		style: {
			data: {
				type: 'circle',
			},
			labels: baseLabelStyles,
			title: assign({}, baseLabelStyles, { padding: 5 }),
		},
	},
	line: assign(
		{
			style: {
				data: {
					fill: 'transparent',
					stroke: colors[0],
					strokeWidth: 1,
				},
				labels: baseLabelStyles,
			},
		},
		baseProps
	),
	pie: {
		style: {
			data: {
				padding: 10,
				stroke: 'transparent',
				strokeWidth: 1,
			},
			labels: assign({}, baseLabelStyles, { padding: 20 }),
		},
		colorScale: colors,
		width: 400,
		height: 400,
		padding: 50,
	},
	scatter: assign(
		{
			style: {
				data: {
					fill: colors[0],
					stroke: 'transparent',
					strokeWidth: 2,
				},
				labels: baseLabelStyles,
			},
		},
		baseProps
	),
	stack: assign(
		{
			colorScale: colors,
		},
		baseProps
	),
	tooltip: {
		style: assign({}, baseLabelStyles, {
			padding: 5,
			pointerEvents: 'none',
		}),
		flyoutStyle: {
			stroke: charcoal,
			strokeWidth: 1,
			fill: '#f0f0f0',
			pointerEvents: 'none',
		},
		cornerRadius: 5,
		pointerLength: 10,
	},
	voronoi: assign(
		{
			style: {
				data: {
					fill: 'transparent',
					stroke: 'transparent',
					strokeWidth: 0,
				},
				labels: assign({}, baseLabelStyles, {
					padding: 5,
					pointerEvents: 'none',
				}),
				flyout: {
					stroke: charcoal,
					strokeWidth: 1,
					fill: '#f0f0f0',
					pointerEvents: 'none',
				},
			},
		},
		baseProps
	),
} as any;

export const chartDarkTheme = {
	area: assign(
		{
			style: {
				data: {
					fill: light,
				},
				labels: darkBaseLabelStyles,
			},
		},
		darkBaseProps
	),
	axis: assign(
		{
			style: {
				axis: {
					fill: 'transparent',
					stroke: light,
					strokeWidth: 1,
					strokeLinecap,
					strokeLinejoin,
				},
				axisLabel: assign({}, centeredLabelStyles, {
					padding: 10,
				}),
				grid: {
					fill: 'none',
					stroke: 'none',
					pointerEvents: 'painted',
				},
				ticks: {
					fill: 'transparent',
					size: 1,
					stroke: light,
				},
				tickLabels: darkBaseLabelStyles,
			},
		},
		darkBaseProps
	),
	bar: assign(
		{
			style: {
				data: {
					fill: light,
					padding: 8,
					strokeWidth: 0,
				},
				labels: darkBaseLabelStyles,
			},
		},
		darkBaseProps
	),
	boxplot: assign(
		{
			style: {
				max: { padding: 8, stroke: light, strokeWidth: 1 },
				maxLabels: darkBaseLabelStyles,
				median: { padding: 8, stroke: light, strokeWidth: 1 },
				medianLabels: darkBaseLabelStyles,
				min: { padding: 8, stroke: light, strokeWidth: 1 },
				minLabels: darkBaseLabelStyles,
				q1: { padding: 8, fill: charcoal },
				q1Labels: darkBaseLabelStyles,
				q3: { padding: 8, fill: charcoal },
				q3Labels: darkBaseLabelStyles,
			},
			boxWidth: 20,
		},
		darkBaseProps
	),
	candlestick: assign(
		{
			style: {
				data: {
					stroke: light,
					strokeWidth: 1,
				},
				labels: darkBaseLabelStyles,
			},
			candleColors: {
				positive: '#ffffff',
				negative: light,
			},
		},
		darkBaseProps
	),
	chart: darkBaseProps,
	errorbar: assign(
		{
			borderWidth: 8,
			style: {
				data: {
					fill: 'transparent',
					stroke: light,
					strokeWidth: 2,
				},
				labels: darkBaseLabelStyles,
			},
		},
		darkBaseProps
	),
	group: assign(
		{
			colorScale: colors,
		},
		darkBaseProps
	),
	legend: {
		colorScale: ['tomato', 'cyan', 'orange', 'navy', 'gold', 'green'],
		gutter: 10,
		orientation: 'vertical',
		titleOrientation: 'top',
		style: {
			data: {
				type: 'circle',
			},
			labels: darkBaseLabelStyles,
			title: assign({}, darkBaseLabelStyles, { padding: 5 }),
		},
	},
	line: assign(
		{
			style: {
				data: {
					fill: 'transparent',
					stroke: darkColors[0],
					strokeWidth: 1,
				},
				labels: darkBaseLabelStyles,
			},
		},
		darkBaseProps
	),
	pie: {
		style: {
			data: {
				padding: 10,
				stroke: 'transparent',
				strokeWidth: 2,
			},
			labels: assign({}, darkBaseLabelStyles, { padding: 20 }),
		},
		colorScale: stackColors,
		width: 300,
		height: 300,
		padding: 50,
	},
	scatter: assign(
		{
			style: {
				data: {
					fill: darkColors[0],
					stroke: 'transparent',
					strokeWidth: 2,
				},
				labels: darkBaseLabelStyles,
			},
		},
		darkBaseProps
	),
	stack: assign(darkBaseProps, {
		colorScale: stackColors,
	}),
	tooltip: {
		style: assign({}, darkBaseLabelStyles, {
			padding: 5,
			pointerEvents: 'none',
		}),
		flyoutStyle: {
			stroke: light,
			strokeWidth: 1,
			fill: '#f0f0f0',
			pointerEvents: 'none',
		},
		cornerRadius: 5,
		pointerLength: 10,
	},
	voronoi: assign(
		{
			style: {
				data: {
					fill: 'transparent',
					stroke: 'transparent',
					strokeWidth: 0,
				},
				labels: assign({}, darkBaseLabelStyles, {
					padding: 5,
					pointerEvents: 'none',
				}),
				flyout: {
					stroke: light,
					strokeWidth: 1,
					fill: '#f0f0f0',
					pointerEvents: 'none',
				},
			},
		},
		darkBaseProps
	),
} as any;

import { useRef } from 'react';
import isEqualWith from 'lodash.isequalwith';

export default function useObject<O extends Object | undefined | null>(obj: O) {
	const objRef = useRef(obj);
	const prevObj = objRef.current;
	if (!isEqualWith(prevObj, obj)) {
		objRef.current = obj;
	}
	return objRef.current;
}

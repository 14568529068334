import NotificationPanel from './Components/NotificationPanel';
import notificationReducer, { parseNotificationState } from './Store/reducer';
import context from './context';

export default NotificationPanel;

const { Provider: NotificationProvider } = context;

export {
	NotificationPanel,
	notificationReducer,
	NotificationProvider,
	parseNotificationState,
};

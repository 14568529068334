import { useCallback, MutableRefObject } from 'react';
import { dropColumns } from '../../../Store/actions';
import orderSelected from '../Helpers/orderSelected';
import { ITableContext } from '../../../context';

export default function useSelectedColumnsDrop(
	contextObjRef: MutableRefObject<ITableContext>
) {
	return useCallback(() => {
		const {
			columnKeys,
			selected,
			setSelected,
			onRequestDispatch,
		} = contextObjRef.current;
		if (!selected) return;
		const [tl, br] = orderSelected(selected);
		const removeKeys = columnKeys.slice(tl[1], br[1] + 1).toArray();
		onRequestDispatch(dropColumns({ columnKeys: removeKeys }));
		setSelected(null);
	}, [contextObjRef]);
}

import React, { useMemo, useCallback, useContext } from 'react';

import useLauncher from '../Hooks/useLauncher';
import { LauncherProps } from '../interfaces';
import DockItem from './DockItem';
import workspaceContext from '../../Workspace/context';

export default function Launcher(props: LauncherProps) {
	const { application, expanded } = props;
	const { icon, name } = application;

	const { toggleHomePage } = useContext(workspaceContext);

	const {
		handleStartApplication,
		activities,
		handleSwitchActivity,
	} = useLauncher(props);

	const handleLauncherPress = useCallback(
		(e?: React.MouseEvent) => {
			activities.size > 0 && (!e || !e.ctrlKey)
				? handleSwitchActivity()
				: handleStartApplication();
			toggleHomePage(false);
		},
		[
			handleSwitchActivity,
			handleStartApplication,
			activities.size,
			toggleHomePage,
		]
	);

	return useMemo(() => {
		return (
			<DockItem
				count={activities.size}
				expanded={expanded}
				icon={icon}
				name={name}
				onPress={handleLauncherPress}
			/>
		);
	}, [icon, name, activities.size, expanded, handleLauncherPress]);
}

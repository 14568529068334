import React, { useMemo, useState, useCallback } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { View } from '../App/Components/Common/View';
import theme from '../theme';
import StandardWindow from '../App/Components/Layouts/Components/StandardWindow';
import { Desktop } from '../App/Components/Layouts/Components/Desktop';
import { Map, List } from 'immutable';
import { DesktopWindowState } from '../App/Components/Layouts/Components/Desktop/interfaces';
import { MaximizeType } from '../App/Components/Layouts/interfaces';
import useDesktopWindow from '../App/Components/Layouts/Components/Desktop/Hooks/useDesktopWindow';

const WindowDemoWrapper = styled(View)`
	flex: 1;
	overflow: hidden;
`;

function TestWindow(props: { windowId: string; title: string }) {
	const { windowId, title } = props;
	const {
		left,
		top,
		width,
		height,
		zIndex,
		originalLeft,
		originalTop,
		originalWidth,
		originalHeight,
		maximized,
		parentWidth,
		parentHeight,
		onChange,
		onMoveStart,
		onFocus,
	} = useDesktopWindow(windowId);

	const handleMaxizimized = useCallback(() => {
		onChange({
			originalLeft: originalLeft || left,
			originalTop: originalTop || top,
			originalWidth: originalWidth || width,
			originalHeight: originalHeight || height,
			left: 0,
			top: 0,
			width: parentWidth,
			height: parentHeight,
			maximized: MaximizeType.FULL,
		});
	}, [
		onChange,
		left,
		top,
		width,
		height,
		parentWidth,
		parentHeight,
		originalLeft,
		originalTop,
		originalWidth,
		originalHeight,
	]);

	const handleRestore = useCallback(() => {
		onChange({
			left: originalLeft || 0,
			top: originalTop || 0,
			width: originalWidth || 0,
			height: originalHeight || 0,
			maximized: MaximizeType.REGULAR,
		});
	}, [onChange, originalLeft, originalTop, originalWidth, originalHeight]);

	return useMemo(() => {
		return (
			<StandardWindow
				title={title}
				left={left}
				top={top}
				width={width}
				height={height}
				zIndex={zIndex}
				parentWidth={parentWidth}
				parentHeight={parentHeight}
				minWidth={200}
				minHeight={200}
				maximized={maximized}
				onChange={onChange}
				onMoveStart={onMoveStart}
				onRequestMaximized={handleMaxizimized}
				onRequestRestore={handleRestore}
				onRequestFocus={onFocus}
			>
				test
			</StandardWindow>
		);
	}, [
		title,
		left,
		top,
		width,
		height,
		zIndex,
		maximized,
		parentWidth,
		parentHeight,
		onFocus,
		onChange,
		onMoveStart,
		handleMaxizimized,
		handleRestore,
	]);
}

export default function WindowDemo() {
	const [windows, setWindows] = useState<Map<string, DesktopWindowState>>(
		Map({
			test: {
				left: 50,
				top: 50,
				width: 800,
				height: 600,
				originalLeft: 50,
				originalTop: 50,
				originalWidth: 800,
				originalHeight: 600,
				maximized: MaximizeType.REGULAR,
				minimized: false,
			},
			test2: {
				left: 100,
				top: 100,
				width: 800,
				height: 600,
				originalLeft: 50,
				originalTop: 50,
				originalWidth: 800,
				originalHeight: 600,
				maximized: MaximizeType.REGULAR,
				minimized: false,
			},
		})
	);
	const [windowIndice, setWindowIndice] = useState<List<string>>(
		List(['test', 'test2'])
	);

	const handleWindowChange = useCallback(
		(windowId: string, windowState: Partial<DesktopWindowState>) => {
			setWindows((windows) => windows.mergeIn([windowId], windowState));
		},
		[setWindows]
	);

	const handleWindowFocus = useCallback(
		(focusingWindowId: string) => {
			setWindowIndice((windowIndice) =>
				windowIndice
					.filter((windowId) => windowId !== focusingWindowId)
					.push(focusingWindowId)
			);
		},
		[setWindowIndice]
	);

	return useMemo(() => {
		return (
			<ThemeProvider theme={theme}>
				<WindowDemoWrapper>
					<Desktop
						width={1200}
						height={800}
						windows={windows}
						windowIndice={windowIndice}
						onWindowChange={handleWindowChange}
						onWindowFocus={handleWindowFocus}
					>
						<TestWindow windowId="test" title="Test Window (1)" />
						<TestWindow windowId="test2" title="Test Window (2)" />
					</Desktop>
				</WindowDemoWrapper>
			</ThemeProvider>
		);
	}, [windows, windowIndice, handleWindowChange, handleWindowFocus]);
}

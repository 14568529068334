import { WindowProps } from '../Window/interfaces';
import {
	StandardResizeHandleProps,
	StandardMoveHandleProps,
	MaximizeType,
} from '../../interfaces';
import { ReactNode } from 'react';
import { CSSProperties } from 'styled-components';

export enum IconPosition {
	LEFT,
	RIGHT,
}

interface WindowState {
	left: number;
	top: number;
	width: number;
	height: number;
}
export interface StandardWindowProps extends WindowProps {
	maximized: MaximizeType;
	title: string;
	leftHeader?: ReactNode;
	rightHeader?: ReactNode;
	style?: CSSProperties;
	resizable?: boolean;
	zIndex?: number;
	onChange?: (windowState: WindowState) => any;
	onRequestFocus?: () => any;
	onRequestMaximized?: () => any;
	onRequestRestore?: () => any;
	onRequestMinimized?: () => any;
	onRequestClose?: () => any;
}

export interface WindowHeaderProps extends StandardMoveHandleProps {
	moving?: boolean;
	children: ReactNode;
	onRequestToggleMaximize?: (e: React.MouseEvent) => any;
}

export interface WindowEdgeProps extends StandardResizeHandleProps {}
export interface WindowFrameProps {
	animation: boolean;
	zIndex?: number;
	cornerRadius?: number;
	style?: CSSProperties;
	children: ReactNode;
	onMouseDown?: (e: React.MouseEvent) => any;
}

export interface StandardWindowHeaderProps extends StandardMoveHandleProps {
	title: string;
	moving?: boolean;
	maximized: MaximizeType;
	leftHeader?: ReactNode;
	rightHeader?: ReactNode;
	onChange?: (windowState: WindowState) => any;
	onRequestMaximized?: () => any;
	onRequestRestore?: () => any;
	onRequestMinimized?: () => any;
	onRequestClose?: () => any;
}

export interface StandardWindowEdgesProps {
	maximized: MaximizeType;
	threshold?: number;
	timeout?: number;
	modifyKey?: 'ctrlKey' | 'altKey' | 'shiftKey' | null;
}

export interface WindowHeaderButtonProps {
	text?: string;
	icon?: string;
	iconPosition?: IconPosition;
	tooltip?: string;
	bordered?: boolean;
	tabIndex?: number;
	onPress?: () => any;
}

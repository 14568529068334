import { StandardWindowEdgesProps } from '../interfaces';
import { ResizeDirection, MaximizeType } from '../../../interfaces';
import React, { useMemo } from 'react';
import WindowEdge from './WindowEdge';

const regularDirections: ResizeDirection[] = [
	ResizeDirection.TOP,
	ResizeDirection.BOTTOM,
	ResizeDirection.LEFT,
	ResizeDirection.RIGHT,
	ResizeDirection.TOP_LEFT,
	ResizeDirection.TOP_RIGHT,
	ResizeDirection.BOTTOM_LEFT,
	ResizeDirection.BOTTOM_RIGHT,
];

const leftDirections: ResizeDirection[] = [ResizeDirection.RIGHT];

const rightDirections: ResizeDirection[] = [ResizeDirection.LEFT];

export default function StandardWindowEdges(props: StandardWindowEdgesProps) {
	const { maximized, modifyKey, threshold, timeout } = props;

	return useMemo(() => {
		const directions =
			maximized === MaximizeType.REGULAR
				? regularDirections
				: maximized === MaximizeType.LEFT
				? leftDirections
				: maximized === MaximizeType.RIGHT
				? rightDirections
				: [];

		return (
			<>
				{directions.map((direction) => (
					<WindowEdge
						key={direction}
						modifyKey={modifyKey}
						threshold={threshold}
						timeout={timeout}
						direction={direction}
					/>
				))}
			</>
		);
	}, [maximized, modifyKey, threshold, timeout]);
}

import styled from 'styled-components';
import { View } from '../../../../Common/View';
import IconTextButton from '../../../../Common/IconTextButton';
import { ButtonOverlayShape } from '../../../../Common/Button/interfaces';
import constants from '../../../constants';

const { HEADER_HEIGHT } = constants;

export const HeaderCellFilterWrapper = styled(View)`
	flex-direction: row;
	align-items: stretch;
	height: ${HEADER_HEIGHT / 2}px;
	padding: 0px 4px;
	background-color: ${({
		theme: {
			background: { accent },
		},
	}) => accent};
`;

export const HeaderCellFilterButton = styled(IconTextButton).attrs(
	({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => ({
		lightBg: false,
		overlayShape: ButtonOverlayShape.circle,
		iconProps: {
			color: darkBgPrimary,
		},
	})
)`
	justify-content: center;
	height: ${HEADER_HEIGHT / 2}px;
	width: ${HEADER_HEIGHT / 2}px;
	padding: 0;
	margin-left: 2px;
`;

import React, { useMemo } from 'react';
import Autocomplete, {
	AutocompleteMenu,
	Suggestion,
} from '../../../../Common/Autocomplete';
import {
	QueryInput,
	QuerySelectorWrapper,
	DistrictText,
	QueryInputsWrapper,
} from './QuerySelector.sc';
import noop from '../../../../../Helpers/noop';
import { PopoverProps } from '../../../../Common/Popover/interfaces';

interface Props {
	district: string | null;
	setDepartment: React.Dispatch<React.SetStateAction<Suggestion>>;
	department: Suggestion;
	departments: Suggestion[];
	transmissionType: Suggestion;
	transmissionTypes: Suggestion[];
	setTransmissionType: React.Dispatch<React.SetStateAction<Suggestion>>;
}

function getAutocompleteMenuStyle({ height = 0 }: PopoverProps) {
	return {
		width: 'auto',
		transform: `translateY(${height + 2}px)`,
	};
}

export default function QuerySelector(props: Props) {
	const {
		district,
		department,
		departments,
		setDepartment,
		transmissionType,
		transmissionTypes,
		setTransmissionType,
	} = props;
	return useMemo(() => {
		return (
			<QuerySelectorWrapper>
				<DistrictText>{district ? district : 'OVERVIEW'}</DistrictText>
				<QueryInputsWrapper>
					<Autocomplete
						suggestions={departments}
						onSelect={setDepartment}
					>
						{({ ref, openMenu, closeMenu, menuProps }) => (
							<>
								<QueryInput
									ref={ref}
									value={department.name}
									onFocus={openMenu}
									onBlur={closeMenu}
									onChange={noop}
									placeholder="Department"
								/>
								<AutocompleteMenu
									{...menuProps}
									style={getAutocompleteMenuStyle(menuProps)}
								/>
							</>
						)}
					</Autocomplete>
					<Autocomplete
						suggestions={transmissionTypes}
						onSelect={setTransmissionType}
					>
						{({ ref, openMenu, closeMenu, menuProps }) => (
							<>
								<QueryInput
									ref={ref}
									value={transmissionType.name}
									onFocus={openMenu}
									onBlur={closeMenu}
									onChange={noop}
									placeholder="Sensor Type"
								/>
								<AutocompleteMenu
									{...menuProps}
									style={getAutocompleteMenuStyle(menuProps)}
								/>
							</>
						)}
					</Autocomplete>
				</QueryInputsWrapper>
			</QuerySelectorWrapper>
		);
	}, [
		district,
		setDepartment,
		department,
		departments,
		setTransmissionType,
		transmissionType,
		transmissionTypes,
	]);
}

import styled from 'styled-components';
import { View } from '../../../../Common/View';
import { HEADER_HEIGHT } from '../../Window/constant';

export interface WindowHeaderWrapperProps {
	moving: boolean;
}

export const WindowHeaderWrapper = styled(View)<WindowHeaderWrapperProps>`
	background-color: ${({
		theme: {
			background: { frame },
		},
	}) => frame};

	user-select: none;
	flex: 0 0 ${HEADER_HEIGHT}px;
	align-items: stretch;
	flex-direction: row;

	cursor: ${({ moving }) => (moving ? 'grabbing' : 'grab')};
`;

import createActionCreator from '../../../../../Helpers/createActionCreator';
import { DesktopWindowState } from '../../../../Layouts/Components/Desktop/interfaces';
import { ActiveSensorChartData } from '../../Home/Components/ActiveSensorChart';
import { SensorTypeChartData } from '../../Home/Components/SensorTypeChart';
import { SignalIntervalChartData } from '../../Home/Components/SignalIntervalChart';
import { BatteryLevelChartData } from '../../Home/Components/BatteryLevelChart';
import { InactiveSensorListData } from '../../Home/Components/InactiveSensorList';
import { ScenarioChangeRecord } from '../../Home/Components/ScenarioChangeLog';
import { TiltingAngleChartData } from '../../Home/Components/TiltingAngleChart';

interface FocusActivityPayload {
	activityId: string;
}

interface UpdateTitlePayload {
	activityId: string;
	title: string;
}

export interface StartApplicationPayload {
	id: string;
	application: {
		key: string;
		name: string;
	};
	parameters?: any;
	windowProps?: any;
}

interface CloseActivityPayload {
	activityId: string;
}

interface ChangeWindowStatePayload {
	activityId: string;
	windowState: Partial<DesktopWindowState>;
}

export interface FetchOverviewPayload {
	deptId: string | null;
	transmissionType: string | null;
	district: string | null;
}

export interface importOverviewPayload {
	activeSensorData: ActiveSensorChartData;
	sensorTypeData: SensorTypeChartData;
	tiltingAngleData: TiltingAngleChartData;
	signalIntervalData: SignalIntervalChartData;
	batteryLevelData: BatteryLevelChartData;
	inactiveSensorData: InactiveSensorListData;
	scenarioChangeRecords: ScenarioChangeRecord[];
}

export const focusActivity = createActionCreator(
	'WORKSPACE/FOCUS_ACTIVITY',
	{} as FocusActivityPayload
);

export const updateTitle = createActionCreator(
	'WORKSPACE/UPDATE_TITLE',
	{} as UpdateTitlePayload
);

export const startApplication = createActionCreator(
	'WORKSPACE/START_APPLICATION',
	{} as StartApplicationPayload
);

export const closeActivity = createActionCreator(
	'WORKSPACE/CLOSE_ACTIVITY',
	{} as CloseActivityPayload
);

export const changeWindowState = createActionCreator(
	'WORKSPACE/CHANGE_WINDOW_STATE',
	{} as ChangeWindowStatePayload
);

export const fetchOverview = createActionCreator(
	'WORKSPACE/FETCH_OVERVIEW',
	{} as FetchOverviewPayload
);

export const importOverview = createActionCreator(
	'WORKSPACE/IMPORT_OVERVIEW',
	{} as importOverviewPayload
);

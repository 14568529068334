import { useState, useEffect } from 'react';
import { StandardWindowProps } from '../interfaces';
import { StandardWindowState } from '../../../interfaces';

export default function useWindowState(props: StandardWindowProps) {
	const { left, top, width, height } = props;

	const state = useState<StandardWindowState>({
		moving: false,
		resizing: false,
		x1: left,
		x2: left + width,
		y1: top,
		y2: top + height,
		left: left,
		top: top,
		width: width,
		height: height,
	});

	const [, setWindowState] = state;

	useEffect(() => {
		setWindowState((s) => ({
			...s,
			x1: left,
			x2: left + width,
			y1: top,
			y2: top + height,
			left: left,
			top: top,
			width: width,
			height: height,
		}));
	}, [left, top, width, height, setWindowState]);

	return state;
}

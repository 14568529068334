import React, {
	useMemo,
	useContext,
	useCallback,
	forwardRef,
	Ref,
	RefObject,
	ChangeEvent as RChangeEvent,
	KeyboardEvent as RKeyboardEvent,
} from 'react';
import isEqual from 'lodash.isequal';
import { RecordOf } from 'immutable';
import {
	BodyCellInputWrapper,
	BodyCellBooleanCheckbox,
	BodyCellBaseInput,
	BodyCellDatePicker,
} from './BodyCellInput.sc';
import { useColumn } from '../../../Hooks/Column';
import useAsState from '../../../../../Hooks/useAsState';
import useObject from '../../../../../Hooks/useObject';
import { useImmutable } from '../../../../../Hooks/useImmutable';
import {
	stringifyRecord,
	formatRecord,
	parseRecord,
	getRecord,
	getUnmergedRecord,
} from '../../../Helpers/Record';
import stopPropagation from '../../../../../Helpers/stopPropagation';
import { MergeStatus, DataType, IFormat } from '../../../interfaces';
import context from '../../../context';

interface BaseInputProps {
	record: any;
	displayRecord: any;
	focused: boolean;
	disabled: boolean;
	dataType: DataType;
	format: RecordOf<IFormat>;
	updateUnmergedRecord(value: any): void;
	dropUnmergedRecord(): void;
}

const BaseInput = forwardRef(function(
	props: BaseInputProps,
	ref: Ref<HTMLInputElement>
) {
	const {
		record,
		displayRecord,
		disabled,
		focused,
		dataType,
		format,
		updateUnmergedRecord,
		dropUnmergedRecord,
	} = props;
	const displayStr = stringifyRecord(displayRecord);
	const [_displayStr, setDisplayStr] = useAsState(displayStr);

	const handleKeyDown = useCallback(
		(e: RKeyboardEvent<HTMLInputElement>) => {
			if (
				!e.ctrlKey &&
				dataType.type === 'number' &&
				/^[a-z]$/i.test(e.key)
			)
				e.preventDefault();
		},
		[dataType]
	);

	const handleChange = useCallback(
		(e: RChangeEvent<HTMLInputElement>) => {
			setDisplayStr(e.target.value);
		},
		[setDisplayStr]
	);

	const handleBlur = useCallback(() => {
		try {
			const parsed = parseRecord(_displayStr, dataType);
			if (!isEqual(parsed, record)) updateUnmergedRecord(parsed);
			else dropUnmergedRecord();
		} catch (e) {
			// console.log(e.details);
			setDisplayStr(displayStr);
		}
	}, [
		_displayStr,
		dataType,
		record,
		updateUnmergedRecord,
		dropUnmergedRecord,
		setDisplayStr,
		displayStr,
	]);

	return useMemo(() => {
		let __displayStr = _displayStr;
		try {
			if (!focused)
				__displayStr = formatRecord(displayStr, dataType, format);
		} catch (e) {
			// console.log(e.details);
			__displayStr = '';
		}

		const tabIndex = focused ? 0 : -1;
		return (
			<BodyCellBaseInput
				ref={ref}
				blurOnEnterPressed={false}
				tabIndex={tabIndex}
				disabled={disabled}
				value={__displayStr}
				onKeyDown={handleKeyDown}
				onChange={handleChange}
				onBlur={handleBlur}
				onMouseDown={stopPropagation}
			/>
		);
	}, [
		ref,
		focused,
		_displayStr,
		displayStr,
		dataType,
		format,
		disabled,
		handleKeyDown,
		handleChange,
		handleBlur,
	]);
});

interface BooleanCheckboxProps {
	record?: boolean | null;
	displayRecord?: boolean | null;
	focused: boolean;
	disabled: boolean;
	updateUnmergedRecord(value?: boolean | null): void;
	dropUnmergedRecord(): void;
}

const BooleanCheckbox = forwardRef(function(
	props: BooleanCheckboxProps,
	ref: Ref<HTMLDivElement>
) {
	const {
		record,
		displayRecord,
		focused,
		disabled,
		updateUnmergedRecord,
		dropUnmergedRecord,
	} = props;

	const handlePress = useCallback(() => {
		const newRecord = !displayRecord;
		if (newRecord !== record) updateUnmergedRecord(newRecord);
		else dropUnmergedRecord();
	}, [displayRecord, record, updateUnmergedRecord, dropUnmergedRecord]);

	return useMemo(() => {
		if (!focused && displayRecord === undefined) return null;
		const tabIndex = focused ? 0 : -1;
		return (
			<BodyCellBooleanCheckbox
				ref={ref}
				tabIndex={tabIndex}
				disabled={disabled}
				checked={displayRecord}
				onMouseDown={stopPropagation}
				onPress={handlePress}
			/>
		);
	}, [ref, focused, disabled, displayRecord, handlePress]);
});

interface DatePickerProps {
	dateFormat: string;
	record?: string | null;
	displayRecord?: string | null;
	focused: boolean;
	disabled: boolean;
	updateUnmergedRecord(value?: string | null): void;
	dropUnmergedRecord(): void;
}

const DatePicker = forwardRef(function(
	props: DatePickerProps,
	ref: Ref<HTMLDivElement>
) {
	const {
		dateFormat,
		record,
		displayRecord,
		focused,
		disabled,
		updateUnmergedRecord,
		dropUnmergedRecord,
	} = props;

	const handleChange = useCallback(
		(date: Date | null) => {
			const newRecord = date ? date.toISOString() : null;
			if (newRecord !== record) updateUnmergedRecord(newRecord);
			else dropUnmergedRecord();
		},
		[record, updateUnmergedRecord, dropUnmergedRecord]
	);

	const datePickerPanelProps = useMemo(() => {
		return {
			shouldCloseOnSelect: false,
			dateFormat,
			onChange: handleChange,
		};
	}, [dateFormat, handleChange]);

	return useMemo(() => {
		if (!focused && displayRecord === undefined) return null;
		const tabIndex = focused ? 0 : -1;
		return (
			<BodyCellDatePicker
				ref={ref}
				tabIndex={tabIndex}
				date={displayRecord}
				disabled={disabled}
				panelProps={datePickerPanelProps}
				onMouseDown={stopPropagation}
			/>
		);
	}, [ref, focused, disabled, displayRecord, datePickerPanelProps]);
});

interface BodyCellInputProps {
	buttonRef: RefObject<HTMLDivElement>;
	recordId: string;
	columnKey: string;
	editingBy: string | null;
	mergeStatus: MergeStatus;
	focused: boolean;
	updateUnmergedRecord(value: any): void;
	dropUnmergedRecord(): void;
}

const BodyCellInput = forwardRef(function(
	props: BodyCellInputProps,
	ref: Ref<HTMLInputElement>
) {
	const {
		buttonRef,
		recordId,
		columnKey,
		focused,
		editingBy,
		mergeStatus,
		updateUnmergedRecord,
		dropUnmergedRecord,
	} = props;
	const contextObj = useContext(context);
	const { user, editable: tableEditable } = contextObj;
	const column = useColumn(contextObj, columnKey);
	const { editable, readonly, dataType, format } = column;
	const _dataType = useObject(dataType);
	const _format = useImmutable(format);

	const record = getRecord(contextObj, recordId, columnKey);
	const unmergedRecord = getUnmergedRecord(contextObj, recordId, columnKey);
	const displayRecord =
		mergeStatus === MergeStatus.none ? record : unmergedRecord;

	const editingByOther = !!editingBy && editingBy !== user;
	const disabled = readonly || !editable || editingByOther || !tableEditable;

	return useMemo(() => {
		// console.log('render BodyCellInput');
		const { dateFormat } = _format;
		return (
			<BodyCellInputWrapper>
				{_dataType.type === 'boolean' ? (
					<BooleanCheckbox
						ref={buttonRef}
						record={record}
						displayRecord={displayRecord}
						focused={focused}
						disabled={disabled}
						updateUnmergedRecord={updateUnmergedRecord}
						dropUnmergedRecord={dropUnmergedRecord}
					/>
				) : _dataType.type === 'date' ? (
					<DatePicker
						ref={buttonRef}
						dateFormat={dateFormat}
						record={record}
						displayRecord={displayRecord}
						focused={focused}
						disabled={disabled}
						updateUnmergedRecord={updateUnmergedRecord}
						dropUnmergedRecord={dropUnmergedRecord}
					/>
				) : null}
				<BaseInput
					ref={ref}
					dataType={_dataType}
					format={_format}
					record={record}
					displayRecord={displayRecord}
					focused={focused}
					disabled={disabled}
					updateUnmergedRecord={updateUnmergedRecord}
					dropUnmergedRecord={dropUnmergedRecord}
				/>
			</BodyCellInputWrapper>
		);
	}, [
		ref,
		buttonRef,
		_dataType,
		_format,
		record,
		displayRecord,
		focused,
		disabled,
		updateUnmergedRecord,
		dropUnmergedRecord,
	]);
});

export default BodyCellInput;

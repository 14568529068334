import React, {
	useCallback,
	forwardRef,
	Ref,
	FocusEvent as RFocusEvent,
	MouseEvent as RMouseEvent,
	KeyboardEvent as RKeyboardEvent,
} from 'react';
import { ToggleableInput as SToggleableInput } from './ToggleableInput.sc';
import useForwardedRef from '../../../../Hooks/useForwardedRef';
import useEdit from '../../../../Hooks/useEdit';
import { ToogleableInputProps } from '../interfaces';

const ToggleableInput = forwardRef(function(
	props: ToogleableInputProps,
	ref: Ref<HTMLInputElement>
) {
	const {
		toggleable = true,
		selectOnEditStart = true,
		onFocus,
		onBlur,
		onMouseDown,
		onKeyDown,
		...restProps
	} = props;
	const _ref = useForwardedRef(ref);
	const { editing, startEdit, endEdit } = useEdit({
		disabled: !toggleable,
		selectOnEditStart,
		targetRef: _ref,
	});

	const handleFocus = useCallback(
		(e: RFocusEvent<HTMLInputElement>) => {
			if (editing) onFocus && onFocus(e);
			else startEdit();
		},
		[editing, onFocus, startEdit]
	);

	const handleBlur = useCallback(
		(e: RFocusEvent<HTMLInputElement>) => {
			onBlur && onBlur(e);
			endEdit();
		},
		[onBlur, endEdit]
	);

	const handleKeyDown = useCallback(
		(e: RKeyboardEvent<HTMLInputElement>) => {
			if (editing) onKeyDown && onKeyDown(e);
			else e.preventDefault();
		},
		[editing, onKeyDown]
	);

	const handleMouseDown = useCallback(
		(e: RMouseEvent<HTMLInputElement>) => {
			if (editing) onMouseDown && onMouseDown(e);
			else e.preventDefault();
		},
		[editing, onMouseDown]
	);

	return (
		<SToggleableInput
			ref={_ref}
			editing={editing}
			onFocus={handleFocus}
			onBlur={handleBlur}
			onMouseDown={handleMouseDown}
			onKeyDown={handleKeyDown}
			onDoubleClick={startEdit}
			{...restProps}
		/>
	);
});

export default ToggleableInput;

import React, {
	useMemo,
	useCallback,
	useRef,
	CSSProperties,
	RefObject,
	FocusEvent as RFocusEvent,
} from 'react';
import { BodyCellWrapper, BodyCellEditorText } from './BodyCell.sc';
import BodyCellStatusIndicator from './BodyCellStatusIndicator';
import BodyCellInput from './BodyCellInput';
import {
	useUnmergedRecordUpdate,
	useUnmergedRecordDrop,
} from '../../../Hooks/Record';
import {
	useCell,
	useCellEditingByUpdate,
	useCellEditingByDrop,
} from '../../../Hooks/Cell';
import useCellSelectByMouse from '../Hooks/useCellSelectByMouse';
import useFocus from '../../../../../Hooks/useFocus';
import useContextWithRef from '../../../../../Hooks/useContextWithRef';
import useHotKeys from '../../../../../Hooks/useHotKeys';
import { getRecordId } from '../../../Helpers/Record';
import { getColumnKey } from '../../../Helpers/Column';
import focusTargetWithRef from '../../../../../Helpers/focusTargetWithRef';
import context, { ITableContext } from '../../../context';

function isCellSelected(
	contextObj: ITableContext,
	rowIndex: number,
	columnIndex: number
) {
	const { selected } = contextObj;
	if (!selected) return false;
	const {
		start: [startRowIndex, startColumnIndex],
	} = selected;
	return startRowIndex === rowIndex && startColumnIndex === columnIndex;
}

interface useBodyCellHotKeysOptions {
	contextObj: ITableContext;
	rowIndex: number;
	columnIndex: number;
	inputRef: RefObject<HTMLInputElement>;
}
function useBodyCellHotKeys(options: useBodyCellHotKeysOptions) {
	const { contextObj, columnIndex, rowIndex, inputRef } = options;
	const { selectionBoxRef } = contextObj;
	const cellSelected = isCellSelected(contextObj, rowIndex, columnIndex);
	const disabled = !cellSelected;
	const handleEnter = useCallback(() => {
		const activeElement = document.activeElement;
		if (activeElement === selectionBoxRef.current)
			focusTargetWithRef(inputRef);
		else if (activeElement === inputRef.current) {
			focusTargetWithRef(selectionBoxRef);
		}
	}, [inputRef, selectionBoxRef]);
	const keyBindMap = useMemo(() => ({ enter: handleEnter }), [handleEnter]);
	useHotKeys(keyBindMap, { disabled });
}

interface BodyCellProps {
	style: CSSProperties;
	rowIndex: number;
	columnIndex: number;
}
export default function BodyCell(props: BodyCellProps) {
	const {
		style: { left, top, width, height },
		rowIndex,
		columnIndex,
	} = props;
	const inputRef = useRef<HTMLInputElement>(null);
	const inputButtonRef = useRef<HTMLDivElement>(null);
	const { contextObj, contextObjRef } = useContextWithRef(context);

	const { handleMouseDown, handleMouseEnter } = useCellSelectByMouse(
		contextObj,
		contextObjRef,
		rowIndex,
		columnIndex
	);
	const { focused, handleFocus, handleBlur } = useFocus();
	const recordId = getRecordId(contextObj, rowIndex);
	const columnKey = getColumnKey(contextObj, columnIndex);
	const { mergeStatus, editingBy } = useCell(contextObj, recordId, columnKey);

	const updateCellEditingBy = useCellEditingByUpdate(
		contextObj,
		recordId,
		columnKey
	);
	const dropCellEditingBy = useCellEditingByDrop(
		contextObj,
		recordId,
		columnKey
	);
	const updateUnmergedRecord = useUnmergedRecordUpdate(
		contextObj,
		recordId,
		columnKey
	);
	const dropUnmergedRecord = useUnmergedRecordDrop(
		contextObj,
		recordId,
		columnKey
	);

	const _handleFocus = useCallback(() => {
		handleFocus();
		updateCellEditingBy();
	}, [handleFocus, updateCellEditingBy]);

	const _handleBlur = useCallback(
		(e: RFocusEvent<HTMLDivElement>) => {
			const inputButton = inputButtonRef.current;
			if (!inputButton || e.relatedTarget !== inputButton) {
				handleBlur();
				dropCellEditingBy();
			}
		},
		[handleBlur, dropCellEditingBy]
	);

	// HotKeys
	useBodyCellHotKeys({
		contextObj,
		rowIndex,
		columnIndex,
		inputRef,
	});

	return useMemo(() => {
		// console.log('render BodyCell');
		return (
			<BodyCellWrapper
				style={{ left, top, width, height }}
				focused={focused}
				mergeStatus={mergeStatus}
				onMouseDown={handleMouseDown}
				onMouseEnter={handleMouseEnter}
				onFocus={_handleFocus}
				onBlur={_handleBlur}
			>
				{recordId && columnKey ? (
					<>
						<BodyCellInput
							ref={inputRef}
							buttonRef={inputButtonRef}
							columnKey={columnKey}
							recordId={recordId}
							focused={focused}
							mergeStatus={mergeStatus}
							editingBy={editingBy}
							updateUnmergedRecord={updateUnmergedRecord}
							dropUnmergedRecord={dropUnmergedRecord}
						/>
						{focused ? null : (
							<BodyCellStatusIndicator
								mergeStatus={mergeStatus}
								dropUnmergedRecord={dropUnmergedRecord}
							/>
						)}
						<BodyCellEditorText editingBy={editingBy}>
							{editingBy}
						</BodyCellEditorText>
					</>
				) : null}
			</BodyCellWrapper>
		);
	}, [
		left,
		top,
		width,
		height,
		columnKey,
		recordId,
		mergeStatus,
		editingBy,
		focused,
		_handleFocus,
		_handleBlur,
		handleMouseDown,
		handleMouseEnter,
		updateUnmergedRecord,
		dropUnmergedRecord,
	]);
}

import { useCallback, RefObject, MutableRefObject, useMemo } from 'react';
import { ITableContext } from '../../../context';

function scrollSelectionBoxIntoView(
	selectionBoxRef: RefObject<HTMLDivElement>
) {
	const selectionBox = selectionBoxRef.current;
	if (selectionBox)
		selectionBox.scrollIntoView({
			behavior: 'smooth',
			block: 'nearest',
		});
}
export default function useCellSelectKeyBindMap(
	contextObjRef: MutableRefObject<ITableContext>
) {
	const up = useCallback(() => {
		const {
			selected,
			setSelected,
			selectionBoxRef,
		} = contextObjRef.current;
		if (!selected) return;
		const [rIndex, cIndex] = selected.start;
		const indices: [number, number] = [Math.max(rIndex - 1, 0), cIndex];
		setSelected({ start: indices, end: indices });
		scrollSelectionBoxIntoView(selectionBoxRef);
	}, [contextObjRef]);

	const down = useCallback(() => {
		const {
			selected,
			pageSize,
			setSelected,
			selectionBoxRef,
		} = contextObjRef.current;
		if (!selected) return;
		const [rIndex, cIndex] = selected.start;
		const indices: [number, number] = [
			Math.min(rIndex + 1, pageSize - 1),
			cIndex,
		];
		setSelected({ start: indices, end: indices });
		scrollSelectionBoxIntoView(selectionBoxRef);
	}, [contextObjRef]);

	const left = useCallback(() => {
		const {
			selected,
			setSelected,
			selectionBoxRef,
		} = contextObjRef.current;
		if (!selected) return;
		const [rIndex, cIndex] = selected.start;
		const indices: [number, number] = [rIndex, Math.max(cIndex - 1, 0)];
		setSelected({ start: indices, end: indices });
		scrollSelectionBoxIntoView(selectionBoxRef);
	}, [contextObjRef]);

	const right = useCallback(() => {
		const {
			selected,
			columns,
			setSelected,
			selectionBoxRef,
		} = contextObjRef.current;
		if (!selected) return;
		const columnCount = columns.size;
		const [rIndex, cIndex] = selected.start;
		const indices: [number, number] = [
			rIndex,
			Math.min(cIndex + 1, columnCount - 1),
		];
		setSelected({ start: indices, end: indices });
		scrollSelectionBoxIntoView(selectionBoxRef);
	}, [contextObjRef]);

	const shiftUp = useCallback(() => {
		const {
			selected,
			setSelected,
			selectionBoxRef,
		} = contextObjRef.current;
		if (!selected) return;
		const [rIndex, cIndex] = selected.end;
		setSelected({
			start: selected.start,
			end: [Math.max(rIndex - 1, 0), cIndex],
		});
		scrollSelectionBoxIntoView(selectionBoxRef);
	}, [contextObjRef]);

	const shiftDown = useCallback(() => {
		const {
			selected,
			pageSize,
			setSelected,
			selectionBoxRef,
		} = contextObjRef.current;
		if (!selected) return;
		const [rIndex, cIndex] = selected.end;
		setSelected({
			start: selected.start,
			end: [Math.min(rIndex + 1, pageSize - 1), cIndex],
		});
		scrollSelectionBoxIntoView(selectionBoxRef);
	}, [contextObjRef]);

	const shiftLeft = useCallback(() => {
		const {
			selected,
			setSelected,
			selectionBoxRef,
		} = contextObjRef.current;
		if (!selected) return;
		const [rIndex, cIndex] = selected.end;
		setSelected({
			start: selected.start,
			end: [rIndex, Math.max(cIndex - 1, 0)],
		});
		scrollSelectionBoxIntoView(selectionBoxRef);
	}, [contextObjRef]);

	const shiftRight = useCallback(() => {
		const {
			selected,
			columns,
			setSelected,
			selectionBoxRef,
		} = contextObjRef.current;
		if (!selected) return;
		const columnCount = columns.size;
		const [rIndex, cIndex] = selected.end;
		setSelected({
			start: selected.start,
			end: [rIndex, Math.min(cIndex + 1, columnCount - 1)],
		});
		scrollSelectionBoxIntoView(selectionBoxRef);
	}, [contextObjRef]);

	return useMemo(
		() => ({
			up,
			down,
			left,
			right,
			'shift+up': shiftUp,
			'shift+down': shiftDown,
			'shift+left': shiftLeft,
			'shift+right': shiftRight,
		}),
		[up, down, left, right, shiftUp, shiftDown, shiftLeft, shiftRight]
	);
}

import styled from 'styled-components';
import { View } from '../../../../Common/View';

export const DesktopWrapper = styled(View)`
	position: relative;
	flex: 1 1 auto;
	align-self: stretch;
	overflow: hidden;
	background-color: ${({
		theme: {
			background: { grey },
		},
	}) => grey};
`;

export const TilePreview = styled(View)`
	position: absolute;
	background-color: ${({
		theme: {
			background: { lighten },
		},
	}) => lighten[2]};
	border: 3px solid
		${({
			theme: {
				background: { lighten },
			},
		}) => lighten[2]};
	transition: all 200ms;
	box-sizing: border-box;
`;

import configs from '../../../configs';

const { UPLOAD_RECORDS_URI } = configs;

export async function uploadRecordsFile(
	file: File,
	collection: string,
	keyField: string,
	token: string
) {
	const form = new FormData();
	form.append('data', file);
	form.append('collection', collection);
	form.append('keyField', keyField);

	await fetch(UPLOAD_RECORDS_URI, {
		method: 'POST',
		headers: {
			authorization: `Bearer ${token}`,
		},
		body: form,
	});
}

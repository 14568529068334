import React, { useMemo, useContext } from 'react';
import {
	mdiFilterRemove,
	mdiDatabaseImport,
	mdiDatabaseExport,
	mdiBroom,
	mdiContentSave,
} from '@mdi/js';
import { PanelButton } from './Panel.sc';
import ImportRecordModal from '../../ImportRecordModal';
import ExportRecordModal from '../../ExportRecordModal';
import {
	useAllRecordMergeRequest,
	useAllUnmergedRecordDrop,
} from '../../../Hooks/Record';
import { useAllColumnFiltersDrop } from '../../../Hooks/Column';
import { usePopoverAtCenter } from '../../../../Common/Popover';
import context from '../../../context';

function SaveButton() {
	const contextObj = useContext(context);
	const { editable } = contextObj;
	const requestMergeAllRecords = useAllRecordMergeRequest(contextObj);
	return useMemo(
		() =>
			editable ? (
				<PanelButton
					tabIndex={0}
					icon={mdiContentSave}
					tooltip="Save"
					onPress={requestMergeAllRecords}
				/>
			) : null,
		[requestMergeAllRecords, editable]
	);
}

function ClearUnSavedButton() {
	const contextObj = useContext(context);
	const { editable } = contextObj;
	const dropAllUnmergedRecord = useAllUnmergedRecordDrop(contextObj);
	return useMemo(
		() =>
			editable ? (
				<PanelButton
					tabIndex={0}
					icon={mdiBroom}
					tooltip="Clear Unsaved"
					onPress={dropAllUnmergedRecord}
				/>
			) : null,
		[dropAllUnmergedRecord, editable]
	);
}

function ImportRecordButton() {
	const { tableRef, editable } = useContext(context);
	const { open, getPopoverProps } = usePopoverAtCenter({
		containerRef: tableRef,
	});
	return useMemo(
		() =>
			editable ? (
				<>
					<PanelButton
						tabIndex={0}
						icon={mdiDatabaseImport}
						tooltip="Import Record"
						onPress={open}
					/>
					<ImportRecordModal
						containerRef={tableRef}
						{...getPopoverProps()}
					/>
				</>
			) : null,
		[tableRef, open, getPopoverProps, editable]
	);
}

function ExportRecordButton() {
	const { tableRef } = useContext(context);
	const { open, getPopoverProps } = usePopoverAtCenter({
		containerRef: tableRef,
	});
	return useMemo(
		() => (
			<>
				<PanelButton
					tabIndex={0}
					icon={mdiDatabaseExport}
					tooltip="Export Record"
					onPress={open}
				/>
				<ExportRecordModal
					containerRef={tableRef}
					{...getPopoverProps()}
				/>
			</>
		),
		[tableRef, open, getPopoverProps]
	);
}

function ClearFiltersButton() {
	const contextObj = useContext(context);
	const dropAllColumnFilters = useAllColumnFiltersDrop(contextObj);
	return useMemo(
		() => (
			<PanelButton
				tabIndex={0}
				icon={mdiFilterRemove}
				tooltip="Clear Filters"
				onPress={dropAllColumnFilters}
			/>
		),
		[dropAllColumnFilters]
	);
}

function PanelTableControl() {
	return useMemo(() => {
		return (
			<>
				<SaveButton />
				<ClearUnSavedButton />
				<ImportRecordButton />
				<ExportRecordButton />
				<ClearFiltersButton />
			</>
		);
	}, []);
}

export default PanelTableControl;

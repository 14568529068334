import createActionCreator from '../../../Helpers/createActionCreator';

interface CreateWorkspacePayload {
	id: string;
	name: string;
}

interface RenameWorkspacePayload {
	index: number;
	name: string;
}

interface RemoveWorkspacePayload {
	index: number;
	id: string;
}

interface SelectWorkspacePayload {
	index: number;
}

export const createWorkspace = createActionCreator(
	'DASHBOARD/CREATE_WORKSPACE',
	{} as CreateWorkspacePayload
);

export const renameWorkspace = createActionCreator(
	'DASHBOARD/RENAME_WORKSPACE',
	{} as RenameWorkspacePayload
);

export const removeWorkspace = createActionCreator(
	'DASHBOARD/REMOVE_WORKSPACE',
	{} as RemoveWorkspacePayload
);

export const selectWorkspace = createActionCreator(
	'DASHBOARD/SELECT_WORKSPACE',
	{} as SelectWorkspacePayload
);

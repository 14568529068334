import React from 'react';
import {
	LoadingScreenWrapper,
	StyledLoadingSpinner,
	LoadingText,
} from './LoadingScreen.sc';

export default function LoadingScreen() {
	return (
		<LoadingScreenWrapper>
			<StyledLoadingSpinner />
			<LoadingText>Authenticating</LoadingText>
		</LoadingScreenWrapper>
	);
}

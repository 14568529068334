import { call } from 'redux-saga/effects';

export function getItem(path: string) {
	return call([localStorage, localStorage.getItem], path);
}

export function setItem(path: string, value: string | Object) {
	return call(
		[localStorage, localStorage.setItem],
		path,
		typeof value === 'string' ? value : JSON.stringify(value)
	);
}

export function removeItem(path: string) {
	return call([localStorage, localStorage.removeItem], path);
}

import hash from 'hash.js';

export default function stringToHSL(str: string, s: number, l: number) {
	const _str = hash
		.sha1()
		.update(str)
		.digest('hex');
	let code = 0;
	for (let i = 0, l = _str.length; i < l; ++i) {
		code = _str.charCodeAt(i) + ((code << 5) - code);
	}
	const h = code % 360;
	return `hsl(${h}, ${s}%, ${l}%)`;
}

import { useContext, useCallback, useState, useEffect } from 'react';
import notificationContext from '../context';
import { ListOnItemsRenderedProps } from 'react-window';

export default function useInfiniteScroll() {
	const {
		notificationIds,
		exhausted,
		loading,
		onFetchNotifications,
	} = useContext(notificationContext);
	const [endReached, setEndReached] = useState<boolean>(true);
	const detectEndReached = useCallback(
		({ visibleStopIndex }: ListOnItemsRenderedProps) => {
			if (visibleStopIndex === notificationIds.size - 1)
				setEndReached(true);
			else setEndReached(false);
		},
		[setEndReached, notificationIds.size]
	);

	useEffect(() => {
		if (endReached && !loading && !exhausted)
			onFetchNotifications && onFetchNotifications(notificationIds.size);
	}, [
		endReached,
		onFetchNotifications,
		notificationIds.size,
		loading,
		exhausted,
	]);

	return {
		detectEndReached,
	};
}

import React, { useMemo, useCallback } from 'react';
import {
	HeaderWrapper,
	ScenarioChangeLogWrapper,
	HeaderText,
	ListScrollView,
	ListWrapper,
	ListItemWrapper,
	ListItemText,
	MoreButton,
} from './ScenarioChangeLog.sc';
import moment from 'moment';
import { mdiOpenInNew } from '@mdi/js';
import { useStartApplication, useToggleHomePage } from '../../Workspace';

export interface ScenarioChangeRecord {
	x: Date;
	from: string;
	to: string;
	count: number;
}

interface Props {
	records: ScenarioChangeRecord[];
}

export default function ScenarioChangeLog(props: Props) {
	const { records } = props;

	const openApplication = useStartApplication();
	const toggleHomePage = useToggleHomePage();

	const handleShowDetail = useCallback(() => {
		toggleHomePage(false);
		openApplication &&
			openApplication({
				key: 'scenarioChangeLogsTable',
				name: 'Scenario Change Log',
			});
	}, [openApplication, toggleHomePage]);

	return useMemo(() => {
		return (
			<ScenarioChangeLogWrapper>
				<HeaderWrapper>
					<HeaderText>SCENARIO CHANGE LOG</HeaderText>
					<MoreButton
						icon={mdiOpenInNew}
						text="MORE"
						onPress={handleShowDetail}
					/>
				</HeaderWrapper>
				<ListScrollView>
					<ListWrapper>
						{records.map(({ x: timestamp, from, to, count }, i) => {
							const date = moment(timestamp).format(
								'YYYY-MM-DD HH:mm'
							);
							return (
								<ListItemWrapper key={i}>
									<ListItemText>
										{from === to
											? `${date}   |   Setting of ${from} has been changed`
											: count > 1
											? `${date}   |   ${count} Sensors have been changed ${
													from
														? `from "${from}" `
														: ''
											  }to "${to}"`
											: `${date}   |   ${count} Sensor has been changed ${
													from
														? `from "${from}" `
														: ''
											  }to "${to}"`}
									</ListItemText>
								</ListItemWrapper>
							);
						})}
					</ListWrapper>
				</ListScrollView>
			</ScenarioChangeLogWrapper>
		);
	}, [records, handleShowDetail]);
}

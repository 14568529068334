import React, {
	useMemo,
	useCallback,
	useContext,
	MouseEvent as RMouseEvent,
	KeyboardEvent as RKeyboardEvent,
} from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { mdiMenuRight, mdiDrag } from '@mdi/js';
import {
	HeaderCellTitleWrapper,
	RearrangeIcon,
	SortButton as SSortButton,
	SortIcon,
	SortPriorityText,
} from './HeaderCellTitle.sc';
import HeaderCellTitleInput from './HeaderCellTitleInput';
import {
	useSortPush,
	useSortUpdate,
	useSortsReplace,
} from '../../../Hooks/Sort';
import { useColumn } from '../../../Hooks/Column';
import { useSortInfo } from '../Hooks/useSortInfo';
import { SortOrder } from '../../../interfaces';
import context from '../../../context';

const RearrangeHandle = SortableHandle(function() {
	return <RearrangeIcon icon={mdiDrag} />;
});

interface SortButtonProps {
	columnKey: string;
}

function getNextSortOrder(order: SortOrder): SortOrder {
	if (order === -1) return 0;
	if (order === 0) return 1;
	return -1;
}

function SortButton(props: SortButtonProps) {
	const { columnKey } = props;
	const contextObj = useContext(context);
	const { multiSorted, priority, order } = useSortInfo(contextObj, columnKey);
	const pushSort = useSortPush(contextObj, columnKey);
	const updateSort = useSortUpdate(contextObj, columnKey);
	const replaceSorts = useSortsReplace(contextObj, columnKey);

	const toggleSort = useCallback(
		(e: RMouseEvent<HTMLDivElement> | RKeyboardEvent<HTMLDivElement>) => {
			const newOrder = getNextSortOrder(order);
			if (e.shiftKey) {
				if (priority === -1) pushSort(newOrder);
				else updateSort(newOrder);
			} else replaceSorts(newOrder);
		},
		[order, priority, pushSort, updateSort, replaceSorts]
	);

	return useMemo(
		() => (
			<SSortButton onPress={toggleSort}>
				<SortIcon icon={mdiMenuRight} order={order} />
				{multiSorted && priority >= 0 ? (
					<SortPriorityText>{priority + 1}</SortPriorityText>
				) : null}
			</SSortButton>
		),
		[multiSorted, priority, order, toggleSort]
	);
}

interface HeaderCellTitleProps {
	columnKey: string;
}
function HeaderCellTitle(props: HeaderCellTitleProps) {
	const { columnKey } = props;
	const contextObj = useContext(context);
	const { resizingColumnKey } = contextObj;
	const { sortable } = useColumn(contextObj, columnKey);
	return useMemo(() => {
		const resizing = columnKey === resizingColumnKey;
		return (
			<HeaderCellTitleWrapper resizing={resizing}>
				<RearrangeHandle />
				<HeaderCellTitleInput columnKey={columnKey} />
				{sortable ? <SortButton columnKey={columnKey} /> : null}
			</HeaderCellTitleWrapper>
		);
	}, [columnKey, resizingColumnKey, sortable]);
}

export default HeaderCellTitle;

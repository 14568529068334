import React, { useState, ReactNode, createRef, RefObject } from 'react';
import { VariableSizeGrid } from 'react-window';

import useMeasure from '../../../Hooks/useMeasure';
import { ITableStore, DispatchProps } from '../interfaces';
import context, { ITableContext } from '../context';

const { Provider } = context;

export type Selected = {
	start: [number, number];
	end: [number, number];
} | null;

export interface TableProviderProps extends ITableStore, DispatchProps {
	user: string;
	editable?: boolean;
	children?: ReactNode;
}

export interface TableState {
	tableRef: RefObject<HTMLDivElement>;
	headerGridRef: RefObject<VariableSizeGrid>;
	indexGridRef: RefObject<VariableSizeGrid>;
	bodyGridRef: RefObject<VariableSizeGrid>;
	selectionBoxRef: RefObject<HTMLDivElement>;
	selecting: boolean;
	selected: Selected;
	resizingColumnKey: string | null;
	resizingRecordId: string | null;
	rearrangingColumnKey: string | null;
	rearrangingRecordId: string | null;
	tableHeight: number;
	tableWidth: number;
	setTableRef(ref: RefObject<HTMLDivElement>): void;
	setHeaderGridRef(ref: RefObject<VariableSizeGrid>): void;
	setIndexGridRef(ref: RefObject<VariableSizeGrid>): void;
	setBodyGridRef(ref: RefObject<VariableSizeGrid>): void;
	setSelectionBoxRef(ref: RefObject<HTMLDivElement>): void;
	setSelecting(selecting: boolean): void;
	setSelected(selected: Selected): void;
	setResizingColumnKey(resizingColumnKey: string | null): void;
	setResizingRecordId(resizingRecordId: string | null): void;
	setRearrangingColumnKey(rearrangingColumnKey: string | null): void;
	setRearrangingRecordId(rearrangingRecordId: string | null): void;
}
export default function TableProvider(props: TableProviderProps) {
	const { children, editable = true, ...restProps } = props;

	const [tableRef, setTableRef] = useState<RefObject<HTMLDivElement>>(
		createRef
	);
	const [headerGridRef, setHeaderGridRef] = useState<
		RefObject<VariableSizeGrid>
	>(createRef);
	const [indexGridRef, setIndexGridRef] = useState<
		RefObject<VariableSizeGrid>
	>(createRef);
	const [bodyGridRef, setBodyGridRef] = useState<RefObject<VariableSizeGrid>>(
		createRef
	);
	const [selectionBoxRef, setSelectionBoxRef] = useState<
		RefObject<HTMLDivElement>
	>(createRef);

	const [selecting, setSelecting] = useState(false);
	const [selected, setSelected] = useState<Selected>(null);
	const [resizingColumnKey, setResizingColumnKey] = useState<string | null>(
		null
	);
	const [resizingRecordId, setResizingRecordId] = useState<string | null>(
		null
	);
	const [rearrangingColumnKey, setRearrangingColumnKey] = useState<
		string | null
	>(null);
	const [rearrangingRecordId, setRearrangingRecordId] = useState<
		string | null
	>(null);

	const { width: tableWidth, height: tableHeight } = useMeasure(tableRef, 50);

	const providerValue: ITableContext = {
		...restProps,
		editable,
		tableRef,
		headerGridRef,
		indexGridRef,
		bodyGridRef,
		selectionBoxRef,
		selecting,
		selected,
		resizingColumnKey,
		resizingRecordId,
		rearrangingColumnKey,
		rearrangingRecordId,
		tableHeight,
		tableWidth,
		setTableRef,
		setHeaderGridRef,
		setIndexGridRef,
		setBodyGridRef,
		setSelectionBoxRef,
		setSelecting,
		setSelected,
		setResizingColumnKey,
		setResizingRecordId,
		setRearrangingColumnKey,
		setRearrangingRecordId,
	};
	return <Provider value={providerValue}>{children}</Provider>;
}

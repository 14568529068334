import { View } from '../../../../Common/View';
import styled from 'styled-components';
import { USER_PANEL_WIDTH, USER_PANEL_ITEM_HEIGHT } from '../constants';
import { BodyText1 } from '../../../../Common/Text';
import Button from '../../../../Common/Button';
import {
	ButtonOverlayShape,
	ButtonProps,
} from '../../../../Common/Button/interfaces';
import Icon from '@mdi/react';
import { IconProps } from '@mdi/react/dist/IconProps';

export const UserPanelMenuWrapper = styled(View)`
	background-color: ${({
		theme: {
			background: { frameDarker },
		},
	}) => frameDarker};
	width: ${USER_PANEL_WIDTH}px;
	border-radius: 8px;
	overflow: hidden;
`;

export const UserPanelItemButton = styled(Button).attrs<{}, ButtonProps>({
	overlayShape: ButtonOverlayShape.rectangle,
	lightBg: false,
})`
	flex: 1 0 ${USER_PANEL_ITEM_HEIGHT}px;
	flex-direction: row;
	align-items: center;
	padding: 12px;
	box-sizing: border-box;
`;

export const UserPanelItemText = styled(BodyText1)`
	color: ${({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => darkBgPrimary};
	flex: 1 0 0;
	margin-left: 8px;
`;

export const UserPanelButtonIcon = styled(Icon).attrs<{}, Partial<IconProps>>(
	({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => ({ size: 0.8, color: darkBgPrimary })
)``;

import React, { useMemo } from 'react';
import { EmbeddedProps } from '../interfaces';
import { EmbeddedWrapper } from './Embedded.sc';
import useEmbedded from '../Hooks/useEmbedded';
import EmbeddedLoadingScreen from './EmbeddedLoadingScreen';

export default function Embedded<S>(props: EmbeddedProps<S>) {
	const {
		component: Component,
		storeId,
		type,
		initialState,
		loadingMessage,
	} = props;

	const { linkedState, dispatch, forwardAction } = useEmbedded<S>(
		storeId,
		type,
		loadingMessage,
		initialState
	);
	const { loading, message, state } = linkedState;

	return useMemo(() => {
		return (
			<EmbeddedWrapper>
				{!loading && state ? (
					<Component
						state={state}
						dispatch={dispatch}
						forwardAction={forwardAction}
					/>
				) : (
					<EmbeddedLoadingScreen message={message} />
				)}
			</EmbeddedWrapper>
		);
	}, [dispatch, state, loading, message, forwardAction]);
}

import { useEffect, useRef } from 'react';

export default function useAsRef<V>(value: V) {
	const valueRef = useRef<V>(value);
	useEffect(() => {
		valueRef.current = value;
	}, [value]);

	return valueRef;
}

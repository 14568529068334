import { TileProps, DropZone } from '../interfaces';
import { useCallback, useState } from 'react';
import isWithinBound from '../Helpers/isWithinBound';
import useAsRef from '../../../Hooks/useAsRef';

export default function useDropZones(props: TileProps) {
	const { dropZones, onEnterZone, onLeaveZones, onDrop } = props;
	const [zone, setZone] = useState<DropZone>();
	const zoneRef = useAsRef(zone);

	return useCallback(() => {
		let hoverZone: DropZone | undefined;
		function activateDropZone(e: MouseEvent) {
			const { clientX, clientY } = e;
			hoverZone = dropZones.find((zone: DropZone) => {
				return isWithinBound(zone, clientX, clientY);
			});
			const zone = zoneRef.current;

			if (!hoverZone) {
				if (zone) {
					onLeaveZones(e);
					setZone(hoverZone);
					window.removeEventListener('mouseup', prepareDrop);
				}
			} else {
				if (!zone || zone.id !== hoverZone.id) {
					onEnterZone(hoverZone, e);
					setZone(hoverZone);
					window.addEventListener('mouseup', prepareDrop);
				}
			}
		}

		function prepareDrop(e: MouseEvent) {
			hoverZone && onDrop(hoverZone, e);
			window.removeEventListener('mouseup', prepareDrop);
		}

		function deactivateDropZone() {
			window.removeEventListener('mousemove', activateDropZone);
			window.removeEventListener('mouseup', deactivateDropZone);
		}

		window.addEventListener('mousemove', activateDropZone);
		window.addEventListener('mouseup', deactivateDropZone);
	}, [dropZones, onEnterZone, onLeaveZones, onDrop, zoneRef]);
}

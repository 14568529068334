import React, { useMemo, useCallback, Fragment } from 'react';
import { mdiTree, mdiAngleAcute, mdiChip, mdiOpenInNew } from '@mdi/js';
import {
	VictoryChart,
	VictoryGroup,
	VictoryTooltip,
	VictoryLine,
	VictoryScatter,
	VictoryArea,
	VictoryAxis,
} from 'victory';
import { format, differenceInDays } from 'date-fns';
import {
	TreeDetailsWrapper,
	TreeDetailsHeaderWrapper,
	TreeDetailsSubHeaderWrapper,
	TreeDetailsHeaderText,
	TreeDetailsHeaderSubText,
	TreeDetailsSubHeaderIcon,
	TreeDetailsSubHeaderText,
	TreeDetailsBodyText,
	TreeDetailsBodyTextPrimary,
	ColumnWrapper,
	TreeDetailsSeparator,
	TreeDetailsColumnWrapper,
	TreeDetailsBodyWrapper,
	ChartNoDataWrapper,
	ChartNoDataText,
	MoreButtonWrapper,
	MoreButton,
	AtributeLabelWrapper,
} from './TreeDetails.sc';
import { chartDarkTheme } from '../../../../../../../chartTheme';
import noop from '../../../../../../../Helpers/noop';
import {
	TiltingAngleChartData,
	Sensor,
	MapTreeDetailsProps,
} from '../../../interfaces';
import { isValid } from 'date-fns/esm';

function formatLeaning(v?: number) {
	if (typeof v !== 'number') return 'NO DATA';
	if (v > 337.5 || v <= 22.5) return 'N';
	if (v > 22.5 || v <= 67.5) return 'NE';
	if (v > 67.5 || v <= 112.5) return 'E';
	if (v > 112.5 || v <= 157.5) return 'SE';
	if (v > 157.5 || v <= 202.5) return 'S';
	if (v > 202.5 || v <= 247.5) return 'SW';
	if (v > 247.5 || v <= 292.5) return 'W';
	if (v > 292.5 || v <= 337.5) return 'NW';
	return 'NO DATA';
}

function formatHeight(v?: number) {
	if (typeof v === 'number') return `${v} m`;
	return 'NO DATA';
}

function formatDBH(v?: number) {
	if (typeof v === 'number') return `${v} mm`;
	return 'NO DATA';
}

function formatTiltingAngle(v?: number) {
	if (typeof v === 'number') return Math.round(v * 100) / 100 + '°';
	return 'NO DATA';
}

function formatVoltage(v?: number) {
	if (typeof v === 'number') return v <= 2700 ? 'Medium' : 'Strong';
	return 'NO DATA';
}

function formatSamplingInterval(v?: number) {
	if (typeof v === 'number') return `${v} min`;
	return 'NO DATA';
}

function formatSendInterval(v?: number) {
	if (typeof v === 'number') return `${v} min`;
	return 'NO DATA';
}

function formatDate(v?: Date | string) {
	if (typeof v === 'string') {
		const date = new Date(v);
		if (isValid(date)) return format(date, 'yyyy-MM-dd HH:mm');
	}
	if (v instanceof Date) return format(v, 'yyyy-MM-dd HH:mm');
	return 'NO DATA';
}

interface AtributeLabelProps {
	title: string;
	value: string;
}
function AtributeLabel(props: AtributeLabelProps) {
	const { title, value } = props;
	return useMemo(() => {
		return (
			<AtributeLabelWrapper>
				<TreeDetailsBodyTextPrimary>{title}</TreeDetailsBodyTextPrimary>
				<TreeDetailsBodyText>{value}</TreeDetailsBodyText>
			</AtributeLabelWrapper>
		);
	}, [title, value]);
}

interface TiltingAngleChartProps {
	data: TiltingAngleChartData;
}

function TiltingAngleChart(props: TiltingAngleChartProps) {
	const { data } = props;

	return useMemo(() => {
		return (
			<TreeDetailsBodyWrapper>
				{data.length > 1 ? (
					<VictoryChart
						width={1000}
						height={500}
						theme={chartDarkTheme}
						domainPadding={{ y: 0.1 }}
					>
						<VictoryGroup
							data={data.map(({ x, tiltingAngle }) => ({
								tiltingAngle,
								x: new Date(x),
							}))}
							scale={'time'}
							x="x"
							y="tiltingAngle"
							labels={({ datum }) =>
								`${Math.round(datum.tiltingAngle * 1000) /
									1000}`
							}
							labelComponent={
								<VictoryTooltip style={{ fontSize: 14 }} />
							}
						>
							<VictoryLine />
							<VictoryScatter size={5} />
							<VictoryArea />
							<VictoryAxis
								tickFormat={(v: Date) => {
									return format(v, 'dd MMM HH:mm');
								}}
							/>
							<VictoryAxis dependentAxis scale="linear" />
						</VictoryGroup>
					</VictoryChart>
				) : (
					<ChartNoDataWrapper>
						<ChartNoDataText>NO DATA</ChartNoDataText>
					</ChartNoDataWrapper>
				)}
			</TreeDetailsBodyWrapper>
		);
	}, [data]);
}

interface SensorDetailsProps extends Sensor {
	onRequestOpenSensorsTable: (sensorId: string) => any;
}

function SensorDetails(props: SensorDetailsProps) {
	const {
		sensorId,
		transmissionType = 'NO DATA',
		voltage,
		samplingInterval,
		sendInterval,
		lastMessageReceivedAt,
		onRequestOpenSensorsTable,
	} = props;

	const handleOpenButtonPress = useCallback(() => {
		onRequestOpenSensorsTable(sensorId);
	}, [onRequestOpenSensorsTable, sensorId]);

	return useMemo(() => {
		const formattedVoltage = formatVoltage(voltage);
		const formattedSamplingInterval = formatSamplingInterval(
			samplingInterval
		);
		const formattedSendInterval = formatSendInterval(sendInterval);
		const formattedLastMessageReceivedAt = formatDate(
			lastMessageReceivedAt
		);

		const inactive = lastMessageReceivedAt
			? differenceInDays(new Date(), new Date(lastMessageReceivedAt)) > 7
			: true;

		return (
			<>
				<TreeDetailsSubHeaderWrapper>
					<TreeDetailsSubHeaderIcon icon={mdiChip} />
					<TreeDetailsSubHeaderText>
						{`Sensor Info (${sensorId})`}
					</TreeDetailsSubHeaderText>
					<MoreButtonWrapper>
						<MoreButton
							icon={mdiOpenInNew}
							text="Open sensor inventory"
							onPress={handleOpenButtonPress}
						/>
					</MoreButtonWrapper>
				</TreeDetailsSubHeaderWrapper>
				<TreeDetailsBodyWrapper>
					<TreeDetailsColumnWrapper>
						<AtributeLabel
							title="TRANSMISSION TYPE"
							value={transmissionType}
						/>
						<AtributeLabel
							title="VOLTAGE"
							value={inactive ? 'NO DATA' : formattedVoltage}
						/>
					</TreeDetailsColumnWrapper>
					<TreeDetailsColumnWrapper>
						<AtributeLabel
							title="SAMPLING INTERVAL"
							value={formattedSamplingInterval}
						/>
						<AtributeLabel
							title="SEND INTERVAL"
							value={formattedSendInterval}
						/>
					</TreeDetailsColumnWrapper>
					<TreeDetailsColumnWrapper>
						<AtributeLabel
							title="LAST MESSAGE RECEIVED AT"
							value={formattedLastMessageReceivedAt}
						/>
					</TreeDetailsColumnWrapper>
				</TreeDetailsBodyWrapper>
			</>
		);
	}, [
		sensorId,
		transmissionType,
		voltage,
		samplingInterval,
		sendInterval,
		lastMessageReceivedAt,
		handleOpenButtonPress,
	]);
}

export default function TreeDetails(props: MapTreeDetailsProps) {
	const {
		tree: { treeId, details, leaning, tiltingAngle },
		sensors,
		tiltingAngleData,
		onRequestOpenTreesTable = noop,
		onRequestOpenReadingsTable = noop,
		onRequestOpenSensorsTable = noop,
	} = props;

	const {
		district = 'NO DATA',
		deptId: department = 'NO DATA',
		species = 'NO DATA',
		DBH = 'NO DATA',
		height = 'NO DATA',
	} = details || {};

	const handleTreesButtonPress = useCallback(() => {
		onRequestOpenTreesTable(treeId);
	}, [onRequestOpenTreesTable, treeId]);

	const handleReadingsButtonPress = useCallback(() => {
		onRequestOpenReadingsTable(treeId);
	}, [onRequestOpenReadingsTable, treeId]);

	return useMemo(() => {
		const formattedLeaning = formatLeaning(leaning);
		const formattedHeight = formatHeight(height);
		const formattedDBH = formatDBH(DBH);
		const formattedTiltingAngle = formatTiltingAngle(tiltingAngle);
		return (
			<TreeDetailsWrapper>
				<TreeDetailsHeaderWrapper>
					<ColumnWrapper>
						<TreeDetailsHeaderText>{treeId}</TreeDetailsHeaderText>
						<TreeDetailsHeaderSubText>
							{district}
						</TreeDetailsHeaderSubText>
					</ColumnWrapper>
				</TreeDetailsHeaderWrapper>
				<TreeDetailsSubHeaderWrapper>
					<TreeDetailsSubHeaderIcon icon={mdiAngleAcute} />
					<TreeDetailsSubHeaderText>
						{'Tilting Angle (°)'}
					</TreeDetailsSubHeaderText>
					<MoreButtonWrapper>
						<MoreButton
							icon={mdiOpenInNew}
							text="Open in reading records"
							onPress={handleReadingsButtonPress}
						/>
					</MoreButtonWrapper>
				</TreeDetailsSubHeaderWrapper>
				{tiltingAngleData.length ? (
					tiltingAngleData.map(({ data, sensorId }) => {
						return <TiltingAngleChart key={sensorId} data={data} />;
					})
				) : (
					<TiltingAngleChart key="nosensor" data={[]} />
				)}
				<TreeDetailsSeparator />
				<TreeDetailsSubHeaderWrapper>
					<TreeDetailsSubHeaderIcon icon={mdiTree} />
					<TreeDetailsSubHeaderText>
						{'Tree Attributes'}
					</TreeDetailsSubHeaderText>
					<MoreButtonWrapper>
						<MoreButton
							icon={mdiOpenInNew}
							text="Open in tree inventory"
							onPress={handleTreesButtonPress}
						/>
					</MoreButtonWrapper>
				</TreeDetailsSubHeaderWrapper>
				<TreeDetailsBodyWrapper>
					<TreeDetailsColumnWrapper>
						<AtributeLabel title="DEPARTMENT" value={department} />
						<AtributeLabel title="SPECIES" value={species} />
						<AtributeLabel
							title="LEANING"
							value={formattedLeaning}
						/>
					</TreeDetailsColumnWrapper>
					<TreeDetailsColumnWrapper>
						<AtributeLabel title="HEIGHT" value={formattedHeight} />
						<AtributeLabel title="DBH" value={formattedDBH} />
						<AtributeLabel
							title="TILTING ANGLE"
							value={formattedTiltingAngle}
						/>
					</TreeDetailsColumnWrapper>
				</TreeDetailsBodyWrapper>
				<TreeDetailsSeparator />
				{sensors.map((sensor, i) => (
					<Fragment key={i}>
						<SensorDetails
							{...sensor}
							onRequestOpenSensorsTable={
								onRequestOpenSensorsTable
							}
						/>
						<TreeDetailsSeparator />
					</Fragment>
				))}
			</TreeDetailsWrapper>
		);
	}, [
		treeId,
		district,
		department,
		species,
		leaning,
		height,
		DBH,
		tiltingAngle,
		sensors,
		tiltingAngleData,
		handleTreesButtonPress,
		handleReadingsButtonPress,
		onRequestOpenSensorsTable,
	]);
}

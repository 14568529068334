import { createContext } from 'react';
import { DashboardContext } from './interfaces';
import { List, Map } from 'immutable';
import noop from '../../Helpers/noop';

export default createContext<DashboardContext>({
	username: '',
	groups: [],
	onRequestLogout: noop,
	currentWorkspace: 0,
	workspaces: List(),
	applications: Map(),
	onCreateWorkspace: noop,
	onSelectWorkspace: noop,
	onRenameWorkspace: noop,
	onRemoveWorkspace: noop,
});

type MessageEventHandler = (msg: string) => any;
type ErrorEventHandler = (evt: Event) => any;

export default class WebSocketClient {
	url: string;
	autoReconnectInterval: number;
	ws!: WebSocket;
	eventHandlers: {
		message: Set<MessageEventHandler>;
		error: Set<ErrorEventHandler>;
	};

	constructor(url: string, autoReconnectInterval: number = 5000) {
		this.url = url;
		this.autoReconnectInterval = autoReconnectInterval;
		this.eventHandlers = {
			message: new Set(),
			error: new Set(),
		};
	}

	open() {
		return new Promise((resolve) => {
			this.ws = new WebSocket(this.url);
			this.ws.onclose = (e) => {
				switch (e.code) {
					case 1000: // CLOSE_NORMAL
						break;
					default:
						// Abnormal closure
						this.reconnect();
						break;
				}
			};

			this.ws.onmessage = (e) => {
				const { data } = e;
				this.eventHandlers.message.forEach((cb) => {
					cb(data);
				});
			};

			this.ws.onerror = (e) => {
				this.eventHandlers.error.forEach((cb) => {
					cb(e);
				});
			};
			this.ws.onopen = () => {
				resolve();
			};
		});
	}

	on(event: 'message', cb: MessageEventHandler): void;
	on(event: 'error', cb: ErrorEventHandler): void;
	on(event: 'message' | 'error', cb: any) {
		this.eventHandlers[event].add(cb);
	}

	send(data: string) {
		try {
			this.ws.send(data);
		} catch (e) {
			console.log(e);
			// this.ws.emit('error', e);
		}
	}

	reconnect() {
		this.ws.close();
		setTimeout(() => {
			this.open();
		}, this.autoReconnectInterval);
	}

	close() {
		try {
			this.ws.close(1000);
		} catch (e) {
			// this.ws.emit('error', e);
		}
	}
	removeListener(event: 'message', cb: MessageEventHandler): void;
	removeListener(event: 'error', cb: ErrorEventHandler): void;
	removeListener(event: 'message' | 'error', cb: any) {
		this.eventHandlers[event].delete(cb);
	}
}

import React, { useRef, useMemo, useContext } from 'react';
import { VariableSizeGrid, GridOnScrollProps } from 'react-window';

import Scrollbars from '../../../../Common/Scrollbars';
import BodyInnerWrapper from './BodyInnerWrapper';
import BodyCell from './BodyCell';
import context from '../../../context';
import constants from '../../../constants';

const {
	HEADER_HEIGHT,
	INDEX_WIDTH,
	PANEL_HEIGHT,
	PAGINATION_HEIGHT,
} = constants;

interface BodyGridProps {
	rowHeight(index: number): number;
	columnWidth(index: number): number;
	onScroll(props: GridOnScrollProps): void;
}

function BodyGrid(props: BodyGridProps) {
	const { rowHeight, columnWidth, onScroll } = props;
	const {
		bodyGridRef,
		tableWidth,
		tableHeight,
		columnKeys,
		pageSize,
	} = useContext(context);
	const bodyOuterRef = useRef<any>(null);
	const columnCount = columnKeys.size;

	return useMemo(() => {
		const bodyHeight = Math.max(
			tableHeight - HEADER_HEIGHT - PANEL_HEIGHT - PAGINATION_HEIGHT,
			0
		);
		const bodyWidth = tableWidth - INDEX_WIDTH;
		return (
			<VariableSizeGrid
				ref={bodyGridRef}
				// style={{ display: 'flex' }}
				outerRef={bodyOuterRef}
				outerElementType={Scrollbars}
				innerElementType={BodyInnerWrapper}
				rowCount={pageSize}
				columnCount={columnCount}
				rowHeight={rowHeight}
				columnWidth={columnWidth}
				width={bodyWidth}
				height={bodyHeight}
				onScroll={onScroll}
			>
				{BodyCell}
			</VariableSizeGrid>
		);
	}, [
		bodyGridRef,
		tableWidth,
		tableHeight,
		pageSize,
		columnCount,
		columnWidth,
		rowHeight,
		onScroll,
	]);
}

export default BodyGrid;

import React, { useMemo, useContext } from 'react';
import {
	FormatModalWrapper,
	FormatModalInputWrapper,
	FormatModalInputText,
} from './FormatModal.sc';
import FormatModalInput from './FormatModalInput';
import Modal from '../../../../Common/Modal';
import {
	useColumn,
	useColumnBooleanFormatUpdate,
	useColumnDateFormatUpdate,
	useColumnNumberFormatUpdate,
	useColumnUnitUpdate,
} from '../../../Hooks/Column';
import validateBooleanFormat from '../../../../../Helpers/validateBooleanFormat';
import validateDateFormat from '../../../../../Helpers/validateDateFormat';
import { ModalProps } from '../../../../Common/Modal/interfaces';
import context from '../../../context';

interface FormatModalContentProps {
	columnKey: string;
}
function FormatModalContent(props: FormatModalContentProps) {
	const { columnKey } = props;
	const contextObj = useContext(context);
	const {
		format: { booleanFormat, dateFormat, numberFormat, unit },
	} = useColumn(contextObj, columnKey);
	const updateColumnBooleanFormat = useColumnBooleanFormatUpdate(
		contextObj,
		columnKey
	);
	const updateColumnDateFormat = useColumnDateFormatUpdate(
		contextObj,
		columnKey
	);
	const updateColumnNumberFormat = useColumnNumberFormatUpdate(
		contextObj,
		columnKey
	);
	const updateColumnUnit = useColumnUnitUpdate(contextObj, columnKey);
	return useMemo(() => {
		return (
			<FormatModalWrapper>
				<FormatModalInputWrapper>
					<FormatModalInputText>Boolean Format:</FormatModalInputText>
					<FormatModalInput
						autoFocus
						value={booleanFormat}
						updateValue={updateColumnBooleanFormat}
						validateValue={validateBooleanFormat}
					/>
				</FormatModalInputWrapper>
				<FormatModalInputWrapper>
					<FormatModalInputText>Date Format:</FormatModalInputText>
					<FormatModalInput
						value={dateFormat}
						updateValue={updateColumnDateFormat}
						validateValue={validateDateFormat}
					/>
				</FormatModalInputWrapper>
				<FormatModalInputWrapper>
					<FormatModalInputText>Number Format:</FormatModalInputText>
					<FormatModalInput
						value={numberFormat}
						updateValue={updateColumnNumberFormat}
					/>
				</FormatModalInputWrapper>
				<FormatModalInputWrapper>
					<FormatModalInputText>Unit:</FormatModalInputText>
					<FormatModalInput
						value={unit}
						updateValue={updateColumnUnit}
					/>
				</FormatModalInputWrapper>
			</FormatModalWrapper>
		);
	}, [
		booleanFormat,
		dateFormat,
		numberFormat,
		unit,
		updateColumnBooleanFormat,
		updateColumnDateFormat,
		updateColumnNumberFormat,
		updateColumnUnit,
	]);
}

interface FormatModalProps extends Omit<ModalProps, 'render' | 'children'> {
	columnKey: string;
}
function FormatModal(props: FormatModalProps) {
	const { columnKey, ...restProps } = props;
	return (
		<Modal title="Format" {...restProps}>
			<FormatModalContent columnKey={columnKey} />
		</Modal>
	);
}

export default FormatModal;

import styled from 'styled-components';
import { View } from '../../../../../../../Components/Common/View';
import {
	CaptionText,
	HeadingText6,
	SubtitleText1,
	SubtitleText2,
	BodyText2,
} from '../../../../../../../Components/Common/Text';
import IconTextButton from '../../../../../../../Components/Common/IconTextButton';
import Scrollbars from '../../../../../../../Components/Common/Scrollbars';
import WrappedIcon from '../../../../../../../Components/Common/WrappedIcon';

export const TreeDetailsWrapper = styled(Scrollbars)``;

export const RowWrapper = styled(View)`
	flex-direction: row;
	align-items: center;
`;
export const ColumnWrapper = styled(View)``;

export const TreeDetailsHeaderWrapper = styled(RowWrapper)`
	flex: 0 0 0;
	padding: 8px;
	margin-top: 4px;
	margin-bottom: 4px;
`;

export const TreeDetailsSubHeaderWrapper = styled(TreeDetailsHeaderWrapper)`
	background-color: ${({
		theme: {
			background: { frameDarker },
		},
	}) => frameDarker};
`;

export const TreeDetailsBodyWrapper = styled(RowWrapper)`
	flex: 0 0 0;
	padding: 8px;
`;

export const TreeDetailsColumnWrapper = styled(ColumnWrapper)`
	flex: 1 0 0;
	padding: 16px;
`;

export const TreeDetailsHeaderText = styled(HeadingText6)`
	color: ${({
		theme: {
			font: {
				color: { primary },
			},
		},
	}) => primary};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const TreeDetailsHeaderSubText = styled(SubtitleText2)`
	color: ${({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => darkBgPrimary};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const TreeDetailsSubHeaderText = styled(SubtitleText1)`
	color: ${({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => darkBgPrimary};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;
export const AtributeLabelWrapper = styled(ColumnWrapper)`
	margin-top: 8px;
	margin-bottom: 8px;
`;

export const TreeDetailsBodyTextPrimary = styled(BodyText2)`
	color: ${({
		theme: {
			font: {
				color: { primary },
			},
		},
	}) => primary};
`;

export const TreeDetailsBodyText = styled(BodyText2)`
	color: ${({
		theme: {
			font: {
				color: { darkBgPrimary },
			},
		},
	}) => darkBgPrimary};
`;

export const TreeDetailsSeparator = styled(View)`
	align-self: stretch;
	flex: 0 0 1px;
	margin-left: 12px;
	margin-right: 12px;
	background-color: ${({
		theme: {
			font: {
				color: { darkBgSecondary },
			},
		},
	}) => darkBgSecondary};
`;

export const ChartNoDataWrapper = styled(View)`
	align-self: stretch;
	justify-self: stretch;
	align-items: center;
	justify-content: center;
`;

export const ChartNoDataText = styled(CaptionText)`
	color: ${({
		theme: {
			font: {
				color: { darkBgDisabled },
			},
		},
	}) => darkBgDisabled};
`;

export const TreeDetailsSubHeaderIcon = styled(WrappedIcon).attrs(
	({
		theme: {
			font: {
				color: { primary },
			},
		},
	}) => ({
		iconProps: {
			color: primary,
			size: 1.4,
		},
	})
)``;

export const MoreButtonWrapper = styled(View)`
	position: absolute;
	right: 16px;
`;

export const MoreButton = styled(IconTextButton).attrs(
	({
		theme: {
			font: {
				color: { primary },
			},
		},
	}) => ({ lightBg: false, iconProps: { color: primary } })
)`
	color: ${({
		theme: {
			font: {
				color: { primary },
			},
		},
	}) => primary};
	border-radius: 4px;
	& > [x-component='overlay'] {
		border-radius: 4px;
	}
`;

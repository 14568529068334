import createReducer from '../../../Helpers/createReducer';
import { Record, List } from 'immutable';
import { DashboardStore, SerializedDashboard } from '../interfaces';
import {
	selectWorkspace,
	renameWorkspace,
	removeWorkspace,
	createWorkspace,
} from './actions';

export const DashboardStoreRecord = Record<DashboardStore>({
	currentWorkspace: 0,
	workspaces: List(),
});

const initialState = DashboardStoreRecord();

export default createReducer(
	initialState,
	{
		createWorkspace,
		renameWorkspace,
		removeWorkspace,
		selectWorkspace,
	},
	{
		createWorkspace: (state, { payload: { id, name } }) =>
			state.withMutations((s) => {
				s.set('currentWorkspace', state.workspaces.size);
				s.update('workspaces', (workspaces) =>
					workspaces.push({ id, name })
				);
			}),
		renameWorkspace: (state, { payload: { index, name } }) =>
			state.setIn(['workspaces', index, 'name'], name),
		removeWorkspace: (state, { payload: { index } }) =>
			state.withMutations((s) => {
				s.update('currentWorkspace', (currentWorkspace) =>
					currentWorkspace <= index
						? currentWorkspace - 1
						: currentWorkspace
				);
				s.update('workspaces', (workspaces) =>
					workspaces.splice(index, 1)
				);
			}),
		selectWorkspace: (state, { payload: { index } }) =>
			state.set('currentWorkspace', index),
	}
);

export const dashboardParser = (
	dashboard: SerializedDashboard = {
		currentWorkspace: 0,
		workspaces: [],
	}
) => {
	const { currentWorkspace, workspaces } = dashboard;
	return DashboardStoreRecord({
		currentWorkspace,
		workspaces: List(workspaces),
	});
};

import styled from 'styled-components';
import { View } from '../../../../Common/View';

export const WorkspaceWrapper = styled(View)`
	flex: 1 0 0;
	align-self: stretch;
	background-color: ${({
		theme: {
			background: { grey },
		},
	}) => grey};
	flex-direction: row;
`;

import createActionCreator from '../../Helpers/createActionCreator';

interface ILoginPayload {
	username: string;
	password: string;
}

interface IAuthenticatePayload {
	token: string;
	state?: any;
}
export const login = createActionCreator('AUTH/LOGIN', {} as ILoginPayload);
export const logout = createActionCreator('AUTH/LOGOUT');
export const initialize = createActionCreator('AUTH/INIT');
export const authenticate = createActionCreator(
	'AUTH/AUTHENTICATE',
	{} as IAuthenticatePayload
);

export const resetApp = createActionCreator('APP/RESET');

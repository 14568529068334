import React, {
	useMemo,
	useRef,
	useEffect,
	useState,
	useCallback,
	ChangeEvent as RChangeEvent,
	KeyboardEvent as RKeyboardEvent,
	ReactNode,
} from 'react';
import Popover from '../Common/Popover';
import Scrollbars from '../Common/Scrollbars';
import useAsRef from '../../Hooks/useAsRef';
import {
	SearchBarIconWrapper,
	SearchBarIcon,
	SearchBarWrapper,
	SearchInputWrapper,
	SearchTextWrapper,
	SearchText,
	OpenedSearchBarWrapper,
	SearchInput,
	Separator,
	SearchSuggestionsWrapper,
} from './SearchBar.sc';
import SearchSuggestion from './SearchSuggestion';
import { Suggestion } from './interfaces';
import noop from '../../Helpers/noop';

type Props = {
	leftIcon: string;
	searchText: string;
	placeholder?: string;
	suggestions: Suggestion[];
	children?: ReactNode;
	onChange: (searchText: string) => any;
	onFocusSuggestion?: (index: number | null) => any;
	onSubmit?: () => any;
};

export default function SearchBar(props: Props) {
	const {
		leftIcon,
		searchText,
		placeholder = 'Search',
		suggestions,
		onChange,
		onFocusSuggestion = noop,
		children,
		onSubmit = noop,
	} = props;
	const containerRef = useRef<HTMLDivElement>(null);

	const [opened, setOpened] = useState<boolean>(false);
	const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
	const [focusedSuggestion, setFocusedSuggestion] = useState<number | null>(
		null
	);

	const [top, setTop] = useState<number>(0);
	const [left, setLeft] = useState<number>(0);
	const [width, setWidth] = useState<number>(0);

	useEffect(() => {
		if (opened) {
			const container = containerRef.current;
			if (!container) return;
			const { top, left, width } = container.getBoundingClientRect();

			setTop(top);
			setLeft(left);
			setWidth(width);
		}
	}, [opened]);

	const focusedSuggestionRef = useAsRef(focusedSuggestion);
	const suggestionsRef = useAsRef(suggestions);

	const handleFocus = useCallback((e: React.MouseEvent) => {
		e.stopPropagation();
		setOpened(true);
	}, []);
	const handleBlur = useCallback(() => {
		setOpened(false);
	}, []);

	const handleUp = useCallback(() => {
		const focusedSuggestion = focusedSuggestionRef.current;
		const suggestions = suggestionsRef.current;
		const newFocus =
			focusedSuggestion === 0 || focusedSuggestion === null
				? suggestions.length - 1
				: focusedSuggestion - 1;
		setFocusedSuggestion(newFocus);
		onFocusSuggestion(newFocus);
	}, [focusedSuggestionRef, suggestionsRef, onFocusSuggestion]);

	const handleDown = useCallback(() => {
		const focusedSuggestion = focusedSuggestionRef.current;
		const suggestions = suggestionsRef.current;
		const newFocus =
			focusedSuggestion === suggestions.length - 1 ||
			focusedSuggestion === null
				? 0
				: focusedSuggestion + 1;
		setFocusedSuggestion(newFocus);
		onFocusSuggestion(newFocus);
	}, [focusedSuggestionRef, suggestionsRef, onFocusSuggestion]);

	const handleConfirm = useCallback(() => {
		const focusedSuggestion = focusedSuggestionRef.current;
		const suggestions = suggestionsRef.current;
		const suggestion =
			typeof focusedSuggestion === 'number'
				? suggestions[focusedSuggestion]
				: null;
		if (suggestion) onChange(suggestion.name);
		onSubmit();
		setShowSuggestions(false);
		setFocusedSuggestion(null);
		onFocusSuggestion(null);
	}, [
		focusedSuggestionRef,
		onChange,
		suggestionsRef,
		onFocusSuggestion,
		onSubmit,
	]);

	const handleInput = useCallback(
		(e: RChangeEvent<HTMLInputElement>) => {
			onChange(e.target.value);
			setFocusedSuggestion(null);
			onFocusSuggestion(null);
		},
		[onChange, onFocusSuggestion]
	);

	const handleKeyDown = useCallback(
		(e: RKeyboardEvent<HTMLInputElement>) => {
			setShowSuggestions(true);

			if (e.keyCode === 38) {
				e.preventDefault();
				handleUp();
			}

			if (e.keyCode === 40) {
				e.preventDefault();
				handleDown();
			}

			if (e.keyCode === 13) {
				e.preventDefault();
				handleConfirm();
			}
		},
		[handleUp, handleDown, handleConfirm]
	);

	const handleSelect = useCallback(
		(index: number) => {
			const suggestion = suggestions[index];
			if (suggestion) {
				onChange(suggestion.name);
				setFocusedSuggestion(index);
				setShowSuggestions(false);
				onSubmit();
			}
		},
		[onChange, suggestions, onSubmit]
	);

	return useMemo(
		() => (
			<SearchBarWrapper ref={containerRef} onMouseDown={handleFocus}>
				<SearchInputWrapper>
					<SearchBarIconWrapper>
						<SearchBarIcon path={leftIcon} />
					</SearchBarIconWrapper>
					<SearchTextWrapper>
						<SearchText>
							{searchText ? searchText : placeholder}
						</SearchText>
					</SearchTextWrapper>
					{children}
				</SearchInputWrapper>
				<Popover
					opened={opened}
					top={top}
					left={left}
					onRequestClose={handleBlur}
					withBackdrop={false}
				>
					<OpenedSearchBarWrapper style={{ width }}>
						<SearchInputWrapper>
							<SearchBarIconWrapper>
								<SearchBarIcon path={leftIcon} />
							</SearchBarIconWrapper>
							<SearchInput
								ref={(input) => {
									setTimeout(() => {
										input && input.focus();
									}, 0);
								}}
								value={
									focusedSuggestion !== null &&
									suggestions[focusedSuggestion]
										? suggestions[focusedSuggestion].name
										: searchText
								}
								placeholder={placeholder}
								onKeyDown={handleKeyDown}
								onChange={handleInput}
							/>
							{children}
						</SearchInputWrapper>
						{showSuggestions ? (
							<>
								<Separator />
								<SearchSuggestionsWrapper
									style={{
										height:
											Math.min(suggestions.length, 10) *
											36,
									}}
								>
									<Scrollbars>
										{suggestions.map(
											({ name, value, icon }, index) => (
												<SearchSuggestion
													key={index}
													fallbackIcon={leftIcon}
													focused={
														index ===
														focusedSuggestion
													}
													icon={icon}
													index={index}
													name={name}
													onSelect={handleSelect}
													value={value}
												/>
											)
										)}
									</Scrollbars>
								</SearchSuggestionsWrapper>
							</>
						) : null}
					</OpenedSearchBarWrapper>
				</Popover>
			</SearchBarWrapper>
		),
		[
			opened,
			searchText,
			top,
			left,
			leftIcon,
			width,
			placeholder,
			suggestions,
			focusedSuggestion,
			handleInput,
			handleKeyDown,
			showSuggestions,
			handleSelect,
			handleFocus,
			handleBlur,
			children,
		]
	);
}

import { Map, List } from 'immutable';
import { ReactNode, RefObject } from 'react';
import { MaximizeType } from '../../interfaces';

export interface DesktopWindowState {
	left: number;
	top: number;
	width: number;
	height: number;
	maximized: MaximizeType;
	minimized: boolean;
	originalLeft: number | null;
	originalTop: number | null;
	originalWidth: number | null;
	originalHeight: number | null;
}

export interface DesktopProps {
	width: number;
	height: number;
	windows: Map<string, DesktopWindowState>;
	windowIndice: List<string>;
	children: ReactNode;
	onWindowMoveStart?: (windowId: string) => any;
	onWindowMoveEnd?: (windowId: string) => any;
	onWindowResizeStart?: (windowId: string) => any;
	onWindowResizeEnd?: (windowId: string) => any;
	onWindowChange?: (
		windowId: string,
		windowState: Partial<DesktopWindowState>
	) => any;
	onWindowFocus?: (windowId: string) => any;
}

export interface DesktopContext {
	containerRef: RefObject<HTMLDivElement>;
	windows: Map<string, DesktopWindowState>;
	windowIndice: List<string>;
	desktopWidth: number;
	desktopHeight: number;
	onWindowMoveStart?: (windowId: string) => any;
	onWindowMoveEnd?: (windowId: string) => any;
	onWindowResizeStart?: (windowId: string) => any;
	onWindowResizeEnd?: (windowId: string) => any;
	onWindowChange?: (
		windowId: string,
		windowState: Partial<DesktopWindowState>
	) => any;
	onWindowFocus?: (windowId: string) => any;
	setTilePreview: React.Dispatch<React.SetStateAction<TilePreviewState>>;
}

export interface DesktopWindowProps {
	windowId: string;
	children: ReactNode;
}

export interface DesktopWindowContext extends DesktopWindowState {
	windowId: string;
	parentWidth: number;
	parentHeight: number;
	onChange: (windowState: Partial<DesktopWindowState>) => any;
	onMoveStart: () => any;
}

export interface TilePreviewState {
	x: number;
	y: number;
	edge: DesktopEdge;
}

export enum DesktopEdge {
	TOP,
	LEFT,
	RIGHT,
	NULL,
}

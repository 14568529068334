import { useState, useCallback } from 'react';

export function useBooleanState(
	initialValue: boolean
): [boolean, () => void, () => void] {
	const [state, setState] = useState(initialValue);

	const setTrue = useCallback(() => setState(true), []);
	const setFalse = useCallback(() => setState(false), []);

	return [state, setTrue, setFalse];
}

import { ListChildComponentProps } from 'react-window';
import { useContext, useMemo, useCallback } from 'react';
import notificationContext from '../context';

export default function useNotificationItem(props: ListChildComponentProps) {
	const { index } = props;
	const {
		notificationIds,
		notifications,
		onMarkNotificationRead,
	} = useContext(notificationContext);
	const notificationId = useMemo(() => notificationIds.toList().get(index), [
		notificationIds,
		index,
	]);
	const notification = useMemo(
		() => (notificationId ? notifications.get(notificationId, null) : null),
		[notifications, notificationId]
	);

	const handleMarkRead = useCallback(() => {
		onMarkNotificationRead &&
			notificationId &&
			onMarkNotificationRead(notificationId);
	}, [onMarkNotificationRead, notificationId]);

	return {
		notificationId,
		notification,
		handleMarkRead,
	};
}

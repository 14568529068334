import styled from 'styled-components';
import { View } from '../../../../Common/View';
import { LAUNCHER_SIZE, EXPANDED_DOCK_SIZE } from '../constants';
import { TransitionStatus } from 'react-transition-group/Transition';

export const DockWrapper = styled(View)`
	overflow: visible;
	align-self: stretch;
	flex: 0 0 ${LAUNCHER_SIZE}px;
	width: ${LAUNCHER_SIZE}px;
	z-index: 100;
	background-color: ${({
		theme: {
			background: { frameDarker },
		},
	}) => frameDarker};
`;

export interface WrapperProps {
	status: TransitionStatus;
}

export const ExpandedDockWrapper = styled(View)<WrapperProps>`
	align-self: stretch;
	/* position: absolute; */
	overflow: hidden;

	flex: 0 0
		${({ status }) =>
			status === 'entered' ? EXPANDED_DOCK_SIZE : LAUNCHER_SIZE}px;

	background-color: ${({
		theme: {
			background: { frameDarker },
		},
	}) => frameDarker};

	flex-direction: column;

	transition: all 100ms;

	${({ theme: { shadow }, status }) =>
		status === 'entered' ? shadow[2] : ''}
`;

export const LaunchersWrapper = styled(View)`
	flex: 1;
	flex-direction: column;
	overflow: hidden;
`;

export const Separator = styled(View)`
	align-self: stretch;
	flex: 0 0 1px;
	height: 1px;
	background-color: ${({
		theme: {
			background: { frameHighlight },
		},
	}) => frameHighlight};
`;

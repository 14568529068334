import { ActionCreator, ActionWithPayload } from './createActionCreator';
import { Reducer, AnyAction } from 'redux';

type Handler<S, A> = (state: S, action: A) => S;

export default function createReducer<
	S,
	AD extends { [key: string]: ActionCreator<any, any> }
>(
	initialState: S,
	actionCreatorDict: AD,
	handerDict: {
		[K in keyof AD]: AD[K] extends ActionCreator<infer T, infer P>
			? Handler<S, ActionWithPayload<T, P>>
			: any;
	}
): Reducer<S, AnyAction> {
	type ActionType = keyof {
		[K in keyof AD]: AD[K] extends ActionCreator<infer T, any> ? T : never;
	};

	const handlers = Object.keys(actionCreatorDict).reduce((r, k) => {
		const handlerKey = k as keyof typeof handerDict;
		return {
			...r,
			[actionCreatorDict[handlerKey].type]: handerDict[handlerKey],
		};
	}, {}) as {
		[T in ActionType]: T extends string
			? Handler<S, ActionWithPayload<T, any>>
			: never;
	};

	return function reducer(
		state: S | undefined = initialState,
		action: AnyAction
	) {
		const { type } = action;
		const handlerKey = type as keyof typeof handlers;
		const handler = handlers[handlerKey];

		let newState = state;
		if (handler)
			newState = handler(state, action as ActionWithPayload<any, any>);

		return newState;
	};
}

import styled from 'styled-components';
import { View } from '../../../../Common/View';
import { CaptionText, BodyText1 } from '../../../../Common/Text';
import LoadingSpinner, {
	LoadingSpinnerProps,
} from '../../../../Common/LoadingSpinner';
import {
	NOTIFICATION_PANEL_WIDTH,
	NOTIFICATION_ITEM_HEIGHT,
} from '../constants';

export const NotificationListWrapper = styled(View)`
	align-items: stretch;
	border-radius: 8px;
	overflow: hidden;

	width: ${NOTIFICATION_PANEL_WIDTH}px;

	background-color: ${({
		theme: {
			background: { light },
		},
	}) => light};

	${({ theme: { shadow } }) => shadow[2]};
`;

export const NotificationLoadingSpinner = styled(LoadingSpinner).attrs<
	{},
	LoadingSpinnerProps
>(({ theme: { background: { accent, grey } } }) => ({
	lowerColor: grey,
	upperColor: accent,
}))``;

export const NotificaitonButtonWrapper = styled(View)`
	height: 24px;
	background-color: ${({
		theme: {
			background: { grey },
		},
	}) => grey};
	align-items: center;
	justify-content: center;
	cursor: pointer;

	color: ${({
		theme: {
			font: {
				color: { lightBgSecondary },
			},
		},
	}) => lightBgSecondary};
	&:hover {
		color: ${({
			theme: {
				font: {
					color: { lightBgPrimary },
				},
			},
		}) => lightBgPrimary};
		background-color: ${({
			theme: {
				background: { darker },
			},
		}) => darker};
	}

	transition: all 0.2s;
`;

export const ReadAllText = styled(CaptionText)``;

export const NoMessageWrapper = styled(View)`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 24px;
	align-items: center;
	justify-content: center;
`;
export const NoMessageText = styled(CaptionText)`
	color: ${({
		theme: {
			font: {
				color: { lightBgDisabled },
			},
		},
	}) => lightBgDisabled};
`;

export const WarningWrapper = styled(View)`
	flex-direction: row;
	height: ${NOTIFICATION_ITEM_HEIGHT + 8}px;
	align-items: center;
	padding: 0 8px;
	border-left: 6px solid ${({ theme }) => theme.background.accent};
	&:hover {
		background: ${({ theme }) => theme.background.darken[1]};
	}
`;
export const WarningIcon = styled.img`
	width: 48px;
	height: 48px;
	margin-right: 8px;
`;
export const WarningText = styled(BodyText1)`
	color: ${({
		theme: {
			font: {
				color: { lightBgSecondary },
			},
		},
	}) => lightBgSecondary};
`;

import { Bound } from '../interfaces';

export default function isWithinBound(
	bound: Bound,
	x: number,
	y: number
): boolean {
	const { x1, y1, x2, y2 } = bound;
	if (x > x1 && x < x2 && y > y1 && y < y2) return true;
	return false;
}

import React, { useMemo, useContext } from 'react';
import { VariableSizeGrid } from 'react-window';

import HeaderInnerWrapper from './HeaderInnerWrapper';
import HeaderCell from './HeaderCell';
import context from '../../../context';
import constants from '../../../constants';

const { HEADER_HEIGHT, INDEX_WIDTH } = constants;

const getHeaderHeight = () => HEADER_HEIGHT;

interface HeaderGridProps {
	columnWidth(index: number): number;
}

function HeaderGrid(props: HeaderGridProps) {
	const { columnWidth } = props;
	const { headerGridRef, tableWidth, columnKeys } = useContext(context);
	const columnCount = columnKeys.size;

	return useMemo(() => {
		const headerWidth = tableWidth - INDEX_WIDTH;
		return (
			<VariableSizeGrid
				style={{
					overflow: 'hidden',
					marginLeft: INDEX_WIDTH,
				}}
				ref={headerGridRef}
				innerElementType={HeaderInnerWrapper}
				rowCount={1}
				columnCount={columnCount}
				rowHeight={getHeaderHeight}
				columnWidth={columnWidth}
				width={headerWidth}
				height={HEADER_HEIGHT}
			>
				{HeaderCell}
			</VariableSizeGrid>
		);
	}, [headerGridRef, tableWidth, columnCount, columnWidth]);
}

export default HeaderGrid;

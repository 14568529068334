import React, { useMemo, forwardRef, Ref } from 'react';
import {
	ModalWrapper,
	ModalTitleWrapper,
	ModalTitleText,
	ModalCloseButton,
} from './Modal.sc';
import useObject from '../../../../Hooks/useObject';
import { ModalProps } from '../interfaces';

const Modal = forwardRef(function(props: ModalProps, ref: Ref<HTMLDivElement>) {
	const {
		title,
		withCloseButton = false,
		render: Content,
		children,
		...restProps
	} = props;
	const _restProps = useObject(restProps);

	const _children = useMemo(() => {
		const { style, className, ...childrenProps } = _restProps;
		const handlePress = childrenProps.onRequestClose;
		return (
			<>
				{title ? (
					<ModalTitleWrapper x-component="title">
						<ModalTitleText>{title}</ModalTitleText>
						{withCloseButton ? (
							<ModalCloseButton
								onPress={handlePress}
								x-component="closebutton"
							/>
						) : null}
					</ModalTitleWrapper>
				) : null}
				{Content ? (
					<Content {...childrenProps} x-component="content" />
				) : (
					children
				)}
			</>
		);
	}, [title, withCloseButton, _restProps, children, Content]);

	return useMemo(() => {
		return (
			<ModalWrapper ref={ref} edgeDodging={false} {..._restProps}>
				{_children}
			</ModalWrapper>
		);
	}, [ref, _restProps, _children]);
});

export default Modal;

import { useCallback, useContext } from 'react';
import { DesktopWindowState } from '../interfaces';
import desktopContext from '../context';
import { MaximizeType, Movement } from '../../../interfaces';

export default function useMoveRestore(
	windowId: string,
	windowState: DesktopWindowState
) {
	const {
		left,
		top,
		width,
		originalWidth,
		originalHeight,
		maximized,
	} = windowState;

	const { containerRef, onWindowChange } = useContext(desktopContext);

	const containerElm = containerRef.current;

	const { left: desktopLeft, top: desktopTop } = containerElm
		? containerElm.getBoundingClientRect()
		: { left: 0, top: 0 };

	return useCallback(
		(movement: Movement) => {
			if (maximized !== MaximizeType.REGULAR) {
				const { e } = movement;
				if (!e) return;
				const { clientX, clientY } = e;

				const windowOffsetX = originalWidth
					? clientX -
					  desktopLeft -
					  ((clientX - desktopLeft - left) / width) * originalWidth
					: 0;
				const windowOffsetY = clientY - desktopTop - top - 12;

				onWindowChange &&
					onWindowChange(windowId, {
						left: windowOffsetX,
						top: windowOffsetY,
						maximized: MaximizeType.REGULAR,
						width: originalWidth || 0,
						height: originalHeight || 0,
						originalLeft: null,
						originalTop: null,
						originalWidth: null,
						originalHeight: null,
					});
			}
		},
		[
			maximized,
			windowId,
			onWindowChange,
			originalWidth,
			originalHeight,
			desktopLeft,
			desktopTop,
			left,
			top,
			width,
		]
	);
}

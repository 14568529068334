import React, {
	useMemo,
	useCallback,
	useContext,
	forwardRef,
	Ref,
	ChangeEvent as RChangeEvent,
	KeyboardEvent as RKeyboardEvent,
} from 'react';
import isEqual from 'lodash.isequal';
import { RecordOf } from 'immutable';
import { FilterType, DataType, IFormat } from '../../../interfaces';
import {
	HeaderCellFilterInputWrapper,
	HeaderCellFilterBaseInput,
	HeaderCellFilterBooleanCheckbox,
	HeaderCellFilterDatePicker,
	HeaderCellFilterDateRangePicker,
} from './HeaderCellFilterInput.sc';
import {
	useColumn,
	useColumnFilterValueUpdate,
	useColumnFilterValueDrop,
} from '../../../Hooks/Column';
import {
	stringifyRecord,
	formatRecord,
	parseRecord,
} from '../../../Helpers/Record';
import useAsState from '../../../../../Hooks/useAsState';
import useObject from '../../../../../Hooks/useObject';
import { useImmutable } from '../../../../../Hooks/useImmutable';
import stopPropagation from '../../../../../Helpers/stopPropagation';
import context from '../../../context';

interface HeaderFilterInputProps {
	columnKey: string;
	focused: boolean;
}

interface BaseInputProps {
	filterValue: any;
	dataType: DataType;
	format: RecordOf<IFormat>;
	focused: boolean;
	disabled: boolean;
	updateColumnFilterValue(value: any): void;
	dropColumnFilterValue(): void;
}

const BaseInput = forwardRef(function(
	props: BaseInputProps,
	ref: Ref<HTMLInputElement>
) {
	const {
		filterValue,
		dataType,
		format,
		focused,
		disabled,
		updateColumnFilterValue,
		dropColumnFilterValue,
	} = props;

	const filterValueStr = stringifyRecord(filterValue);
	const [_filterValueStr, setFilterValueStr] = useAsState(filterValueStr);

	const handleChange = useCallback(
		(e: RChangeEvent<HTMLInputElement>) => {
			const newValueStr = e.target.value;
			setFilterValueStr(newValueStr);
			if (dataType.type === 'string') {
				try {
					const parsed = parseRecord(newValueStr, dataType);
					if (!isEqual(parsed, filterValue))
						updateColumnFilterValue(parsed);
				} catch (e) {
					setFilterValueStr(filterValueStr);
				}
			}
		},
		[
			dataType,
			filterValue,
			filterValueStr,
			setFilterValueStr,
			updateColumnFilterValue,
		]
	);

	const handleKeyDown = useCallback(
		(e: RKeyboardEvent<HTMLInputElement>) => {
			if (
				!e.ctrlKey &&
				dataType.type === 'number' &&
				/^[a-z]$/i.test(e.key)
			)
				e.preventDefault();
		},
		[dataType]
	);

	const handleBlur = useCallback(() => {
		if (_filterValueStr === '') dropColumnFilterValue();
		else {
			try {
				const parsed = parseRecord(_filterValueStr, dataType);
				if (!isEqual(parsed, filterValue))
					updateColumnFilterValue(parsed);
			} catch (e) {
				setFilterValueStr(filterValueStr);
			}
		}
	}, [
		_filterValueStr,
		setFilterValueStr,
		filterValueStr,
		filterValue,
		dataType,
		dropColumnFilterValue,
		updateColumnFilterValue,
	]);

	return useMemo(() => {
		// console.log('render HeaderFilterBaseInput');
		let __filterValueStr = _filterValueStr;
		try {
			if (!focused)
				__filterValueStr = formatRecord(
					filterValueStr,
					dataType,
					format
				);
		} catch (e) {
			__filterValueStr = '';
		}

		return (
			<HeaderCellFilterBaseInput
				ref={ref}
				tabIndex={0}
				value={__filterValueStr}
				disabled={disabled}
				placeholder={'Criteria'}
				onMouseDown={stopPropagation}
				onKeyDown={handleKeyDown}
				onChange={handleChange}
				onBlur={handleBlur}
			/>
		);
	}, [
		ref,
		focused,
		disabled,
		_filterValueStr,
		filterValueStr,
		dataType,
		format,
		handleKeyDown,
		handleChange,
		handleBlur,
	]);
});

interface BooleanCheckboxProps {
	filterValue?: boolean | null;
	updateColumnFilterValue(value?: boolean | null): void;
}

function BooleanCheckbox(props: BooleanCheckboxProps) {
	const { filterValue, updateColumnFilterValue } = props;

	const handlePress = useCallback(() => {
		updateColumnFilterValue(!filterValue);
	}, [updateColumnFilterValue, filterValue]);

	return useMemo(() => {
		return (
			<HeaderCellFilterBooleanCheckbox
				tabIndex={0}
				checked={filterValue}
				onMouseDown={stopPropagation}
				onPress={handlePress}
			/>
		);
	}, [filterValue, handlePress]);
}

interface DatePickerProps {
	dateFormat: string;
	filterValue?: string | null;
	updateColumnFilterValue(value?: string | null): void;
	dropColumnFilterValue(): void;
}

function DatePicker(props: DatePickerProps) {
	const {
		dateFormat,
		filterValue,
		updateColumnFilterValue,
		dropColumnFilterValue,
	} = props;

	const handleChange = useCallback(
		(date: Date | null) => {
			const newFilterValue = date ? date.toISOString() : null;
			if (newFilterValue !== filterValue)
				updateColumnFilterValue(newFilterValue);
			else dropColumnFilterValue();
		},
		[filterValue, updateColumnFilterValue, dropColumnFilterValue]
	);

	const datePickerPanelProps = useMemo(() => {
		return {
			shouldCloseOnSelect: false,
			dateFormat,
			onChange: handleChange,
		};
	}, [dateFormat, handleChange]);

	return useMemo(() => {
		return (
			<HeaderCellFilterDatePicker
				tabIndex={0}
				date={filterValue}
				panelProps={datePickerPanelProps}
				onMouseDown={stopPropagation}
			/>
		);
	}, [filterValue, datePickerPanelProps]);
}

interface DateRangePickerProps {
	dateFormat: string;
	filterValue?: [string | null | undefined, string | null | undefined];
	updateColumnFilterValue(
		value?: [string | null | undefined, string | null | undefined]
	): void;
	dropColumnFilterValue(): void;
}

function DateRangePicker(props: DateRangePickerProps) {
	const {
		dateFormat,
		filterValue,
		updateColumnFilterValue,
		dropColumnFilterValue,
	} = props;

	const handleChange = useCallback(
		(date: [Date | null | undefined, Date | null | undefined]) => {
			const newFilterValue = date.map((date) =>
				date ? date.toISOString() : null
			) as [string | null, string | null];
			if (
				!filterValue ||
				newFilterValue[0] !== filterValue[0] ||
				newFilterValue[1] !== filterValue[1]
			)
				updateColumnFilterValue(newFilterValue);
			else dropColumnFilterValue();
		},
		[filterValue, updateColumnFilterValue, dropColumnFilterValue]
	);

	const datePickerPanelProps = useMemo(() => {
		return {
			shouldCloseOnSelect: false,
			dateFormat,
			onChange: handleChange,
		};
	}, [dateFormat, handleChange]);

	return useMemo(() => {
		return (
			<HeaderCellFilterDateRangePicker
				tabIndex={0}
				dateRange={filterValue}
				panelProps={datePickerPanelProps}
				onMouseDown={stopPropagation}
			/>
		);
	}, [filterValue, datePickerPanelProps]);
}

const HeaderFilterInput = forwardRef(function(
	props: HeaderFilterInputProps,
	ref: Ref<HTMLInputElement>
) {
	const { columnKey, focused } = props;
	const contextObj = useContext(context);
	const { filter, dataType, format } = useColumn(contextObj, columnKey);

	const filterType = filter.type;
	const filterValue = filter.values.get(filterType);

	const _dataType = useObject(dataType);
	const _inputDataType: DataType = useObject(
		filterType === FilterType.between
			? { type: 'array', items: _dataType }
			: _dataType
	);
	const _format = useImmutable(format);

	const updateColumnFilterValue = useColumnFilterValueUpdate(
		contextObj,
		columnKey
	);
	const dropColumnFilterValue = useColumnFilterValueDrop(
		contextObj,
		columnKey
	);

	return useMemo(() => {
		const { dateFormat } = _format;
		return (
			<HeaderCellFilterInputWrapper>
				{_dataType.type === 'boolean' ? (
					<BooleanCheckbox
						filterValue={filterValue}
						updateColumnFilterValue={updateColumnFilterValue}
					/>
				) : _dataType.type === 'date' &&
				  filterType === FilterType.between ? (
					<DateRangePicker
						dateFormat={dateFormat}
						filterValue={filterValue}
						updateColumnFilterValue={updateColumnFilterValue}
						dropColumnFilterValue={dropColumnFilterValue}
					/>
				) : _dataType.type === 'date' &&
				  filterType !== FilterType.between ? (
					<DatePicker
						dateFormat={dateFormat}
						filterValue={filterValue}
						updateColumnFilterValue={updateColumnFilterValue}
						dropColumnFilterValue={dropColumnFilterValue}
					/>
				) : null}
				<BaseInput
					ref={ref}
					filterValue={filterValue}
					dataType={_inputDataType}
					format={_format}
					focused={focused}
					disabled={filterType === FilterType.none}
					updateColumnFilterValue={updateColumnFilterValue}
					dropColumnFilterValue={dropColumnFilterValue}
				/>
			</HeaderCellFilterInputWrapper>
		);
	}, [
		ref,
		filterType,
		filterValue,
		_dataType,
		_inputDataType,
		_format,
		focused,
		updateColumnFilterValue,
		dropColumnFilterValue,
	]);
});

export default HeaderFilterInput;

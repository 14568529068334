import {
	HTMLAttributes,
	MouseEvent as RMouseEvent,
	KeyboardEvent as RKeyboardEvent,
	ReactNode,
	ComponentType,
} from 'react';

export enum ButtonOverlayShape {
	none = 'none',
	circle = 'circle',
	rectangle = 'rectangle',
}

export interface ButtonProps extends HTMLAttributes<HTMLDivElement> {
	lightBg?: boolean;
	hovered?: boolean;
	focused?: boolean;
	activated?: boolean;
	pressed?: boolean;
	disabled?: boolean;
	overlayShape?: ButtonOverlayShape;
	focusOnMouseDown?: boolean;
	onPress?(
		e:
			| RMouseEvent<HTMLElement>
			| RKeyboardEvent<HTMLElement>
			| KeyboardEvent
	): void;
	render?: ComponentType<ButtonChildrenProps>;
	children?: ReactNode;
}

export interface ButtonChildrenProps extends ButtonProps {
	hovered: boolean;
	focused: boolean;
	activated: boolean;
	pressed: boolean;
	disabled: boolean;
}

import { useCallback, MutableRefObject } from 'react';
import { dropUnmergedRecords } from '../../../Store/actions';
import { getRecordId } from '../../../Helpers/Record';
import { getColumnKey } from '../../../Helpers/Column';
import { ITableContext } from '../../../context';

export default function useSelectedUnmergedRecordsDrop(
	contextObjRef: MutableRefObject<ITableContext>
) {
	return useCallback(() => {
		const contextObj = contextObjRef.current;
		const { selected, onRequestDispatch } = contextObj;
		if (!selected) return;
		const {
			start: [startRowIndex, startColumnIndex],
			end: [endRowIndex, endColumnIndex],
		} = selected;
		const dropKeys: { recordId: string; columnKey: string }[] = [];
		for (let i = startRowIndex; i <= endRowIndex; ++i) {
			const recordId = getRecordId(contextObj, i);
			for (let j = startColumnIndex; j <= endColumnIndex; ++j) {
				const columnKey = getColumnKey(contextObj, j);
				dropKeys.push({ recordId, columnKey });
			}
		}
		onRequestDispatch(dropUnmergedRecords({ keys: dropKeys }));
	}, [contextObjRef]);
}

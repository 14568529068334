export default function moveWindow(
	position: { x: number; y: number },
	movement: { dx: number; dy: number },
	width: number,
	height: number,
	parentWidth: number,
	parentHeight: number
) {
	const { x, y } = position;
	const { dx, dy } = movement;
	const _x = x + dx;
	const _y = y + dy;
	return {
		x: _x,
		y: _y,
		left: Math.max(Math.min(parentWidth - width, _x), 0),
		top: Math.max(Math.min(parentHeight - height, _y), 0),
	};
}

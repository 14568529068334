import React, { useContext, useMemo, useEffect } from 'react';
import {
	LoadingModalContentWrapper,
	SLoadingSpinner,
	LoadingText,
} from './LoadingModal.sc';
import Modal from '../../../../Common/Modal';
import { usePopoverAtCenter } from '../../../../Common/Popover';
import context from '../../../context';

function LoadingModal() {
	const { tableRef, loading } = useContext(context);
	const { open, close, getPopoverProps } = usePopoverAtCenter({
		containerRef: tableRef,
	});
	useEffect(() => {
		if (loading) open();
		else close();
	}, [loading, open, close]);

	return useMemo(() => {
		return (
			<Modal
				containerRef={tableRef}
				{...getPopoverProps()}
				closeOnMouseDownOutside={false}
			>
				<LoadingModalContentWrapper>
					<SLoadingSpinner />
					<LoadingText>Loading...</LoadingText>
				</LoadingModalContentWrapper>
			</Modal>
		);
	}, [tableRef, getPopoverProps]);
}

export default LoadingModal;

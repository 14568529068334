import Color from 'color';
import { createMuiTheme } from '@material-ui/core/styles';

const baseTheme = {
	color: {
		primary: '#55CC5C',
		accent: '#F4FA58',
		frame: '#2f2f2f',
		error: '#CB2831',
		success: '#4CAF50',
		primaryAlpha: {
			1: 'rgba(85, 204, 92, .12)',
			2: 'rgba(85, 204, 92, .24)',
			3: 'rgba(85, 204, 92, .36)',
			4: 'rgba(85, 204, 92, .48)',
			5: 'rgba(85, 204, 92, .60)',
			6: 'rgba(85, 204, 92, .72)',
			7: 'rgba(85, 204, 92, .84)',
			8: 'rgba(85, 204, 92, .96)',
		},
		rainbow: {
			1: '#FF0000',
			2: '#FF8000',
			3: '#FFFF00',
			4: '#80FF00',
			5: '#00FF00',
			6: '#00FF80',
			7: '#00FFFF',
			8: '#0080FF',
			9: '#0000FF',
			10: '#8000FF',
		},
		rainbowAlt: {
			1: '#FF3333',
			2: '#FF8033',
			3: '#FFFF33',
			4: '#80FF33',
			5: '#33FF33',
			6: '#22EEAA',
			7: '#33FFFF',
			8: '#3380FF',
			9: '#3300FF',
			10: '#8033FF',
		},
		rainbowShade: {
			1: '#F78181',
			2: '#FAAC58',
			3: '#F4FA58',
			4: '#ACFA58',
			5: '#58FA58',
			6: '#58FAAC',
			7: '#58FAF4',
			8: '#58ACFA',
			9: '#5858FA',
			10: '#AC58FA',
		},
	},
};

const theme = {
	shadow: {
		1: 'box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);',
		2: 'box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);',
		3: 'box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);',
		4: 'box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);',
		5: 'box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);',
	},
	background: {
		frame: baseTheme.color.frame,
		frameHighlight: Color(baseTheme.color.frame).lighten(0.5).toString(),
		frameDarker: Color(baseTheme.color.frame).darken(0.5).toString(),
		light: '#ffffff',
		grey: '#eeeeee',
		darker: '#dddddd',
		accent: baseTheme.color.primary,
		accentDarker: Color(baseTheme.color.primary).darken(0.2).toString(),
		contrast: baseTheme.color.accent,
		lighten: {
			1: 'rgba(255, 255, 255, .12)',
			2: 'rgba(255, 255, 255, .24)',
			3: 'rgba(255, 255, 255, .36)',
			4: 'rgba(255, 255, 255, .48)',
			5: 'rgba(255, 255, 255, .60)',
			6: 'rgba(255, 255, 255, .72)',
			7: 'rgba(255, 255, 255, .84)',
			8: 'rgba(255, 255, 255, .96)',
		},
		primaryAlpha: baseTheme.color.primaryAlpha,
		rainbowShade: baseTheme.color.rainbowShade,
		rainbow: baseTheme.color.rainbow,
		rainbowAlt: baseTheme.color.rainbowAlt,
		success: baseTheme.color.success,
		error: baseTheme.color.error,
		darken: {
			1: 'rgba(0, 0, 0, .12)',
			2: 'rgba(0, 0, 0, .24)',
			3: 'rgba(0, 0, 0, .36)',
			4: 'rgba(0, 0, 0, .48)',
			5: 'rgba(0, 0, 0, .60)',
			6: 'rgba(0, 0, 0, .72)',
			7: 'rgba(0, 0, 0, .84)',
			8: 'rgba(0, 0, 0, .96)',
		},
	},
	font: {
		size: {
			base: 0.0625,
		},
		color: {
			primaryAlpha: baseTheme.color.primaryAlpha,
			rainbow: baseTheme.color.rainbow,
			rainbowShade: baseTheme.color.rainbowShade,
			primary: baseTheme.color.primary,
			lightBgPrimary: 'rgba(0,0,0,.87)',
			lightBgSecondary: 'rgba(0,0,0,.60)',
			lightBgDisabled: 'rgba(0,0,0,.38)',
			lightBgWatermark: 'rgba(0,0,0,.38)',
			darkBgPrimary: 'rgba(255,255,255,.87)',
			darkBgSecondary: 'rgba(255,255,255,.60)',
			darkBgDisabled: 'rgba(255,255,255,.38)',
			darkBgWatermark: 'rgba(255,255,255,.38)',
			error: baseTheme.color.error,
		},
		weight: {
			regular: 400,
			medium: 500,
			light: 300,
		},
	},
};

export default theme;
export type Theme = typeof theme;

export const muiTheme = createMuiTheme({
	typography: {
		fontFamily: "'Noto Sans TC', Roboto, sans-serif, Arial",
	},
	palette: {
		primary: {
			main: '#55CC5C',
		},
		secondary: {
			main: '#2f2f2f',
		},
	},
});
